'use strict';
(function () {
    var app = angular.module('app.fourLevel', [])
        // 社区四级分类
        .controller('FourLevel_Controller', ['$scope', '$state', '$http', '$stateParams', '$location', 'util',
            function ($scope, $state, $http, $stateParams, $location, util) {
                console.log('FourLevel_Controller')
                var self = this;
                self.info = []; // 分类＋分类下的图文信息
                self.cateIndex; // 当前选中分类index
                self.secondIndex;// 当前选中二级分类index
                self.thirdIndex; //当前选中三级分类index
                self.thirdCategoryId = ''
                self.init = function () {
                    self.viewId = $stateParams.moduleId;
                    self.defaultLangCode = util.getDefaultLangCode();
                    self.loadInfo();
                }

                /**
                 * 添加该模版的图文分类
                 *
                 * @method addCategory
                 */
                self.addCategory = function (isLeaf, PID, level, type) {
                    console.log(isLeaf, PID, level)
                    $scope.app.maskParams.viewId = self.viewId;
                    $scope.app.maskParams.addParam = { isLeaf: isLeaf, PID: PID, level: level, type: type };
                    $scope.app.maskParams.loadInfo = self.loadInfo;
                    $scope.app.showHideMask(true, 'pages/tv/PicTextClassCateAdd_Fujia_FourLevel.html');
                }

                /**
                 * 删除图文分类
                 *
                 * @method delCate
                 */
                self.delCate = function (isLeaf, id) {
                    if (!confirm('确认删除?')) {
                        return;
                    }

                    var data = JSON.stringify({
                        "token": util.getParams('token'),
                        "action": "delete",
                        "viewID": self.viewId,
                        "lang": util.langStyle(),
                        "data": {
                            "ID": id,
                            "isLeaf": isLeaf
                        }
                    });
                    self.cateDeleting = true;
                    $http({
                        method: 'POST',
                        url: util.getApiUrl('commonview', '', 'server'),
                        data: data
                    }).then(function successCallback(response) {
                        var data = response.data;
                        if (data.rescode == '200') {
                            self.loadInfo();
                        }
                        else {
                            alert('连接服务器出错' + data.errInfo);
                        }
                    }, function errorCallback(response) {
                        alert('连接服务器出错');
                    }).finally(function (value) {
                        self.cateDeleting = false;
                    });
                }

                /**
                 * 编辑该模版的图文分类
                 *
                 * @method editCate
                 */
                self.editCate = function (isLeaf, obj) {
                    obj.isLeaf = isLeaf
                    $scope.app.maskParams.viewId = self.viewId;
                    $scope.app.maskParams.info = obj;

                    $scope.app.maskParams.loadInfo = self.loadInfo;
                    $scope.app.showHideMask(true, 'pages/tv/PicTextClassCateEdit_Fujia_FourLevel.html');
                }

                /**
                 * 加载分类及分类下的信息
                 *
                 * @method loadInfo
                 */
                self.loadInfo = function () {
                    var data = JSON.stringify({
                        "token": util.getParams('token'),
                        "action": "get",
                        "viewID": self.viewId,
                        "lang": util.langStyle()
                    });
                    self.loading = true;
                    $http({
                        method: 'POST',
                        url: util.getApiUrl('commonview', '', 'server'),
                        data: data
                    }).then(function successCallback(response) {
                        var data = response.data;
                        if (data.rescode == '200') {
                            self.info = data.data.res;
                            console.log(self.info)
                            // 判断分类下内容为空时，sub属性为空数组，不然模板的ng-repeat会报错
                            // if(self.info[0].sub === undefined ){
                            //     self.info[0].sub = [];
                            // }
                            if (!self.cateIndex || (self.cateIndex + 1) > self.info.length) {
                                self.cateIndex = 0;
                            }
                            if (!self.secondIndex || (self.secondIndex + 1) > self.info.length) {
                                self.secondIndex = 0;
                            }
                            if (!self.thirdIndex || (self.thirdIndex + 1) > self.info.length) {
                                self.thirdIndex = 0;
                            }
                            if (self.info.length == 0) {
                                return;
                            }
                            // 默认去第一个 一级分类 下 第一个子分类
                            self.firstCategoryId = self.info[self.cateIndex]['ID'];
                            if (!self.info[self.cateIndex].sub) {
                                return;
                            }
                            self.secondCategoryId = self.info[self.cateIndex].sub[self.secondIndex]['ID'];
                            if (!self.info[self.cateIndex].sub[self.secondIndex]) {
                                return;
                            }
                            self.thirdCategoryId = self.info[self.cateIndex].sub[self.secondIndex].sub[self.thirdIndex]['ID'];


                        }
                        else {
                            alert('连接服务器出错' + data.errInfo);
                        }
                    }, function errorCallback(response) {
                        alert('连接服务器出错');
                    }).finally(function (value) {
                        self.loading = false;
                    });
                }

                /**
                 * 加载分类及分类下的信息
                 *
                 * @method loadInfo
                 * @param index 跳转分类在self.info中的index
                 */
                self.loadPics = function (index, ID) {
                    self.cateIndex = index;
                    // 一级分类的id
                    self.firstCategoryId = ID;

                    if (!self.info[index].sub) return
                    //默认先进入第一个二级分类
                    self.secondCategoryId = self.info[index].sub[0].ID;

                    if (!self.info[index].sub[0].sub) return

                    self.thirdCategoryId = self.info[index].sub[0].sub[0].ID;
                }
                self.loadSecondPics = function (index, ID) {
                    self.secondIndex = index;
                    //二级分类的id
                    self.secondCategoryId = ID;

                    if (!self.info[self.cateIndex].sub[index].sub) return

                    self.thirdCategoryId = self.info[self.cateIndex].sub[index].sub[0].ID;
                }
                self.loadThirdPics = function (index, ID) {
                    console.log(ID)
                    self.thirdIndex = index;
                    self.thirdCategoryId = ID;
                }

            }
        ])

        // 社区四级分类add(添加分类时出现的弹窗)
        .controller('FourLevel_Controller_Add', ['$timeout', '$scope', '$state', '$http', '$stateParams', '$location', 'util', 'CONFIG',
            function ($timeout, $scope, $state, $http, $stateParams, $location, util, CONFIG) {

                var self = this;
                self.viewId = 0;
                self.imgs1 = null;
                self.editLangs = util.getParams('editLangs');

                self.qaList = []

                self.init = function () {
                    self.activeImage = 0
                    self.imageList = [
                        {
                            name: 'icon1',
                            url: 'http://mres.cleartv.cn/default/a1c2f971eedd84c68c18d225f5dcce93_159704661755.png',
                            urlSize: 1536,
                            activeUrl: 'http://mres.cleartv.cn/default/e16cdd6a6fc950c0ab01e167e16f9394_159704887549.png',
                            activeUrlSize: 1094
                        },
                        {
                            name: 'icon2',
                            url: 'http://mres.cleartv.cn/default/1a068b571aa8160ebe217bed0617040f_159704664958.png',
                            urlSize: 1514,
                            activeUrl: 'http://mres.cleartv.cn/default/54e6566a7e7e859f32c959c9d0048f49_159704889743.png',
                            activeUrlSize: 999
                        },
                        {
                            name: 'icon3',
                            url: 'http://mres.cleartv.cn/default/8c49c8ceba264977f4d792c5aec8627c_159704666821.png',
                            urlSize: 2416,
                            activeUrl: 'http://mres.cleartv.cn/default/641ae5c697267eb5c7b0e9f15d8f3bce_159704891439.png',
                            activeUrlSize: 1565
                        },
                        {
                            name: 'icon2',
                            url: 'http://mres.cleartv.cn/default/b687a3b33b707fad9e68c748b5e887d7_159704668503.png',
                            urlSize: 3010,
                            activeUrl: 'http://mres.cleartv.cn/default/f2ad890ca89cdf2c31cbe17c33bec462_159704892833.png',
                            activeUrlSize: 1769
                        }

                    ]
                    self.viewId = $scope.app.maskParams.viewId;
                    self.addParam = $scope.app.maskParams.addParam;
                    console.log(self.addParam)
                    self.imgs1 = new Imgs([], true);
                    self.imgs2 = new Imgs([], true);
                    self.VoiceContent = ''

                    $timeout(function () {
                        var editor = tinymce.init({
                            skin_url: 'tinymce/skins/ui/oxide',
                            selector: '.mytextarea',
                            language: 'zh_CN',
                            language_url: 'tinymce/langs/zh_CN.js',
                            height: 500,
                            menubar: false,
                            plugins: [
                                'autolink lists link image preview anchor',
                                'code fullscreen',
                                'media table paste code help wordcount'
                            ],
                            toolbar: 'undo redo | formatselect | ' +
                                'image media |' +
                                'bold italic backcolor | alignleft aligncenter ' +
                                'alignright alignjustify | bullist numlist outdent indent | ' +
                                'removeformat | help',
                            images_upload_url: '',
                            images_upload_handler: function (blobInfo, success, failure) {
                                var form = new FormData();

                                form.append('action', 'normal');
                                form.append('file', blobInfo.blob());

                                $http({
                                    method: 'POST',
                                    url: CONFIG.uploadUrl,
                                    data: form,
                                    headers: { 'Content-Type': undefined },
                                    transformRequest: angular.identity
                                }).then(function successCallback(res) {
                                    console.log('upload success', res.data);
                                    success(res.data.upload_path)
                                }, function errorCallback(response) {
                                    failure('上传失败')
                                })
                            }
                        })
                    }, 0)

                }

                self.addQA = function (type) {
                    self.qaList.push({
                        type: type,
                        content: ''
                    })
                }

                self.removeQA = function (index) {
                    self.qaList.splice(index, 1)
                }

                /**
                 * 关闭弹窗
                 *
                 * @method cancel
                 */
                self.cancel = function () {
                    $scope.app.showHideMask(false);
                }

                self.getHtml = function () {
                    return tinymce.activeEditor.getContent()
                }

                self.setHtml = function (content) {
                    tinymce.activeEditor.setContent(content)
                }
                /**
                 * 新建分类提交
                 *
                 * @method save
                 */
                self.save = function () {
                    console.log('FourLevel_Controller_Add')
                    if (!(self.imgs1.data[0] && self.imgs1.data[0].src) && self.addParam.type === 'pic') {
                        alert('请上传图片');
                        return;
                    }


                    function getText() {
                        switch (self.addParam.type) {
                            case 'richText':
                                return self.getHtml()
                            case 'text':
                                return JSON.stringify(self.qaList)
                            default:
                                return ''
                        }
                    }

                    var params = self.addParam.level !== 4 ? {
                        "isLeaf": self.addParam.isLeaf,
                        "Level": self.addParam.level,
                        "PID": self.addParam.PID,
                        "PicURL": self.imgs1.data.length && self.imgs1.data[0].src || '',
                        "PicURLFocus": self.imgs2.data.length && self.imgs2.data[0].src || '',
                        "Text": self.title,
                        "Title": self.title,
                        "Seq": self.Seq,
                        "PicSize": self.imgs1.data.length && self.imgs1.data[0].fileSize || 0 - 0,
                        "PicFocusSize": (self.imgs2.data.length && self.imgs2.data[0].fileSize || 0) - 0
                        // "IconURL":self.imgs2.data[0].src,
                        // "IconSize":self.imgs2.data[0].fileSize-0
                    } : {
                            "isLeaf": self.addParam.isLeaf,
                            "PID": self.addParam.PID,
                            "URL": self.imgs1.data.length && self.imgs1.data[0].src || '',
                            "VoiceContent": self.VoiceContent,
                            "Text": getText(),
                            "Title": self.title,
                            "Seq": self.Seq,
                            "Type": self.addParam.type,
                            "Size": (self.imgs1.data.length && self.imgs1.data[0].fileSize || 0) - 0
                        }

                    if (self.addParam.level === 2) {
                        params.PicURL = self.imageList[self.activeImage].url
                        params.PicURLFocus = self.imageList[self.activeImage].activeUrl
                        params.PicSize = self.imageList[self.activeImage].urlSize
                        params.PicFocusSize = self.imageList[self.activeImage].activeUrlSize
                    }


                    var data = JSON.stringify({
                        "token": util.getParams('token'),
                        "action": "add",
                        "viewID": self.viewId - 0,
                        "lang": util.langStyle(),
                        "data": params

                    });

                    self.saving = true;

                    $http({
                        method: 'POST',
                        url: util.getApiUrl('commonview', '', 'server'),
                        data: data
                    }).then(function successCallback(response) {
                        var data = response.data;
                        if (data.rescode == '200') {
                            alert('添加成功');
                            $scope.app.maskParams.loadInfo();
                            self.cancel();
                        } else if (data.rescode == '401') {
                            alert('访问超时，请重新登录');
                            $state.go('login');
                        } else {
                            alert('添加失败，' + data.errInfo);
                        }
                    }, function errorCallback(response) {
                        alert('连接服务器出错');
                    }).finally(function (value) {
                        self.saving = false;
                    });


                }





                // 图片上传相关
                self.clickUpload = function (e) {
                    setTimeout(function () {
                        document.getElementById(e).click();
                    }, 0);
                }

                function Imgs(imgList, single) {
                    this.initImgList = imgList;
                    this.data = [];
                    this.maxId = 0;
                    this.single = single ? true : false;
                }

                Imgs.prototype = {
                    initImgs: function () {
                        var l = this.initImgList;
                        for (var i = 0; i < l.length; i++) {
                            this.data[i] = {
                                "src": l[i].ImageURL,
                                "fileSize": l[i].ImageSize,
                                "id": this.maxId++,
                                "progress": 100
                            };
                        }
                    },
                    deleteById: function (id) {
                        var l = this.data;
                        for (var i = 0; i < l.length; i++) {
                            if (l[i].id == id) {
                                // 如果正在上传，取消上传
                                if (l[i].progress < 100 && l[i].progress != -1) {
                                    l[i].xhr.abort();
                                }
                                l.splice(i, 1);
                                break;
                            }
                        }
                    },

                    add: function (xhr, fileName, fileSize) {
                        this.data.push({
                            "xhr": xhr,
                            "fileName": fileName,
                            "fileSize": fileSize,
                            "progress": 0,
                            "id": this.maxId
                        });
                        return this.maxId++;
                    },

                    update: function (id, progress, leftSize, fileSize) {
                        for (var i = 0; i < this.data.length; i++) {
                            var f = this.data[i];
                            if (f.id === id) {
                                f.progress = progress;
                                f.leftSize = leftSize;
                                f.fileSize = fileSize;
                                break;
                            }
                        }
                    },

                    setSrcSizeByXhr: function (xhr, src, size) {
                        for (var i = 0; i < this.data.length; i++) {
                            if (this.data[i].xhr == xhr) {
                                this.data[i].src = src;
                                this.data[i].fileSize = size;
                                break;
                            }
                        }
                    },

                    uploadFile: function (e, o) {

                        // 如果这个对象只允许上传一张图片
                        if (this.single) {
                            // 删除第二张以后的图片
                            for (var i = 1; i < this.data.length; i++) {
                                this.deleteById(this.data[i].id);
                            }
                        }

                        var file = $scope[e];
                        var uploadUrl = CONFIG.uploadUrl;
                        var xhr = new XMLHttpRequest();
                        var fileId = this.add(xhr, file.name, file.size, xhr);
                        // self.search();

                        util.uploadFileToUrl(xhr, file, uploadUrl, 'normal',
                            function (evt) {
                                $scope.$apply(function () {
                                    if (evt.lengthComputable) {
                                        var percentComplete = Math.round(evt.loaded * 100 / evt.total);
                                        o.update(fileId, percentComplete, evt.total - evt.loaded, evt.total);
                                        console && console.log(percentComplete);
                                    }
                                });
                            },
                            function (xhr) {
                                var ret = JSON.parse(xhr.responseText);
                                console && console.log(ret);
                                $scope.$apply(function () {
                                    o.setSrcSizeByXhr(xhr, ret.upload_path, ret.size);
                                    // 如果这个对象只允许上传一张图片
                                    if (o.single) {
                                        // 如果长度大于1张图片，删除前几张图片
                                        if (o.data.length > 1) {
                                            for (var i = 0; i < o.data.length - 1; i++) {
                                                o.deleteById(o.data[i].id);
                                            }
                                        }
                                    }
                                });
                            },
                            function (xhr) {
                                $scope.$apply(function () {
                                    o.update(fileId, -1, '', '');
                                });
                                console && console.log('failure');
                                xhr.abort();
                            }
                        );
                    }
                }

            }
        ])

        // 社区四级分类Edit(编辑分类时出现的弹窗)
        .controller('FourLevel_Controller_Edit', ['$timeout', '$scope', '$state', '$http', '$stateParams', '$location', 'util', 'CONFIG',
            function ($timeout, $scope, $state, $http, $stateParams, $location, util, CONFIG) {
                var self = this;
                self.viewId = 0;
                self.imgs1 = null;
                self.imgs2 = null;
                self.Text = ''
                self.Title = ''
                self.qaList = []
                self.editLangs = util.getParams('editLangs');



                self.init = function () {
                    self.viewId = $scope.app.maskParams.viewId;
                    self.info = $scope.app.maskParams.info;
                    self.addParam = $scope.app.maskParams.info;
                    console.log($scope.app.maskParams.info);
                    self.Seq = self.info.Seq;
                    self.title = self.info.Title;
                    self.text = self.info.Text;
                    console.log($scope.app.maskParams.info);
                    self.imgs1 = new Imgs([{ "ImageURL": self.info.PicURL || self.info.URL, "ImageSize": self.info.PicSize || self.info.Size }], true);
                    self.imgs1.initImgs();
                    self.imgs2 = new Imgs([{ "ImageURL": self.info.PicURLFocus, "ImageSize": self.info.PicFocusSize }], true);
                    self.imgs2.initImgs();

                    self.info.Type === 'text' && (self.qaList = JSON.parse(self.info.Text))

                    self.imageList = [
                        {
                            name: 'icon1',
                            url: 'http://mres.cleartv.cn/default/a1c2f971eedd84c68c18d225f5dcce93_159704661755.png',
                            urlSize: 1536,
                            activeUrl: 'http://mres.cleartv.cn/default/e16cdd6a6fc950c0ab01e167e16f9394_159704887549.png',
                            activeUrlSize: 1094
                        },
                        {
                            name: 'icon2',
                            url: 'http://mres.cleartv.cn/default/1a068b571aa8160ebe217bed0617040f_159704664958.png',
                            urlSize: 1514,
                            activeUrl: 'http://mres.cleartv.cn/default/54e6566a7e7e859f32c959c9d0048f49_159704889743.png',
                            activeUrlSize: 999
                        },
                        {
                            name: 'icon3',
                            url: 'http://mres.cleartv.cn/default/8c49c8ceba264977f4d792c5aec8627c_159704666821.png',
                            urlSize: 2416,
                            activeUrl: 'http://mres.cleartv.cn/default/641ae5c697267eb5c7b0e9f15d8f3bce_159704891439.png',
                            activeUrlSize: 1565
                        },
                        {
                            name: 'icon2',
                            url: 'http://mres.cleartv.cn/default/b687a3b33b707fad9e68c748b5e887d7_159704668503.png',
                            urlSize: 3010,
                            activeUrl: 'http://mres.cleartv.cn/default/f2ad890ca89cdf2c31cbe17c33bec462_159704892833.png',
                            activeUrlSize: 1769
                        }
                    ];

                    self.activeImage = self.imageList.findIndex(function (item) {
                        return item.url === self.addParam.PicURL
                    })

                    $timeout(function () {
                        var editor = tinymce.init({
                            skin_url: 'tinymce/skins/ui/oxide',
                            selector: '.mytextarea',
                            language: 'zh_CN',
                            language_url: 'tinymce/langs/zh_CN.js',
                            height: 500,
                            menubar: false,
                            plugins: [
                                'autolink lists link image preview anchor',
                                'code fullscreen',
                                'media table paste code help wordcount'
                            ],
                            toolbar: 'undo redo | formatselect | ' +
                                'image media |' +
                                'bold italic backcolor | alignleft aligncenter ' +
                                'alignright alignjustify | bullist numlist outdent indent | ' +
                                'removeformat | help',
                            images_upload_url: '',
                            images_upload_handler: function (blobInfo, success, failure) {
                                var form = new FormData();

                                form.append('action', 'normal');
                                form.append('file', blobInfo.blob());

                                $http({
                                    method: 'POST',
                                    url: CONFIG.uploadUrl,
                                    data: form,
                                    headers: { 'Content-Type': undefined },
                                    transformRequest: angular.identity
                                }).then(function successCallback(res) {
                                    console.log('upload success', res.data);
                                    success(res.data.upload_path)
                                }, function errorCallback(response) {
                                    failure('上传失败')
                                })
                            }
                        })
                    }, 0)

                }

                self.addQA = function (type) {
                    self.qaList.push({
                        type: type,
                        content: ''
                    })
                }

                self.removeQA = function (index) {
                    self.qaList.splice(index, 1)
                }

                /**
                 * 关闭弹窗
                 *
                 * @method cancel
                 */
                self.cancel = function () {
                    $scope.app.showHideMask(false);
                }

                self.getHtml = function () {
                    return tinymce.activeEditor.getContent()
                }

                self.setHtml = function (content) {
                    tinymce.activeEditor.setContent(content)
                }



                function getText() {
                    switch (self.addParam.Type) {
                        case 'richText':
                            return self.getHtml()
                        case 'text':
                            return JSON.stringify(self.qaList)
                        default:
                            return ''
                    }
                }

                /**
                 * 保存分类提交
                 *
                 * @method save
                 */
                self.save = function () {

                    if (!(self.imgs1.data[0] && self.imgs1.data[0].src) && self.addParam.type === 'pic') {
                        alert('请上传图片');
                        return;
                    }


                    var params = self.addParam.Level ? {
                        "ID": self.addParam.ID,
                        "isLeaf": self.addParam.isLeaf,
                        "PicURL": self.imgs1.data[0].src,
                        "PicURLFocus": self.imgs2.data.length && self.imgs2.data[0].src || '',
                        "Text": self.title,
                        "Title": self.title,
                        "Seq": self.Seq,
                        "PicSize": self.imgs1.data[0].fileSize - 0,
                        "PicFocusSize": (self.imgs2.data.length && self.imgs2.data[0].fileSize || 0) - 0
                    } : {
                            "ID": self.addParam.ID,
                            "isLeaf": self.addParam.isLeaf,
                            "URL": self.imgs1.data.length && self.imgs1.data[0].src || '',
                            "VoiceContent": self.addParam.VoiceContent,
                            "Text": getText(),
                            "Title": self.title,
                            "Seq": self.Seq,
                            "Type": self.addParam.type,
                            "Size": (self.imgs1.data.length && self.imgs1.data[0].fileSize || 0) - 0
                        }

                    if (self.addParam.Level === 2) {
                        params.PicURL = self.imageList[self.activeImage].url
                        params.PicURLFocus = self.imageList[self.activeImage].activeUrl
                        params.PicSize = self.imageList[self.activeImage].urlSize
                        params.PicFocusSize = self.imageList[self.activeImage].activeUrlSize
                    }


                    var data = JSON.stringify({
                        "token": util.getParams('token'),
                        "action": "edit",
                        "viewID": self.viewId - 0,
                        "lang": util.langStyle(),
                        "data": params
                    });

                    self.saving = true;

                    return $http({
                        method: 'POST',
                        url: util.getApiUrl('commonview', '', 'server'),
                        data: data
                    }).then(function successCallback(response) {
                        alert('操作成功');
                        $scope.app.showHideMask(false);
                        $scope.app.maskParams.loadInfo();
                    }, function errorCallback(response) {
                        alert('连接服务器出错');
                    }).finally(function (value) {
                        self.saving = false;
                    });
                }

                // 图片上传相关
                self.clickUpload = function (e) {
                    setTimeout(function () {
                        document.getElementById(e).click();
                    }, 0);
                }

                function Imgs(imgList, single) {
                    this.initImgList = imgList;
                    this.data = [];
                    this.maxId = 0;
                    this.single = single ? true : false;
                }

                Imgs.prototype = {
                    initImgs: function () {
                        var l = this.initImgList;
                        for (var i = 0; i < l.length; i++) {
                            this.data[i] = {
                                "src": l[i].ImageURL,
                                "fileSize": l[i].ImageSize,
                                "id": this.maxId++,
                                "progress": 100
                            };
                        }
                    },
                    deleteById: function (id) {
                        var l = this.data;
                        for (var i = 0; i < l.length; i++) {
                            if (l[i].id == id) {
                                // 如果正在上传，取消上传
                                if (l[i].progress < 100 && l[i].progress != -1) {
                                    l[i].xhr.abort();
                                }
                                l.splice(i, 1);
                                break;
                            }
                        }
                    },

                    add: function (xhr, fileName, fileSize) {
                        this.data.push({
                            "xhr": xhr,
                            "fileName": fileName,
                            "fileSize": fileSize,
                            "progress": 0,
                            "id": this.maxId
                        });
                        return this.maxId++;
                    },

                    update: function (id, progress, leftSize, fileSize) {
                        for (var i = 0; i < this.data.length; i++) {
                            var f = this.data[i];
                            if (f.id === id) {
                                f.progress = progress;
                                f.leftSize = leftSize;
                                f.fileSize = fileSize;
                                break;
                            }
                        }
                    },

                    setSrcSizeByXhr: function (xhr, src, size) {
                        for (var i = 0; i < this.data.length; i++) {
                            if (this.data[i].xhr == xhr) {
                                this.data[i].src = src;
                                this.data[i].fileSize = size;
                                break;
                            }
                        }
                    },

                    uploadFile: function (e, o) {

                        // 如果这个对象只允许上传一张图片
                        if (this.single) {
                            // 删除第二张以后的图片
                            for (var i = 1; i < this.data.length; i++) {
                                this.deleteById(this.data[i].id);
                            }
                        }

                        var file = $scope[e];
                        var uploadUrl = CONFIG.uploadUrl;
                        var xhr = new XMLHttpRequest();
                        var fileId = this.add(xhr, file.name, file.size, xhr);
                        // self.search();

                        util.uploadFileToUrl(xhr, file, uploadUrl, 'normal',
                            function (evt) {
                                $scope.$apply(function () {
                                    if (evt.lengthComputable) {
                                        var percentComplete = Math.round(evt.loaded * 100 / evt.total);
                                        o.update(fileId, percentComplete, evt.total - evt.loaded, evt.total);
                                        console && console.log(percentComplete);
                                    }
                                });
                            },
                            function (xhr) {
                                var ret = JSON.parse(xhr.responseText);
                                console && console.log(ret);
                                $scope.$apply(function () {
                                    o.setSrcSizeByXhr(xhr, ret.upload_path, ret.size);
                                    // 如果这个对象只允许上传一张图片
                                    if (o.single) {
                                        // 如果长度大于1张图片，删除前几张图片
                                        if (o.data.length > 1) {
                                            for (var i = 0; i < o.data.length - 1; i++) {
                                                o.deleteById(o.data[i].id);
                                            }
                                        }
                                    }
                                });
                            },
                            function (xhr) {
                                $scope.$apply(function () {
                                    o.update(fileId, -1, '', '');
                                });
                                console && console.log('failure');
                                xhr.abort();
                            }
                        );
                    }
                }

            }
        ])
})();