'use strict';

(function() {
var app = angular.module('app.group', [])
// 团客
.controller('groupController', [
'$scope',
'$state',
'$translate',
'$http',
'$stateParams',
'$filter',
'NgTableParams',
'util',
'$rootScope',
function($scope, $state, $translate, $http, $stateParams, $filter, NgTableParams, util, $rootScope) {
    console.log('groupController');
    var self = this;
    self.init = function() {
        self.defaultLangCode = util.getDefaultLangCode();
        self.langStyle = util.langStyle();
        self.multiLang = util.getParams('editLangs');
        self.form = {};
        self.groupListIndex = -1; // 默认为终端分组

        self.partFunctionList = [
            {
                functionName: '终端',
                url: 'app.group.groupTerminal'
            }, {
                functionName: '弹窗列表',
                url: 'app.group.messageList'
            }
        ];
        self.functionList = [
            {
                functionName: '终端',
                url: 'app.group.groupTerminal'
            }, {
                functionName: '弹窗列表',
                url: 'app.group.messageList'
            }, {
                functionName: '电视推送',
                url: 'app.group.groupTV'
            }, {
                functionName: '广告位设置',
                url: 'app.group.groupAd'
            }, {
                functionName: '欢迎词',
                url: 'app.group.Welcome'
            }
        ];
        self.groupFunctionListIndex = 0;
        self.searchHotelList();

        self.checkboxes = {};
        self.notEmpty = false;
        self.showMaskClass = false;
        self.maskUrl = '';
        self.maskParams = {};
    }

    //获取门店
    self.searchHotelList = function() {
        var data = {
            "action": "getHotelList",
            "token": util.getParams("token"),
            "lang": self.langStyle
        };
        data = JSON.stringify(data);
        $http({
            method: $filter('ajaxMethod')(),
            url: util.getApiUrl('hotelroom', 'hotelroom.json', ''),
            data: data
        }).then(function successCallback(data, status, headers, config) {
            if (data.data.rescode == "200") {

                self.hotelList = data.data.data;
                self.selectHotel = self.hotelList[0];
                self.getTermList(self.selectHotel.ID, 0);
            } else if (data.data.rescode == "401") {
                alert('访问超时，请重新登录');
                $state.go('login')
            } else {
                alert('列表获取失败， ' + data.data.errInfo);
            }

        }, function errorCallback(data, status, headers, config) {
            alert('获取失败， ' + data.data.errInfo);
        }). finally(function(value) {
            self.loading = false;
        });

    }
    //点击终端分组将展示门店全部终端\相应团队列表
    self.getTermList = function(ID, index) {
        self.groupListIndex = -1; // -1为终端分组
        self.groupID = -1;
        self.form.HotelName = self.hotelList[index].Name[self.defaultLangCode];
        self.form.HotelID = ID;
        self.hotelListIndex = index;
        self.getGroupList(ID, index);
        self.goTo(self.groupListIndex, 0);
    }

    //点击酒店获取相应团队列表
    self.getGroupList = function(ID, index) {
        self.form.HotelName = self.hotelList[index].Name[self.defaultLangCode];
        self.form.HotelID = ID;
        self.hotelListIndex = index;
        // self.goTo(self.groupListIndex,self.groupFunctionListIndex);
        var data = {
            "action": "getTermGroupList",
            "token": util.getParams("token"),
            "hotelID": self.form.HotelID,
            // "lang": self.langStyle
        };
        data = JSON.stringify(data);
        $http({
            method: $filter('ajaxMethod')(),
            url: util.getApiUrl('termgroup', 'termgroup.json', ''),
            data: data
        }).then(function successCallback(data, status, headers, config) {
            if (data.data.rescode == "200") {
                self.groupList = data.data.groupList;
                //self.getGroupList(self.selectHotel.ID,0);//默认获取酒店第一个门店对应的团客信息
            } else if (data.data.rescode == "401") {
                alert('访问超时，请重新登录');
                $state.go('login')
            } else {
                alert('列表获取失败， ' + data.data.errInfo);
            }

        }, function errorCallback(data, status, headers, config) {
            alert('获取失败， ' + data.data.errInfo);
        }). finally(function(value) {
            self.loading = false;
        });
    }
    //点击选中团队
    self.selectGroup = function(index) {

        self.groupListIndex = index;
        self.groupID = self.groupList[index].termGroupID;
        self.goTo(self.groupListIndex, self.groupFunctionListIndex);
    }
    //点击选中功能
    self.selectFunction = function(index) {
        self.groupFunctionListIndex = index;

        self.goTo(self.groupListIndex, self.groupFunctionListIndex);
    }
    //点击新增团客
    self.addgroups = function() {
        $scope.app.maskParams.hotelListIndex = self.hotelListIndex; //门店序号
        $scope.app.maskParams.HotelID = self.form.HotelID; //门店ID
        $scope.app.maskParams.HotelName = self.form.HotelName; //门店名
        $scope.app.maskParams.hotelList = self.hotelList; //门店列表
        $scope.app.showHideMask(true, 'pages/group/addGroup.html');
    }
    //点击编辑团客
    self.editgroup = function() {
        if (self.groupID == -1) {
            alert("不可编辑根目录，请选择分组");
            return;
        }
        $scope.app.maskParams.hotelListIndex = self.hotelListIndex; //门店序号
        $scope.app.maskParams.HotelID = self.form.HotelID; //门店ID
        $scope.app.maskParams.HotelName = self.form.HotelName; //门店名
        $scope.app.maskParams.hotelList = self.hotelList; //门店列表
        $scope.app.maskParams.groupID = self.groupID; //分组ID
        $scope.app.maskParams.termGroupName = self.groupList[self.groupListIndex].termGroupName; //选中的分组名
        $scope.app.showHideMask(true, 'pages/group/editGroup.html');
    }
    //点击删除团客
    self.deletegroup = function() {
        if (self.groupID == -1) {
            alert("不可删除根目录，请选择分组");
            return;
        }
        var conf = confirm(self.groupList[self.groupListIndex].termGroupName + '删除后，与该团客相关的所有信息都会被删除，您确定删除吗？');
        if (!conf) {
            return;
        }
        var data = {
            "action": "delTermGroup",
            "token": util.getParams("token"),
            //"lang": self.langStyle,
            // "items": item,
            "hotelID": self.form.HotelID,
            "termGroupID": self.groupID
            //"name":self.form.groupName
        };
        data = JSON.stringify(data);
        self.saving = true;

        $http({
            method: $filter('ajaxMethod')(),
            url: util.getApiUrl('termgroup', 'addGroup.json', ''),
            data: data
        }).then(function successCallback(data, status, headers, config) {
            if (data.data.rescode == "200") {
                alert('删除成功')
                $state.reload();
            } else if (data.data.rescode == "401") {
                alert('访问超时，请重新登录');
                $state.go('login')
            } else {
                alert('删除失败， ' + data.data.errInfo);
            }
        }, function errorCallback(data, status, headers, config) {
            alert('删除失败， ' + data.data.errInfo);
        }). finally(function(value) {
            self.saving = false;
        });
    }
    //前往功能页面
    self.goTo = function(groupListIndex, functionListIndex) {
        $scope.app.maskParams.hotelListIndex = self.hotelListIndex; //门店序号
        $scope.app.maskParams.HotelID = self.form.HotelID; //门店ID
        $scope.app.maskParams.hotelList = self.hotelList; //门店列表
        $scope.app.maskParams.groupListIndex = groupListIndex; //团队序号
        $scope.app.maskParams.groupID = self.groupID; //团队ID
        $scope.app.maskParams.functionListIndex = functionListIndex; //功能序号
        $state.go(self.functionList[functionListIndex].url, {
            HotelID: self.form.HotelID,
            GroupID: self.groupID
        });
    }
}
])
//添加团客
.controller('groupAddController', [
'$scope',
'$state',
'$http',
'$stateParams',
'$translate',
'$filter',
'util',
'NgTableParams',
function($scope, $state, $http, $stateParams, $translate, $filter, util, NgTableParams) {
    console.log('groupAddController');
    var self = this;
    self.init = function() {
        self.defaultLangCode = util.getDefaultLangCode();
        self.langStyle = util.langStyle();
        self.multiLang = util.getParams('editLangs');
        self.saving = false;
        self.form = {};
        // 多语言
        // self.form.groupName = {};

        self.hotelListIndex = $scope.app.maskParams.hotelListIndex; //门店序号
        self.form.HotelID = $scope.app.maskParams.HotelID; //门店ID
        self.form.HotelName = $scope.app.maskParams.HotelName; //门店名
        self.hotelList = $scope.app.maskParams.hotelList; //门店列表
        // self.getTermList();

        self.saving = false;
        self.loading = false;
    }

    self.cancel = function() {
        // $scope.app.showMaskClass = false;
        // $scope.app.maskUrl = "";
        $scope.app.showHideMask(false);
    }

    self.saveForm = function() {
        var data = {
            "action": "setTermGroup",
            "token": util.getParams("token"),
            // "lang": self.langStyle,
            // "items": item,
            "hotelID": self.form.HotelID,
            "termGroupName": self.form.groupName
        };
        data = JSON.stringify(data);
        self.saving = true;

        $http({
            method: $filter('ajaxMethod')(),
            url: util.getApiUrl('termgroup', 'addGroup.json', ''),
            data: data
        }).then(function successCallback(data, status, headers, config) {
            if (data.data.rescode == "200") {
                alert('添加成功')
                $state.reload();
            } else if (data.data.rescode == "401") {
                alert('访问超时，请重新登录');
                $state.go('login')
            } else {
                alert('添加失败， ' + data.data.errInfo);
            }
        }, function errorCallback(data, status, headers, config) {
            alert('添加失败， ' + data.data.errInfo);
        }). finally(function(value) {
            self.saving = false;
        });
    }
}
])
//编辑团客
.controller('groupEditController', [
'$scope',
'$state',
'$http',
'$stateParams',
'$translate',
'$filter',
'util',
'NgTableParams',
function($scope, $state, $http, $stateParams, $translate, $filter, util, NgTableParams) {
    console.log('groupEditController');
    var self = this;
    self.init = function() {
        self.defaultLangCode = util.getDefaultLangCode();
        self.langStyle = util.langStyle();
        self.multiLang = util.getParams('editLangs');
        self.saving = false;
        self.form = {};
        // 多语言
        self.form.groupName = $scope.app.maskParams.termGroupName;

        self.hotelListIndex = $scope.app.maskParams.hotelListIndex; //门店序号
        self.form.HotelID = $scope.app.maskParams.HotelID; //门店ID
        self.form.HotelName = $scope.app.maskParams.HotelName; //门店名
        self.hotelList = $scope.app.maskParams.hotelList; //门店列表
        self.groupID = $scope.app.maskParams.groupID; //分组ID
        // self.getTermList();

        self.saving = false;
        self.loading = false;
    }

    self.cancel = function() {
        // $scope.app.showMaskClass = false;
        // $scope.app.maskUrl = "";
        $scope.app.showHideMask(false);
    }

    self.saveForm = function() {
        var data = {
            "action": "editTermGroup",
            "token": util.getParams("token"),
            //  "lang": self.langStyle,
            // "items": item,
            "hotelID": self.form.HotelID,
            "termGroupName": self.form.groupName,
            "termGroupID": self.groupID
        };
        data = JSON.stringify(data);
        self.saving = true;

        $http({
            method: $filter('ajaxMethod')(),
            url: util.getApiUrl('termgroup', 'addGroup.json', 'server'),
            data: data
        }).then(function successCallback(data, status, headers, config) {
            if (data.data.rescode == "200") {
                alert('编辑成功')
                $state.reload();
            } else if (data.data.rescode == "401") {
                alert('访问超时，请重新登录');
                $state.go('login')
            } else {
                alert('编辑失败， ' + data.data.errInfo);
            }
        }, function errorCallback(data, status, headers, config) {
            alert('编辑失败， ' + data.data.errInfo);
        }). finally(function(value) {
            self.saving = false;
        });
    }
}
])
// 终端管理
.controller('groupTerminalController', [
'$scope',
'$state',
'$translate',
'$http',
'$stateParams',
'$filter',
'NgTableParams',
'util',
function($scope, $state, $translate, $http, $stateParams, $filter, NgTableParams, util) {
    console.log('groupTerminalController');
    var self = this;
    self.init = function() {
        self.form = {};
        self.defaultLangCode = util.getDefaultLangCode();
        self.langStyle = util.langStyle();
        self.multiLang = util.getParams('editLangs');
        self.form.HotelID = $stateParams.HotelID; //门店ID
        self.groupID = $stateParams.GroupID; //团队ID
        self.checkboxes = {};
        self.getDevNum(self.form.HotelID, self.hotelListIndex);
    }
    //移动到
    self.moveTo = function() {
        self.notEmpty = false;
        var items = self.checkboxes.items;
        var item = [];
        if (items == undefined || !items || items == null || items == "") {
            alert("请选择房间！");
            return;
        }
        for (var i in items) {
            if (items[i]) {
                self.notEmpty = true;
                item.push(i);
            }
        }
        if (!self.notEmpty) {
            alert("请选择房间！");
            return;
        }
        $scope.app.maskParams.termID = item;
        $scope.app.maskParams.hotelID = self.form.HotelID;
        $scope.app.maskParams.groupID = self.groupID;
        // $scope.app.maskParams.getDevList=self.getDevList;
        $scope.app.showHideMask(true, 'pages/group/moveTerminal.html');
    }
    //范围添加
    self.rangeMove = function() {
        // $scope.app.maskParams.termID = item;
        $scope.app.maskParams.hotelID = self.form.HotelID
        $scope.app.maskParams.groupID = self.groupID;
        // $scope.app.maskParams.getDevList=self.getDevList;
        $scope.app.showHideMask(true, 'pages/group/rangeMove.html');
    }

    // 获取终端列表 带搜索和分页
    self.getDevList = function() {

        self.noData = false;
        self.loading = true;
        self.tableParams = new NgTableParams({
            page: 1,
            count: 15,
            url: ''
        }, {
            counts: [],
            getData: function(params) {
                var data = {
                    "action": "getDevGroupList",
                    "token": util.getParams("token"),
                    "lang": self.langStyle,
                    "Online": self.form.Online,
                    "HotelID": self.form.HotelID - 0,
                    "TermGroupID": self.groupID - 0,
                    "RoomID": self.form.RoomID
                }
                var paramsUrl = params.url();
                data.per_page = paramsUrl.count - 0;
                data.page = paramsUrl.page - 0;
                data = JSON.stringify(data);
                return $http({
                    method: $filter('ajaxMethod')(),
                    url: util.getApiUrl('devinfo', 'devinfo.json', ''),
                    data: data
                }).then(function successCallback(data, status, headers, config) {
                    if (data.data.rescode == '200') {
                        if (data.data.total == 0) {
                            self.noData = true;
                        }
                        params.total(data.data.total);
                        self.res = data.data.devlist;
                        return data.data.devlist;
                    } else if (data.rescode == '401') {
                        alert('访问超时，请重新登录');
                        $state.go('login');
                    } else {
                        alert(data.rescode + ' ' + data.errInfo);
                    }

                }, function errorCallback(data, status, headers, config) {
                    alert(status + ' 服务器出错');
                }). finally(function(value) {
                    self.loading = false;
                })
            }
        });
    }

    // 获取终端状态 总数目
    self.getDevNum = function(ID, index) {
        self.checkboxes = {};
        self.form.HotelID = ID;
        self.hotelListIndex = index;
        self.getDevList()
        var data = {
            "action": "getDevNum",
            "token": util.getParams("token"),
            "lang": self.langStyle,
            "HotelID": self.form.HotelID
        }

        data = JSON.stringify(data);
        $http({
            method: $filter('ajaxMethod')(),
            url: util.getApiUrl('devinfo', 'devinfoNum.json', ''),
            data: data
        }).then(function successCallback(data, status, headers, config) {
            if (data.data.rescode == '200') {
                self.form.total = data.data.total;
                self.form.online = data.data.online;
            } else if (msg.rescode == '401') {
                alert('访问超时，请重新登录');
                $state.go('login');
            } else {
                alert(data.rescode + ' ' + data.errInfo);
            }
        }, function errorCallback(data, status, headers, config) {
            alert(response.status + ' 服务器出错');
        }). finally(function(value) {
            self.loading = false;
        })

    }

    self.delTerm = function(id) {
        var conf = confirm('确认将该房间从此分组中删除？');
        if (!conf) {
            return;
        }
        var data = {
            "action": "moveTermToGroup",
            "token": util.getParams("token"),
            "hotelID": self.form.HotelID,
            "termGroupID": -1,
            //  "lang": self.langStyle,
            "termID": [id]
        }

        data = JSON.stringify(data);
        $http({
            method: $filter('ajaxMethod')(),
            url: util.getApiUrl('termgroup', '', 'server'),
            data: data
        }).then(function successCallback(data, status, headers, config) {
            if (data.data.rescode == '200') {
                self.getDevList();
                self.getDevNum(self.form.HotelID, self.hotelListIndex);
                alert('删除成功');
            } else if (msg.rescode == '401') {
                alert('访问超时，请重新登录');
                $state.go('login');
            } else {
                alert(data.data.errInfo);
            }
        }, function errorCallback(data, status, headers, config) {
            alert('连接服务器出错');
        })
    }

    //全选
    self.checkAll = function() {
        if (self.res == undefined || !self.res || self.res == null || self.res == "") {
            return;
        }
        var len = self.res.length;
        var a = {};
        if (self.checkboxes.checked == true) {
            for (var i = 0; i < len; i++) {
                a[self.res[i].ID] = true;
            }
            self.checkboxes.items = a;
        } else {
            for (var i = 0; i < len; i++) {
                a[self.res[i].ID] = false;
            }
            self.checkboxes.items = a;
        }
        self.changeCheckBox();
    };

    //选择资源
    self.changeCheckBox = function() {
        if (self.res == undefined || !self.res || self.res == null || self.res == "") {
            return;
        }
        var len = self.res.length;
        var checks = self.checkboxes;
        var checkbox = [];
        if (checks) {
            for (var j in checks.items) {
                if (checks.items[j] == true) {
                    checkbox.push(checks.items[j]);
                }
                if (checks.items[j] == false) {
                    self.checkboxes.checked = false;
                }
            }
            var lens = checkbox.length;
            if (len == lens) {
                self.checkboxes.checked = true;
            }
            return;
        }

    };
}
])
//移动至分组
.controller('groupMoveController', [
'$scope',
'$state',
'$http',
'$stateParams',
'$translate',
'$filter',
'util',
'NgTableParams',
function($scope, $state, $http, $stateParams, $translate, $filter, util, NgTableParams) {
    console.log('groupMoveController');
    var self = this;
    self.init = function() {
        self.defaultLangCode = util.getDefaultLangCode();
        self.langStyle = util.langStyle();
        self.multiLang = util.getParams('editLangs');
        self.saving = false;
        //  self.searchHotelList();
        // 表单提交 商城信息
        self.form = {};
        // 多语言
        self.form.groupName = {};
        console.log($scope.app.maskParams.termID);
        self.hasGroup = $scope.app.maskParams.hasGroup; //是否已有分组
        self.item = $scope.app.maskParams.termID; //选中的终端
        //self.hotelListIndex = $scope.app.maskParams.hotelListIndex;//门店序号
        self.form.HotelID = $scope.app.maskParams.HotelID; //门店ID
        self.groupID_old = $scope.app.maskParams.groupID;
        self.reloadPage = $scope.app.maskParams.reloadPage || 'app.group.groupTerminal';
        self.getGroupList(self.form.HotelID)
        self.saving = false;
        self.loading = false;
    }
    //点击酒店获取相应团队列表
    self.getGroupList = function(ID) {
        //self.form.HotelName = self.hotelList[index].Name[self.defaultLangCode];
        self.noData = true;
        self.form.HotelID = ID;
        var data = {
            "action": "getTermGroupList",
            "token": util.getParams("token"),
            "hotelID": self.form.HotelID,
            // "lang": self.langStyle
        };
        data = JSON.stringify(data);
        $http({
            method: $filter('ajaxMethod')(),
            url: util.getApiUrl('termgroup', 'termgroup.json', ''),
            data: data
        }).then(function successCallback(data, status, headers, config) {
            if (data.data.rescode == "200") {
                self.noData = false;
                self.groupList = data.data.groupList;
            } else if (data.data.rescode == "401") {
                alert('访问超时，请重新登录');
                $state.go('login')
            } else {
                alert('列表获取失败， ' + data.data.errInfo);
            }

        }, function errorCallback(data, status, headers, config) {
            alert('获取失败， ' + data.data.errInfo);
        }). finally(function(value) {
            self.loading = false;
        });
    }
    self.cancel = function() {
        $scope.app.showHideMask(false);
    }
    self.saveForm = function() {
        if (self.hasGroup) {
            var conf = confirm('当前所选终端(部分/全部)已有分组, 确认移动到新的分组吗?');
            if (!conf) {
                return;
            };
        }
        var data = {
            "action": "moveTermToGroup",
            "token": util.getParams("token"),
            // "lang": self.langStyle,
            // "items": item,
            "hotelID": self.form.HotelID,
            "termGroupID": self.form.groupID,
            "termID": self.item
        };
        data = JSON.stringify(data);
        self.saving = true;

        $http({
            method: $filter('ajaxMethod')(),
            url: util.getApiUrl('termgroup', 'addGroup.json', ''),
            data: data
        }).then(function successCallback(data, status, headers, config) {
            if (data.data.rescode == "200") {
                alert('添加成功');
                $scope.app.showHideMask(false);
                $state.reload(self.reloadPage);
            } else if (data.data.rescode == "401") {
                alert('访问超时，请重新登录');
                $state.go('login')
            } else {
                alert('添加失败， ' + data.data.errInfo);
            }
        }, function errorCallback(data, status, headers, config) {
            alert('添加失败， ' + data.data.errInfo);
        }). finally(function(value) {
            self.saving = false;
        });
    }
}
])
//范围添加
.controller('rangMoveController', [
'$scope',
'$state',
'$http',
'$stateParams',
'$translate',
'$filter',
'util',
'NgTableParams',
function($scope, $state, $http, $stateParams, $translate, $filter, util, NgTableParams) {
    console.log('rangMoveController');
    var self = this;
    self.init = function() {
        self.defaultLangCode = util.getDefaultLangCode();
        self.langStyle = util.langStyle();
        self.multiLang = util.getParams('editLangs');
        self.saving = false;
        //  self.searchHotelList();
        // 表单提交 商城信息
        self.form = {};
        // 多语言
        self.form.groupName = {};
        self.item = $scope.app.maskParams.termID; //选中的终端
        self.form.HotelID = $scope.app.maskParams.HotelID; //门店ID
        self.groupID_old = $scope.app.maskParams.groupID;
        self.getGroupList(self.form.HotelID)
        self.saving = false;
        self.loading = false;
    }
    //点击酒店获取相应团队列表
    self.getGroupList = function(ID) {
        //self.form.HotelName = self.hotelList[index].Name[self.defaultLangCode];
        self.noData = true;
        self.form.HotelID = ID;
        var data = {
            "action": "getTermGroupList",
            "token": util.getParams("token"),
            "hotelID": self.form.HotelID,
            // "lang": self.langStyle
        };
        data = JSON.stringify(data);
        $http({
            method: $filter('ajaxMethod')(),
            url: util.getApiUrl('termgroup', 'termgroup.json', ''),
            data: data
        }).then(function successCallback(data, status, headers, config) {
            if (data.data.rescode == "200") {

                self.groupList = data.data.groupList;
                if (self.groupList.length != 0) {
                    self.noData = false;
                }
            } else if (data.data.rescode == "401") {
                alert('访问超时，请重新登录');
                $state.go('login')
            } else {
                alert('列表获取失败， ' + data.data.errInfo);
            }

        }, function errorCallback(data, status, headers, config) {
            alert('获取失败， ' + data.data.errInfo);
        }). finally(function(value) {
            self.loading = false;
        });
    }
    self.cancel = function() {
        $scope.app.showHideMask(false);
    }

    self.saveForm = function() {
        var data = {
            "action": "groupRangeMove",
            "token": util.getParams("token"),
            "lang": self.langStyle,
            // "items": item,
            "hotelID": self.form.HotelID - 0,
            "termGroupID": self.form.groupID - 0,
            "minNum": self.minNum,
            "maxNum": self.maxNum
        };
        data = JSON.stringify(data);
        self.saving = true;

        $http({
            method: $filter('ajaxMethod')(),
            url: util.getApiUrl('termgroup', 'addGroup.json', ''),
            data: data
        }).then(function successCallback(data, status, headers, config) {
            if (data.data.rescode == "200") {
                alert('添加成功');
                $scope.app.showHideMask(false);
                $state.reload('app.group.groupTerminal');
            } else if (data.data.rescode == "401") {
                alert('访问超时，请重新登录');
                $state.go('login')
            } else if (data.data.rescode == '600') {
                $scope.app.maskParams.HotelID = self.form.HotelID; //门店ID
                $scope.app.maskParams.groupID = self.form.groupID; //分组ID
                for (var i = 0; i < self.groupList.length; i++) {
                    if (self.form.groupID == self.groupList[i].termGroupID) {
                        self.termGroupName = self.groupList[i].termGroupName;
                    }
                }

                $scope.app.maskParams.termGroupName = self.termGroupName; //分组名
                $scope.app.maskParams.termList = data.data.termList; //返回的待确认数据
                $scope.app.showHideMask(true, 'pages/group/reconfirm.html'); //二次确认
            } else {
                alert('添加失败， ' + data.data.errInfo);
            }
        }, function errorCallback(data, status, headers, config) {
            alert('添加失败， ' + data.data.errInfo);
        }). finally(function(value) {
            self.saving = false;
        });
    }
}
])
//二次确认
.controller('reconfirmController', [
'$scope',
'$state',
'$http',
'$stateParams',
'$translate',
'$filter',
'util',
'NgTableParams',
function($scope, $state, $http, $stateParams, $translate, $filter, util, NgTableParams) {
    console.log('reconfirmController');
    var self = this;
    self.init = function() {
        self.defaultLangCode = util.getDefaultLangCode();
        self.langStyle = util.langStyle();
        self.multiLang = util.getParams('editLangs');
        self.saving = false;
        self.form = {};
        // 多语言
        self.form.groupName = {};
        self.termList = $scope.app.maskParams.termList; //待确认数据
        self.termRoomID = [];
        for (var i = 0; i < self.termList.length; i++) {
            self.termRoomID[i] = self.termList[i].ID; //待确认房间ID
        }
        self.termGroupName = $scope.app.maskParams.termGroupName; //待确认分组名
        self.form.HotelID = $scope.app.maskParams.HotelID; //门店ID
        self.form.groupID = $scope.app.maskParams.groupID; //分组ID
        self.saving = false;
        self.loading = false;
    }
    self.cancel = function() {
        $scope.app.showHideMask(false);
    }
    self.confirm = function() {
        var data = {
            "action": "moveTermToGroup",
            "token": util.getParams("token"),
            // "lang": self.langStyle,
            // "items": item,
            "hotelID": self.form.HotelID,
            "termGroupID": self.form.groupID,
            "termID": self.termRoomID
        };
        data = JSON.stringify(data);
        self.saving = true;

        $http({
            method: $filter('ajaxMethod')(),
            url: util.getApiUrl('termgroup', 'addGroup.json', ''),
            data: data
        }).then(function successCallback(data, status, headers, config) {
            if (data.data.rescode == "200") {
                alert('添加成功');
                $scope.app.showHideMask(false);
                $state.reload('app.group.groupTerminal');
            } else if (data.data.rescode == "401") {
                alert('访问超时，请重新登录');
                $state.go('login')
            } else {
                alert('添加失败， ' + data.data.errInfo);
            }
        }, function errorCallback(data, status, headers, config) {
            alert('添加失败， ' + data.data.errInfo);
        }). finally(function(value) {
            self.saving = false;
        });
    }
}
])
// 添加字幕和留言
.controller('groupTVController', ['$scope',
'$state',
'$translate',
'$http',
'$stateParams',
'$filter',
'NgTableParams',
'util',
'$rootScope',
function($scope, $state, $translate, $http, $stateParams, $filter, NgTableParams, util, $rootScope) {
    console.log('groupTVController');
    var self = this;
    self.init = function() {
        self.defaultLangCode = util.getDefaultLangCode();
        self.langStyle = util.langStyle();
        self.multiLang = util.getParams('editLangs');
        self.form = {};
        self.hotelListIndex = $scope.app.maskParams.hotelListIndex; //门店序号
        self.form.HotelID = $scope.app.maskParams.HotelID; //门店ID
        self.hotelList = $scope.app.maskParams.hotelList; //门店列表

        self.groupListIndex = $scope.app.maskParams.groupListIndex; //团队序号
        self.groupID = $stateParams.GroupID; //团队ID
        self.getHotelsList(self.form.HotelID, self.hotelListIndex);

        self.checkboxes = {};
        self.notEmpty = false;
        self.showMaskClass = false;
        self.maskUrl = '';
        self.maskParams = {};
    }

    //获取酒店对应房间信息列表
    self.getHotelsList = function(ID, index) {

        self.noData = false;
        self.loading = true;
        self.tableParams = new NgTableParams({
            page: 1,
            count: 15,
            url: ''
        }, {
            counts: [],
            getData: function(params) {
                var data = {
                    "action": "getDevListForMess",
                    "token": util.getParams("token"),
                    "lang": self.langStyle,
                    "HotelID": ID,
                    "TermGroupID": self.groupID - 0,
                    "RoomID": self.form.RoomNum
                        ? self.form.RoomNum
                        : ""
                }
                var paramsUrl = params.url();
                data.per_page = paramsUrl.count - 0;
                data.page = paramsUrl.page - 0;
                data = JSON.stringify(data);
                return $http({
                    method: "POST",
                    url: util.getApiUrl('devinfo', '', 'server'),
                    data: data
                }).then(function successCallback(data, status, headers, config) {
                    if (data.data.rescode == '200') {
                        if (data.data.total == 0) {
                            self.noData = true;
                        } else {
                            self.noData = false;
                        }
                        params.total(data.data.total);
                        self.res = data.data.dev;
                        return data.data.dev;
                    } else if (msg.rescode == '401') {
                        alert('访问超时，请重新登录');
                        $state.go('login');
                    } else {
                        alert(data.rescode + ' ' + data.errInfo);
                    }

                }, function errorCallback(data, status, headers, config) {
                    alert(response.status + ' 服务器出错');
                }). finally(function(value) {
                    self.loading = false;
                })
            }
        });
    }

    //点击添加字幕
    self.addSubtitles = function() {
        self.notEmpty = false;
        var items = self.checkboxes.items;
        var item = [];
        if (items == undefined || !items || items == null || items == "") {
            alert("请选择房间！");
            return;
        }
        for (var i in items) {
            if (items[i]) {
                self.notEmpty = true;
                item.push(i);
            }
        }
        if (!self.notEmpty) {
            alert("请选择房间！");
            return;
        }
        self.maskParams.item = item;
        self.maskParams.HotelID = self.form.HotelID;
        self.maskParams.hotelListIndex = self.hotelListIndex;
        //self.maskParams.groupID = self.groupID;
        self.showMaskClass = true;
        self.maskUrl = "pages/addRealTimeCommand.html";
    }

    //编辑字幕
    self.editSubtitles = function(info) {
        self.maskParams.info = info;
        self.maskParams.HotelID = self.form.HotelID;
        self.maskParams.hotelListIndex = self.hotelListIndex;
        //self.maskParams.groupID = self.groupID;
        self.showMaskClass = true;
        self.maskUrl = "pages/realTimeCommandEdit.html";
    }

    //点击添加留言
    self.addMessage = function() {
        self.notEmpty = false;
        var items = self.checkboxes.items;
        var item = [];
        if (items == undefined || !items || items == null || items == "") {
            alert("请选择房间！");
            return;
        }
        for (var i in items) {
            if (items[i]) {
                self.notEmpty = true;
                item.push(i);
            }
        }
        if (!self.notEmpty) {
            alert("请选择房间！");
            return;
        }
        self.maskParams.item = item;
        self.maskParams.HotelID = self.form.HotelID;
        self.maskParams.hotelListIndex = self.hotelListIndex;
        //self.maskParams.groupID = self.groupID;
        self.showMaskClass = true;
        self.maskUrl = "pages/addMessage.html";
    }
    //点击编辑留言
    self.editMessage = function(info) {
        self.maskParams.info = info;
        self.maskParams.HotelID = self.form.HotelID;
        self.maskParams.hotelListIndex = self.hotelListIndex;
        self.showMaskClass = true;
        self.maskUrl = "pages/editMessage.html";
    }
    //删除字幕
    self.delSubtitles = function(info) {
        var item = [];
        if (info == "ids") {
            self.notEmpty = false;
            var items = self.checkboxes.items;

            if (items == undefined || !items || items == null || items == "") {
                alert("请选择房间！");
                return;
            }
            for (var i in items) {
                if (items[i]) {
                    self.notEmpty = true;
                    item.push(i);
                }
            }
            if (!self.notEmpty) {
                alert("请选择房间！");
                return;
            }
        } else {
            item.push(info.ID);
        }
        var tip = confirm("确定要清除滚动字幕吗？")
        if (tip) {
            var currentDate = util.getToday();
            var data = {
                "action": "addRealTimeCmd",
                "token": util.getParams("token"),

                "data": {
                    CmdType: "ScrollingMarquee",
                    Terms: item,
                    CmdParas: {
                        Content: self.Content,
                        startDate: currentDate,
                        endDate: currentDate,
                        Duration: 1,
                        Interval: 1,
                        switch: 0}
                }
            }
            data = JSON.stringify(data);
        $http({
            method: $filter('ajaxMethod')(),
            url: util.getApiUrl('realtimecmd', 'shopList', 'server'),
            data: data
        }).then(function successCallback(data, status, headers, config) {
            if (data.data.rescode == '200') {
                alert("删除成功");
                self.getHotelsList(self.form.HotelID, self.hotelListIndex);
            } else if (msg.rescode == '401') {
                alert('访问超时，请重新登录');
                $state.go('login');
            } else {
                alert(data.rescode + ' ' + data.errInfo);
            }

        }, function errorCallback(data, status, headers, config) {
            alert(response.status + ' 服务器出错');
        }). finally(function(value) {
            self.loading = false;
        })
    }
    }

    //删除留言
    self.delMessage = function(info) {
        var item = [];
        if (info == "ids") {
            self.notEmpty = false;
            var items = self.checkboxes.items;

            if (items == undefined || !items || items == null || items == "") {
                alert("请选择房间！");
                return;
            }
            for (var i in items) {
                if (items[i]) {
                    self.notEmpty = true;
                    item.push(i);
                }
            }
            if (!self.notEmpty) {
                alert("请选择房间！");
                return;
            }
        } else {
            item.push(info.ID);
        }
        var tip = confirm("确定要清除留言吗？")
        if (tip) {
            var data = {
                "action": "delMessageAllByRoom",
                "token": util.getParams("token"),
                "TermID": item,
                //"termGroupID": self.groupID,
                "lang": self.langStyle
            }
            data = JSON.stringify(data);
            $http({
                method: "POST",
                url: util.getApiUrl('hotelmessage', '', 'server'),
                data: data
            }).then(function successCallback(data, status, headers, config) {
                if (data.data.rescode == '200') {
                    alert("删除成功");
                    self.getHotelsList(self.form.HotelID, self.hotelListIndex);
                } else if (msg.rescode == '401') {
                    alert('访问超时，请重新登录');
                    $state.go('login');
                } else {
                    alert(data.rescode + ' ' + data.errInfo);
                }

            }, function errorCallback(data, status, headers, config) {
                alert(response.status + ' 服务器出错');
            }). finally(function(value) {
                self.loading = false;
            })
        }
    }

    //全选
    self.checkAll = function() {
        if (self.res == undefined || !self.res || self.res == null || self.res == "") {
            return;
        }
        var len = self.res.length;
        var a = {};
        if (self.checkboxes.checked == true) {
            for (var i = 0; i < len; i++) {
                a[self.res[i].ID] = true;
            }
            self.checkboxes.items = a;
        } else {
            for (var i = 0; i < len; i++) {
                a[self.res[i].ID] = false;
            }
            self.checkboxes.items = a;
        }
        self.changeCheckBox();
    };

    //选择资源
    self.changeCheckBox = function() {
        if (self.res == undefined || !self.res || self.res == null || self.res == "") {
            return;
        }
        var len = self.res.length;
        var checks = self.checkboxes;
        var checkbox = [];
        if (checks) {
            for (var j in checks.items) {
                if (checks.items[j] == true) {
                    checkbox.push(checks.items[j]);
                }
                if (checks.items[j] == false) {
                    self.checkboxes.checked = false;
                }
            }
            var lens = checkbox.length;
            if (len == lens) {
                self.checkboxes.checked = true;
            }
            return;
        }

    };
}
])
//广告位
.controller('groupAdController', [
'$scope',
'$state',
'$http',
'$stateParams',
'$location',
'util',
function($scope, $state, $http, $stateParams, $location, util) {
    var self = this;
    self.init = function() {
        self.termGroupID = $stateParams.GroupID; //终端分组
        self.loadList();
        self.loadMenuAdvList();
    }

    self.loadList = function() {
        var data = JSON.stringify({"token": util.getParams('token'), "action": "getPosOpenList", "termGroupID": self.termGroupID, "lang": util.langStyle()});

        self.loading = true;
        $http({
            method: 'POST',
            url: util.getApiUrl('advpos', '', 'server'),
            data: data
        }).then(function successCallback(response) {
            var data = response.data;
            if (data.rescode == '200') {
                self.adList = data.data;
            } else if (data.rescode == '401') {
                alert('访问超时，请重新登录');
                $state.go('login');
            } else {
                alert('加载广告位信息失败，' + data.errInfo);
            }
        }, function errorCallback(response) {
            alert('连接服务器出错');
        }). finally(function(value) {
            self.loading = false;
        });
    }

    self.loadMenuAdvList = function() {
        var data = JSON.stringify({
            "token": util.getParams('token'),
            "action": "getPosOpenList",
            "termGroupID": self.termGroupID - 0,
            "lang": util.langStyle()
        });

        self.loading = true;
        $http({
            method: 'POST',
            url: util.getApiUrl('menuadvpos', '', 'server'),
            data: data
        }).then(function successCallback(response) {
            var data = response.data;
            if (data.rescode == '200') {
                self.menuAdList = data.data;
            } else if (data.rescode == '401') {
                alert('访问超时，请重新登录');
                $state.go('login');
            } else {
                alert('加载广告位信息失败，' + data.errInfo);
            }
        }, function errorCallback(response) {
            alert('连接服务器出错');
        }). finally(function(value) {
            self.loading = false;
        });
    }

    self.close = function(id) {
        if (!confirm('确认删除？')) {
            return;
        }
        var data = JSON.stringify({
            "token": util.getParams('token'),
            "action": "delPos",
            "termGroupID": self.termGroupID - 0,
            "lang": util.langStyle(),
            "data": [
                {
                    "ID": id
                }
            ]
        })

        $http({
            method: 'POST',
            url: util.getApiUrl('advpos', '', 'server'),
            data: data
        }).then(function successCallback(response) {
            var data = response.data;
            if (data.rescode == '200') {
                alert('删除成功');
                self.loadList();
            } else if (data.rescode == '401') {
                alert('访问超时，请重新登录');
                $state.go('login');
            } else {
                alert('删除失败' + data.errInfo);
            }
        }, function errorCallback(response) {
            alert('连接服务器出错');
        }). finally(function(value) {});
    }

    self.menuAdvDel = function(id) {
        if (!confirm('确认删除？')) {
            return;
        }
        var data = JSON.stringify({
            "token": util.getParams('token'),
            "action": "delPos",
            "termGroupID": self.termGroupID,
            "lang": util.langStyle(),
            "data": [
                {
                    "PositionID": id
                }
            ]
        })

        $http({
            method: 'POST',
            url: util.getApiUrl('menuadvpos', '', 'server'),
            data: data
        }).then(function successCallback(response) {
            var data = response.data;
            if (data.rescode == '200') {
                alert('删除成功');
                self.init();
            } else if (data.rescode == '401') {
                alert('访问超时，请重新登录');
                $state.go('login');
            } else {
                alert('删除失败' + data.errInfo);
            }
        }, function errorCallback(response) {
            alert('连接服务器出错');
        }). finally(function(value) {});
    }

    self.submit = function() {
        var conf = confirm('点击确定后会将所有团客的广告设置发布至终端，您确定发布吗？');
        if (!conf) {
            return;
        }
        var data = JSON.stringify({"token": util.getParams('token'), "action": "submit", "lang": util.langStyle(), "type": "Adv"})

        $http({
            method: 'POST',
            url: util.getApiUrl('tvuiversion', '', 'server'),
            data: data
        }).then(function successCallback(response) {
            var data = response.data;
            if (data.rescode == '200') {
                alert('发布成功');
                self.init();
            } else if (data.rescode == '401') {
                alert('访问超时，请重新登录');
                $state.go('login');
            } else {
                alert('发布失败' + data.errInfo);
            }
        }, function errorCallback(response) {
            alert('连接服务器出错');
        }). finally(function(value) {});
    }
    self.add = function() {
        $scope.app.maskParams = {
            'list': self.adList
        };
        $scope.app.maskParams.callback = self.loadList;
        $scope.app.maskParams.termGroupID = self.termGroupID;
        $scope.app.showHideMask(true, 'pages/tv/advAdd.html');
    }

    self.menuAdd = function() {
        $scope.app.maskParams = {
            'list': self.menuAdList
        };
        $scope.app.maskParams.callback = self.init;
        $scope.app.maskParams.termGroupID = self.termGroupID;
        $scope.app.showHideMask(true, 'pages/tv/menuAdvAdd.html');
    }

    self.changeMenuAdvSeq = function(data) {
        $scope.app.maskParams = {
            'data': data
        };
        $scope.app.maskParams.callback = self.init;
        $scope.app.maskParams.termGroupID = self.termGroupID;
        $scope.app.showHideMask(true, 'pages/tv/changeMenuAdvSeq.html');
    }
}
])
//欢迎词
.controller('groupWelcomeController', [
'$scope',
'$state',
'$http',
'$stateParams',
'$translate',
'$filter',
'util',
'NgTableParams',
function($scope, $state, $http, $stateParams, $translate, $filter, util, NgTableParams) {
    console.log('groupWelcomeController');
    var self = this;
    self.init = function() {
        self.defaultLangCode = util.getDefaultLangCode();
        self.langStyle = util.langStyle();
        self.multiLang = util.getParams('editLangs');
        self.saving = false;
        self.form = {};
        // 多语
        self.guestName = {};
        self.welcomeText = {};
        self.form.HotelID = $stateParams.HotelID; //门店ID
        self.groupID = $stateParams.GroupID; //团队ID

        self.saving = false;
        self.loading = false;
        self.getWelInfo();
    }
    self.getWelInfo = function() {
        var data = JSON.stringify({"token": util.getParams('token'), "action": "getWelcomeText", "termGroupID": self.groupID, "lang": util.langStyle()});
        self.loading = true;
        // 获取欢迎页信息
        $http({
            method: 'POST',
            url: util.getApiUrl('termgroup', '', 'server'),
            data: data
        }).then(function successCallback(response) {
            var data = response.data;
            if (data.rescode == '200') {
                // 酒店欢迎辞
                self.welcomeText = data.welcomeText;
                // 酒店客人称呼
                self.guestName = data.guestName;

            } else if (data.rescode == '401') {
                alert('访问超时，请重新登录');
                $state.go('login');
            } else {
                alert('加载欢迎页信息失败，' + data.errInfo);
            }
        }, function errorCallback(response) {
            alert('连接服务器出错');
        }). finally(function(value) {
            self.loading = false;
        });
    }

    self.cancel = function() {
        $scope.app.showHideMask(false);
    }

    self.save = function() {
        var data = {
            "action": "addWelcomeText",
            "token": util.getParams("token"),
            "lang": self.langStyle,
            "hotelID": self.form.HotelID,
            "termGroupID": self.groupID,
            "welcomeText": self.welcomeText,
            "guestName": self.guestName
        };
        data = JSON.stringify(data);
        self.saving = true;

        $http({
            method: $filter('ajaxMethod')(),
            url: util.getApiUrl('termgroup', 'addGroup.json', ''),
            data: data
        }).then(function successCallback(data, status, headers, config) {
            if (data.data.rescode == "200") {
                alert('添加成功')
                $scope.app.showHideMask(false);
                $state.reload('app.group.Welcome');
            } else if (data.data.rescode == "401") {
                alert('访问超时，请重新登录');
                $state.go('login')
            } else {
                alert('添加失败， ' + data.data.errInfo);
            }
        }, function errorCallback(data, status, headers, config) {
            alert('添加失败， ' + data.data.errInfo);
        }). finally(function(value) {
            self.saving = false;
        });
    }
}
])
// 弹窗列表/messageList
.controller('groupMessageController', [
'$scope',
'$state',
'$http',
'$stateParams',
'$translate',
'$filter',
'$uibModal',
'moment',
'util',
'NgTableParams',
function($scope, $state, $http, $stateParams, $translate, $filter, $uibModal, moment, util, NgTableParams){
    console.log('groupMessageController');
    var vm = this;

    var STATUS = {
        UNSENT: {
            text: '待发送',
            textClass: 'white-text',
            bgClass: 'mdc-bg-grey-300',
            code: 1,
        },
        FAILSENT: {
            text: '发送失败',
            textClass:'white-text',
            bgClass:'mdc-bg-red-A200',
            code: 2,
        },
        SUCCSENT: {
            text: '{{row.readNum}}/{{row.desNum}}',
            textClass:'white-text',
            bgClass: 'mdc-bg-green-A400',
            code: 3,
        },
        COMPLETE: {
            text: '已完成',
            textClass:'white-text',
            bgClass: 'mdc-bg-light-blue-A400',
            code: 4,
        },
        DATACORRUPTTED: {
            text: '数据出错',
            textClass:'white-text',
            bgClass: 'mdc-bg-red-800',
            code : 5
        }
    }
    vm.init = function(){
        // Data initialization
        vm.messageData = [];
        vm.tableParams = null;
        vm.getMessageList();
    }
    vm.populateTableParams = function(){
        vm.tableParams = new NgTableParams({
            page:1,
            count:10,
            url:''
        },{
            counts: [],
            total: vm.messageData.length,
            dataset:vm.messageData
        });
    }
    // NgTableParams' getData function
    vm.getMessageList = function(){
        var data = {
        	'action': 'getPicMessageList',
        	'token': util.getParams('token'),
        	'lang': util.langStyle()
        };
        data = JSON.stringify(data);
        return $http({
            method: $filter('ajaxMethod')(),
            url: util.getApiUrl('hotelmessage'),
            data: data
        }).then(function(data){
            switch (data.data.rescode) {
                case 200:
                case '200':
                    var df = 'YYYY/MM/DD HH:mm';
                    // change time format and add status field
                    data.data.message.map(function(d){
                        // Parse time
                        var st = d.Cmdparas.startTime;
                        var et = d.Cmdparas.endTime;
                        d.Cmdparas.startTime = st ? moment(st).format(df):null;
                        d.Cmdparas.endTime = et ? moment(et).format(df):null;
                        d.status = vm.getStatus(d);
                    });
                    vm.messageData = data.data.message.sort(vm.sortDataMessage);
                    vm.populateTableParams();
                    break;
                case 401:
                case '401':
                    alert('访问超时，请重新登录');
                    $state.go('login');
                    break;
                default:
                    alert('Error ' + data.rescode + data.errInfo);
                    return []
            }
        }, function(err){
            alert(err);
        })
    }
    vm.sortDataMessage = function(a, b){
        var sac = a.status.code;
        var sbc = b.status.code;
        if (sac < sbc) return -1;
        if (sac > sbc) return 1;
        return 0;
    }
    // A simply util to parse readNum, desNum and consumedNum into
    // human readable status
    vm.getStatus = function(data){
        var cn = parseInt(data.consumedNum);
        var rn = parseInt(data.readNum);
        if (data.desNum == 0) {
            return STATUS.FAILSENT;
        }
        if (cn == 0){
            return STATUS.UNSENT;
        } else {
            if (rn < cn) {
                return Object.assign({},STATUS.SUCCSENT, {
                    text: String(rn) + '/' + String(cn)
                });
            } else {
                return Object.assign({},STATUS.COMPLETE, {
                    text: rn + '/' + cn + '(已完成)'
                });
            }
        }
    }

    /* *******************
    * Edit and delete
    * *********************
    */
    vm.edit = function(rowData){
        var instance = $uibModal.open({
            templateUrl: 'pages/group/editGroupMessageModal.html',
            controller: 'editGroupMessageController',
            controllerAs: 'ec',
            resolve: {
                onSave: function(){
                    return vm.editMessage
                },
                messageData: function(){
                    return rowData
                }
            }
        })

        instance.result.then(function(result){
            console.log(result);
            $state.reload('app.group.messageList');
        }, function(){
            console.log('editGroupMessageModal Dissmissed');
        });
    }

    vm.editMessage = function(data) {
        var request = {
            'action': 'editPicMessageDetail',
            'token': util.getParams('token'),
            'lang': util.langStyle(),
            'messageID': data.messageID,
            'CmdParas': {
                'Content':data.body,
                'endTime': data.endTime,
                'picUrl': data.imgUrl,
                'startTime': data.startTime,
                'Title': data.title
            }
        }
        return $http({
            method: 'POST',
            url: util.getApiUrl('hotelmessage'),
            data: JSON.stringify(request)
        });
    }

    vm.delete = function(rowData){
        var action = '删除消息'
        var conf = confirm('确认' + action + '?');
        if (!conf) {
            return;
        }
        var request = {
            'action': 'delMessageSingle',
            'token': util.getParams('token'),
            'lang': util.langStyle(),
            'messageID': rowData.messageID
        }
        $http({
            method: 'POST',
            url: util.getApiUrl('hotelmessage'),
            data: JSON.stringify(request)
        }).then(vm.succ(action), vm.fail(action));
    }

    vm.succ = function(type) {
        return function(data, status, headers, config){
            if (data.data.rescode == '200') {
                alert(type + '成功!');
                $state.reload('app.group.messageList');
            } else if (msg.rescode == '401') {
                alert('访问超时，请重新登录');
                $state.go('login');
            } else {
                alert(type + '失败!' + data.data.errInfo);
            }
        }
    }

    vm.fail = function(type) {
        return function() {
            alert(type + '失败!');
        }
    }

    /* *******************
    * END of Edit and delete
    * *********************
    */
}
])
// 编辑客信 Modal
.controller('editGroupMessageController', [
'$scope',
'$uibModalInstance',
'moment',
'Upload',
'CONFIG',
'onSave',
'messageData',
function($scope, $uibModalInstance, moment, Upload, CONFIG, onSave, messageData){
    var vm = this;
    vm.buttonBar = {
        show: true,
        now: {text: '现在'},
        today: {text: '今天'},
        clear: {text: '清除'},
        date: {text: '返回选择日期'},
        time: {text: '选择时间'},
        close: {text: '确认'},
        cancel: {text: '取消'}
    }
    // We could put this with pushNewMessageModalController
    // But that requires a little bit more work and we are short on time
    vm.init = function(){
        // initialization + default values injection
        vm.isStartCalendarOpen = false;
        vm.isEndCalendarOpen = false;
        var params = messageData.Cmdparas;

        var st = params.startTime ? moment(params.startTime).toDate():null;
        var et = params.endTime ? moment(params.endTime).toDate():null;

        vm.form = {
            messageID: messageData.messageID,
            title: params.Title,
            body: params.Content,
            startTime: st,
            endTime: et,
            imgUrl: params.picUrl
        }

        vm.file = null;
        vm.uploading = false;
        vm.uploadProgress = 0;
    }

    vm.openCalendar = function(type){
        switch (type) {
            case 'start':
                vm.isStartCalendarOpen = true;
                break;
            case 'end':
                vm.isEndCalendarOpen = true;
                break;
            default:
                break;

        }
    }
    vm.removeImg = function(){
        vm.form.imgUrl = null;
        vm.file = null;
    }

    vm.upload = function(){
        if (!vm.file){
            console.log('No File');
            return;
        }
        vm.uploading = true;
        Upload.upload({
            url: CONFIG.uploadUrl,
            data: {
                file: vm.file
            }
        }).then(function(resp){
            if(resp.status == 200){
                vm.form.imgUrl = resp.data.upload_path;
                console.log('Successfully uploaded ' + resp.data.upload_path);
            } else {
                console.log(resp);
            }
        }, function(err){
            console.log(err);
        }, function(evt){
            var progressPercentage = parseInt(100.0 * evt.loaded / evt.total);
            vm.uploadProgress = progressPercentage;
            console.log('progress: ' + progressPercentage + '% ' + evt.config.data.file.name);
        }).finally(function(){
            vm.uploading = false;
        });
    }


    vm.ok = function(){
        onSave(vm.form).then(function(data){
            if (data.data.rescode == 200) {
                alert('修改成功!')
                $uibModalInstance.close();
            } else if (data.data.rescode == 401) {
                alert('访问超时，请重新登录');
                $state.go('login')
            } else {
                alert('修改失败! ' + data.data.errInfo);
            }
        },function(err){
            alert('修改失败! ' + err.data.errInfo);
        }).finally(function(){

        });
    }
    vm.cancel = function(){
        $uibModalInstance.dismiss('cancel');
    }
}
])

})();
