// 资源库
"use strict";
(function() {
  var app = angular
    .module("app.material", [])
    .controller("materialController", [
      "$scope",
      "$http",
      "$state",
      "util",
      "$filter",
      "NgTableParams",
      "$location",
      function($scope, $http, $state, util, $filter, NgTableParams, $location) {
        var self = this;
        self.init = function() {
          self.filelist = [];
          self.selectedFile = { items: [] };
          self.permission = "0";
          self.imgs = new util.initUploadImgs([], $scope, true, self.callback);
          self.getMaterialList();
        };

        // 上传成功后回调
        self.callback = function(data) {
          self.uploadInfo = data;
        };

        // 添加资源
        self.addMaterial = function(res) {
          if (self.imgs.data.length == 0) {
            alert("请上传文件");
            return false;
          } else if (self.imgs.data[0].progress < 100) {
            alert("正在上传中，请稍后...");
            return false;
          } else if (self.imgs.data[0].progress == -1) {
            alert("上传失败，请重新上传");
            return false;
          }
          var data = JSON.stringify({
            name: self.fileName,
            upload_path: res.upload_path,
            md5: res.md5,
            size: res.size,
            tags: [$scope.app.materialTag],
            duration: res.duration,
            permission: self.permission,
            token: util.getParams("token"),
            action: "addFile"
          });
          $http({
            method: "POST",
            url: util.getApiUrl("material", "", "server"),
            data: data
          })
            .then(
              function successCallback(response) {
                var data = response.data;
                console.log(data);
                if (data.rescode == "200") {
                  alert("添加成功");
                  self.getMaterialList();
                  self.imgs = new util.initUploadImgs(
                    [],
                    $scope,
                    true,
                    self.callback
                  );
                  self.uploadInfo = {};
                  self.showUpload = false;
                } else if (data.rescode == "401") {
                  alert("访问超时，请重新登录");
                  $state.go("login");
                } else {
                  alert("添加失败，" + data.errInfo);
                }
              },
              function errorCallback(response) {
                alert("连接服务器出错");
              }
            )
            .finally(function(value) {
              self.saving = false;
            });
        };

        // 图片上传相关
        self.clickUpload = function(e) {
          setTimeout(function() {
            document.getElementById(e).click();
          }, 0);
        };

        self.cancel = function() {
          $scope.app.showHideMaterialMask(false);
        };

        self.selectItem = function(item) {
          self.selectedFile[item.ID] = !self.selectedFile[item.ID];

          // 是否多选
          if (!$scope.app.materialMulti) {
            self.selectedFile.items = [];
            if (self.selectedFile[item.ID]) {
              self.selectedFile.items.push(item);
            }
            // 单选
            for (var i in self.selectedFile) {
              if (i == "items") continue;
              if (i != item.ID) {
                self.selectedFile[i] = false;
              }
            }
          } else {
            // 多选
            // console.log($scope.app.selectedMaterial.indexOf(item))
            if (
              self.selectedFile[item.ID] &&
              $scope.app.selectedMaterial.indexOf(item) == -1
            ) {
              self.selectedFile.items.push(item);
            } else {
              var idx = $scope.app.selectedMaterial.indexOf(item);
              self.selectedFile.items.splice(idx, 1);
            }
          }
        };

        self.getMaterialList = function() {
          self.tableParams = new NgTableParams(
            {
              page: 1,
              count: 12,
              url: ""
            },
            {
              counts: false,
              getData: function(params) {
                var paramsUrl = params.url();
                var data = JSON.stringify({
                  tag: $scope.app.materialTag,
                  token: util.getParams("token"),
                  action: "getFileList",
                  page: paramsUrl.page - 0,
                  per_page: paramsUrl.count - 0
                });
                self.loading = true;
                self.noData = false;

                return $http({
                  method: "POST",
                  url: util.getApiUrl("material", "", "server"),
                  data: data
                })
                  .then(
                    function successCallback(response) {
                      var data = response.data;

                      if (data.rescode == "200") {
                        params.total(data.total);
                        data.filelist.forEach(function(item, index) {
                          item.isImg = /.(gif|jpg|jpeg|png|gif|jpg|png)$/.test(
                            item.FileUrl
                          );
                          item.isVideo = /.(mp4|avi|wmv|3gp|flv|swf|mpg)$/.test(
                            item.FileUrl
                          );
                        });
                        console.log(data.filelist);
                        return data.filelist;
                      } else if (data.rescode == "401") {
                        alert("访问超时，请重新登录");
                        $state.go("login");
                      } else {
                        alert("获取列表失败，" + data.errInfo);
                      }
                    },
                    function errorCallback(response) {
                      alert("连接服务器出错");
                    }
                  )
                  .finally(function(value) {
                    self.loading = false;
                  });
              }
            }
          );
        };

        self.submitSelected = function() {
          $scope.app.setMaterial(self.selectedFile.items);
          if (self.selectedFile.items.length == 0) {
            alert("请选择素材");
            return false;
          }
          $scope.app.showHideMaterialMask(false);
        };
      }
    ]);
})();
