'use strict';

(function () {
    var app = angular.module('app.tv-admin-controllers', [])

        .controller('tvAdminController', ['$scope', '$state', '$http', '$stateParams', '$location', 'util', 'CONFIG',
            function ($scope, $state, $http, $stateParams, $location, util, CONFIG) {
                var self = this;
                var my_tree;
                $scope.my_tree = my_tree = {};

                self.init = function () {
                    // 默认加载欢迎页面
                    self.initS = $stateParams.label ? $stateParams.label : '欢迎页面';
                    self.menuRoot = false;
                    self.MainMenu_THJ_SecondMenu = false;
                    self.MainMenu_YCJ_SecondMenu = false;
                    self.MainMenu_QHtl_SecondMenu = false;
                    self.MainMenu_LiFeng_SecondMenu = false;
                    self.MainMenu_SX_SecondMenu = false;
                    self.MainMenu_Yeste_SecondMenu = false;
                    self.MainMenu_SiMaTai_SecondMenu = false;
                    self.MainMenuCommon_SecondMenu = false;
                    // 获取编辑多语言信息
                    self.editLangs = util.getParams('editLangs');

                    // 语言菜单编辑信息
                    self.menu = {};
                }

                // 资源选择
                self.selectMaterial = function (img) {
                    $scope.app.showHideMaterialMask(true, 'MenuIcon')
                    // 选择完资源设置
                    $scope.app.setMaterial = function (data) {
                        self[img] = data
                    }
                }

                // 删除当前菜单
                self.deleteMenu = function () {

                    if (!confirm('确认删除？')) {
                        return;
                    }

                    // 区分一二级菜单
                    var menuLv = my_tree.get_selected_branch().data.menuLevel;

                    // menuId
                    var menuId = my_tree.get_selected_branch().data.menuId;

                    var action = menuLv == 1 ? "deleteMainMenuFirstMenu" : "deleteMainMenuSecondMenu";
                    var delMenu = {};

                    if (menuLv == 1) {
                        delMenu.firstMenuID = menuId;
                    }
                    else {
                        delMenu.SecondMenuID = menuId;
                    }

                    var data = JSON.stringify({
                        "token": util.getParams('token'),
                        "action": action,
                        "viewID": 1,     //主菜单模板ViewID都为1
                        "data": delMenu,
                        "lang": util.langStyle()
                    })

                    // 开始删除
                    self.menuDeleting = true;

                    $http({
                        method: 'POST',
                        url: util.getApiUrl('commonview', '', 'server'),
                        data: data
                    }).then(function successCallback(response) {
                        var data = response.data;
                        if (data.rescode == '200') {
                            // 删除树元素
                            var b, p, l, state;
                            b = my_tree.get_selected_branch();
                            p = my_tree.get_parent_branch(b);
                            p.children.splice(p.children.indexOf(b), 1);

                            alert('已删除');
                            $state.go('app.tvAdmin.blank', { label: p.label }, { reload: true });

                        } else if (data.rescode == '401') {
                            alert('访问超时，请重新登录');
                            $state.go('login');
                        } else {
                            alert('删除失败，' + data.errInfo);
                        }
                    }, function errorCallback(response) {
                        alert('连接服务器出错');
                    }).finally(function (value) {
                        self.menuDeleting = false;
                    });
                }

                // 修改欢迎页风格按钮
                self.changeWelStyle = function () {
                    $scope.app.maskParams = { 'viewName': my_tree.get_selected_branch().data.viewName };
                    $scope.app.showHideMask(true, 'pages/tv/welStyleChange.html');
                }

                // 修改首页风格
                self.changeMainStyle = function () {
                    $scope.app.maskParams = { 'viewName': my_tree.get_selected_branch().data.viewName };
                    $scope.app.showHideMask(true, 'pages/tv/styleChange.html');
                }

                // 添加菜单
                self.addMenu = function (menuLv, parentMenuId) {
                    $scope.app.maskParams = { 'menuLv': menuLv };
                    $scope.app.showHideMask(true, 'pages/tv/menuAdd.html');
                }

                // 主菜单模块
                self.addModule = function (menuLv) {
                    var parentMenu = {};
                    if (menuLv != 1) {
                        parentMenu = {
                            id: my_tree.get_selected_branch().data.menuId,
                            name: my_tree.get_selected_branch().label
                        }
                    }
                    $scope.app.maskParams = { 'menuLv': menuLv, 'parentMenu': parentMenu };
                    $scope.app.showHideMask(true, 'pages/tv/moduleAdd.html');
                }

                // 菜单点击
                $scope.my_tree_handler = function (branch) {
                    console && console.log('select ' + branch.label, 'type: ' + branch.data.type);
                    // 挂载当前模块类型到全局
                    $scope.app.moduleType = branch.data.type
                    // welcome
                    if (branch.data.type == "welcome") {
                        $state.go('app.tvAdmin.welcome', { label: branch.label });
                    }

                    // version
                    if (branch.data.type == 'version') {
                        $state.go('app.tvAdmin.version', { label: branch.label });
                    }

                    // guangGaoWei
                    if (branch.data.type == 'guangGaoWei') {
                        $state.go('app.tvAdmin.guangGaoWei', { label: branch.label });
                    }

                    // projectConfig
                    if (branch.data.type == 'projectConfig') {
                        $state.go('app.tvAdmin.projectConfig', { label: branch.label });
                    }

                    // menuRoot
                    if (branch.data.type == 'menuRoot') {
                        $state.go('app.tvAdmin.blank', { label: branch.label });
                    }

                    // live
                    if (branch.data.type == "Live"
                        || branch.data.type == "CommonLiveView"
                        || branch.data.type == "Live_SX"
                        || branch.data.type == 'QHtl_Live'
                        || branch.data.type == 'DVB_C'
                        || branch.data.type == 'SkyworthDTMB') {
                        $state.go("app.tvAdmin.live", {
                            moduleId: branch.data.moduleId,
                            label: branch.label
                        });
                        self.changeMenuInfo();
                    }

                    // simplePicText
                    if (branch.data.type == 'SimplePicText'
                        || branch.data.type == 'CommonPicTextView'
                        || branch.data.type == 'SimpleSmallPicText'
                        || branch.data.type == 'SimplePicText_SX'
                        || branch.data.type == 'LiFeng_SimpleSmallPicText_Left'
                        || branch.data.type == 'LiFeng_SimpleSmallPicText'
                        || branch.data.type == 'ZheFei_SimpleSmallPicText_Small'
                        || branch.data.type == 'Yeste_SimpleSmallPicText'
                        || branch.data.type == 'Yeste_SimpleSmallPicText_Carousel'
                        || branch.data.type == 'SiMaTai_SimpleSmallPicText'
                        || branch.data.type == 'QHtl_SimplePicText'
                        || branch.data.type == 'ZheFei_SimpleSmallPicText_Carousel'
                        || branch.data.type == 'SimplePicText_Price') {
                        $state.go('app.tvAdmin.simplePicText', { moduleId: branch.data.moduleId, label: branch.label });
                        self.changeMenuInfo();
                    }

                    // 分类图文
                    if (branch.data.type == 'PicText_Classification'
                        || branch.data.type == 'SiMaTai_PicText_Classification'
                        || branch.data.type == 'Samsung_PicText_Classification'
                        || branch.data.type == 'Samsung_Lunch_PicText_Classification'
                        || branch.data.type == 'PicText_Classification_SX'
                        || branch.data.type == 'ZheFei_PicText_Classification'
                        || branch.data.type == 'YCJ_PicText') {
                        $state.go('app.tvAdmin.PicText_Classification', { moduleId: branch.data.moduleId, label: branch.label });
                        self.changeMenuInfo();
                    }

                    //司马台 二级分类图文 SiMaTai_PicText_Classification
                    // if (branch.data.type == 'SiMaTai_PicText_Classification') {
                    //     $state.go('app.tvAdmin.SiMaTai_PicText_Classification', { moduleId: branch.data.moduleId, label: branch.label });
                    //     self.changeMenuInfo();
                    // }

                    //司马台 三级分类图文
                    if (branch.data.type == 'SiMaTai_PicText_Classification_ThreeLevel') {
                        $state.go('app.tvAdmin.SiMaTai_PicText_Classification_ThreeLevel', { moduleId: branch.data.moduleId, label: branch.label });
                        self.changeMenuInfo();
                    }

                    // 社区四级分类图文
                    if (branch.data.type == 'Fujia_FourLevel') {
                        $state.go('app.tvAdmin.Fujia_PicText_Classification_FourLevel', { moduleId: branch.data.moduleId, label: branch.label });
                        self.changeMenuInfo();
                    }

                    // 社区视频
                    if (branch.data.type == 'FujiaVideo') {
                        $state.go('app.tvAdmin.FujiaVideo', { moduleId: branch.data.moduleId, label: branch.label });
                        self.changeMenuInfo();
                    }


                    //三星天气 Samsung_Weather
                    if (branch.data.type == 'Samsung_Weather') {
                        $state.go('app.tvAdmin.Samsung_Weather', { moduleId: branch.data.moduleId, label: branch.label });
                        self.changeMenuInfo();
                    }

                    //通用天气 WeatherCommon
                    if (branch.data.type == 'WeatherCommon') {
                        $state.go('app.tvAdmin.WeatherCommon', { moduleId: branch.data.moduleId, label: branch.label });
                        self.changeMenuInfo();
                    }

                    //三星账单
                    if (branch.data.type == 'Samsung_Link_Bill') {

                        $state.go('app.tvAdmin.Samsung_Link_Bill', { moduleId: branch.data.moduleId, label: branch.label });
                        self.changeMenuInfo();
                    }

                    //SkyworthDTMB
                    // if (branch.data.type == 'SkyworthDTMB') {
                    //     $state.go('app.tvAdmin.SkyworthDTMB', { moduleId: branch.data.moduleId, label: branch.label });
                    //     self.changeMenuInfo();
                    // }

                    //multPic
                    if (branch.data.type == 'MultPic'
                        || branch.data.type == 'CommonMultPicView'
                        || branch.data.type == 'Samsung_MultPic_OutOfTheHotel'
                        || branch.data.type == 'MultPic_SX_Big'
                        || branch.data.type == 'MultPic_SX_Small'
                        || branch.data.type == 'LiFeng_MultPic'
                        || branch.data.type == 'ZheFei_MultPic'
                        || branch.data.type == 'Samsung_MultPic_Flight') {
                        $state.go('app.tvAdmin.multPic', { moduleId: branch.data.moduleId, label: branch.label });
                        self.changeMenuInfo();
                    }

                    //MultPic_SX_Small 书香多图（小）
                    // if (branch.data.type == 'MultPic_SX_Small') {
                    //     $state.go('app.tvAdmin.MultPic_SX_Small', { moduleId: branch.data.moduleId, label: branch.label });
                    //     self.changeMenuInfo();
                    // }

                    //PicText_Classification_SX 书香图文分类
                    // if (branch.data.type == 'PicText_Classification_SX') {
                    //     $state.go('app.tvAdmin.PicText_Classification_SX', { moduleId: branch.data.moduleId, label: branch.label });
                    //     self.changeMenuInfo();
                    // }
                    //ZheFei_PicText_Classification 喆啡图文分类
                    // if (branch.data.type == 'ZheFei_PicText_Classification') {
                    //     // type 和 state 的名字不一样
                    //     $state.go('app.tvAdmin.ZheFei_PicText_Classification', { moduleId: branch.data.moduleId, label: branch.label });
                    //     self.changeMenuInfo();
                    // }
                    // 3rdApp
                    if (branch.data.type == '3rdApp') {
                        $state.go('app.tvAdmin.3rdApp', { moduleId: branch.data.moduleId, label: branch.label });
                        self.changeMenuInfo();
                    }

                    // apkEntry
                    if (branch.data.type == 'ApkEntry') {
                        $state.go('app.tvAdmin.apkEntry', { moduleId: branch.data.moduleId, label: branch.label });
                        self.changeMenuInfo();
                    }

                    // worldClock
                    if (branch.data.type == 'WorldClock'
                        || branch.data.type == 'CommonWorldClockView'
                        || branch.data.type == 'HotelMessage') {
                        $state.go('app.tvAdmin.worldClock', { moduleId: branch.data.moduleId, label: branch.label });
                        self.changeMenuInfo();
                    }

                    // QHtl_worldClock
                    if (branch.data.type == 'QHtl_WorldClock') {
                        $state.go('app.tvAdmin.QHtl_worldClock', { moduleId: branch.data.moduleId, label: branch.label });
                        self.changeMenuInfo();
                    }

                    //雅思特 世界时钟 Yeste_WorldClock
                    if (branch.data.type == 'Yeste_WorldClock') {
                        $state.go('app.tvAdmin.Yeste_WorldClock', { moduleId: branch.data.moduleId, label: branch.label });
                        self.changeMenuInfo();
                    }
                    // SkyworthATV
                    if (branch.data.type == 'SkyworthATV') {
                        $state.go('app.tvAdmin.SkyworthATV', { moduleId: branch.data.moduleId, label: branch.label });
                        self.changeMenuInfo();
                    }
                    // SkyworthHDMI
                    if (branch.data.type == 'SkyworthHDMI') {
                        $state.go('app.tvAdmin.SkyworthHDMI', { moduleId: branch.data.moduleId, label: branch.label });
                        self.changeMenuInfo();
                    }
                    //BaoFengHDMI
                    if (branch.data.type == 'BaoFengHDMI') {
                        $state.go('app.tvAdmin.BaoFengHDMI', { moduleId: branch.data.moduleId, label: branch.label });
                        self.changeMenuInfo();
                    }

                    // SkyworthDTV
                    if (branch.data.type == 'SkyworthDTV') {
                        $state.go('app.tvAdmin.SkyworthDTV', { moduleId: branch.data.moduleId, label: branch.label });
                        self.changeMenuInfo();
                    }

                    // shop
                    if (branch.data.type == 'Shop') {
                        $state.go('app.tvAdmin.shop', { moduleId: branch.data.moduleId, label: branch.label });
                        self.changeMenuInfo();
                    }

                    // ShopOnline
                    if (branch.data.type == 'ShopOnline') {
                        $state.go('app.tvAdmin.ShopOnline', { moduleId: branch.data.moduleId, label: branch.label });
                        self.changeMenuInfo();
                    }

                    // QHtl_shop
                    if (branch.data.type == 'QHtl_Shop') {
                        $state.go('app.tvAdmin.QHtl_shop', { moduleId: branch.data.moduleId, label: branch.label });
                        self.changeMenuInfo();
                    }

                    // movieCommon
                    if (branch.data.type == 'MovieCommon'
                        || branch.data.type == 'CommonMovieView') {
                        $state.go('app.tvAdmin.movieCommon', { moduleId: branch.data.moduleId, label: branch.label });
                        self.changeMenuInfo();
                    }

                    // TVSeriesCommon
                    if (branch.data.type == 'TVSeriesCommon') {
                        $state.go('app.tvAdmin.TVSeriesCommon', { moduleId: branch.data.moduleId, label: branch.label });
                        self.changeMenuInfo();
                    }

                    // TVSeriesLocal
                    if (branch.data.type == 'TVSeriesLocal') {
                        $state.go('app.tvAdmin.TVSeriesLocal', { moduleId: branch.data.moduleId, label: branch.label });
                        self.changeMenuInfo();
                    }


                    // QHtlMovieCommon
                    if (branch.data.type == 'QHtl_MovieCommon') {
                        $state.go('app.tvAdmin.QHtlMovieCommon', { moduleId: branch.data.moduleId, label: branch.label });
                        self.changeMenuInfo();
                    }

                    // movieCommonAdv
                    if (branch.data.type == 'MovieCommonAdv') {
                        $state.go('app.tvAdmin.movieCommonAdv', { moduleId: branch.data.moduleId, label: branch.label });
                        self.changeMenuInfo();
                    }

                    // movieCommonFree
                    if (branch.data.type == 'MovieCommonFree') {
                        $state.go('app.tvAdmin.movieCommonFree', { moduleId: branch.data.moduleId, label: branch.label });
                        self.changeMenuInfo();
                    }

                    // movieTopic 专题大片
                    if (branch.data.type == 'MovieTopic') {
                        $state.go('app.tvAdmin.MovieTopic', { moduleId: branch.data.moduleId, label: branch.label });
                        self.changeMenuInfo();
                    }

                    // movieLocal 项目本地电影
                    if (branch.data.type == 'MovieLocal') {
                        $state.go('app.tvAdmin.MovieLocal', { moduleId: branch.data.moduleId, label: branch.label });
                        self.changeMenuInfo();
                    }

                    //FY_TVSeriesLocal
                    if (branch.data.type == 'FY_TVSeriesLocal') {
                        $state.go('app.tvAdmin.FY_TVSeriesLocal', { moduleId: branch.data.moduleId, label: branch.label });
                        self.changeMenuInfo();
                    }

                    //FY_MovieLocal富悦本地电影
                    if (branch.data.type == 'FY_MovieLocal') {
                        $state.go('app.tvAdmin.FY_MovieLocal', { moduleId: branch.data.moduleId, label: branch.label });
                        self.changeMenuInfo();
                    }

                    // MusicCommon
                    if (branch.data.type == 'MusicCommon') {

                        $state.go('app.tvAdmin.MusicCommon', { moduleId: branch.data.moduleId, label: branch.label });
                        self.changeMenuInfo();
                    }
                    //
                    if (branch.data.type == 'MusicLocal') {

                        $state.go('app.tvAdmin.MusicLocal', { moduleId: branch.data.moduleId, label: branch.label });
                        self.changeMenuInfo();
                    }
                    // MusicCommonSX
                    if (branch.data.type == 'MusicCommon_SX') {

                        $state.go('app.tvAdmin.MusicCommon_SX', { moduleId: branch.data.moduleId, label: branch.label });
                        self.changeMenuInfo();
                    }

                    // WeatherSX
                    if (branch.data.type == 'Weather_SX') {

                        $state.go('app.tvAdmin.Weather_SX', { moduleId: branch.data.moduleId, label: branch.label });
                        self.changeMenuInfo();
                    }
                    //Yeste_Weather
                    if (branch.data.type == 'Yeste_Weather') {

                        $state.go('app.tvAdmin.Yeste_Weather', { moduleId: branch.data.moduleId, label: branch.label });
                        self.changeMenuInfo();
                    }

                    //WeatherCommon
                    if (branch.data.type == 'WeatherCommon') {

                        $state.go('app.tvAdmin.WeatherCommon', { moduleId: branch.data.moduleId, label: branch.label });
                        self.changeMenuInfo();
                    }

                    /**
                     * -------------------------------------  烟草局  -------------------------------------------
                     * author: caizb
                     * date: 2017-8-2
                     */
                    // 烟草局电影
                    if (branch.data.type == 'YCJ_MovieCommon') {
                        $state.go('app.tvAdmin.YCJ_MovieCommon', { moduleId: branch.data.moduleId, label: branch.label });
                        self.changeMenuInfo();
                    }

                    // 烟草局多图文
                    if (branch.data.type == 'YCJ_MultPic') {
                        $state.go('app.tvAdmin.YCJ_MultPic', { moduleId: branch.data.moduleId, label: branch.label });
                        self.changeMenuInfo();
                    }

                    // 烟草局单视频
                    if (branch.data.type == 'YCJ_SingleVideoText') {
                        $state.go('app.tvAdmin.YCJ_SingleVideoText', { moduleId: branch.data.moduleId, label: branch.label });
                        self.changeMenuInfo();
                    }

                    // 烟草局多视频
                    if (branch.data.type == 'YCJ_MultVideoText') {
                        $state.go('app.tvAdmin.YCJ_MultVideoText', { moduleId: branch.data.moduleId, label: branch.label });
                        self.changeMenuInfo();
                    }

                    // 烟草局培训动态
                    if (branch.data.type == 'YCJ_TextList') {
                        $state.go('app.tvAdmin.YCJ_TextList', { moduleId: branch.data.moduleId, label: branch.label });
                        self.changeMenuInfo();
                    }

                    // 烟草局分类视频
                    if (branch.data.type == 'YCJ_MultVideoCategory') {
                        $state.go('app.tvAdmin.YCJ_MultVideoCategory', { moduleId: branch.data.moduleId, label: branch.label });
                        self.changeMenuInfo();
                    }


                    // 烟草局 WPS 文件
                    if (branch.data.type == 'WPS_FileCommon') {
                        $state.go('app.tvAdmin.WPS_FileCommon', { moduleId: branch.data.moduleId, label: branch.label });
                        self.changeMenuInfo();
                    }

                    // 烟草局送站信息
                    if (branch.data.type == 'StationInfo_YCJ') {
                        $state.go('app.tvAdmin.StationInfo_YCJ', { moduleId: branch.data.moduleId, label: branch.label });
                        self.changeMenuInfo();
                    }

                    // 航班
                    if (branch.data.type == 'Flight') {
                        $state.go('app.tvAdmin.Flight', { moduleId: branch.data.moduleId, label: branch.label });
                        self.changeMenuInfo();
                    }

                    // 账单
                    if (branch.data.type == 'Bill') {
                        $state.go('app.tvAdmin.Bill', { moduleId: branch.data.moduleId, label: branch.label });
                        self.changeMenuInfo();
                    }

                    // 线上超市
                    if (branch.data.type == 'ShopDesign') {
                        $state.go('app.tvAdmin.ShopDesign', { moduleId: branch.data.moduleId, label: branch.label });
                        self.changeMenuInfo();
                    }

                    // MainMenu_THJ_SecondMenu
                    if (branch.data.type == 'MainMenu_THJ_SecondMenu'
                        || branch.data.type == 'MainMenu_YCJ_SecondMenu'
                        || branch.data.type == 'MainMenu_QHtl_SecondMenu'
                        || branch.data.type == 'MainMenu_SX_SecondMenu'
                        || branch.data.type == 'MainMenu_LiFeng_SecondMenu'
                        || branch.data.type == 'MainMenu_Yeste_SecondMenu'
                        || branch.data.type == 'MainMenu_SiMaTai_SecondMenu'
                        || branch.data.type == 'MainMenuCommon_SecondMenu'
                        || branch.data.type == 'MainMenu_Samsung_SecondMenu') {
                        $state.go('app.tvAdmin.blank', { label: branch.label });
                        self.changeMenuInfo();
                    }

                    // 汇率
                    if (branch.data.type == 'ExRate') {
                        $state.go('app.tvAdmin.ExRate', { moduleId: branch.data.moduleId, label: branch.label });
                        self.changeMenuInfo();
                    }

                }

                self.changeMenuInfo = function () {
                    // 诡异的问题，如果直接赋值，会变成双向绑定，改成以下
                    var name = JSON.stringify(my_tree.get_selected_branch().data.name);
                    self.menu.name = JSON.parse(name);
                    // 以上
                    // self.imgs3 = new Imgs([{ "ImageURL": my_tree.get_selected_branch().data.img, "ImageSize": my_tree.get_selected_branch().data.imgSize }], true);
                    // self.imgs3.initImgs();
                    self.selectedImg1 = []
                    self.selectedImg2 = []
                    self.selectedImg1.push({
                        FileUrl: my_tree.get_selected_branch().data.img,
                        FileSize: my_tree.get_selected_branch().data.imgSize
                    })
                    // self.imgs4 = new Imgs([{ "ImageURL": my_tree.get_selected_branch().data.focusImg, "ImageSize": my_tree.get_selected_branch().data.focusImgSize }], true);
                    // self.imgs4.initImgs();
                    self.selectedImg2.push({
                        FileUrl: my_tree.get_selected_branch().data.focusImg,
                        FileSize: my_tree.get_selected_branch().data.focusImgSize
                    })


                    self.menu.des = my_tree.get_selected_branch().data.des;
                    self.menu.seq = my_tree.get_selected_branch().data.seq;
                    self.menu.menuState = my_tree.get_selected_branch().data.menuState
                    self.menuSaving = false;
                    console.log(self.menu.des)
                }

                // 修改菜单信息
                self.saveTvMenu = function (refresh) {

                    var menuLv = my_tree.get_selected_branch().data.menuLevel;
                    var action = menuLv == 1 ? 'editMainMenuFirstMenu' : 'editMainMenuSecondMenu';
                    var menuId = my_tree.get_selected_branch().data.menuId;

                    var data = JSON.stringify({
                        "token": util.getParams('token'),
                        "action": action,
                        "viewID": 1,     //主菜单模板ViewID都为1
                        "data": {
                            "firstMenuID": menuId,
                            "SecondMenuID": menuId,
                            "Name": self.menu.name,
                            "Des": self.menu.des || {
                                "zh-CN": "",
                                "en-US": ""
                            },
                            "IconURL": self.selectedImg1[0].FileUrl,
                            "IconSize": self.selectedImg1[0].FileSize,
                            "IconFocusURL": self.selectedImg2[0].FileUrl,
                            "IconFocusSize": self.selectedImg2[0].FileSize,
                            "Seq": Number(self.menu.seq),
                            "MenuState": self.menu.menuState == 1
                        },
                        "lang": util.langStyle()
                    })

                    // 开始保存
                    self.menuSaving = true;

                    $http({
                        method: 'POST',
                        url: util.getApiUrl('commonview', '', 'server'),
                        data: data
                    }).then(function successCallback(response) {
                        var data = response.data;
                        if (data.rescode == '200') {
                            alert('保存成功');
                            $state.go($state.current, { label: self.menu.name[util.getDefaultLangCode()] }, { reload: true });
                        } else if (data.rescode == '401') {
                            alert('访问超时，请重新登录');
                            $state.go('login');
                        } else {
                            alert('保存失败，' + data.errInfo);
                        }
                    }, function errorCallback(response) {
                        alert('连接服务器出错');
                    }).finally(function (value) {
                        self.menuSaving = false;
                    });
                }

                // 图片上传相关
                self.clickUpload = function (e) {
                    setTimeout(function () {
                        document.getElementById(e).click();
                    }, 0);
                }

                function Imgs(imgList, single) {
                    this.initImgList = imgList;
                    this.data = [];
                    this.maxId = 0;
                    this.single = single ? true : false;
                }

                Imgs.prototype = {
                    initImgs: function () {
                        var l = this.initImgList;
                        for (var i = 0; i < l.length; i++) {
                            this.data[i] = {
                                "src": l[i].ImageURL,
                                "fileSize": l[i].ImageSize,
                                "id": this.maxId++,
                                "progress": 100
                            };
                        }
                    },
                    deleteById: function (id) {
                        var l = this.data;
                        for (var i = 0; i < l.length; i++) {
                            if (l[i].id == id) {
                                // 如果正在上传，取消上传
                                if (l[i].progress < 100 && l[i].progress != -1) {
                                    l[i].xhr.abort();
                                }
                                l.splice(i, 1);
                                break;
                            }
                        }
                    },

                    add: function (xhr, fileName, fileSize) {
                        this.data.push({
                            "xhr": xhr,
                            "fileName": fileName,
                            "fileSize": fileSize,
                            "progress": 0,
                            "id": this.maxId
                        });
                        return this.maxId++;
                    },

                    update: function (id, progress, leftSize, fileSize) {
                        for (var i = 0; i < this.data.length; i++) {
                            var f = this.data[i];
                            if (f.id === id) {
                                f.progress = progress;
                                f.leftSize = leftSize;
                                f.fileSize = fileSize;
                                break;
                            }
                        }
                    },

                    setSrcSizeByXhr: function (xhr, src, size) {
                        for (var i = 0; i < this.data.length; i++) {
                            if (this.data[i].xhr == xhr) {
                                this.data[i].src = src;
                                this.data[i].fileSize = size;
                                break;
                            }
                        }
                    },

                    uploadFile: function (e, o) {

                        // 如果这个对象只允许上传一张图片
                        if (this.single) {
                            // 删除第二张以后的图片
                            for (var i = 1; i < this.data.length; i++) {
                                this.deleteById(this.data[i].id);
                            }
                        }

                        var file = e;
                        var uploadUrl = CONFIG.uploadUrl;
                        var xhr = new XMLHttpRequest();
                        var fileId = this.add(xhr, file.name, file.size, xhr);
                        // self.search();

                        util.uploadFileToUrl(xhr, file, uploadUrl, 'normal',
                            function (evt) {
                                $scope.$apply(function () {
                                    if (evt.lengthComputable) {
                                        var percentComplete = Math.round(evt.loaded * 100 / evt.total);
                                        o.update(fileId, percentComplete, evt.total - evt.loaded, evt.total);
                                        console && console.log(percentComplete);
                                    }
                                });
                            },
                            function (xhr) {
                                var ret = JSON.parse(xhr.responseText);
                                console && console.log(ret);
                                $scope.$apply(function () {
                                    o.setSrcSizeByXhr(xhr, ret.upload_path, ret.size);
                                    // 如果这个对象只允许上传一张图片
                                    if (o.single) {
                                        // 如果长度大于1张图片，删除前几张图片
                                        if (o.data.length > 1) {
                                            for (var i = 0; i < o.data.length - 1; i++) {
                                                o.deleteById(o.data[i].id);
                                            }
                                        }
                                    }
                                });
                            },
                            function (xhr) {
                                $scope.$apply(function () {
                                    o.update(fileId, -1, '', '');
                                });
                                console && console.log('failure');
                                xhr.abort();
                            }
                        );
                    }
                }
            }
        ])

        .controller('tvWelcomeController', ['$scope', '$state', '$http', '$stateParams', '$location', 'util', 'CONFIG',
            function ($scope, $state, $http, $stateParams, $location, util, CONFIG) {
                var self = this;

                self.init = function () {
                    self.editLangs = util.getParams('editLangs');
                    self.getWelInfo();
                    // 是否需要设置额外属性
                    self.hasExtra = false
                    self.colorOptions = {
                        inline: false,
                        format: 'hex8',
                        // swatchOnly:true
                    }
                }

                self.getWelInfo = function () {
                    var data = JSON.stringify({
                        "token": util.getParams('token'),
                        "action": "getWelcomePage",
                        "lang": util.langStyle()
                    });

                    self.loading = true;
                    // 获取欢迎页信息
                    $http({
                        method: 'POST',
                        url: util.getApiUrl('welcomepage', '', 'server'),
                        data: data
                    }).then(function successCallback(response) {
                        var data = response.data;
                        if (data.rescode == '200') {
                            // 是否需要设置额外属性，如前景图、主色调等
                            self.hasExtra = data.data.hasOwnProperty('ForegroundImageURL')

                            // 酒店logo图
                            if (data.data.LogoURL) {
                                self.imgs1 = new util.initUploadImgs([{
                                    "ImageURL": data.data.LogoURL,
                                    "ImageSize": data.data.LogoSize
                                }], $scope, true);
                                self.imgs1.initImgs();
                            }
                            else {
                                self.imgs1 = new util.initUploadImgs([], $scope, true)
                            }

                            if (self.hasExtra) {
                                if (data.data.ForegroundImageURL) {
                                    self.imgs2 = new util.initUploadImgs([{
                                        "ImageURL": data.data.ForegroundImageURL,
                                        "ImageSize": data.data.ForegroundImageSize
                                    }], $scope, true);
                                    self.imgs2.initImgs();
                                } else {
                                    self.imgs2 = new util.initUploadImgs([], $scope, true)
                                }

                                self.mainColor = self.s2cColor(data.data.MainColor)
                                self.secondColor = self.s2cColor(data.data.SecondColor)

                                self.styleType = data.data.StyleType || ''
                            }

                            // 酒店欢迎辞
                            self.welcomeText = data.data.WelcomeText || { "zh-CN": "", "en-US": "" };

                            // 酒店客人称呼
                            self.guestName = data.data.GuestName || { "zh-CN": "", "en-US": "" };

                            // 酒店经理
                            self.hotelManagerName = data.data.HotelManagerName || { "zh-CN": "", "en-US": "" };

                            // 酒店经理签名图
                            /*if(data.data.HotelManageSignURL){
                                self.imgs2 = new Imgs([{"ImageURL": data.data.HotelManageSignURL, "ImageSize": data.data.HotelManageSignSize}], true);
                                self.imgs2.initImgs();
                            }
                            else {
                                self.imgs2 = new Imgs([], true);
                            }*/


                            // 背景视频
                            if (data.data.BackgroundVideoURL) {
                                // self.imgs3 = new Imgs([{ "ImageURL": data.data.BackgroundVideoURL, "ImageSize": data.data.BackgroundVideoSize }], true);
                                // self.imgs3.initImgs();
                                self.selectedVideo = []
                                self.selectedVideo.push({
                                    FileUrl: data.data.BackgroundVideoURL,
                                    FileSize: data.data.BackgroundVideoSize
                                })
                            }
                            else {
                                self.selectedVideo = []
                            }


                        } else if (data.rescode == '401') {
                            alert('访问超时，请重新登录');
                            $state.go('login');
                        } else {
                            alert('加载欢迎页信息失败，' + data.errInfo);
                        }
                    }, function errorCallback(response) {
                        alert('连接服务器出错');
                    }).finally(function (value) {
                        self.loading = false;
                    });
                }

                // 视频资源选择
                self.selectVideoMaterial = function (video) {
                    $scope.app.showHideMaterialMask(true, 'Video')
                    // 选择完资源设置
                    $scope.app.setMaterial = function (data) {
                        self[video] = data
                    }
                }

                self.save = function () {
                    // 酒店logo图
                    if (self.imgs1.data.length == 0) {
                        alert('请上传酒店logo图');
                        return;
                    }

                    if (self.selectedVideo.length == 0) {
                        alert('请选择背景视频');
                        return;
                    }

                    // 酒店经理签名图
                    /*if(self.imgs2.data.length == 0) {
                        alert('请上传酒店经理签名图');
                        return;
                    }*/


                    // 编辑的视频未上传完成
                    /*(self.imgs3.data.length > 1 && (self.imgs3.data[1].progress < 100 || self.imgs3.data[1].progress == -1))*/

                    var data = JSON.stringify({
                        "token": util.getParams('token'),
                        "action": "editWelcomePage",
                        "viewID": 0,
                        "data": {
                            "LogoURL": self.imgs1.data[0].src,
                            "LogoSize": self.imgs1.data[0].fileSize,
                            "WelcomeText": self.welcomeText,
                            "GuestName": self.guestName,
                            "HotelManagerName": self.hotelManagerName,
                            /*"HotelManageSignURL":self.imgs2.data[0].src,
                            "HotelManageSignSize":self.imgs2.data[0].fileSize,*/
                            "HotelManageSignURL": "",
                            "HotelManageSignSize": 0,
                            "BackgroundVideoURL": self.selectedVideo[0].FileUrl,
                            "BackgroundVideoSize": self.selectedVideo[0].FileSize,
                            "ForegroundImageURL": (self.imgs2 && self.imgs2.data.length > 0) ? self.imgs2.data[0].src : '',
                            "ForegroundImageSize": (self.imgs2 && self.imgs2.data.length > 0) ? self.imgs2.data[0].fileSize - 0 : '',
                            "MainColor": self.c2sColor(self.mainColor),
                            "SecondColor": self.c2sColor(self.secondColor),
                            // "MainColor":'#FFA70909',
                            // "SecondColor":'#FFA70906'
                            "StyleType": self.styleType
                        },
                        "lang": util.langStyle()
                    })
                    // console.log(data)
                    // return
                    self.saving = true;
                    $http({
                        method: 'POST',
                        url: util.getApiUrl('welcomepage', '', 'server'),
                        data: data
                    }).then(function successCallback(response) {
                        var data = response.data;
                        if (data.rescode == '200') {
                            alert('修改成功');
                            $state.reload();
                        } else if (data.rescode == '401') {
                            alert('访问超时，请重新登录');
                            $state.go('login');
                        } else {
                            alert('加载菜单示意信息失败，' + data.errInfo);
                        }
                    }, function errorCallback(response) {
                        alert('连接服务器出错');
                    }).finally(function (value) {
                        self.loading = false;
                    });

                }

                // 图片上传相关
                self.clickUpload = function (e) {
                    setTimeout(function () {
                        document.getElementById(e).click();
                    }, 0);
                }

                self.c2sColor = function (str) {
                    if (!str) return "#FFFFFFFF"
                    var a = str.slice(-2)
                    var b = str.slice(0, -2)
                    // console.log('#'+a+b)
                    return '#' + a + b
                }

                self.s2cColor = function (str) {
                    if (!str) return 'FFFFFFFF'
                    var a = str.slice(1, 3)
                    var b = str.slice(3)
                    // console.log(b,a)
                    return b + a
                }
            }
        ])



        .controller('tvSimplePicTextEditController', ['$scope', '$state', '$http', '$stateParams', '$location', 'util', 'CONFIG',
            function ($scope, $state, $http, $stateParams, $location, util, CONFIG) {
                var self = this;

                self.init = function () {
                    self.viewId = $scope.app.maskParams.viewId;
                    self.picInfo = $scope.app.maskParams.picInfo;

                    // 获取编辑多语言信息
                    self.editLangs = util.getParams('editLangs');

                    self.setInfo();

                }


                self.cancel = function () {
                    $scope.app.showHideMask(false);
                }

                self.setInfo = function () {

                    self.Seq = self.picInfo.Seq;
                    self.Title = self.picInfo.Title ? JSON.parse(JSON.stringify(self.picInfo.Title)) : {};
                    self.Text = self.picInfo.Text ? JSON.parse(JSON.stringify(self.picInfo.Text)) : {};
                    self.Price = self.picInfo.Price;
                    self.imgs1 = new util.initUploadImgs([{ "ImageURL": self.picInfo.PicURL, "ImageSize": self.picInfo.PicSize }], $scope, true);
                    self.imgs1.initImgs();
                    console.log(self.imgs1);
                }

                self.save = function () {

                    //频道图片必填验证
                    if (self.imgs1.data.length == 0) {
                        alert('请上传频道图片');
                        return;
                    }
                    var params = {
                        "token": util.getParams('token'),
                        "action": "edit",
                        "viewID": Number(self.viewId),
                        "data": {
                            "ID": Number(self.picInfo.ID),
                            "Seq": self.Seq,
                            "Title": self.Title || { "zh-CN": "", "en-US": "" },
                            "PicURL": self.imgs1.data[0].src,
                            "Text": self.Text || { "zh-CN": "", "en-US": "" },
                            "PicSize": self.imgs1.data[0].fileSize
                        },
                        "lang": util.langStyle()
                    }
                    if ($scope.app.moduleType == 'SimplePicText_Price') {
                        params.data.Price = self.Price
                    }
                    var data = JSON.stringify(params)

                    self.saving = true;
                    $http({
                        method: 'POST',
                        url: util.getApiUrl('commonview', '', 'server'),
                        data: data
                    }).then(function successCallback(response) {
                        var data = response.data;
                        if (data.rescode == '200') {
                            alert('修改成功');
                            $state.reload();
                        } else if (data.rescode == '401') {
                            alert('访问超时，请重新登录');
                            $state.go('login');
                        } else {
                            alert('修改失败，' + data.errInfo);
                        }
                    }, function errorCallback(response) {
                        alert('连接服务器出错');
                    }).finally(function (value) {
                        self.saving = false;
                    });

                }


                // 图片上传相关
                self.clickUpload = function (e) {
                    setTimeout(function () {
                        document.getElementById(e).click();
                    }, 0);
                }

            }
        ])

        // 添加图文模块内的图文
        .controller('tvSimplePicTextAddController', ['$scope', '$state', '$http', '$stateParams', '$location', 'util', 'CONFIG',
            function ($scope, $state, $http, $stateParams, $location, util, CONFIG) {
                var self = this;

                self.init = function () {
                    self.viewId = $scope.app.maskParams.viewId;
                    console.log($scope)
                    // 获取编辑多语言信息
                    self.editLangs = util.getParams('editLangs');

                    // 初始化频道图片
                    self.imgs1 = new util.initUploadImgs([], $scope, true);
                }


                self.cancel = function () {
                    $scope.app.showHideMask(false);
                }

                self.save = function () {

                    //频道图片必填验证
                    if (self.imgs1.data.length == 0 || self.imgs1.data[0].progress < 100) {
                        alert('请上传图片');
                        return;
                    }

                    var params = {
                        "token": util.getParams('token'),
                        "action": "add",
                        "viewID": Number(self.viewId),
                        "data": {
                            "PicURL": self.imgs1.data[0].src,
                            "Seq": self.Seq,
                            "Text": self.Text || {
                                "zh-CN": "",
                                "en-US": ""
                            },
                            "Title": self.Title || {
                                "zh-CN": "",
                                "en-US": ""
                            },
                            "PicSize": self.imgs1.data[0].fileSize
                        },
                        "lang": util.langStyle()
                    }
                    if ($scope.app.moduleType == 'SimplePicText_Price') {
                        params.data.Price = self.Price
                    }
                    var data = JSON.stringify(params)
                    self.saving = true;
                    $http({
                        method: 'POST',
                        url: util.getApiUrl('commonview', '', 'server'),
                        data: data
                    }).then(function successCallback(response) {
                        var data = response.data;
                        if (data.rescode == '200') {
                            alert('添加成功');
                            $state.reload();
                        } else if (data.rescode == '401') {
                            alert('访问超时，请重新登录');
                            $state.go('login');
                        } else {
                            alert('添加失败，' + data.errInfo);
                        }
                    }, function errorCallback(response) {
                        alert('连接服务器出错');
                    }).finally(function (value) {
                        self.saving = false;
                    });

                }


                // 图片上传相关
                self.clickUpload = function (e) {
                    setTimeout(function () {
                        document.getElementById(e).click();
                    }, 0);
                }

            }
        ])


        .controller('tvSimplePicTextController', ['$scope', '$state', '$http', '$stateParams', '$location', 'util',
            function ($scope, $state, $http, $stateParams, $location, util) {
                var self = this;

                self.init = function () {
                    self.viewId = $stateParams.moduleId;
                    self.defaultLangCode = util.getDefaultLangCode();
                    self.loadList();
                }

                // 导出下载数据
                self.export = function () {
                    var obj = {
                        type: 'simplePic',
                        data: self.pics
                    }
                    util.exportData(JSON.stringify(obj), 'simplePic.json')
                }

                // 导入数据
                self.import = function () {
                    if (self.pics.length > 0) {
                        if (!confirm('当前模块已有数据，确定导入吗？')) {
                            return
                        }
                    }
                    util.importData(self, $scope, 'simplePic', self.importData)
                }

                // 导入后台处理
                self.importData = function (D) {
                    var params = {
                        "token": util.getParams('token'),
                        "action": "add",
                        "viewID": Number(self.viewId),
                        "data": {
                            "PicURL": D.PicURL,
                            "Seq": D.Seq,
                            "Text": D.Text,
                            "Title": D.Title || {},
                            "PicSize": D.PicSize
                        },
                        "lang": util.langStyle()
                    }
                    if ($scope.app.moduleType == 'SimplePicText_Price') {
                        params.data.Price = D.Price
                    }
                    var data = JSON.stringify(params)
                    self.saving = true;
                    $http({
                        method: 'POST',
                        url: util.getApiUrl('commonview', '', 'server'),
                        data: data
                    }).then(function successCallback(response) {
                        var data = response.data;
                        if (data.rescode == '200') {
                            self.procssFn($state)
                        } else if (data.rescode == '401') {
                            alert('访问超时，请重新登录');
                            $state.go('login');
                        } else {
                            alert('添加失败，' + data.errInfo);
                        }
                    }, function errorCallback(response) {
                        alert('连接服务器出错');
                    }).finally(function (value) {
                        self.saving = false;
                    });
                }

                self.edit = function (index) {
                    $scope.app.maskParams.viewId = self.viewId;
                    $scope.app.maskParams.picInfo = self.pics[index];
                    $scope.app.showHideMask(true, 'pages/tv/simplePicTextEdit.html');
                }

                self.del = function (id, index) {
                    var index = index;
                    if (!confirm('确认删除？')) {
                        return;
                    }
                    var data = JSON.stringify({
                        "token": util.getParams('token'),
                        "action": "delete",
                        "viewID": self.viewId,
                        "data": {
                            "ID": id - 0
                        },
                        "lang": util.langStyle()
                    })
                    $http({
                        method: 'POST',
                        url: util.getApiUrl('commonview', '', 'server'),
                        data: data
                    }).then(function successCallback(response) {
                        var data = response.data;
                        if (data.rescode == '200') {
                            alert('删除成功');
                            self.pics.splice(index, 1);
                        } else if (data.rescode == '401') {
                            alert('访问超时，请重新登录');
                            $state.go('login');
                        } else {
                            alert('删除失败，' + data.errInfo);
                        }
                    }, function errorCallback(response) {
                        alert('连接服务器出错');
                    });
                }

                self.add = function () {
                    $scope.app.maskParams.viewId = self.viewId;
                    $scope.app.showHideMask(true, 'pages/tv/simplePicTextAdd.html');
                }

                self.loadList = function () {
                    var data = JSON.stringify({
                        "token": util.getParams('token'),
                        "action": "get",
                        "viewID": self.viewId - 0,
                        "lang": util.langStyle()
                    })
                    self.loading = true;
                    $http({
                        method: 'POST',
                        url: util.getApiUrl('commonview', '', 'server'),
                        data: data
                    }).then(function successCallback(response) {
                        var data = response.data;
                        if (data.rescode == '200') {
                            self.pics = data.data.res || data.data.apk;
                        } else if (data.rescode == '401') {
                            alert('访问超时，请重新登录');
                            $state.go('login');
                        } else {
                            alert('加载信息失败，' + data.errInfo);
                        }
                    }, function errorCallback(response) {
                        alert('连接服务器出错');
                    }).finally(function (value) {
                        self.loading = false;
                    });
                }

            }
        ])

        .controller('tvApkEntryController', ['$q', '$scope', '$state', '$http', '$stateParams', '$filter', 'util', 'CONFIG',
            function ($q, $scope, $state, $http, $stateParams, $filter, util, CONFIG) {
                console.log('tvApkEntryController')
                var self = this;
                self.init = function () {
                    self.info = {};
                    self.viewId = $stateParams.moduleId;
                    self.defaultLangCode = util.getDefaultLangCode();
                    self.editLangs = util.getParams('editLangs');
                    self.getInfo().then(function () {
                        self.initImgs1();
                    })

                }

                self.getInfo = function () {
                    var deferred = $q.defer();
                    self.loading = true;
                    var data = JSON.stringify({
                        action: "get",
                        token: util.getParams('token'),
                        lang: util.langStyle(),
                        viewID: self.viewId
                    })
                    $http({
                        method: 'POST',
                        url: util.getApiUrl('commonview', '', 'server'),
                        data: data
                    }).then(function successCallback(response) {
                        var data = response.data;
                        if (data.rescode == '200') {
                            self.info = data.data.apk;
                            deferred.resolve();
                        } else if (data.rescode == '401') {
                            alert('访问超时，请重新登录');
                            $state.go('login');
                        } else {
                            alert('读取信息失败，' + data.errInfo);
                            deferred.reject();
                        }
                    }, function errorCallback(response) {
                        alert('服务器出错');
                        deferred.reject();
                    }).finally(function (e) {
                        self.loading = false;
                    });
                    return deferred.promise;
                }

                self.initImgs1 = function () {
                    // 初始化apk url
                    self.imgs1 = new Imgs([{ "ImageURL": self.info.ApkURL, "ImageSize": self.info.ApkSize }], true);
                    self.imgs1.initImgs();
                }

                self.save = function () {

                    //检查logo上传
                    if (self.imgs1.data.length == 0) {
                        alert('请上传酒店Apk');
                        return;
                    }

                    self.info.ApkURL = self.imgs1.data[0].src;
                    self.info.ApkSize = self.imgs1.data[0].fileSize - 0;
                    self.saving = true;

                    var data = JSON.stringify({
                        action: "updateApk",
                        token: util.getParams('token'),
                        lang: util.langStyle(),
                        viewID: self.viewId - 0,
                        data: self.info
                    })
                    $http({
                        method: 'POST',
                        url: util.getApiUrl('commonview', '', 'server'),
                        data: data
                    }).then(function successCallback(response) {
                        var data = response.data;
                        if (data.rescode == '200') {
                            alert('保存成功');
                            $state.reload();
                        } else {
                            alert('保存失败，' + data.errInfo);
                        }
                    }, function errorCallback(response) {
                        alert('服务器出错');
                    }).finally(function (e) {
                        self.saving = false;
                    });
                }

                self.clickUpload = function (e) {
                    setTimeout(function () {
                        document.getElementById(e).click();
                    }, 0);
                }

                function Imgs(imgList, single) {
                    this.initImgList = imgList;
                    this.data = [];
                    this.maxId = 0;
                    this.single = single ? true : false;
                }

                Imgs.prototype = {
                    initImgs: function () {
                        var l = this.initImgList;
                        for (var i = 0; i < l.length; i++) {
                            this.data[i] = {
                                "src": l[i].ImageURL,
                                "fileSize": l[i].ImageSize,
                                "id": this.maxId++,
                                "progress": 100
                            };
                        }
                    },
                    deleteById: function (id) {
                        var l = this.data;
                        for (var i = 0; i < l.length; i++) {
                            if (l[i].id == id) {
                                // 如果正在上传，取消上传
                                if (l[i].progress < 100 && l[i].progress != -1) {
                                    l[i].xhr.abort();
                                }
                                l.splice(i, 1);
                                break;
                            }
                        }
                    },

                    add: function (xhr, fileName, fileSize) {
                        this.data.push({
                            "xhr": xhr,
                            "fileName": fileName,
                            "fileSize": fileSize,
                            "progress": 0,
                            "id": this.maxId
                        });
                        return this.maxId++;
                    },

                    update: function (id, progress, leftSize, fileSize) {
                        for (var i = 0; i < this.data.length; i++) {
                            var f = this.data[i];
                            if (f.id === id) {
                                f.progress = progress;
                                f.leftSize = leftSize;
                                f.fileSize = fileSize;
                                break;
                            }
                        }
                    },

                    setSrcSizeByXhr: function (xhr, src, size) {
                        for (var i = 0; i < this.data.length; i++) {
                            if (this.data[i].xhr == xhr) {
                                this.data[i].src = src;
                                this.data[i].fileSize = size;
                                break;
                            }
                        }
                    },

                    uploadFile: function (e, o) {

                        // 如果这个对象只允许上传一张图片
                        if (this.single) {
                            // 删除第二张以后的图片
                            for (var i = 1; i < this.data.length; i++) {
                                this.deleteById(this.data[i].id);
                            }
                        }

                        var file = $scope[e];
                        var uploadUrl = CONFIG.uploadUrl;
                        var xhr = new XMLHttpRequest();
                        var fileId = this.add(xhr, file.name, file.size, xhr);
                        // self.search();

                        util.uploadFileToUrl(xhr, file, uploadUrl, 'normal',
                            function (evt) {
                                $scope.$apply(function () {
                                    if (evt.lengthComputable) {
                                        var percentComplete = Math.round(evt.loaded * 100 / evt.total);
                                        o.update(fileId, percentComplete, evt.total - evt.loaded, evt.total);
                                        console.log(percentComplete);
                                    }
                                });
                            },
                            function (xhr) {
                                var ret = JSON.parse(xhr.responseText);
                                console && console.log(ret);
                                $scope.$apply(function () {
                                    o.setSrcSizeByXhr(xhr, ret.upload_path, ret.size);
                                    // 如果这个对象只允许上传一张图片
                                    if (o.single) {
                                        // 删除第一站图片
                                        o.deleteById(o.data[0].id);
                                    }
                                });
                            },
                            function (xhr) {
                                $scope.$apply(function () {
                                    o.update(fileId, -1, '', '');
                                });
                                console.log('failure');
                                xhr.abort();
                            }
                        );
                    }
                }
            }
        ])

        //雅思特 世界时钟 Yeste_WorldClock
        .controller('Yeste_WorldClock', ['$q', '$scope', '$state', '$http', '$stateParams', '$filter', 'util', 'CONFIG',
            function ($q, $scope, $state, $http, $stateParams, $filter, util, CONFIG) {
                var self = this;
                self.init = function () {

                }
            }
        ])

        //雅思特天气 Yeste_Weather
        .controller('Yeste_Weather_Controler', ['$q', '$scope', '$state', '$http', '$stateParams', '$filter', 'util', 'CONFIG',
            function ($q, $scope, $state, $http, $stateParams, $filter, util, CONFIG) {
                var self = this;
                self.init = function () {
                    self.info = {};
                    self.viewId = $stateParams.moduleId;
                    self.defaultLangCode = util.getDefaultLangCode();
                    self.editLangs = util.getParams('editLangs');
                    self.ENlang = self.getENlang();
                    self.getInfo();
                    if ($scope.app.maskParams.num) {
                        self.tab = $scope.app.maskParams.num
                    } else {
                        self.tab = $scope.app.tabNum ? $scope.app.tabNum : 1;
                    }
                    // 使用一次后，赋值为空
                    $scope.app.tabNum = null;
                    $scope.app.maskParams.num = null;
                }

                //获取英文城市名
                self.getENlang = function () {
                    var ENlang;
                    for (var i = 0; i < self.editLangs.length; i++) {
                        ENlang = self.editLangs[i];
                        if (ENlang.name == "en") {
                            return ENlang.code;
                        }
                    }
                }

                //获取
                self.getInfo = function () {
                    var deferred = $q.defer();
                    self.loading = true;
                    var data = JSON.stringify({
                        viewID: Number(self.viewId),
                        token: util.getParams('token'),
                        action: "get",
                        lang: util.langStyle()
                    })
                    $http({
                        method: 'POST',
                        url: util.getApiUrl('commonview', '', 'server'),
                        data: data
                    }).then(function successCallback(response) {
                        var data = response.data;
                        if (data.rescode == '200') {
                            self.info = data.data.data;
                            deferred.resolve();
                        } else if (data.rescode == '401') {
                            alert('访问超时，请重新登录');
                            $state.go('login');
                        } else {
                            alert('读取信息失败，' + data.errInfo);
                            deferred.reject();
                        }
                    }, function errorCallback(response) {
                        alert('服务器出错');
                        deferred.reject();
                    }).finally(function (e) {
                        self.loading = false;
                    });
                    return deferred.promise;

                };
                //新增
                self.add = function (num) {
                    $scope.app.maskParams.viewId = self.viewId;
                    $scope.app.maskParams.num = num;
                    $scope.app.showHideMask(true, 'pages/tv/WeatherAdd_Yeste.html');
                }

                self.Country = function () {
                    if (self.tab == 1) {
                        return "China";
                    } else {
                        return "Oversea";
                    }
                }
                //修改
                self.edit = function (Item, num) {
                    console.log('edit');
                    $scope.app.maskParams.viewId = self.viewId;
                    $scope.app.maskParams.cityInfo = Item;
                    $scope.app.maskParams.ENlang = self.ENlang;
                    $scope.app.maskParams.num = num;
                    $scope.app.showHideMask(true, 'pages/tv/WeatherEdit_Yeste.html');
                }
                //删除
                self.del = function (id, index, num) {
                    var index = index;
                    if (!confirm('确认删除？')) {
                        return;
                    }
                    var data = JSON.stringify({
                        "token": util.getParams('token'),
                        "action": "delete",
                        "viewID": self.viewId,
                        "data": {
                            "ID": id - 0
                        },
                        "lang": util.langStyle()
                    })
                    $http({
                        method: 'POST',
                        url: util.getApiUrl('commonview', '', 'server'),
                        data: data
                    }).then(function successCallback(response) {
                        var data = response.data;
                        if (data.rescode == '200') {
                            alert('删除成功');
                            $scope.app.maskParams.num = num;
                            $state.reload('app.tvAdmin.Yeste_Weather');
                        } else if (data.rescode == '401') {
                            alert('访问超时，请重新登录');
                            $state.go('login');
                        } else {
                            alert('删除失败，' + data.errInfo);
                        }
                    }, function errorCallback(response) {
                        alert('连接服务器出错');
                    });
                }
                $scope.$on("tabNum", function () {
                    alert('success')
                })

            }
        ])
        //雅思特天气 Add Yeste_Weather
        .controller('Yeste_Weather_Add_Controler', ['$scope', '$state', '$http', '$stateParams', '$location', 'util', 'CONFIG',
            function ($scope, $state, $http, $stateParams, $location, util, CONFIG) {
                var self = this;
                self.init = function () {
                    self.viewId = $scope.app.maskParams.viewId;
                    self.editLangs = util.getParams('editLangs');
                    self.tabNum = $scope.app.maskParams.num;
                    self.getCountry(self.tabNum)
                };

                self.cancel = function () {
                    $scope.app.showHideMask(false);
                };
                self.getCountry = function (para) {
                    if (para == 1) {
                        self.whichCountry = "China";
                    } else {
                        self.whichCountry = "Overseas";
                    }
                }

                self.save = function () {
                    var data = JSON.stringify({
                        "token": util.getParams('token'),
                        "action": "add",
                        "viewID": Number(self.viewId),
                        "data": {
                            "Seq": self.Seq,
                            "Country": self.whichCountry,
                            "City": self.City
                        },
                        "lang": util.langStyle()
                    })


                    self.saving = true;
                    $http({
                        method: 'POST',
                        url: util.getApiUrl('commonview', '', 'server'),
                        data: data
                    }).then(function successCallback(response) {
                        var data = response.data;
                        if (data.rescode == '200') {
                            alert('添加成功');
                            $state.reload('app.tvAdmin.Yeste_Weather');
                            // 在app控制器上面加了一个天气的参数
                            $scope.app.tabNum = self.tabNum;
                            self.cancel();
                        } else if (data.rescode == '401') {
                            alert('访问超时，请重新登录');
                            $state.go('login');
                        } else {
                            alert('添加失败，' + data.errInfo);
                        }
                    }, function errorCallback(response) {
                        alert('连接服务器出错');
                    }).finally(function (value) {
                        self.saving = false;
                    });

                };
            }
        ])
        //雅思特天气 Edit Yeste_Weather
        .controller('Yeste_Weather_Edit_Controler', ['$scope', '$state', '$http', '$stateParams', '$location', 'util', 'CONFIG',
            function ($scope, $state, $http, $stateParams, $location, util, CONFIG) {
                var self = this;
                self.init = function () {
                    self.viewId = $scope.app.maskParams.viewId;
                    self.cityInfo = $scope.app.maskParams.cityInfo;
                    self.ENlang = $scope.app.maskParams.ENlang;
                    // 获取编辑多语言信息
                    self.editLangs = util.getParams('editLangs');
                    self.tabNum = $scope.app.maskParams.num;
                }

                self.cancel = function () {
                    $scope.app.showHideMask(false);
                }
                self.save = function () {
                    var data = JSON.stringify({
                        "token": util.getParams('token'),
                        "action": "edit",
                        "viewID": Number(self.viewId),
                        "data": {
                            "ID": Number(self.cityInfo.ID),
                            "Seq": self.cityInfo.Seq,
                            "Country": self.cityInfo.Country,
                            "City": self.cityInfo.City
                        },
                        "lang": util.langStyle()
                    })

                    self.saving = true;
                    $http({
                        method: 'POST',
                        url: util.getApiUrl('commonview', '', 'server'),
                        data: data
                    }).then(function successCallback(response) {
                        var data = response.data;
                        if (data.rescode == '200') {
                            alert('修改成功');
                            // 在app控制器上面加了一个天气的参数
                            $scope.app.tabNum = self.tabNum;
                            self.cancel();
                            $state.reload('app.tvAdmin.Yeste_Weather');
                        } else if (data.rescode == '401') {
                            alert('访问超时，请重新登录');
                            $state.go('login');
                        } else {
                            alert('修改失败，' + data.errInfo);
                        }
                    }, function errorCallback(response) {
                        alert('连接服务器出错');
                    }).finally(function (value) {
                        self.saving = false;
                    });

                }
            }
        ])

        //司马台 二级分类图文 SiMaTai_PicText_Classification
        .controller('SiMaTai_PicText_ClassificationController', ['$scope', '$state', '$http', '$stateParams', '$location', 'util',
            function ($scope, $state, $http, $stateParams, $location, util) {
                console.log('SiMaTai_PicText_ClassificationController')
                var self = this;
                self.info = []; // 分类＋分类下的图文信息
                self.cateIndex; // 当前选中分类index

                self.init = function () {
                    self.viewId = $stateParams.moduleId;
                    self.defaultLangCode = util.getDefaultLangCode();
                    self.loadInfo();
                }

                /**
                 * 添加该模版的图文分类
                 *
                 * @method addCategory
                 */
                self.addCategory = function () {
                    $scope.app.maskParams.viewId = self.viewId;
                    $scope.app.maskParams.loadInfo = self.loadInfo;
                    $scope.app.showHideMask(true, 'pages/tv/PicTextClassCateAdd_SiMaTai.html');
                }

                /**
                 * 删除图文分类
                 *
                 * @method delCate
                 */
                self.delCate = function () {
                    if (!confirm('确认删除此分类？（分类下内容将全部删除）')) {
                        return;
                    }
                    var data = JSON.stringify({
                        "token": util.getParams('token'),
                        "action": "delete",
                        "viewID": self.viewId,
                        "lang": util.langStyle(),
                        "data": {
                            "ID": self.info[self.cateIndex].ID
                        }
                    });
                    self.cateDeleting = true;
                    $http({
                        method: 'POST',
                        url: util.getApiUrl('commonview', '', 'server'),
                        data: data
                    }).then(function successCallback(response) {
                        var data = response.data;
                        if (data.rescode == '200') {
                            self.loadInfo();
                        }
                        else {
                            alert('连接服务器出错' + data.errInfo);
                        }
                    }, function errorCallback(response) {
                        alert('连接服务器出错');
                    }).finally(function (value) {
                        self.cateDeleting = false;
                    });
                }

                /**
                 * 编辑该模版的图文分类
                 *
                 * @method editCate
                 */
                self.editCate = function () {
                    $scope.app.maskParams.viewId = self.viewId;
                    $scope.app.maskParams.info = self.info[self.cateIndex];
                    $scope.app.maskParams.loadInfo = self.loadInfo;
                    $scope.app.showHideMask(true, 'pages/tv/PicTextClassCateEdit_SiMaTai.html');
                }

                /**
                 * 编辑图文
                 *
                 * @method editPic
                 * @param index 图片在该分类下列表中的序号
                 */
                self.editPic = function (index) {
                    $scope.app.maskParams.viewId = self.viewId;
                    $scope.app.maskParams.cateId = self.info[self.cateIndex].ID;
                    $scope.app.maskParams.info = self.info[self.cateIndex].sub[index];
                    $scope.app.maskParams.loadInfo = self.loadInfo;
                    $scope.app.showHideMask(true, 'pages/tv/classPicTextEdit_SiMaTai.html');
                }

                /**
                 * 添加该模版的图文
                 *
                 * @method addPic
                 */
                self.addPic = function () {
                    $scope.app.maskParams.viewId = self.viewId;
                    $scope.app.maskParams.cateId = self.info[self.cateIndex].ID;
                    $scope.app.maskParams.loadInfo = self.loadInfo;
                    $scope.app.showHideMask(true, 'pages/tv/classPicTextAdd_SiMaTai.html');
                }

                /**
                 * 删除图文
                 *
                 * @method delPic
                 * @param index 图文在列表中的序号
                 */
                self.delPic = function (index) {
                    if (!confirm('确认删除此图片？')) {
                        return;
                    }
                    var data = JSON.stringify({
                        "token": util.getParams('token'),
                        "action": "delete",
                        "viewID": self.viewId,
                        "lang": util.langStyle(),
                        "data": {
                            "PID": self.info[self.cateIndex].sub[index].ID
                        }
                    });
                    self.picDeleting = true;
                    $http({
                        method: 'POST',
                        url: util.getApiUrl('commonview', '', 'server'),
                        data: data
                    }).then(function successCallback(response) {
                        var data = response.data;
                        if (data.rescode == '200') {
                            self.loadInfo();
                        }
                        else {
                            alert('连接服务器出错' + data.errInfo);
                        }
                    }, function errorCallback(response) {
                        alert('连接服务器出错');
                    }).finally(function (value) {
                        self.picDeleting = false;
                    });
                }

                /**
                 * 加载分类及分类下的信息
                 *
                 * @method loadInfo
                 */
                self.loadInfo = function () {
                    var data = JSON.stringify({
                        "token": util.getParams('token'),
                        "action": "get",
                        "viewID": self.viewId,
                        "lang": util.langStyle()
                    });
                    self.loading = true;
                    $http({
                        method: 'POST',
                        url: util.getApiUrl('commonview', '', 'server'),
                        data: data
                    }).then(function successCallback(response) {
                        var data = response.data;
                        if (data.rescode == '200') {
                            self.info = data.data.res;
                            if (!self.cateIndex || (self.cateIndex + 1) > self.info.length) {
                                self.cateIndex = 0;
                            }
                            //判断分类下内容为空时，sub属性为空数组，不然模板的ng-repeat会报错
                            if (self.info.length == 0) {
                                self.info[0].sub = [];
                            }
                        }
                        else {
                            alert('连接服务器出错' + data.errInfo);
                        }
                    }, function errorCallback(response) {
                        alert('连接服务器出错');
                    }).finally(function (value) {
                        self.loading = false;
                    });
                }

                /**
                 * 加载分类及分类下的信息
                 *
                 * @method loadInfo
                 * @param index 跳转分类在self.info中的index
                 */
                self.loadPics = function (index) {
                    self.cateIndex = index;
                }

            }
        ])
        //司马台 二级分类图文Add SiMaTai_PicText_ClassificationController_Add
        .controller('SiMaTai_PicText_ClassificationController_Add', ['$scope', '$state', '$http', '$stateParams', '$location', 'util', 'CONFIG',
            function ($scope, $state, $http, $stateParams, $location, util, CONFIG) {
                var self = this;
                self.viewId = 0;
                self.imgs1 = null;
                self.editLangs = util.getParams('editLangs');

                self.init = function () {
                    self.viewId = $scope.app.maskParams.viewId;
                    self.imgs1 = new Imgs([], true);
                    self.imgs2 = new Imgs([], true);
                }

                /**
                 * 关闭弹窗
                 *
                 * @method cancel
                 */
                self.cancel = function () {
                    $scope.app.showHideMask(false);
                }

                /**
                 * 新建分类提交
                 *
                 * @method save
                 */
                self.save = function () {

                    if (!(self.imgs1.data[0] && self.imgs1.data[0].src)) {
                        alert('请上传图片');
                        return;
                    }

                    var data = JSON.stringify({
                        "token": util.getParams('token'),
                        "action": "add",
                        "viewID": self.viewId - 0,
                        "lang": util.langStyle(),
                        "data": {
                            "PicURL": self.imgs1.data[0].src,
                            "Text": self.cateName,
                            "Title": self.cateName,
                            "Seq": self.Seq,
                            "PicSize": self.imgs1.data[0].fileSize - 0,
                            "IconURL": self.imgs2.data[0].src,
                            "IconSize": self.imgs2.data[0].fileSize - 0
                        }
                    });

                    self.saving = true;

                    return $http({
                        method: 'POST',
                        url: util.getApiUrl('commonview', '', 'server'),
                        data: data
                    }).then(function successCallback(response) {
                        $scope.app.showHideMask(false);
                        $scope.app.maskParams.loadInfo();
                    }, function errorCallback(response) {
                        alert('连接服务器出错');
                    }).finally(function (value) {
                        self.saving = false;
                    });
                }

                // 图片上传相关
                self.clickUpload = function (e) {
                    setTimeout(function () {
                        document.getElementById(e).click();
                    }, 0);
                }

                function Imgs(imgList, single) {
                    this.initImgList = imgList;
                    this.data = [];
                    this.maxId = 0;
                    this.single = single ? true : false;
                }

                Imgs.prototype = {
                    initImgs: function () {
                        var l = this.initImgList;
                        for (var i = 0; i < l.length; i++) {
                            this.data[i] = {
                                "src": l[i].ImageURL,
                                "fileSize": l[i].ImageSize,
                                "id": this.maxId++,
                                "progress": 100
                            };
                        }
                    },
                    deleteById: function (id) {
                        var l = this.data;
                        for (var i = 0; i < l.length; i++) {
                            if (l[i].id == id) {
                                // 如果正在上传，取消上传
                                if (l[i].progress < 100 && l[i].progress != -1) {
                                    l[i].xhr.abort();
                                }
                                l.splice(i, 1);
                                break;
                            }
                        }
                    },

                    add: function (xhr, fileName, fileSize) {
                        this.data.push({
                            "xhr": xhr,
                            "fileName": fileName,
                            "fileSize": fileSize,
                            "progress": 0,
                            "id": this.maxId
                        });
                        return this.maxId++;
                    },

                    update: function (id, progress, leftSize, fileSize) {
                        for (var i = 0; i < this.data.length; i++) {
                            var f = this.data[i];
                            if (f.id === id) {
                                f.progress = progress;
                                f.leftSize = leftSize;
                                f.fileSize = fileSize;
                                break;
                            }
                        }
                    },

                    setSrcSizeByXhr: function (xhr, src, size) {
                        for (var i = 0; i < this.data.length; i++) {
                            if (this.data[i].xhr == xhr) {
                                this.data[i].src = src;
                                this.data[i].fileSize = size;
                                break;
                            }
                        }
                    },

                    uploadFile: function (e, o) {

                        // 如果这个对象只允许上传一张图片
                        if (this.single) {
                            // 删除第二张以后的图片
                            for (var i = 1; i < this.data.length; i++) {
                                this.deleteById(this.data[i].id);
                            }
                        }

                        var file = $scope[e];
                        var uploadUrl = CONFIG.uploadUrl;
                        var xhr = new XMLHttpRequest();
                        var fileId = this.add(xhr, file.name, file.size, xhr);
                        // self.search();

                        util.uploadFileToUrl(xhr, file, uploadUrl, 'normal',
                            function (evt) {
                                $scope.$apply(function () {
                                    if (evt.lengthComputable) {
                                        var percentComplete = Math.round(evt.loaded * 100 / evt.total);
                                        o.update(fileId, percentComplete, evt.total - evt.loaded, evt.total);
                                        console && console.log(percentComplete);
                                    }
                                });
                            },
                            function (xhr) {
                                var ret = JSON.parse(xhr.responseText);
                                console && console.log(ret);
                                $scope.$apply(function () {
                                    o.setSrcSizeByXhr(xhr, ret.upload_path, ret.size);
                                    // 如果这个对象只允许上传一张图片
                                    if (o.single) {
                                        // 如果长度大于1张图片，删除前几张图片
                                        if (o.data.length > 1) {
                                            for (var i = 0; i < o.data.length - 1; i++) {
                                                o.deleteById(o.data[i].id);
                                            }
                                        }
                                    }
                                });
                            },
                            function (xhr) {
                                $scope.$apply(function () {
                                    o.update(fileId, -1, '', '');
                                });
                                console && console.log('failure');
                                xhr.abort();
                            }
                        );
                    }
                }

            }
        ])
        //司马台 二级分类图文Edit SiMaTai_PicText_ClassificationController_Edit
        .controller('SiMaTai_PicText_ClassificationController_Edit', ['$scope', '$state', '$http', '$stateParams', '$location', 'util', 'CONFIG',
            function ($scope, $state, $http, $stateParams, $location, util, CONFIG) {
                var self = this;
                self.viewId = 0;
                self.imgs1 = null;
                self.imgs2 = null;
                self.editLangs = util.getParams('editLangs');

                self.init = function () {
                    self.viewId = $scope.app.maskParams.viewId;
                    self.info = $scope.app.maskParams.info;
                    self.Seq = self.info.Seq;
                    self.cateName = self.info.Title;
                    self.imgs1 = new Imgs([{ "ImageURL": self.info.PicURL, "ImageSize": self.info.PicSize }], true);
                    self.imgs1.initImgs();
                    self.imgs2 = new Imgs([{ "ImageURL": self.info.IconURL, "ImageSize": self.info.IconSize }], true);
                    self.imgs2.initImgs();
                }

                /**
                 * 关闭弹窗
                 *
                 * @method cancel
                 */
                self.cancel = function () {
                    $scope.app.showHideMask(false);
                }

                /**
                 * 保存分类提交
                 *
                 * @method save
                 */
                self.save = function () {

                    if (!(self.imgs1.data[0] && self.imgs1.data[0].src)) {
                        alert('请上传图片');
                        return;
                    }

                    var data = JSON.stringify({
                        "token": util.getParams('token'),
                        "action": "edit",
                        "viewID": self.viewId - 0,
                        "lang": util.langStyle(),
                        "data": {
                            "ID": self.info.ID,
                            "PicURL": self.imgs1.data[0].src,
                            "Text": self.cateName,
                            "Title": self.cateName,
                            "Seq": self.Seq,
                            "PicSize": self.imgs1.data[0].fileSize - 0,
                            "IconURL": self.imgs2.data[0].src,
                            "IconSize": self.imgs2.data[0].fileSize - 0
                        }
                    });

                    self.saving = true;

                    return $http({
                        method: 'POST',
                        url: util.getApiUrl('commonview', '', 'server'),
                        data: data
                    }).then(function successCallback(response) {
                        $scope.app.showHideMask(false);
                        $scope.app.maskParams.loadInfo();
                    }, function errorCallback(response) {
                        alert('连接服务器出错');
                    }).finally(function (value) {
                        self.saving = false;
                    });
                }

                // 图片上传相关
                self.clickUpload = function (e) {
                    setTimeout(function () {
                        document.getElementById(e).click();
                    }, 0);
                }

                function Imgs(imgList, single) {
                    this.initImgList = imgList;
                    this.data = [];
                    this.maxId = 0;
                    this.single = single ? true : false;
                }

                Imgs.prototype = {
                    initImgs: function () {
                        var l = this.initImgList;
                        for (var i = 0; i < l.length; i++) {
                            this.data[i] = {
                                "src": l[i].ImageURL,
                                "fileSize": l[i].ImageSize,
                                "id": this.maxId++,
                                "progress": 100
                            };
                        }
                    },
                    deleteById: function (id) {
                        var l = this.data;
                        for (var i = 0; i < l.length; i++) {
                            if (l[i].id == id) {
                                // 如果正在上传，取消上传
                                if (l[i].progress < 100 && l[i].progress != -1) {
                                    l[i].xhr.abort();
                                }
                                l.splice(i, 1);
                                break;
                            }
                        }
                    },

                    add: function (xhr, fileName, fileSize) {
                        this.data.push({
                            "xhr": xhr,
                            "fileName": fileName,
                            "fileSize": fileSize,
                            "progress": 0,
                            "id": this.maxId
                        });
                        return this.maxId++;
                    },

                    update: function (id, progress, leftSize, fileSize) {
                        for (var i = 0; i < this.data.length; i++) {
                            var f = this.data[i];
                            if (f.id === id) {
                                f.progress = progress;
                                f.leftSize = leftSize;
                                f.fileSize = fileSize;
                                break;
                            }
                        }
                    },

                    setSrcSizeByXhr: function (xhr, src, size) {
                        for (var i = 0; i < this.data.length; i++) {
                            if (this.data[i].xhr == xhr) {
                                this.data[i].src = src;
                                this.data[i].fileSize = size;
                                break;
                            }
                        }
                    },

                    uploadFile: function (e, o) {

                        // 如果这个对象只允许上传一张图片
                        if (this.single) {
                            // 删除第二张以后的图片
                            for (var i = 1; i < this.data.length; i++) {
                                this.deleteById(this.data[i].id);
                            }
                        }

                        var file = $scope[e];
                        var uploadUrl = CONFIG.uploadUrl;
                        var xhr = new XMLHttpRequest();
                        var fileId = this.add(xhr, file.name, file.size, xhr);
                        // self.search();

                        util.uploadFileToUrl(xhr, file, uploadUrl, 'normal',
                            function (evt) {
                                $scope.$apply(function () {
                                    if (evt.lengthComputable) {
                                        var percentComplete = Math.round(evt.loaded * 100 / evt.total);
                                        o.update(fileId, percentComplete, evt.total - evt.loaded, evt.total);
                                        console && console.log(percentComplete);
                                    }
                                });
                            },
                            function (xhr) {
                                var ret = JSON.parse(xhr.responseText);
                                console && console.log(ret);
                                $scope.$apply(function () {
                                    o.setSrcSizeByXhr(xhr, ret.upload_path, ret.size);
                                    // 如果这个对象只允许上传一张图片
                                    if (o.single) {
                                        // 如果长度大于1张图片，删除前几张图片
                                        if (o.data.length > 1) {
                                            for (var i = 0; i < o.data.length - 1; i++) {
                                                o.deleteById(o.data[i].id);
                                            }
                                        }
                                    }
                                });
                            },
                            function (xhr) {
                                $scope.$apply(function () {
                                    o.update(fileId, -1, '', '');
                                });
                                console && console.log('failure');
                                xhr.abort();
                            }
                        );
                    }
                }

            }
        ])
        //司马台 二级分类图文 分类下的Edit ClassPicTextEdit_SiMaTai_Controller
        .controller('ClassPicTextEdit_SiMaTai_Controller', ['$scope', '$state', '$http', '$stateParams', '$location', 'util', 'CONFIG',
            function ($scope, $state, $http, $stateParams, $location, util, CONFIG) {
                var self = this;

                self.init = function () {
                    self.viewId = $scope.app.maskParams.viewId;
                    self.cateId = $scope.app.maskParams.cateId;
                    self.info = $scope.app.maskParams.info;
                    self.Seq = self.info.Seq;
                    self.Text = self.info.Text;
                    self.Title = self.info.Title;

                    // 获取编辑多语言信息
                    self.editLangs = util.getParams('editLangs');

                    // 初始化频道图片
                    self.imgs1 = new Imgs([{ "ImageURL": self.info.PicURL, "ImageSize": self.info.PicSize }], true);
                    self.imgs1.initImgs();

                }


                self.cancel = function () {
                    $scope.app.showHideMask(false);
                }

                self.save = function () {

                    //频道图片必填验证
                    if (self.imgs1.data.length == 0 || self.imgs1.data[0].progress < 100) {
                        alert('请上传图片');
                        return;
                    }

                    var data = JSON.stringify({
                        "token": util.getParams('token'),
                        "action": "edit",
                        "viewID": Number(self.viewId),
                        "data": {
                            "PID": self.info.ID,
                            "PicURL": self.imgs1.data[0].src,
                            "Seq": self.Seq,
                            "Text": self.Text,
                            "Title": self.Title,
                            "PicSize": self.imgs1.data[0].fileSize
                        },
                        "lang": util.langStyle()
                    })
                    self.saving = true;
                    $http({
                        method: 'POST',
                        url: util.getApiUrl('commonview', '', 'server'),
                        data: data
                    }).then(function successCallback(response) {
                        var data = response.data;
                        if (data.rescode == '200') {
                            $scope.app.showHideMask(false);
                            $scope.app.maskParams.loadInfo();
                        } else if (data.rescode == '401') {
                            alert('访问超时，请重新登录');
                            $state.go('login');
                        } else {
                            alert('添加失败，' + data.errInfo);
                        }
                    }, function errorCallback(response) {
                        alert('连接服务器出错');
                    }).finally(function (value) {
                        self.saving = false;
                    });

                }


                // 图片上传相关
                self.clickUpload = function (e) {
                    setTimeout(function () {
                        document.getElementById(e).click();
                    }, 0);
                }

                function Imgs(imgList, single) {
                    this.initImgList = imgList;
                    this.data = [];
                    this.maxId = 0;
                    this.single = single ? true : false;
                }

                Imgs.prototype = {
                    initImgs: function () {
                        var l = this.initImgList;
                        for (var i = 0; i < l.length; i++) {
                            this.data[i] = {
                                "src": l[i].ImageURL,
                                "fileSize": l[i].ImageSize,
                                "id": this.maxId++,
                                "progress": 100
                            };
                        }
                    },
                    deleteById: function (id) {
                        var l = this.data;
                        for (var i = 0; i < l.length; i++) {
                            if (l[i].id == id) {
                                // 如果正在上传，取消上传
                                if (l[i].progress < 100 && l[i].progress != -1) {
                                    l[i].xhr.abort();
                                }
                                l.splice(i, 1);
                                break;
                            }
                        }
                    },

                    add: function (xhr, fileName, fileSize) {
                        this.data.push({
                            "xhr": xhr,
                            "fileName": fileName,
                            "fileSize": fileSize,
                            "progress": 0,
                            "id": this.maxId
                        });
                        return this.maxId++;
                    },

                    update: function (id, progress, leftSize, fileSize) {
                        for (var i = 0; i < this.data.length; i++) {
                            var f = this.data[i];
                            if (f.id === id) {
                                f.progress = progress;
                                f.leftSize = leftSize;
                                f.fileSize = fileSize;
                                break;
                            }
                        }
                    },

                    setSrcSizeByXhr: function (xhr, src, size) {
                        for (var i = 0; i < this.data.length; i++) {
                            if (this.data[i].xhr == xhr) {
                                this.data[i].src = src;
                                this.data[i].fileSize = size;
                                break;
                            }
                        }
                    },

                    uploadFile: function (e, o) {

                        // 如果这个对象只允许上传一张图片
                        if (this.single) {
                            // 删除第二张以后的图片
                            for (var i = 1; i < this.data.length; i++) {
                                this.deleteById(this.data[i].id);
                            }
                        }

                        var file = $scope[e];
                        var uploadUrl = CONFIG.uploadUrl;
                        var xhr = new XMLHttpRequest();
                        var fileId = this.add(xhr, file.name, file.size, xhr);
                        // self.search();

                        util.uploadFileToUrl(xhr, file, uploadUrl, 'normal',
                            function (evt) {
                                $scope.$apply(function () {
                                    if (evt.lengthComputable) {
                                        var percentComplete = Math.round(evt.loaded * 100 / evt.total);
                                        o.update(fileId, percentComplete, evt.total - evt.loaded, evt.total);
                                        console && console.log(percentComplete);
                                    }
                                });
                            },
                            function (xhr) {
                                var ret = JSON.parse(xhr.responseText);
                                console && console.log(ret);
                                $scope.$apply(function () {
                                    o.setSrcSizeByXhr(xhr, ret.upload_path, ret.size);
                                    // 如果这个对象只允许上传一张图片
                                    if (o.single) {
                                        // 如果长度大于1张图片，删除前几张图片
                                        if (o.data.length > 1) {
                                            for (var i = 0; i < o.data.length - 1; i++) {
                                                o.deleteById(o.data[i].id);
                                            }
                                        }
                                    }
                                });
                            },
                            function (xhr) {
                                $scope.$apply(function () {
                                    o.update(fileId, -1, '', '');
                                });
                                console && console.log('failure');
                                xhr.abort();
                            }
                        );
                    }
                }

            }
        ])
        //司马台 二级分类图文 分类下的Add ClassPicTextAdd_SiMaTai_Controller
        .controller('ClassPicTextAdd_SiMaTai_Controller', ['$scope', '$state', '$http', '$stateParams', '$location', 'util', 'CONFIG',
            function ($scope, $state, $http, $stateParams, $location, util, CONFIG) {
                var self = this;

                self.init = function () {
                    self.viewId = $scope.app.maskParams.viewId;
                    self.cateId = $scope.app.maskParams.cateId;

                    // 获取编辑多语言信息
                    self.editLangs = util.getParams('editLangs');

                    // 初始化频道图片
                    self.imgs1 = new Imgs([], true);

                }


                self.cancel = function () {
                    $scope.app.showHideMask(false);
                }

                self.save = function () {

                    //频道图片必填验证
                    if (self.imgs1.data.length == 0 || self.imgs1.data[0].progress < 100) {
                        alert('请上传图片');
                        return;
                    }

                    var data = JSON.stringify({
                        "token": util.getParams('token'),
                        "action": "add",
                        "viewID": Number(self.viewId),
                        "data": {
                            "PID": self.cateId,
                            "PicURL": self.imgs1.data[0].src,
                            "Seq": self.Seq,
                            "Text": self.Text,
                            "Title": self.Title,
                            "PicSize": self.imgs1.data[0].fileSize
                        },
                        "lang": util.langStyle()
                    })
                    self.saving = true;
                    $http({
                        method: 'POST',
                        url: util.getApiUrl('commonview', '', 'server'),
                        data: data
                    }).then(function successCallback(response) {
                        var data = response.data;
                        if (data.rescode == '200') {
                            $scope.app.showHideMask(false);
                            $scope.app.maskParams.loadInfo();
                        } else if (data.rescode == '401') {
                            alert('访问超时，请重新登录');
                            $state.go('login');
                        } else {
                            alert('添加失败，' + data.errInfo);
                        }
                    }, function errorCallback(response) {
                        alert('连接服务器出错');
                    }).finally(function (value) {
                        self.saving = false;
                    });

                }


                // 图片上传相关
                self.clickUpload = function (e) {
                    setTimeout(function () {
                        document.getElementById(e).click();
                    }, 0);
                }

                function Imgs(imgList, single) {
                    this.initImgList = imgList;
                    this.data = [];
                    this.maxId = 0;
                    this.single = single ? true : false;
                }

                Imgs.prototype = {
                    initImgs: function () {
                        var l = this.initImgList;
                        for (var i = 0; i < l.length; i++) {
                            this.data[i] = {
                                "src": l[i].ImageURL,
                                "fileSize": l[i].ImageSize,
                                "id": this.maxId++,
                                "progress": 100
                            };
                        }
                    },
                    deleteById: function (id) {
                        var l = this.data;
                        for (var i = 0; i < l.length; i++) {
                            if (l[i].id == id) {
                                // 如果正在上传，取消上传
                                if (l[i].progress < 100 && l[i].progress != -1) {
                                    l[i].xhr.abort();
                                }
                                l.splice(i, 1);
                                break;
                            }
                        }
                    },

                    add: function (xhr, fileName, fileSize) {
                        this.data.push({
                            "xhr": xhr,
                            "fileName": fileName,
                            "fileSize": fileSize,
                            "progress": 0,
                            "id": this.maxId
                        });
                        return this.maxId++;
                    },

                    update: function (id, progress, leftSize, fileSize) {
                        for (var i = 0; i < this.data.length; i++) {
                            var f = this.data[i];
                            if (f.id === id) {
                                f.progress = progress;
                                f.leftSize = leftSize;
                                f.fileSize = fileSize;
                                break;
                            }
                        }
                    },

                    setSrcSizeByXhr: function (xhr, src, size) {
                        for (var i = 0; i < this.data.length; i++) {
                            if (this.data[i].xhr == xhr) {
                                this.data[i].src = src;
                                this.data[i].fileSize = size;
                                break;
                            }
                        }
                    },

                    uploadFile: function (e, o) {

                        // 如果这个对象只允许上传一张图片
                        if (this.single) {
                            // 删除第二张以后的图片
                            for (var i = 1; i < this.data.length; i++) {
                                this.deleteById(this.data[i].id);
                            }
                        }

                        var file = $scope[e];
                        var uploadUrl = CONFIG.uploadUrl;
                        var xhr = new XMLHttpRequest();
                        var fileId = this.add(xhr, file.name, file.size, xhr);
                        // self.search();

                        util.uploadFileToUrl(xhr, file, uploadUrl, 'normal',
                            function (evt) {
                                $scope.$apply(function () {
                                    if (evt.lengthComputable) {
                                        var percentComplete = Math.round(evt.loaded * 100 / evt.total);
                                        o.update(fileId, percentComplete, evt.total - evt.loaded, evt.total);
                                        console && console.log(percentComplete);
                                    }
                                });
                            },
                            function (xhr) {
                                var ret = JSON.parse(xhr.responseText);
                                console && console.log(ret);
                                $scope.$apply(function () {
                                    o.setSrcSizeByXhr(xhr, ret.upload_path, ret.size);
                                    // 如果这个对象只允许上传一张图片
                                    if (o.single) {
                                        // 如果长度大于1张图片，删除前几张图片
                                        if (o.data.length > 1) {
                                            for (var i = 0; i < o.data.length - 1; i++) {
                                                o.deleteById(o.data[i].id);
                                            }
                                        }
                                    }
                                });
                            },
                            function (xhr) {
                                $scope.$apply(function () {
                                    o.update(fileId, -1, '', '');
                                });
                                console && console.log('failure');
                                xhr.abort();
                            }
                        );
                    }
                }

            }
        ])
        //司马台 三级分类图文 SiMaTai_PicText_Classification_ThreeLevel
        .controller('SiMaTai_PicText_Classification_ThreeLevel_Controller', ['$scope', '$state', '$http', '$stateParams', '$location', 'util',
            function ($scope, $state, $http, $stateParams, $location, util) {
                console.log('SiMaTai_PicText_Classification_ThreeLevel_Controller')
                var self = this;
                self.info = []; // 分类＋分类下的图文信息
                self.cateIndex; // 当前选中分类index
                self.secondIndex;// 当前选中二级分类index
                self.init = function () {
                    self.viewId = $stateParams.moduleId;
                    self.defaultLangCode = util.getDefaultLangCode();
                    self.loadInfo();
                }

                /**
                 * 添加该模版的图文分类
                 *
                 * @method addCategory
                 */
                self.addCategory = function (isLeaf, PID) {
                    console.log(isLeaf, PID)
                    $scope.app.maskParams.viewId = self.viewId;
                    $scope.app.maskParams.addParam = { isLeaf: isLeaf, PID: PID };
                    $scope.app.maskParams.loadInfo = self.loadInfo;
                    $scope.app.showHideMask(true, 'pages/tv/PicTextClassCateAdd_SiMaTai_ThreeLevel.html');
                }

                /**
                 * 删除图文分类
                 *
                 * @method delCate
                 */
                self.delCate = function (isLeaf, id) {
                    if (!confirm('确认删除?')) {
                        return;
                    }

                    var data = JSON.stringify({
                        "token": util.getParams('token'),
                        "action": "delete",
                        "viewID": self.viewId,
                        "lang": util.langStyle(),
                        "data": {
                            "ID": id,
                            "isLeaf": isLeaf
                        }
                    });
                    self.cateDeleting = true;
                    $http({
                        method: 'POST',
                        url: util.getApiUrl('commonview', '', 'server'),
                        data: data
                    }).then(function successCallback(response) {
                        var data = response.data;
                        if (data.rescode == '200') {
                            self.loadInfo();
                        }
                        else {
                            alert('连接服务器出错' + data.errInfo);
                        }
                    }, function errorCallback(response) {
                        alert('连接服务器出错');
                    }).finally(function (value) {
                        self.cateDeleting = false;
                    });
                }

                /**
                 * 编辑该模版的图文分类
                 *
                 * @method editCate
                 */
                self.editCate = function (isLeaf, PID, index) {
                    $scope.app.maskParams.viewId = self.viewId;
                    $scope.app.maskParams.addParam = { isLeaf: isLeaf, PID: PID };
                    if (index !== undefined) {
                        $scope.app.maskParams.info = self.info[self.cateIndex].sub[self.secondIndex].sub[index];

                    } else {
                        (PID === -1) ? $scope.app.maskParams.info = self.info[self.cateIndex] : $scope.app.maskParams.info = self.info[self.cateIndex].sub[self.secondIndex];
                    }
                    $scope.app.maskParams.loadInfo = self.loadInfo;
                    $scope.app.showHideMask(true, 'pages/tv/PicTextClassCateEdit_SiMaTai_ThreeLevel.html');
                }

                /**
                 * 加载分类及分类下的信息
                 *
                 * @method loadInfo
                 */
                self.loadInfo = function () {
                    var data = JSON.stringify({
                        "token": util.getParams('token'),
                        "action": "get",
                        "viewID": self.viewId,
                        "lang": util.langStyle()
                    });
                    self.loading = true;
                    $http({
                        method: 'POST',
                        url: util.getApiUrl('commonview', '', 'server'),
                        data: data
                    }).then(function successCallback(response) {
                        var data = response.data;
                        if (data.rescode == '200') {
                            self.info = data.data.res;
                            // 判断分类下内容为空时，sub属性为空数组，不然模板的ng-repeat会报错
                            // if(self.info[0].sub === undefined ){
                            //     self.info[0].sub = [];
                            // }
                            if (!self.cateIndex || (self.cateIndex + 1) > self.info.length) {
                                self.cateIndex = 0;
                            }
                            if (!self.secondIndex || (self.secondIndex + 1) > self.info.length) {
                                self.secondIndex = 0;
                            }
                            if (self.info.length == 0) {
                                return;
                            }
                            // 默认去第一个 一级分类 下 第一个子分类
                            self.firstCategoryId = self.info[0]['ID'];
                            if (!self.info[0].sub) {
                                return;
                            }
                            self.secondCategoryId = self.info[0].sub[0]['ID'];


                        }
                        else {
                            alert('连接服务器出错' + data.errInfo);
                        }
                    }, function errorCallback(response) {
                        alert('连接服务器出错');
                    }).finally(function (value) {
                        self.loading = false;
                    });
                }

                /**
                 * 加载分类及分类下的信息
                 *
                 * @method loadInfo
                 * @param index 跳转分类在self.info中的index
                 */
                self.loadPics = function (index, ID) {
                    self.cateIndex = index;
                    // 一级分类的id
                    self.firstCategoryId = ID;
                    //默认先进入第一个二级分类
                    self.secondCategoryId = self.info[index].sub[0].ID;
                }
                self.loadSecondPics = function (index, ID) {
                    self.secondIndex = index;
                    //二级分类的id
                    self.secondCategoryId = ID;
                }

            }
        ])
        //司马台 三级分类图文Add SiMaTai_PicText_Classification_ThreeLevel_Controller_Add
        .controller('SiMaTai_PicText_Classification_ThreeLevel_Controller_Add', ['$scope', '$state', '$http', '$stateParams', '$location', 'util', 'CONFIG',
            function ($scope, $state, $http, $stateParams, $location, util, CONFIG) {
                var self = this;
                self.viewId = 0;
                self.imgs1 = null;
                self.editLangs = util.getParams('editLangs');

                self.init = function () {
                    self.viewId = $scope.app.maskParams.viewId;
                    self.addParam = $scope.app.maskParams.addParam;
                    self.imgs1 = new Imgs([], true);
                    self.imgs2 = new Imgs([], true);
                }

                /**
                 * 关闭弹窗
                 *
                 * @method cancel
                 */
                self.cancel = function () {
                    $scope.app.showHideMask(false);
                }

                /**
                 * 新建分类提交
                 *
                 * @method save
                 */
                self.save = function () {

                    if (!(self.imgs1.data[0] && self.imgs1.data[0].src)) {
                        alert('请上传图片');
                        return;
                    }

                    var data = JSON.stringify({
                        "token": util.getParams('token'),
                        "action": "add",
                        "viewID": self.viewId - 0,
                        "lang": util.langStyle(),
                        "data": {
                            "isLeaf": self.addParam.isLeaf,
                            "PID": self.addParam.PID,
                            "PicURL": self.imgs1.data[0].src,
                            "Text": self.cateName,
                            "Title": self.cateName,
                            "Seq": self.Seq,
                            "PicSize": self.imgs1.data[0].fileSize - 0
                            // "IconURL":self.imgs2.data[0].src,
                            // "IconSize":self.imgs2.data[0].fileSize-0
                        }
                    });

                    self.saving = true;

                    $http({
                        method: 'POST',
                        url: util.getApiUrl('commonview', '', 'server'),
                        data: data
                    }).then(function successCallback(response) {
                        var data = response.data;
                        if (data.rescode == '200') {
                            alert('添加成功');
                            $state.reload("app.tvAdmin.SiMaTai_PicText_Classification_ThreeLevel");
                            self.cancel();
                        } else if (data.rescode == '401') {
                            alert('访问超时，请重新登录');
                            $state.go('login');
                        } else {
                            alert('添加失败，' + data.errInfo);
                        }
                    }, function errorCallback(response) {
                        alert('连接服务器出错');
                    }).finally(function (value) {
                        self.saving = false;
                    });


                }





                // 图片上传相关
                self.clickUpload = function (e) {
                    setTimeout(function () {
                        document.getElementById(e).click();
                    }, 0);
                }

                function Imgs(imgList, single) {
                    this.initImgList = imgList;
                    this.data = [];
                    this.maxId = 0;
                    this.single = single ? true : false;
                }

                Imgs.prototype = {
                    initImgs: function () {
                        var l = this.initImgList;
                        for (var i = 0; i < l.length; i++) {
                            this.data[i] = {
                                "src": l[i].ImageURL,
                                "fileSize": l[i].ImageSize,
                                "id": this.maxId++,
                                "progress": 100
                            };
                        }
                    },
                    deleteById: function (id) {
                        var l = this.data;
                        for (var i = 0; i < l.length; i++) {
                            if (l[i].id == id) {
                                // 如果正在上传，取消上传
                                if (l[i].progress < 100 && l[i].progress != -1) {
                                    l[i].xhr.abort();
                                }
                                l.splice(i, 1);
                                break;
                            }
                        }
                    },

                    add: function (xhr, fileName, fileSize) {
                        this.data.push({
                            "xhr": xhr,
                            "fileName": fileName,
                            "fileSize": fileSize,
                            "progress": 0,
                            "id": this.maxId
                        });
                        return this.maxId++;
                    },

                    update: function (id, progress, leftSize, fileSize) {
                        for (var i = 0; i < this.data.length; i++) {
                            var f = this.data[i];
                            if (f.id === id) {
                                f.progress = progress;
                                f.leftSize = leftSize;
                                f.fileSize = fileSize;
                                break;
                            }
                        }
                    },

                    setSrcSizeByXhr: function (xhr, src, size) {
                        for (var i = 0; i < this.data.length; i++) {
                            if (this.data[i].xhr == xhr) {
                                this.data[i].src = src;
                                this.data[i].fileSize = size;
                                break;
                            }
                        }
                    },

                    uploadFile: function (e, o) {

                        // 如果这个对象只允许上传一张图片
                        if (this.single) {
                            // 删除第二张以后的图片
                            for (var i = 1; i < this.data.length; i++) {
                                this.deleteById(this.data[i].id);
                            }
                        }

                        var file = $scope[e];
                        var uploadUrl = CONFIG.uploadUrl;
                        var xhr = new XMLHttpRequest();
                        var fileId = this.add(xhr, file.name, file.size, xhr);
                        // self.search();

                        util.uploadFileToUrl(xhr, file, uploadUrl, 'normal',
                            function (evt) {
                                $scope.$apply(function () {
                                    if (evt.lengthComputable) {
                                        var percentComplete = Math.round(evt.loaded * 100 / evt.total);
                                        o.update(fileId, percentComplete, evt.total - evt.loaded, evt.total);
                                        console && console.log(percentComplete);
                                    }
                                });
                            },
                            function (xhr) {
                                var ret = JSON.parse(xhr.responseText);
                                console && console.log(ret);
                                $scope.$apply(function () {
                                    o.setSrcSizeByXhr(xhr, ret.upload_path, ret.size);
                                    // 如果这个对象只允许上传一张图片
                                    if (o.single) {
                                        // 如果长度大于1张图片，删除前几张图片
                                        if (o.data.length > 1) {
                                            for (var i = 0; i < o.data.length - 1; i++) {
                                                o.deleteById(o.data[i].id);
                                            }
                                        }
                                    }
                                });
                            },
                            function (xhr) {
                                $scope.$apply(function () {
                                    o.update(fileId, -1, '', '');
                                });
                                console && console.log('failure');
                                xhr.abort();
                            }
                        );
                    }
                }

            }
        ])
        //司马台 三级分类图文Edit SiMaTai_PicText_Classification_ThreeLevel_Controller_Edit
        .controller('SiMaTai_PicText_Classification_ThreeLevel_Controller_Edit', ['$scope', '$state', '$http', '$stateParams', '$location', 'util', 'CONFIG',
            function ($scope, $state, $http, $stateParams, $location, util, CONFIG) {
                var self = this;
                self.viewId = 0;
                self.imgs1 = null;
                self.imgs2 = null;
                self.editLangs = util.getParams('editLangs');

                self.init = function () {
                    self.viewId = $scope.app.maskParams.viewId;
                    self.info = $scope.app.maskParams.info;
                    self.addParam = $scope.app.maskParams.addParam;
                    console.log($scope.app.maskParams.addParam);
                    self.Seq = self.info.Seq;
                    self.cateName = self.info.Title;
                    self.Text = self.info.Text;
                    self.imgs1 = new Imgs([{ "ImageURL": self.info.PicURL, "ImageSize": self.info.PicSize }], true);
                    self.imgs1.initImgs();
                    self.imgs2 = new Imgs([{ "ImageURL": self.info.IconURL, "ImageSize": self.info.IconSize }], true);
                    self.imgs2.initImgs();
                }

                /**
                 * 关闭弹窗
                 *
                 * @method cancel
                 */
                self.cancel = function () {
                    $scope.app.showHideMask(false);
                }

                /**
                 * 保存分类提交
                 *
                 * @method save
                 */
                self.save = function () {

                    if (!(self.imgs1.data[0] && self.imgs1.data[0].src)) {
                        alert('请上传图片');
                        return;
                    }

                    var data = JSON.stringify({
                        "token": util.getParams('token'),
                        "action": "edit",
                        "viewID": self.viewId - 0,
                        "lang": util.langStyle(),
                        "data": {
                            "isLeaf": self.addParam.isLeaf,
                            "ID": self.info.ID,
                            "PicURL": self.imgs1.data[0].src,
                            "Text": self.cateName,
                            "Title": self.cateName,
                            "Seq": self.Seq,
                            "PicSize": self.imgs1.data[0].fileSize - 0,
                            // "IconURL": self.imgs2.data[0].src,
                            // "IconSize": self.imgs2.data[0].fileSize-0
                        }
                    });

                    self.saving = true;

                    return $http({
                        method: 'POST',
                        url: util.getApiUrl('commonview', '', 'server'),
                        data: data
                    }).then(function successCallback(response) {
                        $scope.app.showHideMask(false);
                        $scope.app.maskParams.loadInfo();
                    }, function errorCallback(response) {
                        alert('连接服务器出错');
                    }).finally(function (value) {
                        self.saving = false;
                    });
                }

                // 图片上传相关
                self.clickUpload = function (e) {
                    setTimeout(function () {
                        document.getElementById(e).click();
                    }, 0);
                }

                function Imgs(imgList, single) {
                    this.initImgList = imgList;
                    this.data = [];
                    this.maxId = 0;
                    this.single = single ? true : false;
                }

                Imgs.prototype = {
                    initImgs: function () {
                        var l = this.initImgList;
                        for (var i = 0; i < l.length; i++) {
                            this.data[i] = {
                                "src": l[i].ImageURL,
                                "fileSize": l[i].ImageSize,
                                "id": this.maxId++,
                                "progress": 100
                            };
                        }
                    },
                    deleteById: function (id) {
                        var l = this.data;
                        for (var i = 0; i < l.length; i++) {
                            if (l[i].id == id) {
                                // 如果正在上传，取消上传
                                if (l[i].progress < 100 && l[i].progress != -1) {
                                    l[i].xhr.abort();
                                }
                                l.splice(i, 1);
                                break;
                            }
                        }
                    },

                    add: function (xhr, fileName, fileSize) {
                        this.data.push({
                            "xhr": xhr,
                            "fileName": fileName,
                            "fileSize": fileSize,
                            "progress": 0,
                            "id": this.maxId
                        });
                        return this.maxId++;
                    },

                    update: function (id, progress, leftSize, fileSize) {
                        for (var i = 0; i < this.data.length; i++) {
                            var f = this.data[i];
                            if (f.id === id) {
                                f.progress = progress;
                                f.leftSize = leftSize;
                                f.fileSize = fileSize;
                                break;
                            }
                        }
                    },

                    setSrcSizeByXhr: function (xhr, src, size) {
                        for (var i = 0; i < this.data.length; i++) {
                            if (this.data[i].xhr == xhr) {
                                this.data[i].src = src;
                                this.data[i].fileSize = size;
                                break;
                            }
                        }
                    },

                    uploadFile: function (e, o) {

                        // 如果这个对象只允许上传一张图片
                        if (this.single) {
                            // 删除第二张以后的图片
                            for (var i = 1; i < this.data.length; i++) {
                                this.deleteById(this.data[i].id);
                            }
                        }

                        var file = $scope[e];
                        var uploadUrl = CONFIG.uploadUrl;
                        var xhr = new XMLHttpRequest();
                        var fileId = this.add(xhr, file.name, file.size, xhr);
                        // self.search();

                        util.uploadFileToUrl(xhr, file, uploadUrl, 'normal',
                            function (evt) {
                                $scope.$apply(function () {
                                    if (evt.lengthComputable) {
                                        var percentComplete = Math.round(evt.loaded * 100 / evt.total);
                                        o.update(fileId, percentComplete, evt.total - evt.loaded, evt.total);
                                        console && console.log(percentComplete);
                                    }
                                });
                            },
                            function (xhr) {
                                var ret = JSON.parse(xhr.responseText);
                                console && console.log(ret);
                                $scope.$apply(function () {
                                    o.setSrcSizeByXhr(xhr, ret.upload_path, ret.size);
                                    // 如果这个对象只允许上传一张图片
                                    if (o.single) {
                                        // 如果长度大于1张图片，删除前几张图片
                                        if (o.data.length > 1) {
                                            for (var i = 0; i < o.data.length - 1; i++) {
                                                o.deleteById(o.data[i].id);
                                            }
                                        }
                                    }
                                });
                            },
                            function (xhr) {
                                $scope.$apply(function () {
                                    o.update(fileId, -1, '', '');
                                });
                                console && console.log('failure');
                                xhr.abort();
                            }
                        );
                    }
                }

            }
        ])

        //三星天气 Samsung_Weather
        .controller('Samsung_Weather_Controler', ['$q', '$scope', '$state', '$http', '$stateParams', '$filter', 'util', 'CONFIG',
            function ($q, $scope, $state, $http, $stateParams, $filter, util, CONFIG) {
                console.log('WeatherCommon');
                var self = this;
                self.init = function () {
                    self.info = {};
                    self.viewId = $stateParams.moduleId;
                    self.defaultLangCode = util.getDefaultLangCode();
                    self.editLangs = util.getParams('editLangs');
                    self.ENlang = self.getENlang();
                    self.getInfo();
                    if ($scope.app.maskParams.num) {
                        self.tab = $scope.app.maskParams.num
                    } else {
                        self.tab = $scope.app.tabNum ? $scope.app.tabNum : 1;
                    }
                    // 使用一次后，赋值为空
                    $scope.app.tabNum = null;
                    $scope.app.maskParams.num = null;
                }

                //获取英文城市名
                self.getENlang = function () {
                    var ENlang;
                    for (var i = 0; i < self.editLangs.length; i++) {
                        ENlang = self.editLangs[i];
                        if (ENlang.name == "en") {
                            return ENlang.code;
                        }
                    }
                }

                //获取
                self.getInfo = function () {
                    var deferred = $q.defer();
                    self.loading = true;
                    var data = JSON.stringify({
                        viewID: Number(self.viewId),
                        token: util.getParams('token'),
                        action: "get",
                        lang: util.langStyle()
                    })
                    $http({
                        method: 'POST',
                        url: util.getApiUrl('commonview', '', 'server'),
                        data: data
                    }).then(function successCallback(response) {
                        var data = response.data;
                        if (data.rescode == '200') {
                            self.info = data.data.data;
                            deferred.resolve();
                        } else if (data.rescode == '401') {
                            alert('访问超时，请重新登录');
                            $state.go('login');
                        } else {
                            alert('读取信息失败，' + data.errInfo);
                            deferred.reject();
                        }
                    }, function errorCallback(response) {
                        alert('服务器出错');
                        deferred.reject();
                    }).finally(function (e) {
                        self.loading = false;
                    });
                    return deferred.promise;

                };
                //新增
                self.add = function (num) {
                    $scope.app.maskParams.viewId = self.viewId;
                    $scope.app.maskParams.num = num;
                    $scope.app.showHideMask(true, 'pages/tv/WeatherAdd_Samsung.html');
                }

                self.Country = function () {
                    if (self.tab == 1) {
                        return "China";
                    } else {
                        return "Oversea";
                    }
                }
                //修改
                self.edit = function (Item, num) {
                    console.log('edit');
                    $scope.app.maskParams.viewId = self.viewId;
                    $scope.app.maskParams.cityInfo = Item;
                    $scope.app.maskParams.ENlang = self.ENlang;
                    $scope.app.maskParams.num = num;
                    $scope.app.showHideMask(true, 'pages/tv/WeatherEdit_Samsung.html');
                }
                //删除
                self.del = function (id, index, num) {
                    var index = index;
                    if (!confirm('确认删除？')) {
                        return;
                    }
                    var data = JSON.stringify({
                        "token": util.getParams('token'),
                        "action": "delete",
                        "viewID": self.viewId,
                        "data": {
                            "ID": id - 0
                        },
                        "lang": util.langStyle()
                    })
                    $http({
                        method: 'POST',
                        url: util.getApiUrl('commonview', '', 'server'),
                        data: data
                    }).then(function successCallback(response) {
                        var data = response.data;
                        if (data.rescode == '200') {
                            alert('删除成功');
                            $scope.app.maskParams.num = num;
                            $state.reload('app.tvAdmin.Samsung_Weather');
                        } else if (data.rescode == '401') {
                            alert('访问超时，请重新登录');
                            $state.go('login');
                        } else {
                            alert('删除失败，' + data.errInfo);
                        }
                    }, function errorCallback(response) {
                        alert('连接服务器出错');
                    });
                }
                $scope.$on("tabNum", function () {
                    alert('success')
                })

            }
        ])
        //三星天气 Add Samsung_Weather
        .controller('Samsung_Weather_Add_Controler', ['$scope', '$state', '$http', '$stateParams', '$location', 'util', 'CONFIG',
            function ($scope, $state, $http, $stateParams, $location, util, CONFIG) {
                var self = this;
                self.init = function () {
                    self.viewId = $scope.app.maskParams.viewId;
                    self.editLangs = util.getParams('editLangs');
                    self.tabNum = $scope.app.maskParams.num;
                    self.getCountry(self.tabNum)
                };

                self.cancel = function () {
                    $scope.app.showHideMask(false);
                };
                self.getCountry = function (para) {
                    if (para == 1) {
                        self.whichCountry = "China";
                    } else {
                        self.whichCountry = "Overseas";
                    }
                }

                self.save = function () {
                    var data = JSON.stringify({
                        "token": util.getParams('token'),
                        "action": "add",
                        "viewID": Number(self.viewId),
                        "data": {
                            "Seq": self.Seq,
                            "Country": self.whichCountry,
                            "City": self.City
                        },
                        "lang": util.langStyle()
                    })


                    self.saving = true;
                    $http({
                        method: 'POST',
                        url: util.getApiUrl('commonview', '', 'server'),
                        data: data
                    }).then(function successCallback(response) {
                        var data = response.data;
                        if (data.rescode == '200') {
                            alert('添加成功');
                            $state.reload('app.tvAdmin.Samsung_Weather');
                            // 在app控制器上面加了一个天气的参数
                            $scope.app.tabNum = self.tabNum;
                            self.cancel();
                        } else if (data.rescode == '401') {
                            alert('访问超时，请重新登录');
                            $state.go('login');
                        } else {
                            alert('添加失败，' + data.errInfo);
                        }
                    }, function errorCallback(response) {
                        alert('连接服务器出错');
                    }).finally(function (value) {
                        self.saving = false;
                    });

                };
            }
        ])
        //三星天气 Edit Samsung_Weather
        .controller('Samsung_Weather_Edit_Controler', ['$scope', '$state', '$http', '$stateParams', '$location', 'util', 'CONFIG',
            function ($scope, $state, $http, $stateParams, $location, util, CONFIG) {
                var self = this;
                self.init = function () {
                    self.viewId = $scope.app.maskParams.viewId;
                    self.cityInfo = $scope.app.maskParams.cityInfo;
                    self.ENlang = $scope.app.maskParams.ENlang;
                    // 获取编辑多语言信息
                    self.editLangs = util.getParams('editLangs');
                    self.tabNum = $scope.app.maskParams.num;
                }

                self.cancel = function () {
                    $scope.app.showHideMask(false);
                }
                self.save = function () {
                    var data = JSON.stringify({
                        "token": util.getParams('token'),
                        "action": "edit",
                        "viewID": Number(self.viewId),
                        "data": {
                            "ID": Number(self.cityInfo.ID),
                            "Seq": self.cityInfo.Seq,
                            "Country": self.cityInfo.Country,
                            "City": self.cityInfo.City
                        },
                        "lang": util.langStyle()
                    })

                    self.saving = true;
                    $http({
                        method: 'POST',
                        url: util.getApiUrl('commonview', '', 'server'),
                        data: data
                    }).then(function successCallback(response) {
                        var data = response.data;
                        if (data.rescode == '200') {
                            alert('修改成功');
                            // 在app控制器上面加了一个天气的参数
                            $scope.app.tabNum = self.tabNum;
                            self.cancel();
                            $state.reload('app.tvAdmin.Samsung_Weather');
                        } else if (data.rescode == '401') {
                            alert('访问超时，请重新登录');
                            $state.go('login');
                        } else {
                            alert('修改失败，' + data.errInfo);
                        }
                    }, function errorCallback(response) {
                        alert('连接服务器出错');
                    }).finally(function (value) {
                        self.saving = false;
                    });

                }
            }
        ])

        /*账单URL*/
        .controller('BillingUrlController', ['$scope', '$state', '$http', '$stateParams', '$location', 'util',
            function ($scope, $state, $http, $stateParams, $location, util) {
                var self = this;

                self.init = function () {
                    self.viewId = $stateParams.moduleId;
                    self.loadURL();
                }

                self.saving = false;

                self.save = function (id, index) {

                    var data = JSON.stringify({
                        "token": util.getParams('token'),
                        "action": "add",
                        "viewID": Number(self.viewId),
                        "data": {
                            "URL": self.url
                        },
                        "lang": util.langStyle()
                    })
                    $http({
                        method: 'POST',
                        url: util.getApiUrl('commonview', '', 'server'),
                        data: data
                    }).then(function successCallback(response) {
                        var data = response.data;
                        if (data.rescode == '200') {
                            alert('修改成功');
                            self.saving = false;
                        } else if (data.rescode == '401') {
                            alert('访问超时，请重新登录');
                            $state.go('login');
                        } else {
                            alert('修改失败，' + data.errInfo);
                        }
                    }, function errorCallback(response) {
                        alert('连接服务器出错');
                    });
                }



                self.loadURL = function () {
                    var data = JSON.stringify({
                        "token": util.getParams('token'),
                        "action": "get",
                        "viewID": self.viewId - 0,
                        "lang": util.langStyle()
                    })
                    self.loading = true;
                    $http({
                        method: 'POST',
                        url: util.getApiUrl('commonview', '', 'server'),
                        data: data
                    }).then(function successCallback(response) {
                        var data = response.data;
                        if (data.rescode == '200') {
                            self.url = data.data.URL;
                        } else if (data.rescode == '401') {
                            alert('访问超时，请重新登录');
                            $state.go('login');
                        } else {
                            alert('加载信息失败，' + data.errInfo);
                        }
                    }, function errorCallback(response) {
                        alert('连接服务器出错');
                    }).finally(function (value) {
                        self.loading = false;
                    });
                }

            }
        ])


        //通用天气（土豪金） WeatherCommon
        .controller('WeatherCommon_Controler', ['$q', '$scope', '$state', '$http', '$stateParams', '$filter', 'util', 'CONFIG',
            function ($q, $scope, $state, $http, $stateParams, $filter, util, CONFIG) {
                console.log('WeatherCommon');
                var self = this;
                self.init = function () {
                    self.info = {};
                    self.viewId = $stateParams.moduleId;
                    self.defaultLangCode = util.getDefaultLangCode();
                    self.editLangs = util.getParams('editLangs');
                    self.ENlang = self.getENlang();
                    self.getInfo();
                    if ($scope.app.maskParams.num) {
                        self.tab = $scope.app.maskParams.num
                    } else {
                        self.tab = $scope.app.tabNum ? $scope.app.tabNum : 1;
                    }
                    // 使用一次后，赋值为空
                    $scope.app.tabNum = null;
                    $scope.app.maskParams.num = null;
                }

                //获取英文城市名
                self.getENlang = function () {
                    var ENlang;
                    for (var i = 0; i < self.editLangs.length; i++) {
                        ENlang = self.editLangs[i];
                        if (ENlang.name == "en") {
                            return ENlang.code;
                        }
                    }
                }

                //获取
                self.getInfo = function () {
                    var deferred = $q.defer();
                    self.loading = true;
                    var data = JSON.stringify({
                        viewID: Number(self.viewId),
                        token: util.getParams('token'),
                        action: "get",
                        lang: util.langStyle()
                    })
                    $http({
                        method: 'POST',
                        url: util.getApiUrl('commonview', '', 'server'),
                        data: data
                    }).then(function successCallback(response) {
                        var data = response.data;
                        if (data.rescode == '200') {
                            self.info = data.data.data;
                            deferred.resolve();
                        } else if (data.rescode == '401') {
                            alert('访问超时，请重新登录');
                            $state.go('login');
                        } else {
                            alert('读取信息失败，' + data.errInfo);
                            deferred.reject();
                        }
                    }, function errorCallback(response) {
                        alert('服务器出错');
                        deferred.reject();
                    }).finally(function (e) {
                        self.loading = false;
                    });
                    return deferred.promise;

                };
                //新增
                self.add = function (num) {
                    $scope.app.maskParams.viewId = self.viewId;
                    $scope.app.maskParams.num = num;
                    $scope.app.showHideMask(true, 'pages/tv/WeatherAdd_Common.html');
                }

                self.Country = function () {
                    if (self.tab == 1) {
                        return "China";
                    } else {
                        return "Oversea";
                    }
                }
                //修改
                self.edit = function (Item, num) {
                    console.log('edit');
                    $scope.app.maskParams.viewId = self.viewId;
                    $scope.app.maskParams.cityInfo = Item;
                    $scope.app.maskParams.ENlang = self.ENlang;
                    $scope.app.maskParams.num = num;
                    $scope.app.showHideMask(true, 'pages/tv/WeatherEdit_Common.html');
                }
                //删除
                self.del = function (id, index, num) {
                    var index = index;
                    if (!confirm('确认删除？')) {
                        return;
                    }
                    var data = JSON.stringify({
                        "token": util.getParams('token'),
                        "action": "delete",
                        "viewID": self.viewId,
                        "data": {
                            "ID": id - 0
                        },
                        "lang": util.langStyle()
                    })
                    $http({
                        method: 'POST',
                        url: util.getApiUrl('commonview', '', 'server'),
                        data: data
                    }).then(function successCallback(response) {
                        var data = response.data;
                        if (data.rescode == '200') {
                            alert('删除成功');
                            $scope.app.maskParams.num = num;
                            $state.reload('app.tvAdmin.WeatherCommon');
                        } else if (data.rescode == '401') {
                            alert('访问超时，请重新登录');
                            $state.go('login');
                        } else {
                            alert('删除失败，' + data.errInfo);
                        }
                    }, function errorCallback(response) {
                        alert('连接服务器出错');
                    });
                }
                $scope.$on("tabNum", function () {
                    alert('success')
                })

            }
        ])
        //通用天气（土豪金 Add WeatherCommon
        .controller('WeatherCommon_Add_Controler', ['$scope', '$state', '$http', '$stateParams', '$location', 'util', 'CONFIG',
            function ($scope, $state, $http, $stateParams, $location, util, CONFIG) {
                var self = this;
                self.init = function () {
                    self.viewId = $scope.app.maskParams.viewId;
                    self.editLangs = util.getParams('editLangs');
                    self.tabNum = $scope.app.maskParams.num;
                    self.getCountry(self.tabNum)
                };

                self.cancel = function () {
                    $scope.app.showHideMask(false);
                };
                self.getCountry = function (para) {
                    if (para == 1) {
                        self.whichCountry = "China";
                    } else {
                        self.whichCountry = "Overseas";
                    }
                }

                self.save = function () {
                    var data = JSON.stringify({
                        "token": util.getParams('token'),
                        "action": "add",
                        "viewID": Number(self.viewId),
                        "data": {
                            "Seq": self.Seq,
                            "Country": self.whichCountry,
                            "City": self.City
                        },
                        "lang": util.langStyle()
                    })


                    self.saving = true;
                    $http({
                        method: 'POST',
                        url: util.getApiUrl('commonview', '', 'server'),
                        data: data
                    }).then(function successCallback(response) {
                        var data = response.data;
                        if (data.rescode == '200') {
                            alert('添加成功');
                            $state.reload('app.tvAdmin.WeatherCommon');
                            // 在app控制器上面加了一个天气的参数
                            $scope.app.tabNum = self.tabNum;
                            self.cancel();
                        } else if (data.rescode == '401') {
                            alert('访问超时，请重新登录');
                            $state.go('login');
                        } else {
                            alert('添加失败，' + data.errInfo);
                        }
                    }, function errorCallback(response) {
                        alert('连接服务器出错');
                    }).finally(function (value) {
                        self.saving = false;
                    });

                };
            }
        ])
        //通用天气（土豪金 Edit WeatherCommon
        .controller('WeatherCommon_Edit_Controler', ['$scope', '$state', '$http', '$stateParams', '$location', 'util', 'CONFIG',
            function ($scope, $state, $http, $stateParams, $location, util, CONFIG) {
                var self = this;
                self.init = function () {
                    self.viewId = $scope.app.maskParams.viewId;
                    self.cityInfo = $scope.app.maskParams.cityInfo;
                    self.ENlang = $scope.app.maskParams.ENlang;
                    // 获取编辑多语言信息
                    self.editLangs = util.getParams('editLangs');
                    self.tabNum = $scope.app.maskParams.num;
                }

                self.cancel = function () {
                    $scope.app.showHideMask(false);
                }
                self.save = function () {
                    var data = JSON.stringify({
                        "token": util.getParams('token'),
                        "action": "edit",
                        "viewID": Number(self.viewId),
                        "data": {
                            "ID": Number(self.cityInfo.ID),
                            "Seq": self.cityInfo.Seq,
                            "Country": self.cityInfo.Country,
                            "City": self.cityInfo.City
                        },
                        "lang": util.langStyle()
                    })

                    self.saving = true;
                    $http({
                        method: 'POST',
                        url: util.getApiUrl('commonview', '', 'server'),
                        data: data
                    }).then(function successCallback(response) {
                        var data = response.data;
                        if (data.rescode == '200') {
                            alert('修改成功');
                            // 在app控制器上面加了一个天气的参数
                            $scope.app.tabNum = self.tabNum;
                            self.cancel();
                            $state.reload('app.tvAdmin.WeatherCommon');
                        } else if (data.rescode == '401') {
                            alert('访问超时，请重新登录');
                            $state.go('login');
                        } else {
                            alert('修改失败，' + data.errInfo);
                        }
                    }, function errorCallback(response) {
                        alert('连接服务器出错');
                    }).finally(function (value) {
                        self.saving = false;
                    });

                }
            }
        ])

        //分类图文
        .controller('tvPicTextClassListController', ['$scope', '$state', '$http', '$stateParams', '$location', 'util',
            function ($scope, $state, $http, $stateParams, $location, util) {
                var self = this;
                self.info = []; // 分类＋分类下的图文信息
                self.cateIndex; // 当前选中分类index

                self.init = function () {
                    self.viewId = $stateParams.moduleId;
                    self.defaultLangCode = util.getDefaultLangCode();
                    self.loadInfo();
                }


                // 导出json数据
                self.export = function () {
                    var obj = {
                        type: 'picTextClass',
                        data: self.info
                    }
                    util.exportData(JSON.stringify(obj), 'picTextClass.json')
                }

                // 导入json数据
                self.import = function () {
                    if (self.info.length > 0) {
                        if (!confirm('当前模块已有数据，确定导入吗？')) {
                            return
                        }
                    }
                    util.importData(self, $scope, 'picTextClass', self.importData)
                }

                // 导入数据后，先添加分类
                self.importData = function (D) {
                    self.importItemdIdx = 0  // 导入数据计数
                    self.cateIndex = 0
                    self.importTotalNum = 0  // 导入数据总数
                    self.importing = true
                    self.process = "0%"
                    self.jsonData.data.forEach(function (item) {
                        self.importTotalNum += item.sub.length
                    })
                    self.addCate(D)
                }

                // 每次添加完分类，在当前分类下添加子项目
                self.addCate = function (D) {
                    var params = {
                        "token": util.getParams('token'),
                        "action": "add",
                        "viewID": self.viewId - 0,
                        "lang": util.langStyle(),
                        "data": {
                            "PicURL": D.PicURL,
                            "Text": D.Text,
                            "Title": D.Title,
                            "Seq": D.Seq,
                            "PicSize": D.PicSize
                        }
                    }

                    $scope.app.moduleType == 'Samsung_PicText_Classification' && (params.data.Details = D.Details)

                    if ($scope.app.moduleType == 'PicText_Classification_SX'
                        || $scope.app.moduleType == 'SiMaTai_PicText_Classification') {
                        params.data.IconURL = D.IconURL,
                            params.data.IconSize = D.IconSize
                    }

                    var data = JSON.stringify(params);

                    self.saving = true;

                    return $http({
                        method: 'POST',
                        url: util.getApiUrl('commonview', '', 'server'),
                        data: data
                    }).then(function successCallback(response) {
                        if (response.data.rescode == 200) {
                            self.cateIndex++
                            // 分类加载完，再获取各分类id
                            if (self.cateIndex == self.jsonData.data.length) {
                                self.loadInfo().then(function () {
                                    // 截取新增的分类
                                    var newCateArr = self.info.slice(-self.jsonData.data.length)
                                    var flag = false
                                    self.jsonData.data.forEach(function (item, idx) {
                                        item.sub.forEach(function (i) {
                                            self.addItem(newCateArr[idx].ID, i)
                                        })
                                        // 不存在子元素则只完成分类加载
                                        if (item.sub.length > 0) {
                                            flag = true
                                        }
                                    })
                                    if (!flag) {
                                        self.importing = false
                                    }
                                })
                            }
                        }
                    }, function errorCallback(response) {
                        alert('连接服务器出错');
                    }).finally(function (value) {
                        self.saving = false;
                    });

                }

                // 添加子项目方法
                self.addItem = function (cateId, item) {
                    var params = {
                        "token": util.getParams('token'),
                        "action": "add",
                        "viewID": Number(self.viewId),
                        "data": {
                            "PID": cateId,
                            "PicURL": item.PicURL,
                            "Seq": item.Seq,
                            "Text": item.Text,
                            "Title": item.Title,
                            "PicSize": item.PicSize
                        },
                        "lang": util.langStyle()
                    }
                    // 三星午餐
                    $scope.app.moduleType == 'Samsung_Lunch_PicText_Classification' && (params.data.Price = item.Price)

                    // 书香分类
                    $scope.app.moduleType == 'PicText_Classification_SX' && (params.data.Addr = item.Addr)

                    var data = JSON.stringify(params)
                    self.saving = true;
                    $http({
                        method: 'POST',
                        url: util.getApiUrl('commonview', '', 'server'),
                        data: data
                    }).then(function successCallback(response) {
                        var data = response.data;
                        if (data.rescode == '200') {
                            self.importItemdIdx++
                            self.process = Math.floor((self.importItemdIdx / self.importTotalNum) * 100) + '%'
                            // 全部导入成功
                            if (self.importItemdIdx == self.importTotalNum) {
                                self.importing = false
                                $state.reload();
                            }
                        } else if (data.rescode == '401') {
                            alert('访问超时，请重新登录');
                            $state.go('login');
                        } else {
                            alert('添加失败，' + data.errInfo);
                        }
                    }, function errorCallback(response) {
                        alert('连接服务器出错');
                    }).finally(function (value) {
                        self.saving = false;
                    });

                }

                /**
                 * 添加该模版的图文分类
                 *
                 * @method addCategory
                 */
                self.addCategory = function () {
                    $scope.app.maskParams.viewId = self.viewId;
                    $scope.app.maskParams.loadInfo = self.loadInfo;
                    $scope.app.showHideMask(true, 'pages/tv/PicTextClassCateAdd.html');
                }

                /**
                 * 修改该模版的信息
                 *
                 * @method addCategory
                 */
                self.editModule = function () {
                    $scope.app.maskParams.viewId = self.viewId;
                    $scope.app.showHideMask(true, 'pages/tv/PicTextClassModuleEdit_SamsungLunch.html');
                }

                /**
                 * 删除图文分类
                 *
                 * @method delCate
                 */
                self.delCate = function () {
                    if (!confirm('确认删除？')) {
                        return;
                    }
                    var data = JSON.stringify({
                        "token": util.getParams('token'),
                        "action": "delete",
                        "viewID": self.viewId,
                        "lang": util.langStyle(),
                        "data": {
                            "ID": self.info[self.cateIndex].ID
                        }
                    });
                    self.cateDeleting = true;
                    $http({
                        method: 'POST',
                        url: util.getApiUrl('commonview', '', 'server'),
                        data: data
                    }).then(function successCallback(response) {
                        var data = response.data;
                        if (data.rescode == '200') {
                            self.loadInfo();
                        }
                        else {
                            alert('连接服务器出错' + data.errInfo);
                        }
                    }, function errorCallback(response) {
                        alert('连接服务器出错');
                    }).finally(function (value) {
                        self.cateDeleting = false;
                    });
                }

                /**
                 * 编辑该模版的图文分类
                 *
                 * @method editCate
                 */
                self.editCate = function () {
                    $scope.app.maskParams.viewId = self.viewId;
                    $scope.app.maskParams.info = self.info[self.cateIndex];
                    $scope.app.maskParams.loadInfo = self.loadInfo;
                    $scope.app.showHideMask(true, 'pages/tv/PicTextClassCateEdit.html');
                }

                /**
                 * 编辑图文
                 *
                 * @method editPic
                 * @param index 图片在该分类下列表中的序号
                 */
                self.editPic = function (index) {
                    $scope.app.maskParams.viewId = self.viewId;
                    $scope.app.maskParams.cateId = self.info[self.cateIndex].ID;
                    $scope.app.maskParams.info = self.info[self.cateIndex].sub[index];
                    $scope.app.maskParams.loadInfo = self.loadInfo;
                    $scope.app.showHideMask(true, 'pages/tv/classPicTextEdit.html');
                }

                /**
                 * 添加该模版的图文
                 *
                 * @method addPic
                 */
                self.addPic = function () {
                    $scope.app.maskParams.viewId = self.viewId;
                    $scope.app.maskParams.cateId = self.info[self.cateIndex].ID;
                    $scope.app.maskParams.loadInfo = self.loadInfo;
                    $scope.app.showHideMask(true, 'pages/tv/classPicTextAdd.html');
                }

                /**
                 * 删除图文
                 *
                 * @method delPic
                 * @param index 图文在列表中的序号
                 */
                self.delPic = function (index) {
                    if (!confirm('确认删除？')) {
                        return;
                    }
                    var data = JSON.stringify({
                        "token": util.getParams('token'),
                        "action": "delete",
                        "viewID": self.viewId,
                        "lang": util.langStyle(),
                        "data": {
                            "PID": self.info[self.cateIndex].sub[index].ID
                        }
                    });
                    self.picDeleting = true;
                    $http({
                        method: 'POST',
                        url: util.getApiUrl('commonview', '', 'server'),
                        data: data
                    }).then(function successCallback(response) {
                        var data = response.data;
                        if (data.rescode == '200') {
                            self.loadInfo();
                        }
                        else {
                            alert('连接服务器出错' + data.errInfo);
                        }
                    }, function errorCallback(response) {
                        alert('连接服务器出错');
                    }).finally(function (value) {
                        self.picDeleting = false;
                    });
                }

                /**
                 * 加载分类及分类下的信息
                 *
                 * @method loadInfo
                 */
                self.loadInfo = function () {
                    var data = JSON.stringify({
                        "token": util.getParams('token'),
                        "action": "get",
                        "viewID": self.viewId,
                        "lang": util.langStyle()
                    });
                    self.loading = true;
                    return $http({
                        method: 'POST',
                        url: util.getApiUrl('commonview', '', 'server'),
                        data: data
                    }).then(function successCallback(response) {
                        var data = response.data;
                        if (data.rescode == '200') {
                            self.info = data.data.res;
                            if (!self.cateIndex || (self.cateIndex + 1) > self.info.length) {
                                self.cateIndex = 0;
                            }
                            //判断分类下内容为空时，sub属性为空数组，不然模板的ng-repeat会报错
                            if (self.info.length == 0) {
                                self.info[0].sub = [];
                            }
                        }
                        else {
                            alert('连接服务器出错' + data.errInfo);
                        }
                    }, function errorCallback(response) {
                        alert('连接服务器出错');
                    }).finally(function (value) {
                        self.loading = false;
                    });
                }

                /**
                 * 加载分类及分类下的信息
                 *
                 * @method loadInfo
                 * @param index 跳转分类在self.info中的index
                 */
                self.loadPics = function (index) {
                    self.cateIndex = index;
                }

            }
        ])
        //分类图文 Add
        .controller('tvPicTextClassCateAddController', ['$scope', '$state', '$http', '$stateParams', '$location', 'util', 'CONFIG',
            function ($scope, $state, $http, $stateParams, $location, util, CONFIG) {
                var self = this;
                self.viewId = 0;
                self.imgs1 = null;
                self.editLangs = util.getParams('editLangs');

                self.init = function () {
                    self.viewId = $scope.app.maskParams.viewId;
                    self.imgs1 = new util.initUploadImgs([], $scope, true);
                    self.imgs2 = new util.initUploadImgs([], $scope, true);
                    console.log($scope.app.moduleType)
                }

                /**
                 * 关闭弹窗
                 *
                 * @method cancel
                 */
                self.cancel = function () {
                    $scope.app.showHideMask(false);
                }

                /**
                 * 新建分类提交
                 *
                 * @method save
                 */
                self.save = function () {
                    if ($scope.app.moduleType != 'ZheFei_PicText_Classification') {
                        if (!(self.imgs1.data[0] && self.imgs1.data[0].src)) {
                            alert('请上传图片');
                            return;
                        }
                    }
                    var params = {
                        "token": util.getParams('token'),
                        "action": "add",
                        "viewID": self.viewId - 0,
                        "lang": util.langStyle(),
                        "data": {
                            "Text": self.cateName,
                            "Title": self.cateName,
                            "Seq": self.Seq
                        }
                    }
                    if ($scope.app.moduleType != 'ZheFei_PicText_Classification') {
                        params.data.PicURL = self.imgs1.data[0].src || ''
                        params.data.PicSize = self.imgs1.data[0].fileSize || 0
                    }
                    self.Details && (params.data.Details = self.Details)

                    self.imgs2.data[0] && self.imgs2.data[0].src && (
                        params.data.IconURL = self.imgs2.data[0].src || '', params.data.IconSize = self.imgs2.data[0].fileSize || 0
                    )

                    var data = JSON.stringify(params);

                    self.saving = true;

                    return $http({
                        method: 'POST',
                        url: util.getApiUrl('commonview', '', 'server'),
                        data: data
                    }).then(function successCallback(response) {
                        if (response.data.rescode == 200) {
                            alert("添加成功")
                            $scope.app.showHideMask(false);
                            $scope.app.maskParams.loadInfo();
                        }
                    }, function errorCallback(response) {
                        alert('连接服务器出错');
                    }).finally(function (value) {
                        self.saving = false;
                    });
                }

                // 图片上传相关
                self.clickUpload = function (e) {
                    setTimeout(function () {
                        document.getElementById(e).click();
                    }, 0);
                }
            }
        ])
        //分类图文Edit
        .controller('tvPicTextClassCateEditController', ['$scope', '$state', '$http', '$stateParams', '$location', 'util', 'CONFIG',
            function ($scope, $state, $http, $stateParams, $location, util, CONFIG) {
                var self = this;
                self.viewId = 0;
                self.imgs1 = null;
                self.editLangs = util.getParams('editLangs');

                self.init = function () {
                    self.viewId = $scope.app.maskParams.viewId;
                    self.info = JSON.parse(JSON.stringify($scope.app.maskParams.info));
                    self.Seq = self.info.Seq;
                    self.cateName = self.info.Title;
                    self.Details = self.info.Details;
                    self.imgs1 = new util.initUploadImgs([{ "ImageURL": self.info.PicURL, "ImageSize": self.info.PicSize }], $scope, true);
                    self.imgs1.initImgs();
                    self.imgs2 = new util.initUploadImgs([{ "ImageURL": self.info.IconURL, "ImageSize": self.info.IconSize }], $scope, true);
                    self.imgs2.initImgs();
                }

                /**
                 * 关闭弹窗
                 *
                 * @method cancel
                 */
                self.cancel = function () {
                    $scope.app.showHideMask(false);
                }

                /**
                 * 保存分类提交
                 *
                 * @method save
                 */
                self.save = function () {
                    if ($scope.app.moduleType != 'ZheFei_PicText_Classification') {
                        if (!(self.imgs1.data[0] && self.imgs1.data[0].src)) {
                            alert('请上传图片');
                            return;
                        }
                    }

                    var params = {
                        "token": util.getParams('token'),
                        "action": "edit",
                        "viewID": self.viewId - 0,
                        "lang": util.langStyle(),
                        "data": {
                            "ID": self.info.ID,
                            "Text": self.cateName,
                            "Title": self.cateName,
                            "Seq": self.Seq
                        }
                    }

                    if ($scope.app.moduleType != 'ZheFei_PicText_Classification') {
                        params.data.PicURL = self.imgs1.data[0].src || ''
                        params.data.PicSize = self.imgs1.data[0].fileSize || 0
                    }

                    self.Details && (params.data.Details = self.Details)

                    self.imgs2.data[0] && self.imgs2.data[0].src && (
                        params.data.IconURL = self.imgs2.data[0].src || '',
                        params.data.IconSize = self.imgs2.data[0].fileSize || 0
                    )

                    var data = JSON.stringify(params);

                    self.saving = true;

                    return $http({
                        method: 'POST',
                        url: util.getApiUrl('commonview', '', 'server'),
                        data: data
                    }).then(function successCallback(response) {
                        if (response.data.rescode == 200) {
                            alert('修改成功'),
                                $scope.app.showHideMask(false),
                                $scope.app.maskParams.loadInfo()
                        }
                    }, function errorCallback(response) {
                        alert('连接服务器出错');
                    }).finally(function (value) {
                        self.saving = false;
                    });
                }

                // 图片上传相关
                self.clickUpload = function (e) {
                    setTimeout(function () {
                        document.getElementById(e).click();
                    }, 0);
                }

            }
        ])
        //分类图文 分类下的Add
        .controller('tvClassPicTextAddController', ['$scope', '$state', '$http', '$stateParams', '$location', 'util', 'CONFIG',
            function ($scope, $state, $http, $stateParams, $location, util, CONFIG) {
                var self = this;

                self.init = function () {
                    self.viewId = $scope.app.maskParams.viewId;
                    self.cateId = $scope.app.maskParams.cateId;

                    // 获取编辑多语言信息
                    self.editLangs = util.getParams('editLangs');

                    // 初始化频道图片
                    self.imgs1 = util.initUploadImgs([], $scope, true);

                }


                self.cancel = function () {
                    $scope.app.showHideMask(false);
                }

                self.save = function () {

                    //频道图片必填验证
                    if (self.imgs1.data.length == 0 || self.imgs1.data[0].progress < 100) {
                        alert('请上传图片');
                        return;
                    }

                    var params = {
                        "token": util.getParams('token'),
                        "action": "add",
                        "viewID": Number(self.viewId),
                        "data": {
                            "PID": self.cateId,
                            "PicURL": self.imgs1.data[0].src,
                            "Seq": self.Seq,
                            "Text": self.Text,
                            "Title": self.Title,
                            "PicSize": self.imgs1.data[0].fileSize
                        },
                        "lang": util.langStyle()
                    }
                    self.Price && (params.data.Price = self.Price)
                    self.Addr && (params.data.Addr = self.Addr)
                    var data = JSON.stringify(params)
                    self.saving = true;
                    $http({
                        method: 'POST',
                        url: util.getApiUrl('commonview', '', 'server'),
                        data: data
                    }).then(function successCallback(response) {
                        var data = response.data;
                        if (data.rescode == '200') {
                            alert("添加成功")
                            $scope.app.showHideMask(false);
                            $scope.app.maskParams.loadInfo();
                        } else if (data.rescode == '401') {
                            alert('访问超时，请重新登录');
                            $state.go('login');
                        } else {
                            alert('添加失败，' + data.errInfo);
                        }
                    }, function errorCallback(response) {
                        alert('连接服务器出错');
                    }).finally(function (value) {
                        self.saving = false;
                    });

                }


                // 图片上传相关
                self.clickUpload = function (e) {
                    setTimeout(function () {
                        document.getElementById(e).click();
                    }, 0);
                }

            }
        ])
        //分类图文 分类下的Edit
        .controller('tvClassPicTextEditController', ['$scope', '$state', '$http', '$stateParams', '$location', 'util', 'CONFIG',
            function ($scope, $state, $http, $stateParams, $location, util, CONFIG) {
                var self = this;

                self.init = function () {
                    self.viewId = $scope.app.maskParams.viewId;
                    self.cateId = $scope.app.maskParams.cateId;
                    self.info = $scope.app.maskParams.info;
                    self.Price = self.info.Price;
                    self.Addr = self.info.Addr;
                    self.Seq = self.info.Seq;
                    self.Text = self.info.Text;
                    self.Title = self.info.Title;

                    // 获取编辑多语言信息
                    self.editLangs = util.getParams('editLangs');

                    // 初始化频道图片
                    self.imgs1 = util.initUploadImgs([{ "ImageURL": self.info.PicURL, "ImageSize": self.info.PicSize }], $scope, true);
                    self.imgs1.initImgs();

                }


                self.cancel = function () {
                    $scope.app.showHideMask(false);
                }

                self.save = function () {

                    //频道图片必填验证
                    if (self.imgs1.data.length == 0 || self.imgs1.data[0].progress < 100) {
                        alert('请上传图片');
                        return;
                    }

                    var params = {
                        "token": util.getParams('token'),
                        "action": "edit",
                        "viewID": Number(self.viewId),
                        "data": {
                            "PID": self.info.ID,
                            "PicURL": self.imgs1.data[0].src,
                            "Seq": self.Seq,
                            "Text": self.Text,
                            "Title": self.Title,
                            "PicSize": self.imgs1.data[0].fileSize
                        },
                        "lang": util.langStyle()
                    }

                    if ($scope.app.moduleType == 'ZheFei_PicText_Classification') {
                        delete params.data.Title
                    }

                    self.Price && (params.data.Price = self.Price)
                    self.Addr && (params.data.Addr = self.Addr)

                    var data = JSON.stringify(params)
                    self.saving = true;
                    $http({
                        method: 'POST',
                        url: util.getApiUrl('commonview', '', 'server'),
                        data: data
                    }).then(function successCallback(response) {
                        var data = response.data;
                        if (data.rescode == '200') {
                            alert("编辑成功")
                            $scope.app.showHideMask(false);
                            $scope.app.maskParams.loadInfo();
                        } else if (data.rescode == '401') {
                            alert('访问超时，请重新登录');
                            $state.go('login');
                        } else {
                            alert('添加失败，' + data.errInfo);
                        }
                    }, function errorCallback(response) {
                        alert('连接服务器出错');
                    }).finally(function (value) {
                        self.saving = false;
                    });

                }


                // 图片上传相关
                self.clickUpload = function (e) {
                    setTimeout(function () {
                        document.getElementById(e).click();
                    }, 0);
                }

            }
        ])

        //三星午餐 编辑模块信息
        .controller('tvPicTextClassModuleEditController_SamsungLunch', ['$scope', '$state', '$http', '$stateParams', '$location', 'util', 'CONFIG',
            function ($scope, $state, $http, $stateParams, $location, util, CONFIG) {
                var self = this;
                self.viewId = 0;

                self.init = function () {
                    self.viewId = $scope.app.maskParams.viewId;
                    self.loadModuleInfo()
                }

                /**
                 * 关闭弹窗
                 *
                 * @method cancel
                 */
                self.cancel = function () {
                    $scope.app.showHideMask(false);
                }

                /**
                 * 保存模块信息
                 *
                 * @method save
                 */
                self.save = function () {
                    var data = JSON.stringify({
                        "token": util.getParams('token'),
                        "action": "editReserveInfo",
                        "viewID": self.viewId - 0,
                        "lang": util.langStyle(),
                        "data": {
                            "tel": self.tel
                        }
                    });

                    self.saving = true;
                    console.log(data)
                    return $http({
                        method: 'POST',
                        url: util.getApiUrl('commonview', '', 'server'),
                        data: data
                    }).then(function successCallback(response) {
                        alert('保存成功')
                        $scope.app.showHideMask(false);
                    }, function errorCallback(response) {
                        alert('连接服务器出错');
                    }).finally(function (value) {
                        self.saving = false;
                    });
                }

                // 加载模块信息
                self.loadModuleInfo = function () {
                    var data = JSON.stringify({
                        "token": util.getParams('token'),
                        "action": "getReserveInfo",
                        "viewID": self.viewId - 0,
                        "lang": util.langStyle()
                    })
                    self.loading = true;
                    $http({
                        method: 'POST',
                        url: util.getApiUrl('commonview', '', 'server'),
                        data: data
                    }).then(function successCallback(response) {
                        var data = response.data;
                        console.log(data.data)
                        if (data.rescode == '200') {
                            if (data.data) {
                                self.tel = data.data.tel
                            } else {
                                self.tel = null
                            }
                            console.log(self.tel)
                        } else if (data.rescode == '401') {
                            alert('访问超时，请重新登录');
                            $state.go('login');
                        } else {
                            alert('加载信息失败，' + data.errInfo);
                        }
                    }, function errorCallback(response) {
                        alert('连接服务器出错');
                    }).finally(function (value) {
                        self.loading = false;
                    });
                }
            }
        ])



        //Live
        .controller('tvLiveController', ['$scope', '$state', '$http', '$stateParams', '$location', 'util',
            function ($scope, $state, $http, $stateParams, $location, util) {
                var self = this;

                self.init = function () {
                    self.viewId = $stateParams.moduleId;
                    self.defaultLangCode = util.getDefaultLangCode();
                    self.loadLiveList();
                }

                self.edit = function (live) {
                    $scope.app.maskParams.viewId = self.viewId;
                    $scope.app.maskParams.liveInfo = live;
                    if (live.ChannelType == "Custom") {
                        $scope.app.showHideMask(true, 'pages/tv/selfLiveEdit.html');
                    } else {
                        $scope.app.showHideMask(true, 'pages/tv/liveEdit.html');
                    }
                }

                self.changeState = function (live) {
                    var data = JSON.stringify({
                        "token": util.getParams('token'),
                        "action": "editChannel",
                        "viewID": live.ViewID,
                        "data": {
                            "ID": live.ID,
                            "Src": live.Src,
                            "ChannelNum": live.ChannelNum,
                            "ChannelPicURL": live.ChannelPicURL,
                            "ChannelName": live.ChannelName,
                            "ChannelPicSize": live.ChannelPicSize,
                            "ChannelState": live.ChannelState
                        },
                        "lang": util.langStyle()
                    })

                    self.saving = true;
                    $http({
                        method: 'POST',
                        url: util.getApiUrl('commonview', '', 'server'),
                        data: data
                    }).then(function successCallback(response) {
                        var data = response.data;
                        if (data.rescode == '200') {
                            alert('修改成功');
                            // $state.reload();
                        } else if (data.rescode == '401') {
                            alert('访问超时，请重新登录');
                            $state.go('login');
                        } else {
                            alert('修改失败，' + data.errInfo);
                        }
                    }, function errorCallback(response) {
                        alert('连接服务器出错');
                    }).finally(function (value) {
                        self.saving = false;
                    });
                }
                // 导出下载数据
                self.export = function () {
                    var obj = {
                        type: 'Live',
                        data: self.lives
                    }
                    util.exportData(JSON.stringify(obj), 'Live.json')
                }

                // 导入数据
                self.import = function () {
                    if (self.lives.length > 0) {
                        if (!confirm('当前模块已有数据，确定导入吗？')) {
                            return
                        }
                    }
                    util.importData(self, $scope, 'Live', self.importData)
                }

                // 导入后台处理
                self.importData = function (D) {
                    var params = {
                        "token": util.getParams('token'),
                        "action": "addChannel",
                        "viewID": Number(self.viewId),
                        "data": {
                            "Src": D.Src,
                            "ChannelType": D.ChannelType,
                            "ChannelNum": D.ChannelNum,
                            "ChannelPicURL": D.ChannelPicURL,
                            "ChannelName": D.ChannelName,
                            "ChannelPicSize": D.ChannelPicSize
                        },
                        "lang": util.langStyle()
                    }
                    if (D.ChannelType === "Custom") {
                        params.data.ChannelVideoURL = D.ChannelVideoURL
                        params.data.ChannelVideoSize = D.ChannelVideoSize
                    }
                    var data = JSON.stringify(params)
                    self.saving = true;
                    $http({
                        method: 'POST',
                        url: util.getApiUrl('commonview', '', 'server'),
                        data: data
                    }).then(function successCallback(response) {
                        var data = response.data;
                        if (data.rescode == '200') {
                            self.procssFn($state) // 数据加载百分比处理
                        } else if (data.rescode == '401') {
                            alert('访问超时，请重新登录');
                            $state.go('login');
                        } else {
                            alert('添加失败，' + data.errInfo);
                        }
                    }, function errorCallback(response) {
                        alert('连接服务器出错');
                    }).finally(function (value) {
                        self.saving = false;
                    });

                }

                self.del = function (id, index) {
                    var index = index;
                    if (!confirm('确认删除？')) {
                        return;
                    }
                    var data = JSON.stringify({
                        "token": util.getParams('token'),
                        "action": "delChannel",
                        "viewID": self.viewId,
                        "data": {
                            "ChannelList": [
                                { "ID": id - 0 }
                            ]
                        },
                        "lang": util.langStyle()
                    })
                    $http({
                        method: 'POST',
                        url: util.getApiUrl('commonview', '', 'server'),
                        data: data
                    }).then(function successCallback(response) {
                        var data = response.data;
                        if (data.rescode == '200') {
                            alert('删除成功');
                            self.lives.splice(index, 1);
                        } else if (data.rescode == '401') {
                            alert('访问超时，请重新登录');
                            $state.go('login');
                        } else {
                            alert('删除失败，' + data.errInfo);
                        }
                    }, function errorCallback(response) {
                        alert('连接服务器出错');
                    });
                }

                self.add = function () {
                    $scope.app.maskParams.viewId = self.viewId;
                    $scope.app.showHideMask(true, 'pages/tv/liveAdd.html');
                }

                self.addSelf = function () {
                    $scope.app.maskParams.viewId = self.viewId;
                    $scope.app.showHideMask(true, 'pages/tv/selfLiveAdd.html');
                }

                self.loadLiveList = function () {
                    var data = JSON.stringify({
                        "token": util.getParams('token'),
                        "action": "getChannelList",
                        "viewID": self.viewId - 0,
                        "lang": util.langStyle()
                    })
                    self.loading = true;
                    $http({
                        method: 'POST',
                        url: util.getApiUrl('commonview', '', 'server'),
                        data: data
                    }).then(function successCallback(response) {
                        var data = response.data;
                        if (data.rescode == '200') {
                            self.lives = data.data.ChannelList;
                        } else if (data.rescode == '401') {
                            alert('访问超时，请重新登录');
                            $state.go('login');
                        } else {
                            alert('加载直播列表信息失败，' + data.errInfo);
                        }
                    }, function errorCallback(response) {
                        alert('连接服务器出错');
                    }).finally(function (value) {
                        self.loading = false;
                    });
                }

            }
        ])
        // 添加“Live“模块内的直播
        .controller('tvLiveAddController', ['$scope', '$state', '$http', '$stateParams', '$location', 'util', 'CONFIG',
            function ($scope, $state, $http, $stateParams, $location, util, CONFIG) {
                var self = this;

                self.init = function () {
                    self.viewId = $scope.app.maskParams.viewId;

                    // 获取编辑多语言信息
                    self.editLangs = util.getParams('editLangs');
                    // 初始化视频
                    self.imgs2 = new util.initUploadImgs([], $scope, true);
                    // 从资源库选择的图片
                    self.selectedImg = []
                }

                // 资源选择
                self.selectMaterial = function () {
                    $scope.app.showHideMaterialMask(true, 'ChannelIcon')
                    // 选择完资源设置
                    $scope.app.setMaterial = function (data) {
                        self.selectedImg = data
                    }
                }

                self.cancel = function () {
                    $scope.app.showHideMask(false);
                }

                self.save = function (type) {

                    //频道图片必填验证
                    if (self.selectedImg.length == 0) {
                        alert('请选择频道图片');
                        return;
                    }

                    var params = {
                        "token": util.getParams('token'),
                        "action": "addChannel",
                        "viewID": Number(self.viewId),
                        "data": {
                            "ChannelType": "Default",
                            "Src": self.src,
                            "ChannelNum": self.channelNum,
                            "ChannelPicURL": self.selectedImg[0].FileUrl,
                            "ChannelName": self.channelName,
                            "ChannelPicSize": self.selectedImg[0].FileSize
                        },
                        "lang": util.langStyle()
                    }
                    if (type) {
                        params.data.ChannelType = "Custom"
                        if (self.imgs2.data[0]) {
                            params.data.ChannelVideoURL = self.imgs2.data[0].src
                            params.data.ChannelVideoSize = self.imgs2.data[0].fileSize
                            var plusSign
                            if (self.src.charAt(self.src.length - 1) == "/") {
                                plusSign = ""
                            } else {
                                plusSign = "/"
                            }
                            params.data.Src = self.src + plusSign + params.data.ChannelVideoURL.replace(/(.+)[＼＼/]/, "")
                        } else {
                            params.data.ChannelVideoURL = ''
                            params.data.ChannelVideoSize = 0
                            params.data.Src = self.src
                        }
                    }
                    var data = JSON.stringify(params)

                    self.saving = true;
                    $http({
                        method: 'POST',
                        url: util.getApiUrl('commonview', '', 'server'),
                        data: data
                    }).then(function successCallback(response) {
                        var data = response.data;
                        if (data.rescode == '200') {
                            alert('添加成功');
                            $state.reload();
                        } else if (data.rescode == '401') {
                            alert('访问超时，请重新登录');
                            $state.go('login');
                        } else {
                            alert('添加失败，' + data.errInfo);
                        }
                    }, function errorCallback(response) {
                        alert('连接服务器出错');
                    }).finally(function (value) {
                        self.saving = false;
                    });

                }

                // 图片上传相关
                self.clickUpload = function (e) {
                    setTimeout(function () {
                        document.getElementById(e).click();
                    }, 0);
                }

            }
        ])

        // 编辑“Live“模块内的直播
        .controller('tvLiveEditController', ['$scope', '$state', '$http', '$stateParams', '$location', 'util', 'CONFIG',
            function ($scope, $state, $http, $stateParams, $location, util, CONFIG) {
                var self = this;

                self.init = function () {
                    self.viewId = $scope.app.maskParams.viewId;
                    self.liveInfo = JSON.parse(JSON.stringify($scope.app.maskParams.liveInfo));

                    // 获取编辑多语言信息
                    self.editLangs = util.getParams('editLangs');
                    // 从资源库选择的图片
                    self.selectedImg = []

                    self.setInfo();
                }

                // 资源选择
                self.selectMaterial = function () {
                    $scope.app.showHideMaterialMask(true, 'ChannelIcon')
                    // 选择完资源设置
                    $scope.app.setMaterial = function (data) {
                        self.selectedImg = data
                    }
                }

                self.cancel = function () {
                    $scope.app.showHideMask(false);
                }

                self.setInfo = function () {
                    self.channelNum = self.liveInfo.ChannelNum;
                    self.channelName = self.liveInfo.ChannelName;
                    self.src = self.liveInfo.Src;
                    self.selectedImg.push({
                        FileUrl: self.liveInfo.ChannelPicURL,
                        FileSize: self.liveInfo.ChannelPicSize
                    })

                    if (self.liveInfo.ChannelType === "Custom") {
                        var fileName = self.src.replace(/(.+)[＼＼/]/, "")
                        self.src = self.src.replace(fileName, '')
                        self.imgs2 = new util.initUploadImgs([{ "ImageURL": self.liveInfo.ChannelVideoURL, "ImageSize": self.liveInfo.ChannelVideoSize }], $scope, true);
                        self.imgs2.initImgs();
                    }
                }

                self.save = function () {

                    //频道图片必填验证
                    if (self.selectedImg.length == 0) {
                        alert('请选择频道图片');
                        return;
                    }

                    var params = {
                        "token": util.getParams('token'),
                        "action": "editChannel",
                        "viewID": Number(self.viewId),
                        "data": {
                            "ChannelType": "Default",
                            "ID": Number(self.liveInfo.ID),
                            "Src": self.src,
                            "ChannelNum": self.channelNum,
                            "ChannelPicURL": self.selectedImg[0].FileUrl,
                            "ChannelName": self.channelName,
                            "ChannelPicSize": self.selectedImg[0].FileSize,
                            "ChannelState": self.liveInfo.ChannelState
                        },
                        "lang": util.langStyle()
                    }
                    if (self.liveInfo.ChannelType == "Custom") {
                        params.data.ChannelType = "Custom"
                        if (self.imgs2.data[0]) {
                            params.data.ChannelVideoURL = self.imgs2.data[0].src
                            params.data.ChannelVideoSize = self.imgs2.data[0].fileSize
                            var plusSign
                            if (self.src.charAt(self.src.length - 1) == "/") {
                                plusSign = ""
                            } else {
                                plusSign = "/"
                            }
                            params.data.Src = self.src + plusSign + params.data.ChannelVideoURL.replace(/(.+)[＼＼/]/, "")
                        } else {
                            params.data.ChannelVideoURL = ''
                            params.data.ChannelVideoSize = 0
                            params.data.Src = self.src
                        }
                    }
                    var data = JSON.stringify(params)

                    self.saving = true;
                    $http({
                        method: 'POST',
                        url: util.getApiUrl('commonview', '', 'server'),
                        data: data
                    }).then(function successCallback(response) {
                        var data = response.data;
                        if (data.rescode == '200') {
                            alert('修改成功');
                            $state.reload();
                        } else if (data.rescode == '401') {
                            alert('访问超时，请重新登录');
                            $state.go('login');
                        } else {
                            alert('修改失败，' + data.errInfo);
                        }
                    }, function errorCallback(response) {
                        alert('连接服务器出错');
                    }).finally(function (value) {
                        self.saving = false;
                    });

                }


                // 图片上传相关
                self.clickUpload = function (e) {
                    setTimeout(function () {
                        document.getElementById(e).click();
                    }, 0);
                }
            }
        ])

        //SkyworthDTMB
        .controller('tvLiveController_SkyworthDTMB', ['$scope', '$state', '$http', '$stateParams', '$location', 'util',
            function ($scope, $state, $http, $stateParams, $location, util) {
                var self = this;

                self.init = function () {
                    self.viewId = $stateParams.moduleId;
                    self.defaultLangCode = util.getDefaultLangCode();
                    self.loadLiveList();
                }

                self.edit = function (index) {
                    $scope.app.maskParams.viewId = self.viewId;
                    $scope.app.maskParams.liveInfo = self.lives[index];
                    $scope.app.showHideMask(true, 'pages/tv/liveEdit_SkyworthDTMB.html');
                }

                self.del = function (id, index) {
                    var index = index;
                    if (!confirm('确认删除？')) {
                        return;
                    }
                    var data = JSON.stringify({
                        "token": util.getParams('token'),
                        "action": "delChannel",
                        "viewID": self.viewId,
                        "data": {
                            "ChannelList": [
                                { "ID": id - 0 }
                            ]
                        },
                        "lang": util.langStyle()
                    })
                    $http({
                        method: 'POST',
                        url: util.getApiUrl('commonview', '', 'server'),
                        data: data
                    }).then(function successCallback(response) {
                        var data = response.data;
                        if (data.rescode == '200') {
                            alert('删除成功');
                            self.lives.splice(index, 1);
                        } else if (data.rescode == '401') {
                            alert('访问超时，请重新登录');
                            $state.go('login');
                        } else {
                            alert('删除失败，' + data.errInfo);
                        }
                    }, function errorCallback(response) {
                        alert('连接服务器出错');
                    });
                }

                self.add = function () {
                    $scope.app.maskParams.viewId = self.viewId;
                    $scope.app.showHideMask(true, 'pages/tv/liveAdd_SkyworthDTMB.html');
                }

                self.loadLiveList = function () {
                    var data = JSON.stringify({
                        "token": util.getParams('token'),
                        "action": "get",
                        "viewID": self.viewId - 0,
                        "lang": util.langStyle()
                    })
                    self.loading = true;
                    $http({
                        method: 'POST',
                        url: util.getApiUrl('commonview', '', 'server'),
                        data: data
                    }).then(function successCallback(response) {
                        var data = response.data;
                        if (data.rescode == '200') {
                            self.lives = data.data.ChannelList;
                        } else if (data.rescode == '401') {
                            alert('访问超时，请重新登录');
                            $state.go('login');
                        } else {
                            alert('加载直播列表信息失败，' + data.errInfo);
                        }
                    }, function errorCallback(response) {
                        alert('连接服务器出错');
                    }).finally(function (value) {
                        self.loading = false;
                    });
                }

            }
        ])
        // SkyworthDTMB添加
        .controller('tvLiveAddController_SkyworthDTMB', ['$scope', '$state', '$http', '$stateParams', '$location', 'util', 'CONFIG',
            function ($scope, $state, $http, $stateParams, $location, util, CONFIG) {
                var self = this;

                self.init = function () {
                    self.viewId = $scope.app.maskParams.viewId;

                    // 获取编辑多语言信息
                    self.editLangs = util.getParams('editLangs');

                    // 初始化频道图片
                    self.imgs1 = new Imgs([], true);

                }


                self.cancel = function () {
                    $scope.app.showHideMask(false);
                }

                self.save = function () {

                    //频道图片必填验证
                    if (self.imgs1.data.length == 0 || self.imgs1.data[0].progress < 100) {
                        alert('请上传频道图片');
                        return;
                    }

                    var data = JSON.stringify({
                        "token": util.getParams('token'),
                        "action": "addChannel",
                        "viewID": Number(self.viewId),
                        "data": {
                            "Src": self.src,
                            "ChannelNum": self.channelNum,
                            "ChannelPicURL": self.imgs1.data[0].src,
                            "ChannelName": self.channelName,
                            "ChannelPicSize": self.imgs1.data[0].fileSize
                        },
                        "lang": util.langStyle()
                    })
                    self.saving = true;
                    $http({
                        method: 'POST',
                        url: util.getApiUrl('commonview', '', 'server'),
                        data: data
                    }).then(function successCallback(response) {
                        var data = response.data;
                        if (data.rescode == '200') {
                            alert('添加成功');
                            $state.reload();
                        } else if (data.rescode == '401') {
                            alert('访问超时，请重新登录');
                            $state.go('login');
                        } else {
                            alert('添加失败，' + data.errInfo);
                        }
                    }, function errorCallback(response) {
                        alert('连接服务器出错');
                    }).finally(function (value) {
                        self.saving = false;
                    });

                }


                // 图片上传相关
                self.clickUpload = function (e) {
                    setTimeout(function () {
                        document.getElementById(e).click();
                    }, 0);
                }

                function Imgs(imgList, single) {
                    this.initImgList = imgList;
                    this.data = [];
                    this.maxId = 0;
                    this.single = single ? true : false;
                }

                Imgs.prototype = {
                    initImgs: function () {
                        var l = this.initImgList;
                        for (var i = 0; i < l.length; i++) {
                            this.data[i] = {
                                "src": l[i].ImageURL,
                                "fileSize": l[i].ImageSize,
                                "id": this.maxId++,
                                "progress": 100
                            };
                        }
                    },
                    deleteById: function (id) {
                        var l = this.data;
                        for (var i = 0; i < l.length; i++) {
                            if (l[i].id == id) {
                                // 如果正在上传，取消上传
                                if (l[i].progress < 100 && l[i].progress != -1) {
                                    l[i].xhr.abort();
                                }
                                l.splice(i, 1);
                                break;
                            }
                        }
                    },

                    add: function (xhr, fileName, fileSize) {
                        this.data.push({
                            "xhr": xhr,
                            "fileName": fileName,
                            "fileSize": fileSize,
                            "progress": 0,
                            "id": this.maxId
                        });
                        return this.maxId++;
                    },

                    update: function (id, progress, leftSize, fileSize) {
                        for (var i = 0; i < this.data.length; i++) {
                            var f = this.data[i];
                            if (f.id === id) {
                                f.progress = progress;
                                f.leftSize = leftSize;
                                f.fileSize = fileSize;
                                break;
                            }
                        }
                    },

                    setSrcSizeByXhr: function (xhr, src, size) {
                        for (var i = 0; i < this.data.length; i++) {
                            if (this.data[i].xhr == xhr) {
                                this.data[i].src = src;
                                this.data[i].fileSize = size;
                                break;
                            }
                        }
                    },

                    uploadFile: function (e, o) {

                        // 如果这个对象只允许上传一张图片
                        if (this.single) {
                            // 删除第二张以后的图片
                            for (var i = 1; i < this.data.length; i++) {
                                this.deleteById(this.data[i].id);
                            }
                        }

                        var file = $scope[e];
                        var uploadUrl = CONFIG.uploadUrl;
                        var xhr = new XMLHttpRequest();
                        var fileId = this.add(xhr, file.name, file.size, xhr);
                        // self.search();

                        util.uploadFileToUrl(xhr, file, uploadUrl, 'normal',
                            function (evt) {
                                $scope.$apply(function () {
                                    if (evt.lengthComputable) {
                                        var percentComplete = Math.round(evt.loaded * 100 / evt.total);
                                        o.update(fileId, percentComplete, evt.total - evt.loaded, evt.total);
                                        console && console.log(percentComplete);
                                    }
                                });
                            },
                            function (xhr) {
                                var ret = JSON.parse(xhr.responseText);
                                console && console.log(ret);
                                $scope.$apply(function () {
                                    o.setSrcSizeByXhr(xhr, ret.upload_path, ret.size);
                                    // 如果这个对象只允许上传一张图片
                                    if (o.single) {
                                        // 如果长度大于1张图片，删除前几张图片
                                        if (o.data.length > 1) {
                                            for (var i = 0; i < o.data.length - 1; i++) {
                                                o.deleteById(o.data[i].id);
                                            }
                                        }
                                    }
                                });
                            },
                            function (xhr) {
                                $scope.$apply(function () {
                                    o.update(fileId, -1, '', '');
                                });
                                console && console.log('failure');
                                xhr.abort();
                            }
                        );
                    }
                }

            }
        ])
        // SkyworthDTMB编辑
        .controller('tvLiveEditController_SkyworthDTMB', ['$scope', '$state', '$http', '$stateParams', '$location', 'util', 'CONFIG',
            function ($scope, $state, $http, $stateParams, $location, util, CONFIG) {
                var self = this;

                self.init = function () {
                    self.viewId = $scope.app.maskParams.viewId;
                    self.liveInfo = $scope.app.maskParams.liveInfo;

                    // 获取编辑多语言信息
                    self.editLangs = util.getParams('editLangs');

                    self.setInfo();

                }


                self.cancel = function () {
                    $scope.app.showHideMask(false);
                }

                self.setInfo = function () {

                    self.channelNum = self.liveInfo.ChannelNum;
                    self.channelName = self.liveInfo.ChannelName;
                    self.src = self.liveInfo.Src;
                    self.imgs1 = new Imgs([{ "ImageURL": self.liveInfo.ChannelPicURL, "ImageSize": self.liveInfo.ChannelPicSize }], true);
                    self.imgs1.initImgs();
                }

                self.save = function () {

                    //频道图片必填验证
                    if (self.imgs1.data.length == 0) {
                        alert('请上传频道图片');
                        return;
                    }

                    var data = JSON.stringify({
                        "token": util.getParams('token'),
                        "action": "editChannel",
                        "viewID": Number(self.viewId),
                        "data": {
                            "ID": Number(self.liveInfo.ID),
                            "Src": self.src,
                            "ChannelNum": self.channelNum,
                            "ChannelPicURL": self.imgs1.data[0].src,
                            "ChannelName": self.channelName,
                            "ChannelPicSize": self.imgs1.data[0].fileSize
                        },
                        "lang": util.langStyle()
                    })

                    self.saving = true;
                    $http({
                        method: 'POST',
                        url: util.getApiUrl('commonview', '', 'server'),
                        data: data
                    }).then(function successCallback(response) {
                        var data = response.data;
                        if (data.rescode == '200') {
                            alert('修改成功');
                            $state.reload();
                        } else if (data.rescode == '401') {
                            alert('访问超时，请重新登录');
                            $state.go('login');
                        } else {
                            alert('修改失败，' + data.errInfo);
                        }
                    }, function errorCallback(response) {
                        alert('连接服务器出错');
                    }).finally(function (value) {
                        self.saving = false;
                    });

                }


                // 图片上传相关
                self.clickUpload = function (e) {
                    setTimeout(function () {
                        document.getElementById(e).click();
                    }, 0);
                }

                function Imgs(imgList, single) {
                    this.initImgList = imgList;
                    this.data = [];
                    this.maxId = 0;
                    this.single = single ? true : false;
                }

                Imgs.prototype = {
                    initImgs: function () {
                        var l = this.initImgList;
                        for (var i = 0; i < l.length; i++) {
                            this.data[i] = {
                                "src": l[i].ImageURL,
                                "fileSize": l[i].ImageSize,
                                "id": this.maxId++,
                                "progress": 100
                            };
                        }
                    },
                    deleteById: function (id) {
                        var l = this.data;
                        for (var i = 0; i < l.length; i++) {
                            if (l[i].id == id) {
                                // 如果正在上传，取消上传
                                if (l[i].progress < 100 && l[i].progress != -1) {
                                    l[i].xhr.abort();
                                }
                                l.splice(i, 1);
                                break;
                            }
                        }
                    },

                    add: function (xhr, fileName, fileSize) {
                        this.data.push({
                            "xhr": xhr,
                            "fileName": fileName,
                            "fileSize": fileSize,
                            "progress": 0,
                            "id": this.maxId
                        });
                        return this.maxId++;
                    },

                    update: function (id, progress, leftSize, fileSize) {
                        for (var i = 0; i < this.data.length; i++) {
                            var f = this.data[i];
                            if (f.id === id) {
                                f.progress = progress;
                                f.leftSize = leftSize;
                                f.fileSize = fileSize;
                                break;
                            }
                        }
                    },

                    setSrcSizeByXhr: function (xhr, src, size) {
                        for (var i = 0; i < this.data.length; i++) {
                            if (this.data[i].xhr == xhr) {
                                this.data[i].src = src;
                                this.data[i].fileSize = size;
                                break;
                            }
                        }
                    },

                    uploadFile: function (e, o) {

                        // 如果这个对象只允许上传一张图片
                        if (this.single) {
                            // 删除第二张以后的图片
                            for (var i = 1; i < this.data.length; i++) {
                                this.deleteById(this.data[i].id);
                            }
                        }

                        var file = $scope[e];
                        var uploadUrl = CONFIG.uploadUrl;
                        var xhr = new XMLHttpRequest();
                        var fileId = this.add(xhr, file.name, file.size, xhr);
                        // self.search();

                        util.uploadFileToUrl(xhr, file, uploadUrl, 'normal',
                            function (evt) {
                                $scope.$apply(function () {
                                    if (evt.lengthComputable) {
                                        var percentComplete = Math.round(evt.loaded * 100 / evt.total);
                                        o.update(fileId, percentComplete, evt.total - evt.loaded, evt.total);
                                        console && console.log(percentComplete);
                                    }
                                });
                            },
                            function (xhr) {
                                var ret = JSON.parse(xhr.responseText);
                                console && console.log(ret);
                                $scope.$apply(function () {
                                    o.setSrcSizeByXhr(xhr, ret.upload_path, ret.size);
                                    // 如果这个对象只允许上传一张图片
                                    if (o.single) {
                                        // 如果长度大于1张图片，删除前几张图片
                                        if (o.data.length > 1) {
                                            for (var i = 0; i < o.data.length - 1; i++) {
                                                o.deleteById(o.data[i].id);
                                            }
                                        }
                                    }
                                });
                            },
                            function (xhr) {
                                $scope.$apply(function () {
                                    o.update(fileId, -1, '', '');
                                });
                                console && console.log('failure');
                                xhr.abort();
                            }
                        );
                    }
                }

            }
        ])

        // 多图模板
        .controller('tvMultPicController', ['$scope', '$state', '$http', '$stateParams', '$location', 'util',
            function ($scope, $state, $http, $stateParams, $location, util) {
                var self = this;
                self.init = function () {
                    self.viewId = $stateParams.moduleId;
                    console.log(self.viewId);
                    self.defaultLangCode = util.getDefaultLangCode();
                    console.log(self.defaultLangCode);
                    self.loadList();
                }

                // 导出下载数据
                self.export = function () {
                    var obj = {
                        type: 'MultPic',
                        data: self.pics
                    }
                    util.exportData(JSON.stringify(obj), 'MultPic.json')
                }

                // 导入数据
                self.import = function () {
                    if (self.pics.length > 0) {
                        if (!confirm('当前模块已有数据，确定导入吗？')) {
                            return
                        }
                    }
                    util.importData(self, $scope, 'MultPic', self.importData)
                }

                // 导入后台处理
                self.importData = function (D) {
                    var data = {
                        "token": util.getParams('token'),
                        "action": "add",
                        "viewID": Number(self.viewId),
                        "data": [{
                            "Seq": D.Seq,
                            "SourceData": D.SourceData
                        }],
                        "lang": util.langStyle()
                    };
                    data = JSON.stringify(data);
                    self.saving = true;
                    $http({
                        method: 'POST',
                        url: util.getApiUrl('commonview', '', 'server'),
                        data: data
                    }).then(function successCallback(response) {
                        var data = response.data;
                        if (data.rescode == '200') {
                            self.procssFn($state)
                        } else if (data.rescode == '401') {
                            alert('访问超时，请重新登录');
                            $state.go('login');
                        } else {
                            alert('添加失败，' + data.errInfo);
                        }
                    }, function errorCallback(response) {
                        alert('连接服务器出错');
                    }).finally(function (value) {
                        self.saving = false;
                    });

                }

                self.edit = function (index) {
                    $scope.app.maskParams.viewId = self.viewId;
                    $scope.app.maskParams.picInfo = self.pics[index];
                    $scope.app.showHideMask(true, 'pages/tv/multPicEdit.html');
                }

                self.del = function (id, index) {
                    var index = index;
                    if (!confirm('确认删除？')) {
                        return;
                    }
                    var data = JSON.stringify({
                        "token": util.getParams('token'),
                        "action": "delete",
                        "viewID": self.viewId,
                        "data": {
                            "ID": id - 0
                        },
                        "lang": util.langStyle()
                    })
                    $http({
                        method: 'POST',
                        url: util.getApiUrl('commonview', '', 'server'),
                        data: data
                    }).then(function successCallback(response) {
                        var data = response.data;
                        if (data.rescode == '200') {
                            alert('删除成功');
                            self.pics.splice(index, 1);
                        } else if (data.rescode == '401') {
                            alert('访问超时，请重新登录');
                            $state.go('login');
                        } else {
                            alert('删除失败，' + data.errInfo);
                        }
                    }, function errorCallback(response) {
                        alert('连接服务器出错');
                    });
                }

                self.add = function () {
                    $scope.app.maskParams.viewId = self.viewId;
                    $scope.app.showHideMask(true, 'pages/tv/multPicAdd.html');
                }

                self.loadList = function () {
                    var data = JSON.stringify({
                        "token": util.getParams('token'),
                        "action": "get",
                        "viewID": self.viewId - 0,
                        "lang": util.langStyle()
                    })
                    self.loading = true;
                    $http({
                        method: 'POST',
                        url: util.getApiUrl('commonview', '', 'server'),
                        data: data
                    }).then(function successCallback(response) {
                        var data = response.data;
                        if (data.rescode == '200') {
                            self.pics = data.data.pic;
                            // var DFL = util.getDefaultLangCode();
                            // self.defaultLangPic = [];
                            // for(var i=0;i<self.pics.length;i++){
                            //     self.defaultLangPic[i] = self.pics[i].SourceData[DFL]
                            // }
                            console.log(self.pics);
                        } else if (data.rescode == '401') {
                            alert('访问超时，请重新登录');
                            $state.go('login');
                        } else {
                            alert('加载信息失败，' + data.errInfo);
                        }
                    }, function errorCallback(response) {
                        alert('连接服务器出错');
                    }).finally(function (value) {
                        self.loading = false;
                    });
                }

            }
        ])

        // 多图编辑
        .controller('tvMultPicEditController', ['$scope', '$state', '$http', '$stateParams', '$location', 'util', 'CONFIG',
            function ($scope, $state, $http, $stateParams, $location, util, CONFIG) {
                var self = this;

                self.init = function () {
                    self.viewId = $scope.app.maskParams.viewId;
                    self.picInfo = $scope.app.maskParams.picInfo;

                    // 获取编辑多语言信息
                    self.editLangs = util.getParams('editLangs');
                    self.langArr = [];
                    self.langNameArr = [];
                    self.upImgs = [];
                    self.setInfo();

                }


                self.cancel = function () {
                    $scope.app.showHideMask(false);
                }

                self.setInfo = function () {
                    self.Seq = self.picInfo.Seq;
                    self.images = self.picInfo.SourceData;
                    //多语言信息数组
                    for (var key in self.images) {
                        for (var i = 0; i < self.editLangs.length; i++) {
                            if (key == self.editLangs[i].code) {
                                self.langNameArr.push(self.editLangs[i].name);
                                self.langArr.push(key);
                            }
                        }
                    }
                    //创建图片实例
                    for (var i = 0; i < self.langArr.length; i++) {
                        var lang = self.langArr[i];
                        self.upImgs[i] = util.initUploadImgs([{ "ImageURL": self.images[lang].PicURL, "ImageSize": self.images[lang].PicSize }], $scope, true);
                        self.upImgs[i].initImgs();
                        console.log(self.upImgs[i]);
                    }
                }

                self.save = function () {

                    //频道图片必填验证(新建的时候验证，编辑的时候不可能为空，所以不用验证)
                    // if(self.imgs1.data.length == 0) {
                    //     alert('请上传频道图片');
                    //     return;
                    // }

                    var data = {
                        "token": util.getParams('token'),
                        "action": "edit",
                        "viewID": Number(self.viewId),
                        "data": {
                            "ID": Number(self.picInfo.ID),
                            "Seq": self.Seq
                        },
                        "lang": util.langStyle()
                    }
                    for (var i = 0; i < self.langArr.length; i++) {
                        var lang = self.langArr[i];
                        var url = self.upImgs[i].data[0].src;
                        var size = self.upImgs[i].data[0].fileSize;
                        data.data[lang] = { "PicURL": url, "PicSize": size }
                    }
                    console.log(data);
                    data = JSON.stringify(data);
                    self.saving = true;
                    // var URL = util.getApiUrl('commonview', '', 'server');
                    // console.log(URL);
                    $http({
                        method: 'POST',
                        url: util.getApiUrl('commonview', '', 'server'),
                        data: data
                    }).then(function successCallback(response) {
                        var data = response.data;
                        if (data.rescode == '200') {
                            alert('修改成功');
                            $state.reload();
                        } else if (data.rescode == '401') {
                            alert('访问超时，请重新登录');
                            $state.go('login');
                        } else {
                            alert('修改失败，' + data.errInfo);
                        }
                    }, function errorCallback(response) {
                        alert('连接服务器出错');
                    }).finally(function (value) {
                        self.saving = false;
                    });

                }


                // 图片上传相关
                self.clickUpload = function (e) {
                    setTimeout(function () {
                        document.getElementById(e).click();
                    }, 0);
                }

            }
        ])

        // 多图新增
        .controller('tvMultPicAddController', ['$scope', '$state', '$http', '$stateParams', '$location', 'util', 'CONFIG',
            function ($scope, $state, $http, $stateParams, $location, util, CONFIG) {
                var self = this;
                self.uplImgs = [];

                self.init = function () {
                    self.viewId = $scope.app.maskParams.viewId;

                    // 获取编辑多语言信息
                    self.editLangs = util.getParams('editLangs');

                    if ($scope.app.moduleType == 'Samsung_MultPic_OutOfTheHotel') {
                        self.editLangs = self.editLangs.slice(0, 1)
                    }

                    self.defaultLangCode = util.getDefaultLangCode();

                    // 初始化频道图片
                    for (var i = 0; i < self.editLangs.length; i++) {
                        self.uplImgs[i] = util.initUploadImgs([], $scope, true);
                    }
                }


                self.cancel = function () {
                    $scope.app.showHideMask(false);
                }

                self.save = function () {
                    var defaultLang;
                    //频道图片必填验证
                    for (var i = 0; i < self.editLangs.length; i++) {
                        if (self.editLangs[i].code == self.defaultLangCode) {
                            defaultLang = i;
                        }
                    }
                    if (self.uplImgs[defaultLang].data.length == 0 || self.uplImgs[defaultLang].data[0].progress < 100) {
                        alert('请上传默认语言图片');
                        return;
                    }

                    var data = {
                        "token": util.getParams('token'),
                        "action": "add",
                        "viewID": Number(self.viewId),
                        "data": [{
                            "Seq": self.Seq,
                            "SourceData": {}
                        }],
                        "lang": util.langStyle()
                    };
                    for (var i = 0; i < self.editLangs.length; i++) {
                        var lang = self.editLangs[i].code;
                        data.data[0].SourceData[lang] = {
                            "PicURL": self.uplImgs[i].data[0].src,
                            "PicSize": self.uplImgs[i].data[0].fileSize
                        }
                    }
                    console.log(data);
                    data = JSON.stringify(data);
                    self.saving = true;
                    $http({
                        method: 'POST',
                        url: util.getApiUrl('commonview', '', 'server'),
                        data: data
                    }).then(function successCallback(response) {
                        var data = response.data;
                        if (data.rescode == '200') {
                            alert('添加成功');
                            $state.reload();
                        } else if (data.rescode == '401') {
                            alert('访问超时，请重新登录');
                            $state.go('login');
                        } else {
                            alert('添加失败，' + data.errInfo);
                        }
                    }, function errorCallback(response) {
                        alert('连接服务器出错');
                    }).finally(function (value) {
                        self.saving = false;
                    });

                }


                // 图片上传相关
                self.clickUpload = function (e) {
                    setTimeout(function () {
                        document.getElementById(e).click();
                    }, 0);
                }
            }
        ])

        .controller('tvShopController', ['$scope', '$state', '$http', '$stateParams', 'util',
            function ($scope, $state, $http, $stateParams, util) {
                var self = this;

                self.init = function () {
                    self.viewId = $stateParams.moduleId;
                    self.loadInfo();
                }

                self.save = function () {
                    var data = JSON.stringify({
                        "token": util.getParams('token'),
                        "action": "update",
                        "viewID": self.viewId - 0,
                        "lang": util.langStyle(),
                        "data": {
                            "ShopType": self.shopSign
                        }
                    })
                    self.saving = true;
                    $http({
                        method: 'POST',
                        url: util.getApiUrl('commonview', '', 'server'),
                        data: data
                    }).then(function successCallback(response) {
                        var data = response.data;
                        if (data.rescode == '200') {
                            alert('保存成功');
                        } else if (data.rescode == '401') {
                            alert('访问超时，请重新登录');
                            $state.go('login');
                        } else {
                            alert('保存失败，' + data.errInfo);
                        }
                    }, function errorCallback(response) {
                        alert('保存失败');
                    }).finally(function (value) {
                        self.saving = false;
                    });
                }

                self.loadInfo = function () {
                    var data = JSON.stringify({
                        "token": util.getParams('token'),
                        "action": "get",
                        "viewID": self.viewId - 0,
                        "lang": util.langStyle()
                    })
                    self.loading = true;
                    $http({
                        method: 'POST',
                        url: util.getApiUrl('commonview', '', 'server'),
                        data: data
                    }).then(function successCallback(response) {
                        var data = response.data;
                        if (data.rescode == '200') {
                            self.shopSign = data.data.ShopType;
                        } else if (data.rescode == '401') {
                            alert('访问超时，请重新登录');
                            $state.go('login');
                        } else {
                            alert('加载信息失败，' + data.errInfo);
                        }
                    }, function errorCallback(response) {
                        alert('连接服务器出错');
                    }).finally(function (value) {
                        self.loading = false;
                    });
                }

            }
        ])

        .controller('tvShopOnlineController', ['$scope', '$state', '$http', '$stateParams', 'util',
            function ($scope, $state, $http, $stateParams, util) {
                var self = this;

                self.init = function () {
                    self.viewId = $stateParams.moduleId;
                    self.loadInfo();
                }

                self.save = function () {
                    var data = JSON.stringify({
                        "token": util.getParams('token'),
                        "action": "update",
                        "viewID": self.viewId - 0,
                        "lang": util.langStyle(),
                        "data": {
                            "ShopType": self.shopSign,
                            "Url": self.Url
                        }
                    })
                    self.saving = true;
                    $http({
                        method: 'POST',
                        url: util.getApiUrl('commonview', '', 'server'),
                        data: data
                    }).then(function successCallback(response) {
                        var data = response.data;
                        if (data.rescode == '200') {
                            alert('保存成功');
                        } else if (data.rescode == '401') {
                            alert('访问超时，请重新登录');
                            $state.go('login');
                        } else {
                            alert('保存失败，' + data.errInfo);
                        }
                    }, function errorCallback(response) {
                        alert('保存失败');
                    }).finally(function (value) {
                        self.saving = false;
                    });
                }

                self.loadInfo = function () {
                    var data = JSON.stringify({
                        "token": util.getParams('token'),
                        "action": "get",
                        "viewID": self.viewId - 0,
                        "lang": util.langStyle()
                    })
                    self.loading = true;
                    $http({
                        method: 'POST',
                        url: util.getApiUrl('commonview', '', 'server'),
                        data: data
                    }).then(function successCallback(response) {
                        var data = response.data;
                        if (data.rescode == '200') {
                            self.shopSign = data.data.ShopType;
                            self.Url = data.data.Url
                        } else if (data.rescode == '401') {
                            alert('访问超时，请重新登录');
                            $state.go('login');
                        } else {
                            alert('加载信息失败，' + data.errInfo);
                        }
                    }, function errorCallback(response) {
                        alert('连接服务器出错');
                    }).finally(function (value) {
                        self.loading = false;
                    });
                }

            }
        ])

        .controller('tvQHtlShopController', ['$scope', '$state', '$http', '$stateParams', 'util',
            function ($scope, $state, $http, $stateParams, util) {
                var self = this;

                self.init = function () {
                    self.viewId = $stateParams.moduleId;
                    self.loadInfo();
                }

                self.save = function () {
                    var data = JSON.stringify({
                        "token": util.getParams('token'),
                        "action": "update",
                        "viewID": self.viewId - 0,
                        "lang": util.langStyle(),
                        "data": {
                            "ShopType": self.shopSign
                        }
                    })
                    self.saving = true;
                    $http({
                        method: 'POST',
                        url: util.getApiUrl('commonview', '', 'server'),
                        data: data
                    }).then(function successCallback(response) {
                        var data = response.data;
                        if (data.rescode == '200') {
                            alert('保存成功');
                        } else if (data.rescode == '401') {
                            alert('访问超时，请重新登录');
                            $state.go('login');
                        } else {
                            alert('保存失败，' + data.errInfo);
                        }
                    }, function errorCallback(response) {
                        alert('保存失败');
                    }).finally(function (value) {
                        self.saving = false;
                    });
                }

                self.loadInfo = function () {
                    var data = JSON.stringify({
                        "token": util.getParams('token'),
                        "action": "get",
                        "viewID": self.viewId - 0,
                        "lang": util.langStyle()
                    })
                    self.loading = true;
                    $http({
                        method: 'POST',
                        url: util.getApiUrl('commonview', '', 'server'),
                        data: data
                    }).then(function successCallback(response) {
                        var data = response.data;
                        if (data.rescode == '200') {
                            self.shopSign = data.data.ShopType;
                        } else if (data.rescode == '401') {
                            alert('访问超时，请重新登录');
                            $state.go('login');
                        } else {
                            alert('加载信息失败，' + data.errInfo);
                        }
                    }, function errorCallback(response) {
                        alert('连接服务器出错');
                    }).finally(function (value) {
                        self.loading = false;
                    });
                }

            }
        ])





        .controller('tv3rdAppController', ['$scope', '$state', '$http', '$stateParams', 'util',
            function ($scope, $state, $http, $stateParams, util) {
                var self = this;

                self.init = function () {
                    self.viewId = $stateParams.moduleId;
                    self.loadInfo();
                }

                self.edit = function () {
                    $scope.app.maskParams.viewId = self.viewId;
                    $scope.app.maskParams.appGroupID = self.info.AppGroupID;
                    $scope.app.maskParams.callback = self.loadInfo;
                    $scope.app.showHideMask(true, 'pages/tv/3rdAppEdit.html');
                }

                self.add = function () {
                    $scope.app.maskParams.viewId = self.viewId;
                    $scope.app.maskParams.callback = self.loadInfo;
                    $scope.app.showHideMask(true, 'pages/tv/3rdAppAdd.html');
                }

                self.loadInfo = function () {
                    var data = JSON.stringify({
                        "token": util.getParams('token'),
                        "action": "getAppGroup",
                        "viewID": self.viewId - 0,
                        "lang": util.langStyle()
                    })
                    self.loading = true;
                    $http({
                        method: 'POST',
                        url: util.getApiUrl('commonview', '', 'server'),
                        data: data
                    }).then(function successCallback(response) {
                        var data = response.data;
                        if (data.rescode == '200') {
                            self.info = data.data;
                        } else if (data.rescode == '401') {
                            alert('访问超时，请重新登录');
                            $state.go('login');
                        } else {
                            alert('加载信息失败，' + data.errInfo);
                        }
                    }, function errorCallback(response) {
                        alert('连接服务器出错');
                    }).finally(function (value) {
                        self.loading = false;
                    });
                }

            }
        ])

        .controller('tv3rdAppAddController', ['$scope', '$http', 'util',
            function ($scope, $http, util) {
                var self = this;

                self.init = function () {
                    self.viewId = $scope.app.maskParams.viewId;
                    self.callback = $scope.app.maskParams.callback;
                    self.loadList();
                }

                self.loadList = function () {
                    var data = JSON.stringify({
                        "token": util.getParams('token'),
                        "action": "getAppGroupList",
                        "viewID": self.viewId - 0,
                        "lang": util.langStyle()
                    })
                    self.loading = true;
                    $http({
                        method: 'POST',
                        url: util.getApiUrl('commonview', '', 'server'),
                        data: data
                    }).then(function successCallback(response) {
                        var data = response.data;
                        if (data.rescode == '200') {
                            self.appList = data.data;
                            self.selApp = 0;
                        } else if (data.rescode == '401') {
                            alert('访问超时，请重新登录');
                            $state.go('login');
                        } else {
                            alert('加载信息失败，' + data.errInfo);
                        }
                    }, function errorCallback(response) {
                        alert('连接服务器出错');
                    }).finally(function (value) {
                        self.loading = false;
                    });
                }

                self.cancel = function () {
                    $scope.app.showHideMask(false);
                }

                self.save = function () {

                    var data = JSON.stringify({
                        "token": util.getParams('token'),
                        "action": "updateAppGroup",
                        "viewID": Number(self.viewId),
                        "data": {
                            "AppGroupID": self.appList[self.selApp].ID,
                            "AppGroupName": self.appList[self.selApp].Name,
                            "AppGroupDesc": self.appList[self.selApp].Description,
                        },
                        "lang": util.langStyle()
                    })
                    self.saving = true;
                    $http({
                        method: 'POST',
                        url: util.getApiUrl('commonview', '', 'server'),
                        data: data
                    }).then(function successCallback(response) {
                        var data = response.data;
                        if (data.rescode == '200') {
                            alert('保存成功');
                            self.cancel();
                            self.callback();
                        } else if (data.rescode == '401') {
                            alert('访问超时，请重新登录');
                            $state.go('login');
                        } else {
                            alert('保存失败，' + data.errInfo);
                        }
                    }, function errorCallback(response) {
                        alert('连接服务器出错');
                    }).finally(function (value) {
                        self.saving = false;
                    });

                }

            }
        ])

        .controller('tv3rdAppEditController', ['$scope', '$http', 'util',
            function ($scope, $http, util) {
                var self = this;

                self.init = function () {
                    self.viewId = $scope.app.maskParams.viewId;
                    self.callback = $scope.app.maskParams.callback;
                    self.appGroupID = $scope.app.maskParams.appGroupID;
                    self.loadList();
                }

                self.loadList = function () {
                    var data = JSON.stringify({
                        "token": util.getParams('token'),
                        "action": "getAppGroupList",
                        "viewID": self.viewId - 0,
                        "lang": util.langStyle()
                    })
                    self.loading = true;
                    $http({
                        method: 'POST',
                        url: util.getApiUrl('commonview', '', 'server'),
                        data: data
                    }).then(function successCallback(response) {
                        var data = response.data;
                        if (data.rescode == '200') {
                            self.appList = data.data;
                            self.selApp = 0;
                            for (var i = 0; i < self.appList.length; i++) {
                                if (self.appList[i].ID == self.appGroupID) {
                                    self.selApp = i;
                                    break;
                                }
                            }
                        } else if (data.rescode == '401') {
                            alert('访问超时，请重新登录');
                            $state.go('login');
                        } else {
                            alert('加载信息失败，' + data.errInfo);
                        }
                    }, function errorCallback(response) {
                        alert('连接服务器出错');
                    }).finally(function (value) {
                        self.loading = false;
                    });
                }

                self.cancel = function () {
                    $scope.app.showHideMask(false);
                }

                self.save = function () {

                    var data = JSON.stringify({
                        "token": util.getParams('token'),
                        "action": "updateAppGroup",
                        "viewID": Number(self.viewId),
                        "data": {
                            "AppGroupID": self.appList[self.selApp].ID,
                            "AppGroupName": self.appList[self.selApp].Name,
                            "AppGroupDesc": self.appList[self.selApp].Description,
                        },
                        "lang": util.langStyle()
                    })
                    self.saving = true;
                    $http({
                        method: 'POST',
                        url: util.getApiUrl('commonview', '', 'server'),
                        data: data
                    }).then(function successCallback(response) {
                        var data = response.data;
                        if (data.rescode == '200') {
                            alert('保存成功');
                            self.cancel();
                            self.callback();
                        } else if (data.rescode == '401') {
                            alert('访问超时，请重新登录');
                            $state.go('login');
                        } else {
                            alert('保存失败，' + data.errInfo);
                        }
                    }, function errorCallback(response) {
                        alert('连接服务器出错');
                    }).finally(function (value) {
                        self.saving = false;
                    });

                }

            }
        ])

        .controller('tvMovieCommonController', ['$scope', '$state', '$http', '$stateParams', '$location', 'util',
            function ($scope, $state, $http, $stateParams, $location, util) {
                var self = this;

                self.init = function () {
                    self.viewId = $stateParams.moduleId;
                    self.getInfo();
                }

                self.getInfo = function () {
                    var data = JSON.stringify({
                        "token": util.getParams('token'),
                        "action": "getAPIInfo",
                        "viewID": self.viewId - 0,
                        "lang": util.langStyle()
                    });
                    self.loading = true;
                    $http({
                        method: 'POST',
                        url: util.getApiUrl('commonview', '', 'server'),
                        data: data
                    }).then(function successCallback(response) {
                        var data = response.data;
                        if (data.rescode == '200') {
                            self.MovieContentAPIParam = data.data.MovieContentAPIParam;
                            self.MovieContentAPIURL = data.data.MovieContentAPIURL;
                            self.PackageFee = Number(data.data.PackageFee);
                            self.FeeDiscount = Number(data.data.FeeDiscount);
                        } else if (data.rescode == '401') {
                            alert('访问超时，请重新登录');
                            $state.go('login');
                        } else {
                            alert('加载电影信息失败，' + data.errInfo);
                        }
                    }, function errorCallback(response) {
                        alert('连接服务器出错');
                    }).finally(function (value) {
                        self.loading = false;
                    });
                }

                self.save = function () {
                    var data = JSON.stringify({
                        "token": util.getParams('token'),
                        "action": "updateAPIInfo",
                        "viewID": self.viewId - 0,
                        "data": {
                            "MovieContentAPIParam": self.MovieContentAPIParam,
                            "MovieContentAPIURL": self.MovieContentAPIURL,
                            "PackageFee": self.PackageFee,
                            "FeeDiscount": self.FeeDiscount
                        },
                        "lang": util.langStyle()
                    })
                    self.saving = true;
                    $http({
                        method: 'POST',
                        url: util.getApiUrl('commonview', '', 'server'),
                        data: data
                    }).then(function successCallback(response) {
                        var data = response.data;
                        if (data.rescode == '200') {
                            alert('修改成功');
                        } else if (data.rescode == '401') {
                            alert('访问超时，请重新登录');
                            $state.go('login');
                        } else {
                            alert('修改失败' + data.errInfo);
                        }
                    }, function errorCallback(response) {
                        alert('连接服务器出错');
                    }).finally(function (value) {
                        self.saving = false;
                    });
                }

            }
        ])

        .controller('tvSeriesCommonController', ['$scope', '$state', '$http', '$stateParams', '$location', 'util',
            function ($scope, $state, $http, $stateParams, $location, util) {
                var self = this;

                self.init = function () {
                    self.viewId = $stateParams.moduleId;
                    self.getInfo();
                }

                self.getInfo = function () {
                    var data = JSON.stringify({
                        "token": util.getParams('token'),
                        "action": "getAPIInfo",
                        "viewID": self.viewId - 0,
                        "lang": util.langStyle()
                    });
                    self.loading = true;
                    $http({
                        method: 'POST',
                        url: util.getApiUrl('commonview', '', 'server'),
                        data: data
                    }).then(function successCallback(response) {
                        var data = response.data;
                        if (data.rescode == '200') {
                            self.TVSeriesContentAPIParam = data.data.TVSeriesContentAPIParam;
                            self.TVSeriesContentAPIURL = data.data.TVSeriesContentAPIURL;
                            self.PackageFee = data.data.PackageFee;
                            self.FeeDiscount = data.data.FeeDiscount;
                        } else if (data.rescode == '401') {
                            alert('访问超时，请重新登录');
                            $state.go('login');
                        } else {
                            alert('加载电影信息失败，' + data.errInfo);
                        }
                    }, function errorCallback(response) {
                        alert('连接服务器出错');
                    }).finally(function (value) {
                        self.loading = false;
                    });
                }

                self.save = function () {
                    var data = JSON.stringify({
                        "token": util.getParams('token'),
                        "action": "updateAPIInfo",
                        "viewID": self.viewId - 0,
                        "data": {
                            "TVSeriesContentAPIParam": self.TVSeriesContentAPIParam,
                            "TVSeriesContentAPIURL": self.TVSeriesContentAPIURL,
                            "PackageFee": self.PackageFee,
                            "FeeDiscount": self.FeeDiscount
                        },
                        "lang": util.langStyle()
                    })
                    self.saving = true;
                    $http({
                        method: 'POST',
                        url: util.getApiUrl('commonview', '', 'server'),
                        data: data
                    }).then(function successCallback(response) {
                        var data = response.data;
                        if (data.rescode == '200') {
                            alert('修改成功');
                        } else if (data.rescode == '401') {
                            alert('访问超时，请重新登录');
                            $state.go('login');
                        } else {
                            alert('修改失败' + data.errInfo);
                        }
                    }, function errorCallback(response) {
                        alert('连接服务器出错');
                    }).finally(function (value) {
                        self.saving = false;
                    });
                }

            }
        ])
        .controller('tvSeriesLocalController', ['$scope', '$state', '$http', '$stateParams', '$location', 'util',
            function ($scope, $state, $http, $stateParams, $location, util) {
                var self = this;

                self.init = function () {
                    self.viewId = $stateParams.moduleId;
                    self.getInfo();
                }

                self.getInfo = function () {
                    var data = JSON.stringify({
                        "token": util.getParams('token'),
                        "action": "getAPIInfo",
                        "viewID": self.viewId - 0,
                        "lang": util.langStyle()
                    });
                    self.loading = true;
                    $http({
                        method: 'POST',
                        url: util.getApiUrl('commonview', '', 'server'),
                        data: data
                    }).then(function successCallback(response) {
                        var data = response.data;
                        if (data.rescode == '200') {
                            self.TVSeriesContentAPIParam = data.data.TVSeriesContentAPIParam;
                            self.TVSeriesContentAPIURL = data.data.TVSeriesContentAPIURL;
                            self.PackageFee = data.data.PackageFee;
                            self.FeeDiscount = data.data.FeeDiscount;
                        } else if (data.rescode == '401') {
                            alert('访问超时，请重新登录');
                            $state.go('login');
                        } else {
                            alert('加载电影信息失败，' + data.errInfo);
                        }
                    }, function errorCallback(response) {
                        alert('连接服务器出错');
                    }).finally(function (value) {
                        self.loading = false;
                    });
                }

                self.save = function () {
                    var data = JSON.stringify({
                        "token": util.getParams('token'),
                        "action": "updateAPIInfo",
                        "viewID": self.viewId - 0,
                        "data": {
                            "TVSeriesContentAPIParam": self.TVSeriesContentAPIParam,
                            "TVSeriesContentAPIURL": self.TVSeriesContentAPIURL,
                            "PackageFee": self.PackageFee,
                            "FeeDiscount": self.FeeDiscount
                        },
                        "lang": util.langStyle()
                    })
                    self.saving = true;
                    $http({
                        method: 'POST',
                        url: util.getApiUrl('commonview', '', 'server'),
                        data: data
                    }).then(function successCallback(response) {
                        var data = response.data;
                        if (data.rescode == '200') {
                            alert('修改成功');
                        } else if (data.rescode == '401') {
                            alert('访问超时，请重新登录');
                            $state.go('login');
                        } else {
                            alert('修改失败' + data.errInfo);
                        }
                    }, function errorCallback(response) {
                        alert('连接服务器出错');
                    }).finally(function (value) {
                        self.saving = false;
                    });
                }

            }
        ])

        .controller('tvMovieCommonFreeController', ['$scope', '$state', '$http', '$stateParams', '$location', 'util',
            function ($scope, $state, $http, $stateParams, $location, util) {
                var self = this;

                self.init = function () {
                    self.viewId = $stateParams.moduleId;
                    self.getInfo();
                }

                self.getInfo = function () {
                    var data = JSON.stringify({
                        "token": util.getParams('token'),
                        "action": "getAPIInfo",
                        "viewID": self.viewId - 0,
                        "lang": util.langStyle()
                    });
                    self.loading = true;
                    $http({
                        method: 'POST',
                        url: util.getApiUrl('commonview', '', 'server'),
                        data: data
                    }).then(function successCallback(response) {
                        var data = response.data;
                        if (data.rescode == '200') {
                            self.MovieContentAPIParam = data.data.MovieContentAPIParam;
                            self.MovieContentAPIURL = data.data.MovieContentAPIURL;
                        } else if (data.rescode == '401') {
                            alert('访问超时，请重新登录');
                            $state.go('login');
                        } else {
                            alert('加载电影信息失败，' + data.errInfo);
                        }
                    }, function errorCallback(response) {
                        alert('连接服务器出错');
                    }).finally(function (value) {
                        self.loading = false;
                    });
                }

                self.save = function () {
                    var data = JSON.stringify({
                        "token": util.getParams('token'),
                        "action": "updateAPIInfo",
                        "viewID": self.viewId - 0,
                        "data": {
                            "MovieContentAPIParam": self.MovieContentAPIParam,
                            "MovieContentAPIURL": self.MovieContentAPIURL
                        },
                        "lang": util.langStyle()
                    })
                    self.saving = true;
                    $http({
                        method: 'POST',
                        url: util.getApiUrl('commonview', '', 'server'),
                        data: data
                    }).then(function successCallback(response) {
                        var data = response.data;
                        if (data.rescode == '200') {
                            alert('修改成功');
                        } else if (data.rescode == '401') {
                            alert('访问超时，请重新登录');
                            $state.go('login');
                        } else {
                            alert('修改失败' + data.errInfo);
                        }
                    }, function errorCallback(response) {
                        alert('连接服务器出错');
                    }).finally(function (value) {
                        self.saving = false;
                    });
                }

            }
        ])

        .controller('tvMovieCommonAdvController', ['$scope', '$state', '$http', '$stateParams', '$location', 'util',
            function ($scope, $state, $http, $stateParams, $location, util) {
                var self = this;

                self.init = function () {
                    self.viewId = $stateParams.moduleId;
                    self.getInfo();
                }

                self.getInfo = function () {
                    var data = JSON.stringify({
                        "token": util.getParams('token'),
                        "action": "getAPIInfo",
                        "viewID": self.viewId - 0,
                        "lang": util.langStyle()
                    });
                    self.loading = true;
                    $http({
                        method: 'POST',
                        url: util.getApiUrl('commonview', '', 'server'),
                        data: data
                    }).then(function successCallback(response) {
                        var data = response.data;
                        if (data.rescode == '200') {
                            self.MovieContentAPIParam = data.data.MovieContentAPIParam;
                            self.MovieContentAPIURL = data.data.MovieContentAPIURL;
                        } else if (data.rescode == '401') {
                            alert('访问超时，请重新登录');
                            $state.go('login');
                        } else {
                            alert('加载电影信息失败，' + data.errInfo);
                        }
                    }, function errorCallback(response) {
                        alert('连接服务器出错');
                    }).finally(function (value) {
                        self.loading = false;
                    });
                }

                self.save = function () {
                    var data = JSON.stringify({
                        "token": util.getParams('token'),
                        "action": "updateAPIInfo",
                        "viewID": self.viewId - 0,
                        "data": {
                            "MovieContentAPIParam": self.MovieContentAPIParam,
                            "MovieContentAPIURL": self.MovieContentAPIURL
                        },
                        "lang": util.langStyle()
                    })
                    self.saving = true;
                    $http({
                        method: 'POST',
                        url: util.getApiUrl('commonview', '', 'server'),
                        data: data
                    }).then(function successCallback(response) {
                        var data = response.data;
                        if (data.rescode == '200') {
                            alert('修改成功');
                        } else if (data.rescode == '401') {
                            alert('访问超时，请重新登录');
                            $state.go('login');
                        } else {
                            alert('修改失败' + data.errInfo);
                        }
                    }, function errorCallback(response) {
                        alert('连接服务器出错');
                    }).finally(function (value) {
                        self.saving = false;
                    });
                }

            }
        ])
        // 专题大片
        .controller('tvMovieTopicController', ['$scope', '$state', '$http', '$stateParams', '$location', 'util',
            function ($scope, $state, $http, $stateParams, $location, util) {
                var self = this;

                self.init = function () {
                    self.viewId = $stateParams.moduleId;
                    self.getInfo();
                }

                self.getInfo = function () {
                    var data = JSON.stringify({
                        "token": util.getParams('token'),
                        "action": "getAPIInfo",
                        "viewID": self.viewId - 0,
                        "lang": util.langStyle()
                    });
                    self.loading = true;
                    $http({
                        method: 'POST',
                        url: util.getApiUrl('commonview', '', 'server'),
                        data: data
                    }).then(function successCallback(response) {
                        var data = response.data;
                        if (data.rescode == '200') {
                            self.MovieContentAPIParam = data.data.MovieContentAPIParam;
                            self.MovieContentAPIURL = data.data.MovieContentAPIURL;
                            self.PackageFee = data.data.PackageFee;
                            self.FeeDiscount = data.data.FeeDiscount;
                        } else if (data.rescode == '401') {
                            alert('访问超时，请重新登录');
                            $state.go('login');
                        } else {
                            alert('加载电影信息失败，' + data.errInfo);
                        }
                    }, function errorCallback(response) {
                        alert('连接服务器出错');
                    }).finally(function (value) {
                        self.loading = false;
                    });
                }

                self.save = function () {
                    var data = JSON.stringify({
                        "token": util.getParams('token'),
                        "action": "updateAPIInfo",
                        "viewID": self.viewId - 0,
                        "data": {
                            "MovieContentAPIParam": self.MovieContentAPIParam,
                            "MovieContentAPIURL": self.MovieContentAPIURL,
                            "PackageFee": self.PackageFee,
                            "FeeDiscount": self.FeeDiscount
                        },
                        "lang": util.langStyle()
                    })
                    self.saving = true;
                    $http({
                        method: 'POST',
                        url: util.getApiUrl('commonview', '', 'server'),
                        data: data
                    }).then(function successCallback(response) {
                        var data = response.data;
                        if (data.rescode == '200') {
                            alert('修改成功');
                        } else if (data.rescode == '401') {
                            alert('访问超时，请重新登录');
                            $state.go('login');
                        } else {
                            alert('修改失败' + data.errInfo);
                        }
                    }, function errorCallback(response) {
                        alert('连接服务器出错');
                    }).finally(function (value) {
                        self.saving = false;
                    });
                }

            }
        ])

        // 项目本地电影
        .controller('tvMovieLocalController', ['$scope', '$state', '$http', '$stateParams', '$location', 'util',
            function ($scope, $state, $http, $stateParams, $location, util) {
                var self = this;

                self.init = function () {
                    self.viewId = $stateParams.moduleId;
                    self.getInfo();
                }

                self.getInfo = function () {
                    var data = JSON.stringify({
                        "token": util.getParams('token'),
                        "action": "getAPIInfo",
                        "viewID": self.viewId - 0,
                        "lang": util.langStyle()
                    });
                    self.loading = true;
                    $http({
                        method: 'POST',
                        url: util.getApiUrl('commonview', '', 'server'),
                        data: data
                    }).then(function successCallback(response) {
                        var data = response.data;
                        if (data.rescode == '200') {
                            self.MovieContentAPIParam = data.data.MovieContentAPIParam;
                            self.MovieContentAPIURL = data.data.MovieContentAPIURL;
                            self.PackageFee = data.data.PackageFee;
                            self.FeeDiscount = data.data.FeeDiscount;
                        } else if (data.rescode == '401') {
                            alert('访问超时，请重新登录');
                            $state.go('login');
                        } else {
                            alert('加载电影信息失败，' + data.errInfo);
                        }
                    }, function errorCallback(response) {
                        alert('连接服务器出错');
                    }).finally(function (value) {
                        self.loading = false;
                    });
                }

                self.save = function () {
                    var data = JSON.stringify({
                        "token": util.getParams('token'),
                        "action": "updateAPIInfo",
                        "viewID": self.viewId - 0,
                        "data": {
                            "MovieContentAPIParam": self.MovieContentAPIParam,
                            "MovieContentAPIURL": self.MovieContentAPIURL,
                            "PackageFee": self.PackageFee,
                            "FeeDiscount": self.FeeDiscount
                        },
                        "lang": util.langStyle()
                    })
                    self.saving = true;
                    $http({
                        method: 'POST',
                        url: util.getApiUrl('commonview', '', 'server'),
                        data: data
                    }).then(function successCallback(response) {
                        var data = response.data;
                        if (data.rescode == '200') {
                            alert('修改成功');
                        } else if (data.rescode == '401') {
                            alert('访问超时，请重新登录');
                            $state.go('login');
                        } else {
                            alert('修改失败' + data.errInfo);
                        }
                    }, function errorCallback(response) {
                        alert('连接服务器出错');
                    }).finally(function (value) {
                        self.saving = false;
                    });
                }

            }
        ])

        .controller('tvMenuAddController', ['$scope', '$state', '$http', '$stateParams', '$location', 'util', 'CONFIG',
            function ($scope, $state, $http, $stateParams, $location, util, CONFIG) {
                var self = this;

                self.init = function () {
                    // 目前只有首页上添加一级菜单，menuLv：1
                    self.menuLv = $scope.app.maskParams.menuLv;
                    console.log(self.menuLv)
                    // 获取编辑多语言信息
                    self.editLangs = util.getParams('editLangs');

                    // 获取菜单类型名称、风格图
                    self.getMenuInfo();

                    // 初始化菜单图片和高亮图片
                    self.imgs1 = new Imgs([], true);
                    self.imgs2 = new Imgs([], true);

                }

                self.getMenuInfo = function () {
                    var data = JSON.stringify({
                        "token": util.getParams('token'),
                        "action": "getMainMenuTemplate",
                        "lang": util.langStyle()
                    });
                    self.loading = true;
                    // 获取菜单类型名称、风格图
                    $http({
                        method: 'POST',
                        url: util.getApiUrl('mainmenu', '', 'server'),
                        data: data
                    }).then(function successCallback(response) {
                        var data = response.data;
                        if (data.rescode == '200') {
                            self.styleImg = data.data.SamplePic;
                        } else if (data.rescode == '401') {
                            alert('访问超时，请重新登录');
                            $state.go('login');
                        } else {
                            alert('加载菜单示意信息失败，' + data.errInfo);
                        }
                    }, function errorCallback(response) {
                        alert('连接服务器出错');
                    }).finally(function (value) {
                        self.loading = false;
                    });
                }


                self.cancel = function () {
                    $scope.app.showHideMask(false);
                }

                self.save = function () {

                    //菜单图片必填验证
                    if (self.imgs1.data.length == 0 || self.imgs1.data[0].progress < 100) {
                        alert('请上传菜单图片');
                        return;
                    }

                    //菜单高亮图片必填验证
                    if (self.imgs2.data.length == 0 || self.imgs2.data[0].progress < 100) {
                        alert('请上传菜单高亮图片');
                        return;
                    }

                    var data = JSON.stringify({
                        "token": util.getParams('token'),
                        "action": "addMainMenuFirstMenu",
                        "viewID": 1,     //主菜单模板ViewID都为1
                        "data": {
                            "isMenu": 1,     //0不是主菜单，1是主菜单
                            "viewType": "",
                            "Name": self.menuName,
                            "IconURL": self.imgs1.data[0].src,
                            "IconSize": self.imgs1.data[0].fileSize,
                            "IconFocusURL": self.imgs2.data[0].src,
                            "IconFocusSize": self.imgs2.data[0].fileSize,
                            "Des": self.des || {
                                "zh-CN": "",
                                "en-US": ""
                            },
                            "Seq": self.seq  //在一级菜单中的排序号，从1开始
                        },
                        "lang": util.langStyle()
                    })

                    self.saving = true;
                    $http({
                        method: 'POST',
                        url: util.getApiUrl('commonview', '', 'server'),
                        data: data
                    }).then(function successCallback(response) {
                        var data = response.data;
                        if (data.rescode == '200') {
                            alert('添加成功');
                            $state.reload();
                        } else if (data.rescode == '401') {
                            alert('访问超时，请重新登录');
                            $state.go('login');
                        } else {
                            alert('加载菜单示意信息失败，' + data.errInfo);
                        }
                    }, function errorCallback(response) {
                        alert('连接服务器出错');
                    }).finally(function (value) {
                        self.saving = false;
                    });

                }


                // 图片上传相关
                self.clickUpload = function (e) {
                    setTimeout(function () {
                        document.getElementById(e).click();
                    }, 0);
                }

                function Imgs(imgList, single) {
                    this.initImgList = imgList;
                    this.data = [];
                    this.maxId = 0;
                    this.single = single ? true : false;
                }

                Imgs.prototype = {
                    initImgs: function () {
                        var l = this.initImgList;
                        for (var i = 0; i < l.length; i++) {
                            this.data[i] = {
                                "src": l[i].ImageURL,
                                "fileSize": l[i].ImageSize,
                                "id": this.maxId++,
                                "progress": 100
                            };
                        }
                    },
                    deleteById: function (id) {
                        var l = this.data;
                        for (var i = 0; i < l.length; i++) {
                            if (l[i].id == id) {
                                // 如果正在上传，取消上传
                                if (l[i].progress < 100 && l[i].progress != -1) {
                                    l[i].xhr.abort();
                                }
                                l.splice(i, 1);
                                break;
                            }
                        }
                    },

                    add: function (xhr, fileName, fileSize) {
                        this.data.push({
                            "xhr": xhr,
                            "fileName": fileName,
                            "fileSize": fileSize,
                            "progress": 0,
                            "id": this.maxId
                        });
                        return this.maxId++;
                    },

                    update: function (id, progress, leftSize, fileSize) {
                        for (var i = 0; i < this.data.length; i++) {
                            var f = this.data[i];
                            if (f.id === id) {
                                f.progress = progress;
                                f.leftSize = leftSize;
                                f.fileSize = fileSize;
                                break;
                            }
                        }
                    },

                    setSrcSizeByXhr: function (xhr, src, size) {
                        for (var i = 0; i < this.data.length; i++) {
                            if (this.data[i].xhr == xhr) {
                                this.data[i].src = src;
                                this.data[i].fileSize = size;
                                break;
                            }
                        }
                    },

                    uploadFile: function (e, o) {

                        // 如果这个对象只允许上传一张图片
                        if (this.single) {
                            // 删除第二张以后的图片
                            for (var i = 1; i < this.data.length; i++) {
                                this.deleteById(this.data[i].id);
                            }
                        }

                        var file = $scope[e];
                        var uploadUrl = CONFIG.uploadUrl;
                        var xhr = new XMLHttpRequest();
                        var fileId = this.add(xhr, file.name, file.size, xhr);
                        // self.search();

                        util.uploadFileToUrl(xhr, file, uploadUrl, 'normal',
                            function (evt) {
                                $scope.$apply(function () {
                                    if (evt.lengthComputable) {
                                        var percentComplete = Math.round(evt.loaded * 100 / evt.total);
                                        o.update(fileId, percentComplete, evt.total - evt.loaded, evt.total);
                                    }
                                });
                            },
                            function (xhr) {
                                var ret = JSON.parse(xhr.responseText);
                                console && console.log(ret);
                                $scope.$apply(function () {
                                    o.setSrcSizeByXhr(xhr, ret.upload_path, ret.size);
                                    // 如果这个对象只允许上传一张图片
                                    if (o.single) {
                                        // 如果长度大于1张图片，删除前几张图片
                                        if (o.data.length > 1) {
                                            for (var i = 0; i < o.data.length - 1; i++) {
                                                o.deleteById(o.data[i].id);
                                            }
                                        }
                                    }
                                });
                            },
                            function (xhr) {
                                $scope.$apply(function () {
                                    o.update(fileId, -1, '', '');
                                });
                                console && console.log('failure');
                                xhr.abort();
                            }
                        );
                    }
                }

            }
        ])

        .controller('tvModuleAddController', ['$scope', '$state', '$http', '$stateParams', '$location', 'util', 'CONFIG',
            function ($scope, $state, $http, $stateParams, $location, util, CONFIG) {
                var self = this;

                self.init = function () {
                    // 目前只有首页上添加一级菜单，menuLv：1
                    self.menuLv = $scope.app.maskParams.menuLv;

                    // 非一级菜单时，parentMenu
                    if (self.menuLv != 1) {
                        self.parentMenu = $scope.app.maskParams.parentMenu;
                        // self.parentMenu.id
                        // self.parentMenu.name
                    }

                    // 获取编辑多语言信息
                    self.editLangs = util.getParams('editLangs');

                    // 获取菜单类型名称、风格图
                    self.getModuleInfo();

                    // 初始化菜单图片和高亮图片
                    self.selectedImg1 = []
                    self.selectedImg2 = []
                }

                // 资源选择
                self.selectMaterial = function (img) {
                    $scope.app.showHideMaterialMask(true, 'MenuIcon')
                    // 选择完资源设置
                    $scope.app.setMaterial = function (data) {
                        self[img] = data
                    }
                }

                self.getModuleInfo = function () {
                    var data = JSON.stringify({
                        "token": util.getParams('token'),
                        "action": "getCommonTemplate",
                        "lang": util.langStyle()
                    });
                    self.loading = true;
                    // 获取模块类型名称、风格图
                    $http({
                        method: 'POST',
                        url: util.getApiUrl('mainmenu', '', 'server'),
                        data: data
                    }).then(function successCallback(response) {
                        var data = response.data;
                        if (data.rescode == '200') {
                            self.modules = data.data;
                            self.module = self.modules[0].Name;
                        } else if (data.rescode == '401') {
                            alert('访问超时，请重新登录');
                            $state.go('login');
                        } else {
                            alert('加载模版信息失败，' + data.errInfo);
                        }
                    }, function errorCallback(response) {
                        alert('连接服务器出错');
                    }).finally(function (value) {
                        self.loading = false;
                    });
                }


                self.cancel = function () {
                    $scope.app.showHideMask(false);
                }

                self.save = function () {

                    //菜单图片必填验证
                    // if(self.imgs1.data.length == 0 || self.imgs1.data[0].progress < 100) {
                    //     alert('请上传菜单图片');
                    //     return;
                    // }

                    //菜单高亮图片必填验证
                    // if(self.imgs2.data.length == 0 || self.imgs2.data[0].progress < 100) {
                    //     alert('请上传菜单高亮图片');
                    //     return;
                    // }

                    var action = self.menuLv == 1 ? 'addMainMenuFirstMenu' : 'addMainMenuSecondMenu';
                    var firstMenuID = self.menuLv == 1 ? '' : self.parentMenu.id;

                    var data = JSON.stringify({
                        "token": util.getParams('token'),
                        "action": action,
                        "viewID": 1,     //主菜单模板ViewID都为1
                        "data": {
                            "isMenu": 0,     //0不是主菜单，1是主菜单
                            "firstMenuID": firstMenuID,
                            "viewType": self.module,
                            "Name": self.menuName,
                            "IconURL": self.selectedImg1[0] ? self.selectedImg1[0].FileUrl : '',
                            "IconSize": self.selectedImg1[0] ? self.selectedImg1[0].FileSize : 0,
                            "IconFocusURL": self.selectedImg2[0] ? self.selectedImg2[0].FileUrl : '',
                            "IconFocusSize": self.selectedImg2[0] ? self.selectedImg2[0].FileSize : 0,
                            "Des": self.des || null,
                            "Seq": self.seq  //在一级菜单中的排序号，从1开始
                        },
                        "lang": util.langStyle()
                    })

                    self.saving = true;
                    $http({
                        method: 'POST',
                        url: util.getApiUrl('commonview', '', 'server'),
                        data: data
                    }).then(function successCallback(response) {
                        var data = response.data;
                        if (data.rescode == '200') {
                            alert('添加成功');
                            $state.reload();
                        } else if (data.rescode == '401') {
                            alert('访问超时，请重新登录');
                            $state.go('login');
                        } else {
                            alert('加载菜单示意信息失败，' + data.errInfo);
                        }
                    }, function errorCallback(response) {
                        alert('连接服务器出错');
                    }).finally(function (value) {
                        self.saving = false;
                    });

                }


                // 图片上传相关
                self.clickUpload = function (e) {
                    setTimeout(function () {
                        document.getElementById(e).click();
                    }, 0);
                }

                function Imgs(imgList, single) {
                    this.initImgList = imgList;
                    this.data = [];
                    this.maxId = 0;
                    this.single = single ? true : false;
                }

                Imgs.prototype = {
                    initImgs: function () {
                        var l = this.initImgList;
                        for (var i = 0; i < l.length; i++) {
                            this.data[i] = {
                                "src": l[i].ImageURL,
                                "fileSize": l[i].ImageSize,
                                "id": this.maxId++,
                                "progress": 100
                            };
                        }
                    },
                    deleteById: function (id) {
                        var l = this.data;
                        for (var i = 0; i < l.length; i++) {
                            if (l[i].id == id) {
                                // 如果正在上传，取消上传
                                if (l[i].progress < 100 && l[i].progress != -1) {
                                    l[i].xhr.abort();
                                }
                                l.splice(i, 1);
                                break;
                            }
                        }
                    },

                    add: function (xhr, fileName, fileSize) {
                        this.data.push({
                            "xhr": xhr,
                            "fileName": fileName,
                            "fileSize": fileSize,
                            "progress": 0,
                            "id": this.maxId
                        });
                        return this.maxId++;
                    },

                    update: function (id, progress, leftSize, fileSize) {
                        for (var i = 0; i < this.data.length; i++) {
                            var f = this.data[i];
                            if (f.id === id) {
                                f.progress = progress;
                                f.leftSize = leftSize;
                                f.fileSize = fileSize;
                                break;
                            }
                        }
                    },

                    setSrcSizeByXhr: function (xhr, src, size) {
                        for (var i = 0; i < this.data.length; i++) {
                            if (this.data[i].xhr == xhr) {
                                this.data[i].src = src;
                                this.data[i].fileSize = size;
                                break;
                            }
                        }
                    },

                    uploadFile: function (e, o) {
                        // 如果这个对象只允许上传一张图片
                        if (this.single) {
                            // 删除第二张以后的图片
                            for (var i = 1; i < this.data.length; i++) {
                                this.deleteById(this.data[i].id);
                            }
                        }

                        var file = $scope[e];
                        var uploadUrl = CONFIG.uploadUrl;
                        var xhr = new XMLHttpRequest();
                        var fileId = this.add(xhr, file.name, file.size, xhr);
                        // self.search();

                        util.uploadFileToUrl(xhr, file, uploadUrl, 'normal',
                            function (evt) {
                                $scope.$apply(function () {
                                    if (evt.lengthComputable) {
                                        var percentComplete = Math.round(evt.loaded * 100 / evt.total);
                                        o.update(fileId, percentComplete, evt.total - evt.loaded, evt.total);
                                        console && console.log(percentComplete);
                                    }
                                });
                            },
                            function (xhr) {
                                var ret = JSON.parse(xhr.responseText);
                                console && console.log(ret);
                                $scope.$apply(function () {
                                    o.setSrcSizeByXhr(xhr, ret.upload_path, ret.size);
                                    // 如果这个对象只允许上传一张图片
                                    if (o.single) {
                                        // 如果长度大于1张图片，删除前几张图片
                                        if (o.data.length > 1) {
                                            for (var i = 0; i < o.data.length - 1; i++) {
                                                o.deleteById(o.data[i].id);
                                            }
                                        }
                                    }
                                });
                            },
                            function (xhr) {
                                $scope.$apply(function () {
                                    o.update(fileId, -1, '', '');
                                });
                                console && console.log('failure');
                                xhr.abort();
                            }
                        );
                    }
                }

            }
        ])

        .controller('tvStyleChangeController', ['$scope', '$state', '$http', '$stateParams', '$location', 'util', 'CONFIG',
            function ($scope, $state, $http, $stateParams, $location, util, CONFIG) {
                var self = this;

                self.init = function () {

                    // 目前菜单已选风格
                    self.viewName = $scope.app.maskParams.viewName;

                    // 获取首页风格图片列表
                    self.getViewList();
                }

                self.getViewList = function () {
                    var data = JSON.stringify({
                        "token": util.getParams('token'),
                        "action": "getMainMenuTemplateList",
                        "lang": util.langStyle()
                    });
                    self.loading = true;

                    $http({
                        method: 'POST',
                        url: util.getApiUrl('mainmenu', '', 'server'),
                        data: data
                    }).then(function successCallback(response) {
                        var data = response.data;
                        if (data.rescode == '200') {
                            self.styles = data.data;
                            self.style = self.viewName;
                        } else if (data.rescode == '401') {
                            alert('访问超时，请重新登录');
                            $state.go('login');
                        } else {
                            alert('加载首页模版失败，' + data.errInfo);
                        }
                    }, function errorCallback(response) {
                        alert('连接服务器出错');
                    }).finally(function (value) {
                        self.loading = false;
                    });
                }


                self.cancel = function () {
                    $scope.app.showHideMask(false);
                }

                self.save = function () {

                    // 确认修改提示
                    if (confirm('修改会导致所有主菜单全部清空，确认修改？')) {
                        if (confirm('再次确认：修改会导致所有主菜单全部清空，确认修改？')) {
                        }
                        else {
                            return;
                        }
                    } else {
                        return;
                    }

                    var data = JSON.stringify({
                        "token": util.getParams('token'),
                        "action": "createMainMenu",
                        "viewID": 1,     //主菜单模板ViewID都为1
                        "viewType": self.style,
                        "lang": util.langStyle()
                    })
                    self.saving = true;
                    $http({
                        method: 'POST',
                        url: util.getApiUrl('mainmenu', '', 'server'),
                        data: data
                    }).then(function successCallback(response) {
                        var data = response.data;
                        if (data.rescode == '200') {
                            alert('修改成功');
                            $state.reload();
                        } else if (data.rescode == '401') {
                            alert('访问超时，请重新登录');
                            $state.go('login');
                        } else {
                            alert('修改失败，' + data.errInfo);
                        }
                    }, function errorCallback(response) {
                        alert('连接服务器出错');
                    }).finally(function (value) {
                        self.saving = false;
                    });

                }

            }
        ])

        .controller('tvWelStyleChangeController', ['$scope', '$state', '$http', '$stateParams', '$location', 'util', 'CONFIG',
            function ($scope, $state, $http, $stateParams, $location, util, CONFIG) {
                var self = this;

                self.init = function () {

                    // 目前欢迎页已选风格
                    self.viewName = $scope.app.maskParams.viewName;

                    // 获取欢迎页风格图片列表
                    self.getViewList();
                }

                self.getViewList = function () {
                    var data = JSON.stringify({
                        "token": util.getParams('token'),
                        "action": "getWelcomePageTemplateList",
                        "lang": util.langStyle()
                    });
                    self.loading = true;

                    $http({
                        method: 'POST',
                        url: util.getApiUrl('welcomepage', '', 'server'),
                        data: data
                    }).then(function successCallback(response) {
                        var data = response.data;
                        if (data.rescode == '200') {
                            self.styles = data.data;
                            self.style = self.viewName;
                        } else if (data.rescode == '401') {
                            alert('访问超时，请重新登录');
                            $state.go('login');
                        } else {
                            alert('加载欢迎页模版失败，' + data.errInfo);
                        }
                    }, function errorCallback(response) {
                        alert('连接服务器出错');
                    }).finally(function (value) {
                        self.loading = false;
                    });
                }


                self.cancel = function () {
                    $scope.app.showHideMask(false);
                }

                self.save = function () {

                    var data = JSON.stringify({
                        "token": util.getParams('token'),
                        "action": "createWelcomePage",
                        "viewType": self.style,
                        "lang": util.langStyle()
                    })

                    self.saving = true;
                    $http({
                        method: 'POST',
                        url: util.getApiUrl('welcomepage', '', 'server'),
                        data: data
                    }).then(function successCallback(response) {
                        var data = response.data;
                        if (data.rescode == '200') {
                            alert('修改成功');
                            $state.reload();
                        } else if (data.rescode == '401') {
                            alert('访问超时，请重新登录');
                            $state.go('login');
                        } else {
                            alert('修改失败，' + data.errInfo);
                        }
                    }, function errorCallback(response) {
                        alert('连接服务器出错');
                    }).finally(function (value) {
                        self.saving = false;
                    });

                }

            }
        ])

        .controller('tvAdvAddController', ['$scope', '$http', 'util',
            function ($scope, $http, util) {
                var self = this;

                self.init = function () {
                    self.disabledList = $scope.app.maskParams.list;
                    self.callback = $scope.app.maskParams.callback;
                    self.termGroupID = $scope.app.maskParams.termGroupID;
                    self.loadList();
                    self.noData = false;
                }

                self.loadList = function () {
                    var data = JSON.stringify({
                        "token": util.getParams('token'),
                        "action": "getPosList",
                        "termGroupID": self.termGroupID,
                        "lang": util.langStyle()
                    });

                    self.loading = true;
                    $http({
                        method: 'POST',
                        url: util.getApiUrl('advpos', '', 'server'),
                        data: data
                    }).then(function successCallback(response) {
                        var data = response.data;
                        if (data.rescode == '200') {
                            var a = data.data;
                            var list = data.data;
                            // 将已添加的广告禁用,设为已选；其他默认都设置为未选
                            for (var i = 0; i < list.length; i++) {
                                list[i].disabled = false;
                                list[i].checked = false;
                                // 判断是否已选
                                for (var j = 0; j < self.disabledList.length; j++) {
                                    if (list[i].ID == self.disabledList[j].PositionID) {
                                        list[i].disabled = true;
                                        list[i].checked = true;
                                        break;
                                    }
                                }
                            }
                            self.adList = list;
                            /////////////
                            //总条数
                            self.total = list.length;
                            console.log(list.length)
                            //反转函数转化abcd转dcba
                            //  self.data = response.data.reverse();
                            self.data = self.adList;
                            //选择显示的条数
                            self.values = [{ "limit": 5 }, { "limit": 10 }, { "limit": 15 }];
                            //默认显示的条数
                            self.selectedLimit = self.values[0];
                            //默认显示当前页数
                            self.currentPage = 1;
                            if (self.data != null) {
                                self.datas = self.data.slice(self.selectedLimit.limit * (self.currentPage - 1), self.selectedLimit.limit * self.currentPage);
                            } else {
                                self.noData = true;
                            }
                            self.page = {
                                "limit": self.selectedLimit.limit, "pageSize": 5, "pageNo": self.currentPage, "totalCount": self.total
                            };
                            /////////////
                        } else if (data.rescode == '401') {
                            alert('访问超时，请重新登录');
                            $state.go('login');
                        } else {
                            alert('加载广告位信息失败，' + data.errInfo);
                        }
                    }, function errorCallback(response) {
                        alert('连接服务器出错');
                    }).finally(function (value) {
                        self.loading = false;
                    });
                }
                self.change = function (selectedLimit) {
                    self.page.limit = selectedLimit.limit;
                    self.datas = self.data.slice(self.selectedLimit.limit * (self.page.pageNo - 1), self.selectedLimit.limit * self.page.pageNo);
                }
                self.pageChanged = function () {
                    self.page.limit = self.selectedLimit.limit;
                    self.datas = self.data.slice(self.selectedLimit.limit * (self.page.pageNo - 1), self.selectedLimit.limit * self.page.pageNo);
                }
                self.setPage = function (go) {
                    self.length = Math.ceil(self.total / self.selectedLimit.limit);
                    console.log(self.length);
                    if (go > self.length) {
                        self.page.pageNo = self.length;
                        console.log(self.length);

                    } else {
                        self.page.pageNo = go;
                    }
                    self.datas = self.data.slice(self.selectedLimit.limit * (self.page.pageNo - 1), self.selectedLimit.limit * self.page.pageNo);
                };
                self.save = function (id) {

                    var selAdList = [];
                    for (var i = 0; i < self.adList.length; i++) {
                        if (!self.adList[i].disabled && self.adList[i].checked) {
                            selAdList.push({
                                "AdvPositionTemplateName": self.adList[i].AdvPositionTemplateName,
                                "ID": self.adList[i].ID,
                                "Name": self.adList[i].Name
                            })
                        }
                    }
                    if (selAdList.length == 0) {
                        alert('请选择要添加的广告');
                        return;
                    }

                    var data = JSON.stringify({
                        "token": util.getParams('token'),
                        "action": "addPos",
                        "termGroupID": self.termGroupID,
                        "lang": util.langStyle(),
                        "data": selAdList
                    })

                    self.saving = true;
                    $http({
                        method: 'POST',
                        url: util.getApiUrl('advpos', '', 'server'),
                        data: data
                    }).then(function successCallback(response) {
                        var data = response.data;
                        if (data.rescode == '200') {
                            alert('添加成功');
                            self.cancel();
                            self.callback();
                        } else if (data.rescode == '401') {
                            alert('访问超时，请重新登录');
                            $state.go('login');
                        } else {
                            alert('添加失败' + data.errInfo);
                        }
                    }, function errorCallback(response) {
                        alert('连接服务器出错');
                    }).finally(function (value) {
                        self.saving = false;
                    });
                }

                self.cancel = function () {
                    $scope.app.showHideMask(false);
                }


            }])

        .controller('changeMenuAdvSeqController', ['$scope', '$http', 'util',
            function ($scope, $http, util) {
                var self = this;

                self.init = function () {
                    self.data = $scope.app.maskParams.data;
                    self.newSeq = null;
                    self.callback = $scope.app.maskParams.callback;
                    self.termGroupID = $scope.app.maskParams.termGroupID;
                }


                self.save = function () {
                    if (!self.newSeq) {
                        alert('请输入新序号');
                        return;
                    }
                    var data = JSON.stringify({
                        "token": util.getParams('token'),
                        "action": "changeSeq",
                        "termGroupID": self.termGroupID,
                        "lang": util.langStyle(),
                        "data": {
                            PositionID: self.data.PositionID,
                            Seq: self.newSeq
                        }
                    })

                    self.saving = true;
                    $http({
                        method: 'POST',
                        url: util.getApiUrl('menuadvpos', '', 'server'),
                        data: data
                    }).then(function successCallback(response) {
                        var data = response.data;
                        if (data.rescode == '200') {
                            alert('修改成功');
                            self.cancel();
                            self.callback();
                        } else if (data.rescode == '401') {
                            alert('访问超时，请重新登录');
                            $state.go('login');
                        } else {
                            alert('添加失败' + data.errInfo);
                        }
                    }, function errorCallback(response) {
                        alert('连接服务器出错');
                    }).finally(function (value) {
                        self.saving = false;
                    });
                }

                self.cancel = function () {
                    $scope.app.showHideMask(false);
                }

            }])

        .controller('tvMenuAdvAddController', ['$scope', '$http', 'util',
            function ($scope, $http, util) {
                var self = this;

                self.init = function () {
                    self.disabledList = $scope.app.maskParams.list;
                    self.callback = $scope.app.maskParams.callback;
                    self.termGroupID = $scope.app.maskParams.termGroupID;
                    self.loadList();
                }

                self.loadList = function () {
                    var data = JSON.stringify({
                        "token": util.getParams('token'),
                        "action": "getPosList",
                        "termGroupID": self.termGroupID,
                        "lang": util.langStyle()
                    });

                    self.loading = true;
                    $http({
                        method: 'POST',
                        url: util.getApiUrl('menuadvpos', '', 'server'),
                        data: data
                    }).then(function successCallback(response) {
                        var data = response.data;
                        if (data.rescode == '200') {
                            var list = data.data;
                            // 将已添加的广告禁用,设为已选；其他默认都设置为未选
                            for (var i = 0; i < list.length; i++) {
                                list[i].disabled = false;
                                list[i].checked = false;
                                // 判断是否已选
                                for (var j = 0; j < self.disabledList.length; j++) {
                                    if (list[i].ID == self.disabledList[j].PositionID) {
                                        list[i].disabled = true;
                                        list[i].checked = true;
                                        break;
                                    }
                                }
                            }

                            self.adList = list;
                        } else if (data.rescode == '401') {
                            alert('访问超时，请重新登录');
                            $state.go('login');
                        } else {
                            alert('加载广告位信息失败，' + data.errInfo);
                        }
                    }, function errorCallback(response) {
                        alert('连接服务器出错');
                    }).finally(function (value) {
                        self.loading = false;
                    });
                }

                self.save = function (id) {

                    var selAdList = [];
                    for (var i = 0; i < self.adList.length; i++) {
                        if (!self.adList[i].disabled && self.adList[i].checked) {
                            selAdList.push({
                                "Seq": 1,
                                "ID": self.adList[i].ID,
                                "Name": self.adList[i].Name
                            })
                        }
                    }
                    if (selAdList.length == 0) {
                        alert('请选择要添加的菜单广告');
                        return;
                    }

                    var data = JSON.stringify({
                        "token": util.getParams('token'),
                        "action": "addPos",
                        "termGroupID": self.termGroupID,
                        "lang": util.langStyle(),
                        "data": selAdList
                    })

                    self.saving = true;
                    $http({
                        method: 'POST',
                        url: util.getApiUrl('menuadvpos', '', 'server'),
                        data: data
                    }).then(function successCallback(response) {
                        var data = response.data;
                        if (data.rescode == '200') {
                            alert('添加成功');
                            self.cancel();
                            self.callback();
                        } else if (data.rescode == '401') {
                            alert('访问超时，请重新登录');
                            $state.go('login');
                        } else {
                            alert('添加失败' + data.errInfo);
                        }
                    }, function errorCallback(response) {
                        alert('连接服务器出错');
                    }).finally(function (value) {
                        self.saving = false;
                    });
                }

                self.cancel = function () {
                    $scope.app.showHideMask(false);
                }

            }])

        .controller('tvVersionController', ['$scope', '$state', '$http', '$stateParams', '$location', 'util',
            function ($scope, $state, $http, $stateParams, $location, util) {
                var self = this;

                self.init = function () {
                    self.getSV();
                    self.getCV();
                }

                // 获取服务端版本
                self.getSV = function () {
                    var data = JSON.stringify({
                        "token": util.getParams('token'),
                        "action": "get",
                        "type": "All",
                        "lang": util.langStyle()
                    });
                    self.loadingS = true;
                    $http({
                        method: 'POST',
                        url: util.getApiUrl('tvuiversion', '', 'server'),
                        data: data
                    }).then(function successCallback(response) {
                        var data = response.data;
                        if (data.rescode == '200') {

                            self.serviceVersion = data.data;
                        } else if (data.rescode == '401') {
                            alert('访问超时，请重新登录');
                            $state.go('login');
                        } else {
                            alert('加载服务器版本信息失败，' + data.errInfo);
                        }
                    }, function errorCallback(response) {
                        alert('连接服务器出错');
                    }).finally(function (value) {
                        self.loadingS = false;
                    });
                }

                // 获取小前端端版本
                self.getCV = function () {
                    var data = JSON.stringify({
                        "token": util.getParams('token'),
                        "action": "getProxyVersion",
                        "lang": util.langStyle()
                    });
                    self.loadingC = true;
                    $http({
                        method: 'POST',
                        url: util.getApiUrl('tvuiversion', '', 'server'),
                        data: data
                    }).then(function successCallback(response) {
                        var data = response.data;
                        if (data.rescode == '200') {
                            self.clientVersion = data.data;
                        } else if (data.rescode == '401') {
                            alert('访问超时，请重新登录');
                            $state.go('login');
                        } else {
                            alert('加载小前端版本信息失败，' + data.errInfo);
                        }
                    }, function errorCallback(response) {
                        alert('连接服务器出错');
                    }).finally(function (value) {
                        self.loadingC = false;
                    });
                }

                self.submit = function () {
                    var data = JSON.stringify({
                        "token": util.getParams('token'),
                        "action": "submit",
                        "type": "All",
                        "lang": util.langStyle()
                    })
                    self.saving = true;
                    alert('提交版本命令已发送，这需要大约一分钟的时间');
                    $http({
                        method: 'POST',
                        url: util.getApiUrl('tvuiversion', '', 'server'),
                        data: data
                    }).then(function successCallback(response) {
                        var data = response.data;
                        if (data.rescode == '200') {
                            alert('版本提交成功');
                            $state.reload();
                        } else if (data.rescode == '401') {
                            alert('访问超时，请重新登录');
                            $state.go('login');
                        } else {
                            alert('提交版本失败' + data.errInfo);
                        }
                    }, function errorCallback(response) {
                        alert('连接服务器出错');
                    }).finally(function (value) {
                        self.saving = false;
                    });
                }

            }
        ])

        .controller('tvGuangGaoWeiController', ['$scope', '$state', '$http', '$stateParams', '$location', 'util',
            function ($scope, $state, $http, $stateParams, $location, util) {
                var self = this;


                self.init = function () {
                    self.termGroupID = $stateParams.GroupID;//终端分组
                    self.loadList();
                    self.loadMenuAdvList();
                }

                self.loadList = function () {
                    var data = JSON.stringify({
                        "token": util.getParams('token'),
                        "action": "getPosOpenList",
                        "termGroupID": self.termGroupID,
                        "lang": util.langStyle()
                    });

                    self.loading = true;
                    $http({
                        method: 'POST',
                        url: util.getApiUrl('advpos', '', 'server'),
                        data: data
                    }).then(function successCallback(response) {
                        var data = response.data;
                        if (data.rescode == '200') {
                            self.adList = data.data;
                        } else if (data.rescode == '401') {
                            alert('访问超时，请重新登录');
                            $state.go('login');
                        } else {
                            alert('加载广告位信息失败，' + data.errInfo);
                        }
                    }, function errorCallback(response) {
                        alert('连接服务器出错');
                    }).finally(function (value) {
                        self.loading = false;
                    });
                }

                self.loadMenuAdvList = function () {
                    var data = JSON.stringify({
                        "token": util.getParams('token'),
                        "action": "getPosOpenList",
                        "termGroupID": self.termGroupID,
                        "lang": util.langStyle()
                    });

                    self.loading = true;
                    $http({
                        method: 'POST',
                        url: util.getApiUrl('menuadvpos', '', 'server'),
                        data: data
                    }).then(function successCallback(response) {
                        var data = response.data;
                        if (data.rescode == '200') {
                            self.menuAdList = data.data;
                        } else if (data.rescode == '401') {
                            alert('访问超时，请重新登录');
                            $state.go('login');
                        } else {
                            alert('加载广告位信息失败，' + data.errInfo);
                        }
                    }, function errorCallback(response) {
                        alert('连接服务器出错');
                    }).finally(function (value) {
                        self.loading = false;
                    });
                }

                self.close = function (id) {
                    if (!confirm('确认删除？')) {
                        return;
                    }
                    var data = JSON.stringify({
                        "token": util.getParams('token'),
                        "action": "delPos",
                        "termGroupID": self.termGroupID,
                        "lang": util.langStyle(),
                        "data": [
                            {
                                "ID": id
                            }
                        ]
                    })

                    $http({
                        method: 'POST',
                        url: util.getApiUrl('advpos', '', 'server'),
                        data: data
                    }).then(function successCallback(response) {
                        var data = response.data;
                        if (data.rescode == '200') {
                            alert('删除成功');
                            self.loadList();
                        } else if (data.rescode == '401') {
                            alert('访问超时，请重新登录');
                            $state.go('login');
                        } else {
                            alert('删除失败' + data.errInfo);
                        }
                    }, function errorCallback(response) {
                        alert('连接服务器出错');
                    }).finally(function (value) {

                    });
                }

                self.menuAdvDel = function (id) {
                    if (!confirm('确认删除？')) {
                        return;
                    }
                    var data = JSON.stringify({
                        "token": util.getParams('token'),
                        "action": "delPos",
                        "termGroupID": self.termGroupID,
                        "lang": util.langStyle(),
                        "data": [
                            {
                                "PositionID": id
                            }
                        ]
                    })

                    $http({
                        method: 'POST',
                        url: util.getApiUrl('menuadvpos', '', 'server'),
                        data: data
                    }).then(function successCallback(response) {
                        var data = response.data;
                        if (data.rescode == '200') {
                            alert('删除成功');
                            self.init();
                        } else if (data.rescode == '401') {
                            alert('访问超时，请重新登录');
                            $state.go('login');
                        } else {
                            alert('删除失败' + data.errInfo);
                        }
                    }, function errorCallback(response) {
                        alert('连接服务器出错');
                    }).finally(function (value) {

                    });
                }

                self.add = function () {
                    $scope.app.maskParams = { 'list': self.adList };
                    $scope.app.maskParams.callback = self.loadList;
                    $scope.app.maskParams.termGroupID = self.termGroupID;
                    $scope.app.showHideMask(true, 'pages/tv/advAdd.html');
                }

                self.menuAdd = function () {
                    $scope.app.maskParams = { 'list': self.menuAdList };
                    $scope.app.maskParams.callback = self.init;
                    $scope.app.maskParams.termGroupID = self.termGroupID;
                    $scope.app.showHideMask(true, 'pages/tv/menuAdvAdd.html');
                }

                self.changeMenuAdvSeq = function (data) {
                    $scope.app.maskParams = { 'data': data };
                    $scope.app.maskParams.callback = self.init;
                    $scope.app.maskParams.termGroupID = self.termGroupID;
                    $scope.app.showHideMask(true, 'pages/tv/changeMenuAdvSeq.html');
                }
            }
        ])

        .controller('tvprojectConfigController', ['$scope', '$filter', '$state', '$timeout', 'Upload', '$http', '$stateParams', '$location', 'util', 'CONFIG',
            function ($scope, $filter, $state, $timeout, Upload, $http, $stateParams, $location, util, CONFIG) {
                console.log('tvprojectConfigController')

                function isJson(str) {
                    try {
                        if (typeof JSON.parse(str) == "object") {
                            return true
                        }
                    } catch (e) {

                    }
                    return false
                }

                var self = this;

                self.init = function () {
                    self.getProjectConfig();
                    // 初始化
                    self.imgs1 = new Imgs([], true);
                    self.imgs2 = new Imgs([], true);
                    // // angular.element 不支持选择器
                    // self.restartTime = angular.element(document.querySelector("#restartTime"));
                }

                self.upload = function (item) {
                    // wait update, this is so dumb
                    $timeout(self.uploadActual(item), 100);
                }

                self.uploadActual = function (item) {
                    console.log("FILE:", item.file);
                    item.uploading = true;
                    Upload.upload({
                        url: CONFIG.uploadUrl,
                        data: {
                            file: item.file
                        }
                    }).then(function (resp) {
                        if (resp.status == 200) {
                            // push to our images
                            item.imgObj.ImageURL = resp.data.upload_path
                            item.imgObj.ImageSize = resp.data.size
                            console.log('Successfully uploaded ' + resp.data.upload_path);
                        } else {
                            console.log(resp);
                        }
                    }, function (err) {
                        console.log(err);
                    }, function (evt) {
                        console.log(evt)
                        var progressPercentage = parseInt(100.0 * evt.loaded / evt.total);
                        item.uploadProgress = progressPercentage;
                        console.log('progress: ' + progressPercentage + '% ' + evt.config.data.file.name);
                    }).finally(function () {
                        // reset file
                        item.file = null;
                        // item.uploading = false;
                    });
                }

                self.getProjectConfig = function () {
                    var data = JSON.stringify({
                        "token": util.getParams('token'),
                        "action": "getTermConfig",
                        "lang": util.langStyle()
                    });

                    self.loading = true;

                    $http({
                        method: 'POST',
                        url: util.getApiUrl('termconfig', '', 'server'),
                        data: data
                    }).then(function successCallback(response) {
                        var data = response.data;
                        if (data.rescode == '200') {
                            var projectData = data.data;
                            self.projectData = {};

                            self.extraData = projectData.filter(function (i) {
                                var defaultData = ['Font', 'RestartTime', 'Signature']
                                return defaultData.indexOf(i.Type) < 0
                            })

                            // 图片对象类型初始化
                            self.extraData.forEach(function (i, index) {
                                i.isJson = typeof i.Data ==='object'
                                if (i.isJson) {
                                    i.imgObj = {
                                        ImageURL: i.Data.URL || '',
                                        ImageSize: i.Data.Size || 0
                                    }
                                }
                            })

                            for (var i = 0; i < projectData.length; i++) {
                                self.projectData[projectData[i]["Type"]] = projectData[i]
                            }
                            self.restartTime = new Date("2000 " + self.projectData.RestartTime.Data);
                            self.imgs1.data[0] = {
                                src: self.projectData.Font.Data.URL,
                                progress: 100,
                                fileSize: self.projectData.Font.Data.Size
                            };
                            self.imgs2.data[0] = {
                                src: self.projectData.Signature.Data.URL,
                                progress: 100,
                                fileSize: self.projectData.Signature.Data.Size
                            };
                        } else if (data.rescode == '401') {
                            alert('访问超时，请重新登录');
                            $state.go('login');
                        } else {
                            alert('加载信息失败，' + data.errInfo);
                        }
                    }, function errorCallback(response) {
                        alert('连接服务器出错');
                    }).finally(function (value) {
                        self.loading = false;
                    });
                }

                self.saveProjectConfig = function () {
                    var date = angular.element(document.querySelector("#restartTime")).val(),
                        re = /\d*:\d*:*\d*/;
                    var dateString = re.exec(date)[0];
                    if (dateString.length == 5) {
                        dateString += ":00"
                    }
                    self.formDisabled = true;

                    var params = {
                        "token": util.getParams('token'),
                        "action": "setTermConfig",
                        "data": {
                            "Font": {
                                "Data": {
                                    "URL": self.imgs1.data[0].src,
                                    "Size": Number(self.imgs1.data[0].fileSize)
                                },
                                "Enable": Number(self.projectData.Font.Enable)
                            },
                            "RestartTime": {
                                "Data": dateString,
                                "Enable": Number(self.projectData.RestartTime.Enable)
                            },
                            "Signature": {
                                "Data": {
                                    "URL": self.imgs2.data[0].src,
                                    "Size": Number(self.imgs2.data[0].fileSize)
                                },
                                "Enable": Number(self.projectData.Signature.Enable)
                            }
                        }
                    }
                    // 数据拼接
                    self.extraData.forEach(function (i, index) {
                        var data =  i.Data 
                        if (i.isJson) {
                            data = {
                                URL: i.imgObj.ImageURL,
                                Size: Number(i.imgObj.ImageSize)
                            }
                        }
                        params.data[i.Type] = {
                            Data: data,
                            Enable: i.Enable
                        }
                    })

                    var data = JSON.stringify(params);

                    $http({
                        method: 'POST',
                        url: util.getApiUrl('termconfig', '', 'server'),
                        data: data
                    }).then(function successCallback(response) {
                        var data = response.data;
                        if (data.rescode == '200') {
                            $state.reload("app.tvAdmin.projectConfig")
                            alert('保存成功')
                        } else if (data.rescode == '401') {
                            alert('访问超时，请重新登录');
                            $state.go('login');
                        } else {
                            alert('加载信息失败，' + data.errInfo);
                        }
                    }, function errorCallback(response) {
                        alert('连接服务器出错');
                    }).finally(function (value) {
                        self.formDisabled = false;
                    });
                }


                // 图片上传相关
                self.clickUpload = function (e) {
                    setTimeout(function () {
                        document.getElementById(e).click();
                        console.log($scope)
                    }, 0);
                }

                function Imgs(imgList, single) {
                    this.initImgList = imgList;
                    this.data = [];
                    this.maxId = 0;
                    this.single = single ? true : false;
                }

                Imgs.prototype = {
                    initImgs: function () {
                        var l = this.initImgList;
                        for (var i = 0; i < l.length; i++) {
                            this.data[i] = {
                                "src": l[i].ImageURL,
                                "fileSize": l[i].ImageSize,
                                "id": this.maxId++,
                                "progress": 100
                            };
                        }
                    },
                    deleteById: function (id) {
                        var l = this.data;
                        for (var i = 0; i < l.length; i++) {
                            if (l[i].id == id) {
                                // 如果正在上传，取消上传
                                if (l[i].progress < 100 && l[i].progress != -1) {
                                    l[i].xhr.abort();
                                }
                                l.splice(i, 1);
                                break;
                            }
                        }
                    },

                    add: function (xhr, fileName, fileSize) {
                        this.data.push({
                            "xhr": xhr,
                            "fileName": fileName,
                            "fileSize": fileSize,
                            "progress": 0,
                            "id": this.maxId
                        });
                        return this.maxId++;
                    },

                    update: function (id, progress, leftSize, fileSize) {
                        for (var i = 0; i < this.data.length; i++) {
                            var f = this.data[i];
                            if (f.id === id) {
                                f.progress = progress;
                                f.leftSize = leftSize;
                                f.fileSize = fileSize;
                                break;
                            }
                        }
                    },

                    setSrcSizeByXhr: function (xhr, src, size) {
                        for (var i = 0; i < this.data.length; i++) {
                            if (this.data[i].xhr == xhr) {
                                this.data[i].src = src;
                                this.data[i].fileSize = size;
                                break;
                            }
                        }
                    },

                    uploadFile: function (e, o) {
                        console.log($scope, e, o)
                        // 如果这个对象只允许上传一张图片
                        if (this.single) {
                            // 删除第二张以后的图片
                            for (var i = 1; i < this.data.length; i++) {
                                this.deleteById(this.data[i].id);
                            }
                        }
                        var file = $scope[e];
                        if (!file) {
                            alert("请先选择字体")
                            return;
                        }
                        var uploadUrl = CONFIG.uploadUrl;
                        var xhr = new XMLHttpRequest();
                        var fileId = this.add(xhr, file.name, file.size, xhr);
                        // self.search();

                        util.uploadFileToUrl(xhr, file, uploadUrl, 'normal',
                            function (evt) {
                                $scope.$apply(function () {
                                    if (evt.lengthComputable) {
                                        var percentComplete = Math.round(evt.loaded * 100 / evt.total);
                                        o.update(fileId, percentComplete, evt.total - evt.loaded, evt.total);
                                        console.log("percentComplete");
                                        console && console.log(percentComplete);
                                    }
                                });
                            },
                            function (xhr) {
                                var ret = JSON.parse(xhr.responseText);
                                console && console.log(ret);
                                $scope.$apply(function () {
                                    o.setSrcSizeByXhr(xhr, ret.upload_path, ret.size);
                                    // 如果这个对象只允许上传一张图片
                                    if (o.single) {
                                        // 如果长度大于1张图片，删除前几张图片
                                        if (o.data.length > 1) {
                                            for (var i = 0; i < o.data.length - 1; i++) {
                                                o.deleteById(o.data[i].id);
                                            }
                                        }
                                    }
                                });
                            },
                            function (xhr) {
                                $scope.$apply(function () {
                                    o.update(fileId, -1, '', '');
                                });
                                console && console.log('failure');
                                xhr.abort();
                            }
                        );
                    }
                }

            }
        ])

        .controller('tvWorldClockController', ['$q', '$scope', '$state', '$http', '$stateParams', '$filter', 'util', 'CONFIG',
            function ($q, $scope, $state, $http, $stateParams, $filter, util, CONFIG) {
                console.log('tvWorldClockController')
                var self = this;
                self.init = function () {

                }
            }
        ])

        .controller('tvQHtlWorldClockController', ['$q', '$scope', '$state', '$http', '$stateParams', '$filter', 'util', 'CONFIG',
            function ($q, $scope, $state, $http, $stateParams, $filter, util, CONFIG) {
                var self = this;
                self.init = function () {

                }
            }
        ])
        .controller('tvSkyworthATVController', ['$q', '$scope', '$state', '$http', '$stateParams', '$filter', 'util', 'CONFIG',
            function ($q, $scope, $state, $http, $stateParams, $filter, util, CONFIG) {
                console.log('tvSkyworthATVController')
                var self = this;
                self.init = function () {

                }
            }
        ])

        .controller('tvSkyworthHDMIController', ['$q', '$scope', '$state', '$http', '$stateParams', '$filter', 'util', 'CONFIG',
            function ($q, $scope, $state, $http, $stateParams, $filter, util, CONFIG) {
                console.log('tvSkyworthHDMIController')
                var self = this;
                self.init = function () {

                }
            }
        ])
        //BaoFengHDMI
        .controller('tvBaoFengHDMIController', ['$q', '$scope', '$state', '$http', '$stateParams', '$filter', 'util', 'CONFIG',
            function ($q, $scope, $state, $http, $stateParams, $filter, util, CONFIG) {
                console.log('tvBaoFengHDMIController')
                var self = this;
                self.init = function () {

                }
            }
        ])

        .controller('tvSkyworthDTVController', ['$q', '$scope', '$state', '$http', '$stateParams', '$filter', 'util', 'CONFIG',
            function ($q, $scope, $state, $http, $stateParams, $filter, util, CONFIG) {
                console.log('tvSkyworthDTVController')
                var self = this;
                self.init = function () {

                }
            }
        ])

        //书香天气
        .controller('tvWeatherSXController', ['$q', '$scope', '$state', '$http', '$stateParams', '$filter', 'util', 'CONFIG',
            function ($q, $scope, $state, $http, $stateParams, $filter, util, CONFIG) {
                console.log('tvWeatherSXController')
                var self = this;
                self.init = function () {

                }
            }
        ])




        // 音乐库
        .controller('tvMusicCommonController', ['$q', '$scope', '$state', '$http', '$stateParams', '$filter', 'util', 'CONFIG',
            function ($q, $scope, $state, $http, $stateParams, $filter, util, CONFIG) {
                console.log('tvMusicCommonController')
                var self = this;
                self.init = function () {
                    self.info = {};
                    self.viewId = $stateParams.moduleId;
                    self.defaultLangCode = util.getDefaultLangCode();
                    self.editLangs = util.getParams('editLangs');
                    self.getInfo().then(function () {
                        self.initImgs1();
                    })


                }

                self.getInfo = function () {
                    var deferred = $q.defer();
                    self.loading = true;
                    var data = JSON.stringify({
                        action: "getAPIInfo",
                        token: util.getParams('token'),
                        lang: util.langStyle(),
                        viewID: Number(self.viewId)
                    })
                    $http({
                        method: 'POST',
                        url: util.getApiUrl('commonview', '', 'server'),
                        data: data
                    }).then(function successCallback(response) {
                        var data = response.data;
                        if (data.rescode == '200') {
                            self.info = data.data;
                            deferred.resolve();
                        } else if (data.rescode == '401') {
                            alert('访问超时，请重新登录');
                            $location.path("pages/login.html");
                        } else {
                            alert('读取信息失败，' + data.errInfo);
                            deferred.reject();
                        }
                    }, function errorCallback(response) {
                        alert('服务器出错');
                        deferred.reject();
                    }).finally(function (e) {
                        self.loading = false;
                    });
                    return deferred.promise;
                }

                self.initImgs1 = function () {
                    // 初始化apk url
                    self.imgs1 = new Imgs([{ "ImageURL": self.info.ApkURL, "ImageSize": self.info.ApkSize }], true);
                    self.imgs1.initImgs();
                }

                self.save = function () {

                    //检查logo上传
                    if (self.imgs1.data.length == 0) {
                        alert('请上传酒店Apk');
                        return;
                    }

                    self.info.ApkURL = self.imgs1.data[0].src;
                    self.info.ApkSize = self.imgs1.data[0].fileSize - 0;
                    self.saving = true;

                    var data = JSON.stringify({
                        action: "updateAPIInfo",
                        token: util.getParams('token'),
                        lang: util.langStyle(),
                        viewID: self.viewId - 0,
                        data: {
                            "MusicContentAPIParam": self.info.MusicContentAPIParam,
                            "MusicContentAPIURL": self.info.MusicContentAPIURL
                        }
                    })
                    $http({
                        method: 'POST',
                        url: util.getApiUrl('commonview', '', 'server'),
                        data: data
                    }).then(function successCallback(response) {
                        var data = response.data;
                        if (data.rescode == '200') {
                            alert('保存成功');
                            $state.reload();
                        } else {
                            alert('保存失败，' + data.errInfo);
                        }
                    }, function errorCallback(response) {
                        alert('服务器出错');
                    }).finally(function (e) {
                        self.saving = false;
                    });
                }

                self.clickUpload = function (e) {
                    setTimeout(function () {
                        document.getElementById(e).click();
                    }, 0);
                }

                function Imgs(imgList, single) {
                    this.initImgList = imgList;
                    this.data = [];
                    this.maxId = 0;
                    this.single = single ? true : false;
                }

                Imgs.prototype = {
                    initImgs: function () {
                        var l = this.initImgList;
                        for (var i = 0; i < l.length; i++) {
                            this.data[i] = {
                                "src": l[i].ImageURL,
                                "fileSize": l[i].ImageSize,
                                "id": this.maxId++,
                                "progress": 100
                            };
                        }
                    },
                    deleteById: function (id) {
                        var l = this.data;
                        for (var i = 0; i < l.length; i++) {
                            if (l[i].id == id) {
                                // 如果正在上传，取消上传
                                if (l[i].progress < 100 && l[i].progress != -1) {
                                    l[i].xhr.abort();
                                }
                                l.splice(i, 1);
                                break;
                            }
                        }
                    },

                    add: function (xhr, fileName, fileSize) {
                        this.data.push({
                            "xhr": xhr,
                            "fileName": fileName,
                            "fileSize": fileSize,
                            "progress": 0,
                            "id": this.maxId
                        });
                        return this.maxId++;
                    },

                    update: function (id, progress, leftSize, fileSize) {
                        for (var i = 0; i < this.data.length; i++) {
                            var f = this.data[i];
                            if (f.id === id) {
                                f.progress = progress;
                                f.leftSize = leftSize;
                                f.fileSize = fileSize;
                                break;
                            }
                        }
                    },

                    setSrcSizeByXhr: function (xhr, src, size) {
                        for (var i = 0; i < this.data.length; i++) {
                            if (this.data[i].xhr == xhr) {
                                this.data[i].src = src;
                                this.data[i].fileSize = size;
                                break;
                            }
                        }
                    },

                    uploadFile: function (e, o) {

                        // 如果这个对象只允许上传一张图片
                        if (this.single) {
                            // 删除第二张以后的图片
                            for (var i = 1; i < this.data.length; i++) {
                                this.deleteById(this.data[i].id);
                            }
                        }

                        var file = $scope[e];
                        var uploadUrl = CONFIG.uploadUrl;
                        var xhr = new XMLHttpRequest();
                        var fileId = this.add(xhr, file.name, file.size, xhr);
                        // self.search();

                        util.uploadFileToUrl(xhr, file, uploadUrl, 'normal',
                            function (evt) {
                                $scope.$apply(function () {
                                    if (evt.lengthComputable) {
                                        var percentComplete = Math.round(evt.loaded * 100 / evt.total);
                                        o.update(fileId, percentComplete, evt.total - evt.loaded, evt.total);
                                        console.log(percentComplete);
                                    }
                                });
                            },
                            function (xhr) {
                                var ret = JSON.parse(xhr.responseText);
                                console && console.log(ret);
                                $scope.$apply(function () {
                                    o.setSrcSizeByXhr(xhr, ret.upload_path, ret.size);
                                    // 如果这个对象只允许上传一张图片
                                    if (o.single) {
                                        // 删除第一站图片
                                        o.deleteById(o.data[0].id);
                                    }
                                });
                            },
                            function (xhr) {
                                $scope.$apply(function () {
                                    o.update(fileId, -1, '', '');
                                });
                                console.log('failure');
                                xhr.abort();
                            }
                        );
                    }
                }
            }
        ])

        //书香音乐库
        .controller('tvMusicCommonSXController', ['$q', '$scope', '$state', '$http', '$stateParams', '$filter', 'util', 'CONFIG',
            function ($q, $scope, $state, $http, $stateParams, $filter, util, CONFIG) {
                console.log('tvMusicCommonController')
                var self = this;
                self.init = function () {
                    self.info = {};
                    self.viewId = $stateParams.moduleId;
                    self.defaultLangCode = util.getDefaultLangCode();
                    self.editLangs = util.getParams('editLangs');
                    self.getInfo().then(function () {
                        self.initImgs1();
                    })

                }

                self.getInfo = function () {
                    var deferred = $q.defer();
                    self.loading = true;
                    var data = JSON.stringify({
                        action: "getAPIInfo",
                        token: util.getParams('token'),
                        lang: util.langStyle(),
                        viewID: Number(self.viewId)
                    })
                    $http({
                        method: 'POST',
                        url: util.getApiUrl('commonview', '', 'server'),
                        data: data
                    }).then(function successCallback(response) {
                        var data = response.data;
                        if (data.rescode == '200') {
                            self.info = data.data;
                            deferred.resolve();
                        } else if (data.rescode == '401') {
                            alert('访问超时，请重新登录');
                            $location.path("pages/login.html");
                        } else {
                            alert('读取信息失败，' + data.errInfo);
                            deferred.reject();
                        }
                    }, function errorCallback(response) {
                        alert('服务器出错');
                        deferred.reject();
                    }).finally(function (e) {
                        self.loading = false;
                    });
                    return deferred.promise;
                }

                self.initImgs1 = function () {
                    // 初始化apk url
                    self.imgs1 = new Imgs([{ "ImageURL": self.info.ApkURL, "ImageSize": self.info.ApkSize }], true);
                    self.imgs1.initImgs();
                }

                self.save = function () {

                    //检查logo上传
                    if (self.imgs1.data.length == 0) {
                        alert('请上传酒店Apk');
                        return;
                    }

                    self.info.ApkURL = self.imgs1.data[0].src;
                    self.info.ApkSize = self.imgs1.data[0].fileSize - 0;
                    self.saving = true;

                    var data = JSON.stringify({
                        action: "updateAPIInfo",
                        token: util.getParams('token'),
                        lang: util.langStyle(),
                        viewID: self.viewId - 0,
                        data: {
                            "MusicContentAPIParam": self.info.MusicContentAPIParam,
                            "MusicContentAPIURL": self.info.MusicContentAPIURL
                        }
                    })
                    $http({
                        method: 'POST',
                        url: util.getApiUrl('commonview', '', 'server'),
                        data: data
                    }).then(function successCallback(response) {
                        var data = response.data;
                        if (data.rescode == '200') {
                            alert('保存成功');
                            $state.reload();
                        } else {
                            alert('保存失败，' + data.errInfo);
                        }
                    }, function errorCallback(response) {
                        alert('服务器出错');
                    }).finally(function (e) {
                        self.saving = false;
                    });
                }

                self.clickUpload = function (e) {
                    setTimeout(function () {
                        document.getElementById(e).click();
                    }, 0);
                }

                function Imgs(imgList, single) {
                    this.initImgList = imgList;
                    this.data = [];
                    this.maxId = 0;
                    this.single = single ? true : false;
                }

                Imgs.prototype = {
                    initImgs: function () {
                        var l = this.initImgList;
                        for (var i = 0; i < l.length; i++) {
                            this.data[i] = {
                                "src": l[i].ImageURL,
                                "fileSize": l[i].ImageSize,
                                "id": this.maxId++,
                                "progress": 100
                            };
                        }
                    },
                    deleteById: function (id) {
                        var l = this.data;
                        for (var i = 0; i < l.length; i++) {
                            if (l[i].id == id) {
                                // 如果正在上传，取消上传
                                if (l[i].progress < 100 && l[i].progress != -1) {
                                    l[i].xhr.abort();
                                }
                                l.splice(i, 1);
                                break;
                            }
                        }
                    },

                    add: function (xhr, fileName, fileSize) {
                        this.data.push({
                            "xhr": xhr,
                            "fileName": fileName,
                            "fileSize": fileSize,
                            "progress": 0,
                            "id": this.maxId
                        });
                        return this.maxId++;
                    },

                    update: function (id, progress, leftSize, fileSize) {
                        for (var i = 0; i < this.data.length; i++) {
                            var f = this.data[i];
                            if (f.id === id) {
                                f.progress = progress;
                                f.leftSize = leftSize;
                                f.fileSize = fileSize;
                                break;
                            }
                        }
                    },

                    setSrcSizeByXhr: function (xhr, src, size) {
                        for (var i = 0; i < this.data.length; i++) {
                            if (this.data[i].xhr == xhr) {
                                this.data[i].src = src;
                                this.data[i].fileSize = size;
                                break;
                            }
                        }
                    },

                    uploadFile: function (e, o) {

                        // 如果这个对象只允许上传一张图片
                        if (this.single) {
                            // 删除第二张以后的图片
                            for (var i = 1; i < this.data.length; i++) {
                                this.deleteById(this.data[i].id);
                            }
                        }

                        var file = $scope[e];
                        var uploadUrl = CONFIG.uploadUrl;
                        var xhr = new XMLHttpRequest();
                        var fileId = this.add(xhr, file.name, file.size, xhr);
                        // self.search();

                        util.uploadFileToUrl(xhr, file, uploadUrl, 'normal',
                            function (evt) {
                                $scope.$apply(function () {
                                    if (evt.lengthComputable) {
                                        var percentComplete = Math.round(evt.loaded * 100 / evt.total);
                                        o.update(fileId, percentComplete, evt.total - evt.loaded, evt.total);
                                        console.log(percentComplete);
                                    }
                                });
                            },
                            function (xhr) {
                                var ret = JSON.parse(xhr.responseText);
                                console && console.log(ret);
                                $scope.$apply(function () {
                                    o.setSrcSizeByXhr(xhr, ret.upload_path, ret.size);
                                    // 如果这个对象只允许上传一张图片
                                    if (o.single) {
                                        // 删除第一站图片
                                        o.deleteById(o.data[0].id);
                                    }
                                });
                            },
                            function (xhr) {
                                $scope.$apply(function () {
                                    o.update(fileId, -1, '', '');
                                });
                                console.log('failure');
                                xhr.abort();
                            }
                        );
                    }
                }
            }
        ])

        .controller('tvQHtlMovieCommonController', ['$scope', '$state', '$http', '$stateParams', '$location', 'util',
            function ($scope, $state, $http, $stateParams, $location, util) {
                var self = this;

                self.init = function () {
                    self.viewId = $stateParams.moduleId;
                    self.getInfo();
                }

                self.getInfo = function () {
                    var data = JSON.stringify({
                        "token": util.getParams('token'),
                        "action": "getAPIInfo",
                        "viewID": self.viewId - 0,
                        "lang": util.langStyle()
                    });
                    self.loading = true;
                    $http({
                        method: 'POST',
                        url: util.getApiUrl('commonview', '', 'server'),
                        data: data
                    }).then(function successCallback(response) {
                        var data = response.data;
                        if (data.rescode == '200') {
                            self.MovieContentAPIParam = data.data.MovieContentAPIParam;
                            self.MovieContentAPIURL = data.data.MovieContentAPIURL;
                            self.PackageFee = data.data.PackageFee;
                            self.FeeDiscount = data.data.FeeDiscount;
                        } else if (data.rescode == '401') {
                            alert('访问超时，请重新登录');
                            $state.go('login');
                        } else {
                            alert('加载电影信息失败，' + data.errInfo);
                        }
                    }, function errorCallback(response) {
                        alert('连接服务器出错');
                    }).finally(function (value) {
                        self.loading = false;
                    });
                }

                self.save = function () {
                    var data = JSON.stringify({
                        "token": util.getParams('token'),
                        "action": "updateAPIInfo",
                        "viewID": self.viewId - 0,
                        "data": {
                            "MovieContentAPIParam": self.MovieContentAPIParam,
                            "MovieContentAPIURL": self.MovieContentAPIURL,
                            "PackageFee": self.PackageFee,
                            "FeeDiscount": self.FeeDiscount
                        },
                        "lang": util.langStyle()
                    })
                    self.saving = true;
                    $http({
                        method: 'POST',
                        url: util.getApiUrl('commonview', '', 'server'),
                        data: data
                    }).then(function successCallback(response) {
                        var data = response.data;
                        if (data.rescode == '200') {
                            alert('修改成功');
                        } else if (data.rescode == '401') {
                            alert('访问超时，请重新登录');
                            $state.go('login');
                        } else {
                            alert('修改失败' + data.errInfo);
                        }
                    }, function errorCallback(response) {
                        alert('连接服务器出错');
                    }).finally(function (value) {
                        self.saving = false;
                    });
                }

            }
        ])

        .controller('tvQHtlSimplePicTextEditController', ['$scope', '$state', '$http', '$stateParams', '$location', 'util', 'CONFIG',
            function ($scope, $state, $http, $stateParams, $location, util, CONFIG) {
                var self = this;

                self.init = function () {
                    self.viewId = $scope.app.maskParams.viewId;
                    self.picInfo = $scope.app.maskParams.picInfo;

                    // 获取编辑多语言信息
                    self.editLangs = util.getParams('editLangs');

                    self.setInfo();

                }


                self.cancel = function () {
                    $scope.app.showHideMask(false);
                }

                self.setInfo = function () {

                    self.Seq = self.picInfo.Seq;
                    self.Title = self.picInfo.Title;
                    self.Text = self.picInfo.Text;
                    self.imgs1 = new Imgs([{ "ImageURL": self.picInfo.PicURL, "ImageSize": self.picInfo.PicSize }], true);
                    self.imgs1.initImgs();
                    console.log(self.imgs1);
                }

                self.save = function () {

                    //频道图片必填验证
                    if (self.imgs1.data.length == 0) {
                        alert('请上传频道图片');
                        return;
                    }

                    var data = JSON.stringify({
                        "token": util.getParams('token'),
                        "action": "edit",
                        "viewID": Number(self.viewId),
                        "data": {
                            "ID": Number(self.picInfo.ID),
                            "Seq": self.Seq,
                            "Title": self.Title,
                            "PicURL": self.imgs1.data[0].src,
                            "Text": self.Text,
                            "PicSize": self.imgs1.data[0].fileSize
                        },
                        "lang": util.langStyle()
                    })

                    self.saving = true;
                    $http({
                        method: 'POST',
                        url: util.getApiUrl('commonview', '', 'server'),
                        data: data
                    }).then(function successCallback(response) {
                        var data = response.data;
                        if (data.rescode == '200') {
                            alert('修改成功');
                            $state.reload();
                        } else if (data.rescode == '401') {
                            alert('访问超时，请重新登录');
                            $state.go('login');
                        } else {
                            alert('修改失败，' + data.errInfo);
                        }
                    }, function errorCallback(response) {
                        alert('连接服务器出错');
                    }).finally(function (value) {
                        self.saving = false;
                    });

                }


                // 图片上传相关
                self.clickUpload = function (e) {
                    setTimeout(function () {
                        document.getElementById(e).click();
                    }, 0);
                }

                function Imgs(imgList, single) {
                    this.initImgList = imgList;
                    this.data = [];
                    this.maxId = 0;
                    this.single = single ? true : false;
                }

                Imgs.prototype = {
                    initImgs: function () {
                        var l = this.initImgList;
                        for (var i = 0; i < l.length; i++) {
                            this.data[i] = {
                                "src": l[i].ImageURL,
                                "fileSize": l[i].ImageSize,
                                "id": this.maxId++,
                                "progress": 100
                            };
                        }
                    },
                    deleteById: function (id) {
                        var l = this.data;
                        for (var i = 0; i < l.length; i++) {
                            if (l[i].id == id) {
                                // 如果正在上传，取消上传
                                if (l[i].progress < 100 && l[i].progress != -1) {
                                    l[i].xhr.abort();
                                }
                                l.splice(i, 1);
                                break;
                            }
                        }
                    },

                    add: function (xhr, fileName, fileSize) {
                        this.data.push({
                            "xhr": xhr,
                            "fileName": fileName,
                            "fileSize": fileSize,
                            "progress": 0,
                            "id": this.maxId
                        });
                        return this.maxId++;
                    },

                    update: function (id, progress, leftSize, fileSize) {
                        for (var i = 0; i < this.data.length; i++) {
                            var f = this.data[i];
                            if (f.id === id) {
                                f.progress = progress;
                                f.leftSize = leftSize;
                                f.fileSize = fileSize;
                                break;
                            }
                        }
                    },

                    setSrcSizeByXhr: function (xhr, src, size) {
                        for (var i = 0; i < this.data.length; i++) {
                            if (this.data[i].xhr == xhr) {
                                this.data[i].src = src;
                                this.data[i].fileSize = size;
                                break;
                            }
                        }
                    },

                    uploadFile: function (e, o) {

                        // 如果这个对象只允许上传一张图片
                        if (this.single) {
                            // 删除第二张以后的图片
                            for (var i = 1; i < this.data.length; i++) {
                                this.deleteById(this.data[i].id);
                            }
                        }

                        var file = $scope[e];
                        var uploadUrl = CONFIG.uploadUrl;
                        var xhr = new XMLHttpRequest();
                        var fileId = this.add(xhr, file.name, file.size, xhr);
                        // self.search();

                        util.uploadFileToUrl(xhr, file, uploadUrl, 'normal',
                            function (evt) {
                                $scope.$apply(function () {
                                    if (evt.lengthComputable) {
                                        var percentComplete = Math.round(evt.loaded * 100 / evt.total);
                                        o.update(fileId, percentComplete, evt.total - evt.loaded, evt.total);
                                        console && console.log(percentComplete);
                                    }
                                });
                            },
                            function (xhr) {
                                var ret = JSON.parse(xhr.responseText);
                                console && console.log(ret);
                                $scope.$apply(function () {
                                    o.setSrcSizeByXhr(xhr, ret.upload_path, ret.size);
                                    // 如果这个对象只允许上传一张图片
                                    if (o.single) {
                                        // 如果长度大于1张图片，删除前几张图片
                                        if (o.data.length > 1) {
                                            for (var i = 0; i < o.data.length - 1; i++) {
                                                o.deleteById(o.data[i].id);
                                            }
                                        }
                                    }
                                });
                            },
                            function (xhr) {
                                $scope.$apply(function () {
                                    o.update(fileId, -1, '', '');
                                });
                                console && console.log('failure');
                                xhr.abort();
                            }
                        );
                    }
                }

            }
        ])

        // 添加图文模块内的图文
        .controller('tvQHtlSimplePicTextAddController', ['$scope', '$state', '$http', '$stateParams', '$location', 'util', 'CONFIG',
            function ($scope, $state, $http, $stateParams, $location, util, CONFIG) {
                var self = this;

                self.init = function () {
                    self.viewId = $scope.app.maskParams.viewId;

                    // 获取编辑多语言信息
                    self.editLangs = util.getParams('editLangs');

                    // 初始化频道图片
                    self.imgs1 = new Imgs([], true);

                }

                self.cancel = function () {
                    $scope.app.showHideMask(false);
                }

                self.save = function () {

                    //频道图片必填验证
                    if (self.imgs1.data.length == 0 || self.imgs1.data[0].progress < 100) {
                        alert('请上传图片');
                        return;
                    }

                    var data = JSON.stringify({
                        "token": util.getParams('token'),
                        "action": "add",
                        "viewID": Number(self.viewId),
                        "data": {
                            "PicURL": self.imgs1.data[0].src,
                            "Seq": self.Seq,
                            "Text": self.Text,
                            "Title": self.Title,
                            "PicSize": self.imgs1.data[0].fileSize
                        },
                        "lang": util.langStyle()
                    })
                    self.saving = true;
                    $http({
                        method: 'POST',
                        url: util.getApiUrl('commonview', '', 'server'),
                        data: data
                    }).then(function successCallback(response) {
                        var data = response.data;
                        if (data.rescode == '200') {
                            alert('添加成功');
                            $state.reload();
                        } else if (data.rescode == '401') {
                            alert('访问超时，请重新登录');
                            $state.go('login');
                        } else {
                            alert('添加失败，' + data.errInfo);
                        }
                    }, function errorCallback(response) {
                        alert('连接服务器出错');
                    }).finally(function (value) {
                        self.saving = false;
                    });

                }


                // 图片上传相关
                self.clickUpload = function (e) {
                    setTimeout(function () {
                        document.getElementById(e).click();
                    }, 0);
                }

                function Imgs(imgList, single) {
                    this.initImgList = imgList;
                    this.data = [];
                    this.maxId = 0;
                    this.single = single ? true : false;
                }

                Imgs.prototype = {
                    initImgs: function () {
                        var l = this.initImgList;
                        for (var i = 0; i < l.length; i++) {
                            this.data[i] = {
                                "src": l[i].ImageURL,
                                "fileSize": l[i].ImageSize,
                                "id": this.maxId++,
                                "progress": 100
                            };
                        }
                    },
                    deleteById: function (id) {
                        var l = this.data;
                        for (var i = 0; i < l.length; i++) {
                            if (l[i].id == id) {
                                // 如果正在上传，取消上传
                                if (l[i].progress < 100 && l[i].progress != -1) {
                                    l[i].xhr.abort();
                                }
                                l.splice(i, 1);
                                break;
                            }
                        }
                    },

                    add: function (xhr, fileName, fileSize) {
                        this.data.push({
                            "xhr": xhr,
                            "fileName": fileName,
                            "fileSize": fileSize,
                            "progress": 0,
                            "id": this.maxId
                        });
                        return this.maxId++;
                    },

                    update: function (id, progress, leftSize, fileSize) {
                        for (var i = 0; i < this.data.length; i++) {
                            var f = this.data[i];
                            if (f.id === id) {
                                f.progress = progress;
                                f.leftSize = leftSize;
                                f.fileSize = fileSize;
                                break;
                            }
                        }
                    },

                    setSrcSizeByXhr: function (xhr, src, size) {
                        for (var i = 0; i < this.data.length; i++) {
                            if (this.data[i].xhr == xhr) {
                                this.data[i].src = src;
                                this.data[i].fileSize = size;
                                break;
                            }
                        }
                    },

                    uploadFile: function (e, o) {

                        // 如果这个对象只允许上传一张图片
                        if (this.single) {
                            // 删除第二张以后的图片
                            for (var i = 1; i < this.data.length; i++) {
                                this.deleteById(this.data[i].id);
                            }
                        }

                        var file = $scope[e];
                        var uploadUrl = CONFIG.uploadUrl;
                        var xhr = new XMLHttpRequest();
                        var fileId = this.add(xhr, file.name, file.size, xhr);
                        // self.search();

                        util.uploadFileToUrl(xhr, file, uploadUrl, 'normal',
                            function (evt) {
                                $scope.$apply(function () {
                                    if (evt.lengthComputable) {
                                        var percentComplete = Math.round(evt.loaded * 100 / evt.total);
                                        o.update(fileId, percentComplete, evt.total - evt.loaded, evt.total);
                                        console && console.log(percentComplete);
                                    }
                                });
                            },
                            function (xhr) {
                                var ret = JSON.parse(xhr.responseText);
                                console && console.log(ret);
                                $scope.$apply(function () {
                                    o.setSrcSizeByXhr(xhr, ret.upload_path, ret.size);
                                    // 如果这个对象只允许上传一张图片
                                    if (o.single) {
                                        // 如果长度大于1张图片，删除前几张图片
                                        if (o.data.length > 1) {
                                            for (var i = 0; i < o.data.length - 1; i++) {
                                                o.deleteById(o.data[i].id);
                                            }
                                        }
                                    }
                                });
                            },
                            function (xhr) {
                                $scope.$apply(function () {
                                    o.update(fileId, -1, '', '');
                                });
                                console && console.log('failure');
                                xhr.abort();
                            }
                        );
                    }
                }

            }
        ])

        //航班查询
        .controller('tvFlightController', ['$scope', '$state', '$http', '$stateParams', '$location', 'util',
            function ($scope, $state, $http, $stateParams, $location, util) {
                var self = this;

                self.init = function () {
                    self.viewId = $stateParams.moduleId;
                    self.defaultLangCode = util.getDefaultLangCode();
                    self.loadFlightList();
                }

                self.edit = function (index) {
                    $scope.app.maskParams.viewId = self.viewId;
                    $scope.app.maskParams.flightInfo = self.flights[index];
                    $scope.app.showHideMask(true, 'pages/tv/flightEdit.html');
                }

                self.del = function (id, index) {
                    var index = index;
                    if (!confirm('确认删除？')) {
                        return;
                    }
                    var data = JSON.stringify({
                        "token": util.getParams('token'),
                        "action": "delAirport",
                        "viewID": self.viewId,
                        "data": {
                            "AirportList": [
                                { "ID": id - 0 }
                            ]
                        },
                        "lang": util.langStyle()
                    })
                    $http({
                        method: 'POST',
                        url: util.getApiUrl('commonview', '', 'server'),
                        data: data
                    }).then(function successCallback(response) {
                        var data = response.data;
                        if (data.rescode == '200') {
                            alert('删除成功');
                            self.flights.splice(index, 1);
                        } else if (data.rescode == '401') {
                            alert('访问超时，请重新登录');
                            $state.go('login');
                        } else {
                            alert('删除失败，' + data.errInfo);
                        }
                    }, function errorCallback(response) {
                        alert('连接服务器出错');
                    });
                }

                self.add = function () {
                    $scope.app.maskParams.viewId = self.viewId;
                    $scope.app.showHideMask(true, 'pages/tv/flightAdd.html');
                }

                self.loadFlightList = function () {
                    var data = JSON.stringify({
                        "token": util.getParams('token'),
                        "action": "get",
                        "viewID": self.viewId - 0,
                        "lang": util.langStyle()
                    })
                    self.loading = true;
                    $http({
                        method: 'POST',
                        url: util.getApiUrl('commonview', '', 'server'),
                        data: data
                    }).then(function successCallback(response) {
                        var data = response.data;
                        if (data.rescode == '200') {
                            self.flights = data.data.AirportList;
                        } else if (data.rescode == '401') {
                            alert('访问超时，请重新登录');
                            $state.go('login');
                        } else {
                            alert('加载机场列表信息失败，' + data.errInfo);
                        }
                    }, function errorCallback(response) {
                        alert('连接服务器出错');
                    }).finally(function (value) {
                        self.loading = false;
                    });
                }

            }
        ])

        // 航班查询 ----添加机场
        .controller('tvFlightAddController', ['$scope', '$state', '$http', '$stateParams', '$location', 'util', 'CONFIG',
            function ($scope, $state, $http, $stateParams, $location, util, CONFIG) {
                var self = this;

                self.init = function () {
                    self.viewId = $scope.app.maskParams.viewId;

                    // 获取编辑多语言信息
                    self.editLangs = util.getParams('editLangs');
                }


                self.cancel = function () {
                    $scope.app.showHideMask(false);
                }

                self.save = function () {
                    var data = JSON.stringify({
                        "token": util.getParams('token'),
                        "action": "addAirport",
                        "viewID": Number(self.viewId),
                        "data": {
                            "AirportNum": self.airportNum,
                            "AirportCode": self.airportCode,
                            "AirportName": self.airportName
                        },
                        "lang": util.langStyle()
                    })
                    self.saving = true;
                    $http({
                        method: 'POST',
                        url: util.getApiUrl('commonview', '', 'server'),
                        data: data
                    }).then(function successCallback(response) {
                        var data = response.data;
                        if (data.rescode == '200') {
                            alert('添加成功');
                            $state.reload();
                        } else if (data.rescode == '401') {
                            alert('访问超时，请重新登录');
                            $state.go('login');
                        } else {
                            alert('添加失败，' + data.errInfo);
                        }
                    }, function errorCallback(response) {
                        alert('连接服务器出错');
                    }).finally(function (value) {
                        self.saving = false;
                    });

                }
            }
        ])

        // 航班查询 ----编辑机场
        .controller('tvFlightEditController', ['$scope', '$state', '$http', '$stateParams', '$location', 'util', 'CONFIG',
            function ($scope, $state, $http, $stateParams, $location, util, CONFIG) {
                var self = this;

                self.init = function () {
                    self.viewId = $scope.app.maskParams.viewId;
                    self.flightInfo = $scope.app.maskParams.flightInfo;

                    // 获取编辑多语言信息
                    self.editLangs = util.getParams('editLangs');

                    self.setInfo();

                }

                self.cancel = function () {
                    $scope.app.showHideMask(false);
                }

                self.setInfo = function () {
                    self.airportNum = self.flightInfo.AirportNum;
                    self.airportCode = self.flightInfo.AirportCode;
                    self.airportName = self.flightInfo.AirportName;
                }

                self.save = function () {
                    var data = JSON.stringify({
                        "token": util.getParams('token'),
                        "action": "editAirport",
                        "viewID": Number(self.viewId),
                        "data": {
                            "ID": Number(self.flightInfo.ID),
                            "AirportNum": self.airportNum,
                            "AirportCode": self.airportCode,
                            "AirportName": self.airportName,
                        },
                        "lang": util.langStyle()
                    })

                    self.saving = true;
                    $http({
                        method: 'POST',
                        url: util.getApiUrl('commonview', '', 'server'),
                        data: data
                    }).then(function successCallback(response) {
                        var data = response.data;
                        if (data.rescode == '200') {
                            alert('修改成功');
                            $state.reload();
                        } else if (data.rescode == '401') {
                            alert('访问超时，请重新登录');
                            $state.go('login');
                        } else {
                            alert('修改失败，' + data.errInfo);
                        }
                    }, function errorCallback(response) {
                        alert('连接服务器出错');
                    }).finally(function (value) {
                        self.saving = false;
                    });

                }
            }
        ])

        // Wps文件
        .controller('tvWpsController', ['$scope', '$state', '$http', '$stateParams', '$location', 'util', 'CONFIG',
            function ($scope, $state, $http, $stateParams, $location, util, CONFIG) {
                var self = this;

                self.init = function () {
                    self.viewId = $stateParams.moduleId;
                    self.defaultLangCode = util.getDefaultLangCode();
                    self.loadWpsList();
                }

                self.loadWpsList = function () {
                    var data = JSON.stringify({
                        "token": util.getParams('token'),
                        "action": "get",
                        "viewID": self.viewId - 0,
                        "lang": util.langStyle()
                    })
                    self.loading = true;
                    $http({
                        method: 'POST',
                        url: util.getApiUrl('commonview', '', 'server'),
                        data: data
                    }).then(function successCallback(response) {
                        var data = response.data;
                        if (data.rescode == '200') {
                            if (data.data.FileURL) {
                                self.imgs1 = new Imgs([{ "ImageURL": data.data.FileURL, "ImageSize": data.data.FileSize }], true);
                                self.imgs1.initImgs();
                            }
                            else {
                                self.imgs1 = new Imgs([], true);
                            }
                        } else if (data.rescode == '401') {
                            alert('访问超时，请重新登录');
                            $state.go('login');
                        } else {
                            alert('加载机场列表信息失败，' + data.errInfo);
                        }
                    }, function errorCallback(response) {
                        alert('连接服务器出错');
                    }).finally(function (value) {
                        self.loading = false;
                    });
                }

                self.save = function () {

                    if (self.imgs1.data.length == 0) {
                        alert('请上传文件');
                        return;
                    }
                    else if (self.imgs1.data[0].progress < 100) {
                        alert('文件正在上传中，请稍后...');
                        return;
                    }
                    else if (self.imgs1.data[0].progress == -1) {
                        alert('文件上传失败，请重新上传');
                        return;
                    }

                    var data = JSON.stringify({
                        "token": util.getParams('token'),
                        "action": "update",
                        "viewID": Number(self.viewId),
                        "data": {
                            "FileURL": self.imgs1.data[0].src,
                            "FileSize": self.imgs1.data[0].fileSize
                        },
                        "lang": util.langStyle()
                    })
                    $http({
                        method: 'POST',
                        url: util.getApiUrl('commonview', '', 'server'),
                        data: data
                    }).then(function successCallback(response) {
                        var data = response.data;
                        if (data.rescode == '200') {
                            alert('修改成功');
                            $state.reload()
                            self.saving = false;
                        } else if (data.rescode == '401') {
                            alert('访问超时，请重新登录');
                            $state.go('login');
                        } else {
                            alert('修改失败，' + data.errInfo);
                        }
                    }, function errorCallback(response) {
                        alert('连接服务器出错');
                    });

                }
                // 图片上传相关
                self.clickUpload = function (e) {
                    setTimeout(function () {
                        document.getElementById(e).click();
                    }, 0);
                }

                function Imgs(imgList, single) {
                    this.initImgList = imgList;
                    this.data = [];
                    this.maxId = 0;
                    this.single = single ? true : false;
                }

                Imgs.prototype = {
                    initImgs: function () {
                        var l = this.initImgList;
                        for (var i = 0; i < l.length; i++) {
                            this.data[i] = {
                                "src": l[i].ImageURL,
                                "fileSize": l[i].ImageSize,
                                "id": this.maxId++,
                                "progress": 100
                            };
                        }
                    },
                    deleteById: function (id) {
                        var l = this.data;
                        for (var i = 0; i < l.length; i++) {
                            if (l[i].id == id) {
                                // 如果正在上传，取消上传
                                if (l[i].progress < 100 && l[i].progress != -1) {
                                    l[i].xhr.abort();
                                }
                                l.splice(i, 1);
                                break;
                            }
                        }
                    },

                    add: function (xhr, fileName, fileSize) {
                        this.data.push({
                            "xhr": xhr,
                            "fileName": fileName,
                            "fileSize": fileSize,
                            "progress": 0,
                            "id": this.maxId
                        });
                        return this.maxId++;
                    },

                    update: function (id, progress, leftSize, fileSize) {
                        for (var i = 0; i < this.data.length; i++) {
                            var f = this.data[i];
                            if (f.id === id) {
                                f.progress = progress;
                                f.leftSize = leftSize;
                                f.fileSize = fileSize;
                                break;
                            }
                        }
                    },

                    setSrcSizeByXhr: function (xhr, src, size) {
                        for (var i = 0; i < this.data.length; i++) {
                            if (this.data[i].xhr == xhr) {
                                this.data[i].src = src;
                                this.data[i].fileSize = size;
                                break;
                            }
                        }
                    },

                    uploadFile: function (e, o) {
                        console.log(e)
                        if (!e || !checkType(e.name)) {
                            return false
                        }

                        // 如果这个对象只允许上传一张图片
                        if (this.single) {
                            // 删除第二张以后的图片
                            for (var i = 1; i < this.data.length; i++) {
                                this.deleteById(this.data[i].id);
                            }
                        }

                        var file = e;
                        var uploadUrl = CONFIG.uploadUrl;
                        var xhr = new XMLHttpRequest();
                        var fileId = this.add(xhr, file.name, file.size, xhr);
                        // self.search();

                        util.uploadFileToUrl(xhr, file, uploadUrl, 'normal',
                            function (evt) {
                                $scope.$apply(function () {
                                    if (evt.lengthComputable) {
                                        var percentComplete = Math.round(evt.loaded * 100 / evt.total);
                                        o.update(fileId, percentComplete, evt.total - evt.loaded, evt.total);
                                        console && console.log(percentComplete);
                                    }
                                });
                            },
                            function (xhr) {
                                var ret = JSON.parse(xhr.responseText);
                                console && console.log(ret);
                                $scope.$apply(function () {
                                    o.setSrcSizeByXhr(xhr, ret.upload_path, ret.size);
                                    // 如果这个对象只允许上传一张图片
                                    if (o.single) {
                                        // 如果长度大于1张图片，删除前几张图片
                                        if (o.data.length > 1) {
                                            for (var i = 0; i < o.data.length - 1; i++) {
                                                o.deleteById(o.data[i].id);
                                            }
                                        }
                                    }
                                });
                            },
                            function (xhr) {
                                $scope.$apply(function () {
                                    o.update(fileId, -1, '', '');
                                });
                                console && console.log('failure');
                                xhr.abort();
                            }
                        );
                    }
                }

                function checkType(target) {
                    var filetypes = ['.doc', '.docx', '.xls', '.xlsx', '.ppt', '.pptx'];
                    var filepath = target;
                    if (filepath) {
                        var isnext = false;
                        var fileend = filepath.substring(filepath.lastIndexOf("."));
                        console.log(fileend)
                        if (filetypes && filetypes.length > 0) {
                            for (var i = 0; i < filetypes.length; i++) {
                                if (filetypes[i] == fileend) {
                                    isnext = true;
                                    break;
                                }
                            }
                        }
                        if (!isnext) {
                            alert("不接受此文件类型！");
                            return false;
                        }
                    } else {
                        return false;
                    }
                    return true
                }
            }
        ])


        // 单视频图文
        .controller('singleVideoTextController', ['$scope', '$state', '$http', '$stateParams', '$location', 'util', 'CONFIG',
            function ($scope, $state, $http, $stateParams, $location, util, CONFIG) {
                var self = this;

                self.init = function () {
                    self.viewId = $stateParams.moduleId;
                    self.editLangs = util.getParams('editLangs')
                    self.getSingleVideo();
                }

                self.saving = false;

                self.save = function () {
                    if (self.imgs1.data.length == 0) {
                        alert('请上传封面图片');
                        return;
                    }
                    // 视频未上传完成
                    else if (self.imgs1.data[0].progress < 100) {
                        alert('封面图片正在上传中，请稍后...');
                        return;
                    }
                    // 视频上传失败时
                    else if (self.imgs1.data[0].progress == -1) {
                        alert('封面图片上传失败，请重新上传');
                        return;
                    }

                    if (self.imgs2.data.length == 0) {
                        alert('请上传视频');
                        return;
                    }
                    // 视频未上传完成
                    else if (self.imgs2.data[0].progress < 100) {
                        alert('视频正在上传中，请稍后...');
                        return;
                    }
                    // 视频上传失败时
                    else if (self.imgs2.data[0].progress == -1) {
                        alert('视频上传失败，请重新上传');
                        return;
                    }
                    var data
                    if (self.video.length > 0) {
                        data = JSON.stringify({
                            "token": util.getParams('token'),
                            "action": "edit",
                            "viewID": Number(self.viewId),
                            "data": {
                                "ID": self.ID,
                                "Title": self.Title,
                                "PicURL": self.imgs1.data[0].src,
                                "PicSize": self.imgs1.data[0].fileSize,
                                "VideoURL": self.imgs2.data[0].src,
                                "VideoSize": self.imgs2.data[0].fileSize
                            },
                            "lang": util.langStyle()
                        })
                    } else {
                        data = JSON.stringify({
                            "token": util.getParams('token'),
                            "action": "add",
                            "viewID": Number(self.viewId),
                            "data": {
                                "Title": self.Title,
                                "PicURL": self.imgs1.data[0].src,
                                "PicSize": self.imgs1.data[0].fileSize,
                                "VideoURL": self.imgs2.data[0].src,
                                "VideoSize": self.imgs2.data[0].fileSize
                            },
                            "lang": util.langStyle()
                        })
                    }

                    $http({
                        method: 'POST',
                        url: util.getApiUrl('commonview', '', 'server'),
                        data: data
                    }).then(function successCallback(response) {
                        var data = response.data;
                        if (data.rescode == '200') {
                            alert('修改成功');
                            // self.saving = false;
                            $state.reload();
                        } else if (data.rescode == '401') {
                            alert('访问超时，请重新登录');
                            $state.go('login');
                        } else {
                            alert('修改失败，' + data.errInfo);
                        }
                    }, function errorCallback(response) {
                        alert('连接服务器出错');
                    });
                }

                self.getSingleVideo = function () {
                    var data = JSON.stringify({
                        "token": util.getParams('token'),
                        "action": "get",
                        "viewID": self.viewId - 0,
                        "lang": util.langStyle()
                    })
                    self.loading = true;
                    $http({
                        method: 'POST',
                        url: util.getApiUrl('commonview', '', 'server'),
                        data: data
                    }).then(function successCallback(response) {
                        var data = response.data;
                        if (data.rescode == '200') {
                            if (data.data.res.length > 0) {
                                var res = data.data.res[0]
                                self.imgs1 = new Imgs([{ "ImageURL": res.PicURL, "ImageSize": res.PicSize }], true);
                                self.imgs1.initImgs();
                                self.imgs2 = new Imgs([{ "ImageURL": res.VideoURL, "ImageSize": res.VideoSize }], true);
                                self.imgs2.initImgs();
                                self.Title = res.Title
                                self.ID = res.ID
                                self.video = data.data.res
                            }
                            else {
                                self.imgs1 = new Imgs([], true);
                                self.imgs2 = new Imgs([], true);
                                self.Title = {
                                    'zh-CN': '',
                                    'en-US': ''
                                }
                                self.video = []
                            }

                        } else if (data.rescode == '401') {
                            alert('访问超时，请重新登录');
                            $state.go('login');
                        } else {
                            alert('加载信息失败，' + data.errInfo);
                        }
                    }, function errorCallback(response) {
                        alert('连接服务器出错');
                    }).finally(function (value) {
                        self.loading = false;
                    });
                }

                // 图片上传相关
                self.clickUpload = function (e) {
                    setTimeout(function () {
                        document.getElementById(e).click();
                    }, 0);
                }

                function Imgs(imgList, single) {
                    this.initImgList = imgList;
                    this.data = [];
                    this.maxId = 0;
                    this.single = single ? true : false;
                }

                Imgs.prototype = {
                    initImgs: function () {
                        var l = this.initImgList;
                        for (var i = 0; i < l.length; i++) {
                            this.data[i] = {
                                "src": l[i].ImageURL,
                                "fileSize": l[i].ImageSize,
                                "id": this.maxId++,
                                "progress": 100
                            };
                        }
                    },
                    deleteById: function (id) {
                        var l = this.data;
                        for (var i = 0; i < l.length; i++) {
                            if (l[i].id == id) {
                                // 如果正在上传，取消上传
                                if (l[i].progress < 100 && l[i].progress != -1) {
                                    l[i].xhr.abort();
                                }
                                l.splice(i, 1);
                                break;
                            }
                        }
                    },

                    add: function (xhr, fileName, fileSize) {
                        this.data.push({
                            "xhr": xhr,
                            "fileName": fileName,
                            "fileSize": fileSize,
                            "progress": 0,
                            "id": this.maxId
                        });
                        return this.maxId++;
                    },

                    update: function (id, progress, leftSize, fileSize) {
                        for (var i = 0; i < this.data.length; i++) {
                            var f = this.data[i];
                            if (f.id === id) {
                                f.progress = progress;
                                f.leftSize = leftSize;
                                f.fileSize = fileSize;
                                break;
                            }
                        }
                    },

                    setSrcSizeByXhr: function (xhr, src, size) {
                        for (var i = 0; i < this.data.length; i++) {
                            if (this.data[i].xhr == xhr) {
                                this.data[i].src = src;
                                this.data[i].fileSize = size;
                                break;
                            }
                        }
                    },

                    uploadFile: function (e, o) {
                        console.log(11212)
                        // 如果这个对象只允许上传一张图片
                        if (this.single) {
                            // 删除第二张以后的图片
                            for (var i = 1; i < this.data.length; i++) {
                                this.deleteById(this.data[i].id);
                            }
                        }

                        var file = e;
                        var uploadUrl = CONFIG.uploadUrl;
                        var xhr = new XMLHttpRequest();
                        var fileId = this.add(xhr, file.name, file.size, xhr);
                        // self.search();

                        util.uploadFileToUrl(xhr, file, uploadUrl, 'normal',
                            function (evt) {
                                $scope.$apply(function () {
                                    if (evt.lengthComputable) {
                                        var percentComplete = Math.round(evt.loaded * 100 / evt.total);
                                        o.update(fileId, percentComplete, evt.total - evt.loaded, evt.total);
                                        console && console.log(percentComplete);
                                    }
                                });
                            },
                            function (xhr) {
                                var ret = JSON.parse(xhr.responseText);
                                console && console.log(ret);
                                $scope.$apply(function () {
                                    o.setSrcSizeByXhr(xhr, ret.upload_path, ret.size);
                                    // 如果这个对象只允许上传一张图片
                                    if (o.single) {
                                        // 如果长度大于1张图片，删除前几张图片
                                        if (o.data.length > 1) {
                                            for (var i = 0; i < o.data.length - 1; i++) {
                                                o.deleteById(o.data[i].id);
                                            }
                                        }
                                    }
                                });
                            },
                            function (xhr) {
                                $scope.$apply(function () {
                                    o.update(fileId, -1, '', '');
                                });
                                console && console.log('failure');
                                xhr.abort();
                            }
                        );
                    }
                }
            }
        ])

        // 多视频图文
        .controller('multVideoController', ['$scope', '$state', '$http', '$stateParams', '$location', 'util',
            function ($scope, $state, $http, $stateParams, $location, util) {
                var self = this;
                self.init = function () {
                    self.viewId = $stateParams.moduleId;
                    console.log(self.viewId);
                    self.defaultLangCode = util.getDefaultLangCode();
                    console.log(self.defaultLangCode);
                    self.loadList();
                }

                self.edit = function (index) {
                    $scope.app.maskParams.viewId = self.viewId;
                    $scope.app.maskParams.videoInfo = self.videos[index];
                    $scope.app.showHideMask(true, 'pages/tv/multVideoTextEdit.html');
                }

                self.del = function (id, index) {
                    var index = index;
                    if (!confirm('确认删除？')) {
                        return;
                    }
                    var data = JSON.stringify({
                        "token": util.getParams('token'),
                        "action": "delete",
                        "viewID": self.viewId,
                        "data": {
                            "ID": id - 0
                        },
                        "lang": util.langStyle()
                    })
                    $http({
                        method: 'POST',
                        url: util.getApiUrl('commonview', '', 'server'),
                        data: data
                    }).then(function successCallback(response) {
                        var data = response.data;
                        if (data.rescode == '200') {
                            alert('删除成功');
                            self.pics.splice(index, 1);
                        } else if (data.rescode == '401') {
                            alert('访问超时，请重新登录');
                            $state.go('login');
                        } else {
                            alert('删除失败，' + data.errInfo);
                        }
                    }, function errorCallback(response) {
                        alert('连接服务器出错');
                    });
                }

                self.del = function (index) {
                    if (!confirm('确认删除？')) {
                        return;
                    }
                    var data = JSON.stringify({
                        "token": util.getParams('token'),
                        "action": "delete",
                        "viewID": self.viewId,
                        "lang": util.langStyle(),
                        "data": {
                            "ID": self.videos[index].ID
                        }
                    });
                    self.picDeleting = true;
                    $http({
                        method: 'POST',
                        url: util.getApiUrl('commonview', '', 'server'),
                        data: data
                    }).then(function successCallback(response) {
                        var data = response.data;
                        if (data.rescode == '200') {
                            alert('删除成功')
                            $state.reload();
                        }
                        else {
                            alert('连接服务器出错' + data.errInfo);
                        }
                    }, function errorCallback(response) {
                        alert('连接服务器出错');
                    }).finally(function (value) {
                        self.picDeleting = false;
                    });
                }

                self.add = function () {
                    $scope.app.maskParams.viewId = self.viewId;
                    $scope.app.showHideMask(true, 'pages/tv/multVideoTextAdd.html');
                }

                self.loadList = function () {
                    var data = JSON.stringify({
                        "token": util.getParams('token'),
                        "action": "get",
                        "viewID": self.viewId - 0,
                        "lang": util.langStyle()
                    })
                    self.loading = true;
                    $http({
                        method: 'POST',
                        url: util.getApiUrl('commonview', '', 'server'),
                        data: data
                    }).then(function successCallback(response) {
                        var data = response.data;
                        if (data.rescode == '200') {
                            self.videos = data.data.res
                        } else if (data.rescode == '401') {
                            alert('访问超时，请重新登录');
                            $state.go('login');
                        } else {
                            alert('加载信息失败，' + data.errInfo);
                        }
                    }, function errorCallback(response) {
                        alert('连接服务器出错');
                    }).finally(function (value) {
                        self.loading = false;
                    });
                }

            }
        ])

        // 多视频图文——添加项目
        .controller('multVideoAddController', ['$scope', '$state', '$http', '$stateParams', '$location', 'util', 'CONFIG',
            function ($scope, $state, $http, $stateParams, $location, util, CONFIG) {

                var self = this;
                self.init = function () {
                    self.viewId = $scope.app.maskParams.viewId;
                    self.cateId = $scope.app.maskParams.cateId;
                    // 获取编辑多语言信息
                    self.editLangs = util.getParams('editLangs');

                    // 初始化频道图片
                    self.imgs1 = new Imgs([], true);
                    // 初始化频道图片
                    self.imgs2 = new Imgs([], true);
                }


                self.cancel = function () {
                    $scope.app.showHideMask(false);
                }

                self.save = function () {
                    if (self.imgs1.data.length == 0) {
                        alert('请上传封面图片');
                        return;
                    }
                    // 视频未上传完成
                    else if (self.imgs1.data[0].progress < 100) {
                        alert('封面图片正在上传中，请稍后...');
                        return;
                    }
                    // 视频上传失败时
                    else if (self.imgs1.data[0].progress == -1) {
                        alert('封面图片上传失败，请重新上传');
                        return;
                    }

                    if (self.imgs2.data.length == 0) {
                        alert('请上传视频');
                        return;
                    }
                    // 视频未上传完成
                    else if (self.imgs2.data[0].progress < 100) {
                        alert('视频正在上传中，请稍后...');
                        return;
                    }
                    // 视频上传失败时
                    else if (self.imgs2.data[0].progress == -1) {
                        alert('视频上传失败，请重新上传');
                        return;
                    }

                    var data = JSON.stringify({
                        "token": util.getParams('token'),
                        "action": "add",
                        "viewID": Number(self.viewId),
                        "data": {
                            "Title": self.Title,
                            "PicURL": self.imgs1.data[0].src,
                            "PicSize": self.imgs1.data[0].fileSize,
                            "VideoURL": self.imgs2.data[0].src,
                            "VideoSize": self.imgs2.data[0].fileSize
                        },
                        "lang": util.langStyle()
                    })
                    if (self.cateId) {
                        data = JSON.stringify({
                            "token": util.getParams('token'),
                            "action": "addVideo",
                            "viewID": Number(self.viewId),
                            "data": {
                                "CategoryID": self.cateId,
                                "Title": self.Title,
                                "PicURL": self.imgs1.data[0].src,
                                "PicSize": self.imgs1.data[0].fileSize,
                                "VideoURL": self.imgs2.data[0].src,
                                "VideoSize": self.imgs2.data[0].fileSize
                            },
                            "lang": util.langStyle()
                        })
                    }
                    self.saving = true;
                    $http({
                        method: 'POST',
                        url: util.getApiUrl('commonview', '', 'server'),
                        data: data
                    }).then(function successCallback(response) {
                        var data = response.data;
                        if (data.rescode == '200') {
                            alert('添加成功');
                            $state.reload();
                        } else if (data.rescode == '401') {
                            alert('访问超时，请重新登录');
                            $state.go('login');
                        } else {
                            alert('添加失败，' + data.errInfo);
                        }
                    }, function errorCallback(response) {
                        alert('连接服务器出错');
                    }).finally(function (value) {
                        self.saving = false;
                    });

                }


                // 图片上传相关
                self.clickUpload = function (e) {
                    setTimeout(function () {
                        document.getElementById(e).click();
                    }, 0);
                }

                function Imgs(imgList, single) {
                    this.initImgList = imgList;
                    this.data = [];
                    this.maxId = 0;
                    this.single = single ? true : false;
                }

                Imgs.prototype = {
                    initImgs: function () {
                        var l = this.initImgList;
                        for (var i = 0; i < l.length; i++) {
                            this.data[i] = {
                                "src": l[i].ImageURL,
                                "fileSize": l[i].ImageSize,
                                "id": this.maxId++,
                                "progress": 100
                            };
                        }
                    },
                    deleteById: function (id) {
                        var l = this.data;
                        for (var i = 0; i < l.length; i++) {
                            if (l[i].id == id) {
                                // 如果正在上传，取消上传
                                if (l[i].progress < 100 && l[i].progress != -1) {
                                    l[i].xhr.abort();
                                }
                                l.splice(i, 1);
                                break;
                            }
                        }
                    },

                    add: function (xhr, fileName, fileSize) {
                        this.data.push({
                            "xhr": xhr,
                            "fileName": fileName,
                            "fileSize": fileSize,
                            "progress": 0,
                            "id": this.maxId
                        });
                        return this.maxId++;
                    },

                    update: function (id, progress, leftSize, fileSize) {
                        for (var i = 0; i < this.data.length; i++) {
                            var f = this.data[i];
                            if (f.id === id) {
                                f.progress = progress;
                                f.leftSize = leftSize;
                                f.fileSize = fileSize;
                                break;
                            }
                        }
                    },

                    setSrcSizeByXhr: function (xhr, src, size) {
                        for (var i = 0; i < this.data.length; i++) {
                            if (this.data[i].xhr == xhr) {
                                this.data[i].src = src;
                                this.data[i].fileSize = size;
                                break;
                            }
                        }
                    },

                    uploadFile: function (e, o) {

                        // 如果这个对象只允许上传一张图片
                        if (this.single) {
                            // 删除第二张以后的图片
                            for (var i = 1; i < this.data.length; i++) {
                                this.deleteById(this.data[i].id);
                            }
                        }

                        var file = $scope[e];
                        var uploadUrl = CONFIG.uploadUrl;
                        var xhr = new XMLHttpRequest();
                        var fileId = this.add(xhr, file.name, file.size, xhr);
                        // self.search();

                        util.uploadFileToUrl(xhr, file, uploadUrl, 'normal',
                            function (evt) {
                                $scope.$apply(function () {
                                    if (evt.lengthComputable) {
                                        var percentComplete = Math.round(evt.loaded * 100 / evt.total);
                                        o.update(fileId, percentComplete, evt.total - evt.loaded, evt.total);
                                        console && console.log(percentComplete);
                                    }
                                });
                            },
                            function (xhr) {
                                var ret = JSON.parse(xhr.responseText);
                                console && console.log(ret);
                                $scope.$apply(function () {
                                    o.setSrcSizeByXhr(xhr, ret.upload_path, ret.size);
                                    // 如果这个对象只允许上传一张图片
                                    if (o.single) {
                                        // 如果长度大于1张图片，删除前几张图片
                                        if (o.data.length > 1) {
                                            for (var i = 0; i < o.data.length - 1; i++) {
                                                o.deleteById(o.data[i].id);
                                            }
                                        }
                                    }
                                });
                            },
                            function (xhr) {
                                $scope.$apply(function () {
                                    o.update(fileId, -1, '', '');
                                });
                                console && console.log('failure');
                                xhr.abort();
                            }
                        );
                    }
                }

            }
        ])

        // 多视频图文——编辑项目
        .controller('multVideoEditController', ['$scope', '$state', '$http', '$stateParams', '$location', 'util', 'CONFIG',
            function ($scope, $state, $http, $stateParams, $location, util, CONFIG) {
                var self = this;

                self.init = function () {
                    self.viewId = $scope.app.maskParams.viewId;
                    self.info = $scope.app.maskParams.videoInfo;
                    self.cateId = $scope.app.maskParams.cateId;
                    self.Title = self.info.Title;

                    // 获取编辑多语言信息
                    self.editLangs = util.getParams('editLangs');

                    // 初始化频道图片
                    self.imgs1 = new util.initUploadImgs([{ "ImageURL": self.info.PicURL, "ImageSize": self.info.PicSize }], $scope, true);
                    self.imgs1.initImgs();

                    // 初始化频道图片
                    self.imgs2 = new util.initUploadImgs([{ "ImageURL": self.info.VideoURL, "ImageSize": self.info.VideoSize }], $scope, true);
                    self.imgs2.initImgs();

                }


                self.cancel = function () {
                    $scope.app.showHideMask(false);
                }

                self.save = function () {
                    if (self.imgs1.data.length == 0) {
                        alert('请上传封面图片');
                        return;
                    }
                    // 视频未上传完成
                    else if (self.imgs1.data[0].progress < 100) {
                        alert('封面图片正在上传中，请稍后...');
                        return;
                    }
                    // 视频上传失败时
                    else if (self.imgs1.data[0].progress == -1) {
                        alert('封面图片上传失败，请重新上传');
                        return;
                    }

                    if (self.imgs2.data.length == 0) {
                        alert('请上传视频');
                        return;
                    }
                    // 视频未上传完成
                    else if (self.imgs2.data[0].progress < 100) {
                        alert('视频正在上传中，请稍后...');
                        return;
                    }
                    // 视频上传失败时
                    else if (self.imgs2.data[0].progress == -1) {
                        alert('视频上传失败，请重新上传');
                        return;
                    }
                    var data = JSON.stringify({
                        "token": util.getParams('token'),
                        "action": "edit",
                        "viewID": Number(self.viewId),
                        "data": {
                            "ID": self.info.ID,
                            "Title": self.Title,
                            "PicURL": self.imgs1.data[0].src,
                            "PicSize": self.imgs1.data[0].fileSize,
                            "VideoURL": self.imgs2.data[0].src,
                            "VideoSize": self.imgs2.data[0].fileSize
                        },
                        "lang": util.langStyle()
                    })
                    if (self.cateId) {
                        data = JSON.stringify({
                            "token": util.getParams('token'),
                            "action": "editVideo",
                            "viewID": Number(self.viewId),
                            "data": {
                                "CategoryID": self.cateId,
                                "ID": self.info.ID,
                                "Title": self.Title,
                                "PicURL": self.imgs1.data[0].src,
                                "PicSize": self.imgs1.data[0].fileSize,
                                "VideoURL": self.imgs2.data[0].src,
                                "VideoSize": self.imgs2.data[0].fileSize
                            },
                            "lang": util.langStyle()
                        })
                    }
                    self.saving = true;
                    $http({
                        method: 'POST',
                        url: util.getApiUrl('commonview', '', 'server'),
                        data: data
                    }).then(function successCallback(response) {
                        var data = response.data;
                        if (data.rescode == '200') {
                            alert('更新成功！');
                            $state.reload();
                        } else if (data.rescode == '401') {
                            alert('访问超时，请重新登录');
                            $state.go('login');
                        } else {
                            alert('添加失败，' + data.errInfo);
                        }
                    }, function errorCallback(response) {
                        alert('连接服务器出错');
                    }).finally(function (value) {
                        self.saving = false;
                    });

                }


                // 图片上传相关
                self.clickUpload = function (e) {
                    setTimeout(function () {
                        document.getElementById(e).click();
                    }, 0);
                }

            }
        ])

        /*账单URL*/
        .controller('BillController', ['$scope', '$state', '$http', '$stateParams', '$location', 'util',
            function ($scope, $state, $http, $stateParams, $location, util) {
                var self = this;

                self.init = function () {
                    self.viewId = $stateParams.moduleId;
                    self.loadURL();
                }

                self.saving = false;

                self.save = function (id, index) {

                    var data = JSON.stringify({
                        "token": util.getParams('token'),
                        "action": "update",
                        "viewID": Number(self.viewId),
                        "data": {
                            "Url": self.url
                        },
                        "lang": util.langStyle()
                    })
                    $http({
                        method: 'POST',
                        url: util.getApiUrl('commonview', '', 'server'),
                        data: data
                    }).then(function successCallback(response) {
                        var data = response.data;
                        if (data.rescode == '200') {
                            alert('修改成功');
                            $state.reload();
                            self.saving = false;
                        } else if (data.rescode == '401') {
                            alert('访问超时，请重新登录');
                            $state.go('login');
                        } else {
                            alert('修改失败，' + data.errInfo);
                        }
                    }, function errorCallback(response) {
                        alert('连接服务器出错');
                    });
                }



                self.loadURL = function () {
                    var data = JSON.stringify({
                        "token": util.getParams('token'),
                        "action": "get",
                        "viewID": self.viewId - 0,
                        "lang": util.langStyle()
                    })
                    self.loading = true;
                    $http({
                        method: 'POST',
                        url: util.getApiUrl('commonview', '', 'server'),
                        data: data
                    }).then(function successCallback(response) {
                        var data = response.data;
                        if (data.rescode == '200') {
                            self.url = data.data.Url;
                        } else if (data.rescode == '401') {
                            alert('访问超时，请重新登录');
                            $state.go('login');
                        } else {
                            alert('加载信息失败，' + data.errInfo);
                        }
                    }, function errorCallback(response) {
                        alert('连接服务器出错');
                    }).finally(function (value) {
                        self.loading = false;
                    });
                }

            }
        ])

        /*烟草局送站信息*/
        .controller('tvStationInfoController_YCJ', ['$scope', '$state', '$http', '$stateParams', '$location', 'util',
            function ($scope, $state, $http, $stateParams, $location, util) {
                var self = this;

                self.init = function () {
                    self.viewId = $stateParams.moduleId;
                    self.loadInfo();
                }

                self.saving = false;

                self.save = function (id, index) {

                    var data = JSON.stringify({
                        "token": util.getParams('token'),
                        "action": "update",
                        "viewID": Number(self.viewId),
                        "data": {
                            "Tips": self.tips,
                            "Url": self.url
                        },
                        "lang": util.langStyle()
                    })
                    $http({
                        method: 'POST',
                        url: util.getApiUrl('commonview', '', 'server'),
                        data: data
                    }).then(function successCallback(response) {
                        var data = response.data;
                        if (data.rescode == '200') {
                            alert('修改成功');
                            self.saving = false;
                        } else if (data.rescode == '401') {
                            alert('访问超时，请重新登录');
                            $state.go('login');
                        } else {
                            alert('修改失败，' + data.errInfo);
                        }
                    }, function errorCallback(response) {
                        alert('连接服务器出错');
                    });
                }



                self.loadInfo = function () {
                    var data = JSON.stringify({
                        "token": util.getParams('token'),
                        "action": "get",
                        "viewID": self.viewId - 0,
                        "lang": util.langStyle()
                    })
                    self.loading = true;
                    $http({
                        method: 'POST',
                        url: util.getApiUrl('commonview', '', 'server'),
                        data: data
                    }).then(function successCallback(response) {
                        var data = response.data;
                        if (data.rescode == '200') {
                            self.url = data.data.Url;
                            self.tips = data.data.Tips;
                        } else if (data.rescode == '401') {
                            alert('访问超时，请重新登录');
                            $state.go('login');
                        } else {
                            alert('加载信息失败，' + data.errInfo);
                        }
                    }, function errorCallback(response) {
                        alert('连接服务器出错');
                    }).finally(function (value) {
                        self.loading = false;
                    });
                }

            }
        ])

        // 烟草局培训动态
        .controller('YCJ_textListController', ['$scope', '$state', '$http', '$stateParams', '$location', 'util',
            function ($scope, $state, $http, $stateParams, $location, util) {
                var self = this;

                self.init = function () {
                    self.viewId = $stateParams.moduleId;
                    self.defaultLangCode = util.getDefaultLangCode();
                    self.loadFlightList();
                }
                self.add = function () {
                    $scope.app.maskParams.viewId = self.viewId;
                    $scope.app.showHideMask(true, 'pages/tv/YCJ_textList/textListAdd.html');
                }

                self.edit = function (id, index) {
                    $scope.app.maskParams.viewId = self.viewId;
                    $scope.app.maskParams.itemId = id;
                    $scope.app.maskParams.itemInfo = self.itemList[index];
                    $scope.app.showHideMask(true, 'pages/tv/YCJ_textList/textListEdit.html');
                }

                self.del = function (id, index) {
                    var index = index;
                    if (!confirm('确认删除？')) {
                        return;
                    }
                    var data = JSON.stringify({
                        "token": util.getParams('token'),
                        "action": "delText",
                        "viewID": self.viewId,
                        "data": {
                            "TextList": [
                                { "ID": id - 0 }
                            ]
                        },
                        "lang": util.langStyle()
                    })
                    $http({
                        method: 'POST',
                        url: util.getApiUrl('commonview', '', 'server'),
                        data: data
                    }).then(function successCallback(response) {
                        var data = response.data;
                        if (data.rescode == '200') {
                            alert('删除成功');
                            self.itemList.splice(index, 1);
                        } else if (data.rescode == '401') {
                            alert('访问超时，请重新登录');
                            $state.go('login');
                        } else {
                            alert('删除失败，' + data.errInfo);
                        }
                    }, function errorCallback(response) {
                        alert('连接服务器出错');
                    });
                }



                self.loadFlightList = function () {
                    var data = JSON.stringify({
                        "token": util.getParams('token'),
                        "action": "get",
                        "viewID": self.viewId - 0,
                        "lang": util.langStyle()
                    })
                    self.loading = true;
                    $http({
                        method: 'POST',
                        url: util.getApiUrl('commonview', '', 'server'),
                        data: data
                    }).then(function successCallback(response) {
                        var data = response.data;
                        if (data.rescode == '200') {
                            self.itemList = data.data.res;
                        } else if (data.rescode == '401') {
                            alert('访问超时，请重新登录');
                            $state.go('login');
                        } else {
                            alert('加载机场列表信息失败，' + data.errInfo);
                        }
                    }, function errorCallback(response) {
                        alert('连接服务器出错');
                    }).finally(function (value) {
                        self.loading = false;
                    });
                }
            }
        ])

        // 烟草局培训动态新增条目
        .controller('YCJ_textListAddController', ['$scope', '$state', '$http', '$stateParams', '$location', 'util', 'CONFIG',
            function ($scope, $state, $http, $stateParams, $location, util, CONFIG) {
                var self = this;

                self.init = function () {
                    self.viewId = $scope.app.maskParams.viewId;

                    // 获取编辑多语言信息
                    self.editLangs = util.getParams('editLangs');
                }


                self.cancel = function () {
                    $scope.app.showHideMask(false);
                }

                self.save = function () {
                    var zhArray = [
                        self.name["zh-CN"] ? self.name["zh-CN"] : '',
                        self.time["zh-CN"] ? self.time["zh-CN"] : '',
                        self.obj["zh-CN"] ? self.obj["zh-CN"] : ''
                    ]
                    var enArray = [
                        self.name["en-US"] ? self.name["en-US"] : '',
                        self.time["en-US"] ? self.time["en-US"] : '',
                        self.obj["en-US"] ? self.obj["en-US"] : ''
                    ]
                    var data = JSON.stringify({
                        "token": util.getParams('token'),
                        "action": "addText",
                        "viewID": Number(self.viewId),
                        "data": {
                            "Text": {
                                "zh-CN": zhArray,
                                "en-US": enArray
                            },
                            "TextNum": self.num
                        },
                        "lang": util.langStyle()
                    })
                    self.saving = true;
                    $http({
                        method: 'POST',
                        url: util.getApiUrl('commonview', '', 'server'),
                        data: data
                    }).then(function successCallback(response) {
                        var data = response.data;
                        if (data.rescode == '200') {
                            alert('添加成功');
                            $state.reload();
                        } else if (data.rescode == '401') {
                            alert('访问超时，请重新登录');
                            $state.go('login');
                        } else {
                            alert('添加失败，' + data.errInfo);
                        }
                    }, function errorCallback(response) {
                        alert('连接服务器出错');
                    }).finally(function (value) {
                        self.saving = false;
                    });

                }
            }
        ])

        // 烟草局培训动态新增条目
        .controller('YCJ_textListEditController', ['$scope', '$state', '$http', '$stateParams', '$location', 'util', 'CONFIG',
            function ($scope, $state, $http, $stateParams, $location, util, CONFIG) {
                var self = this;

                self.init = function () {
                    self.viewId = $scope.app.maskParams.viewId;
                    var itemInfo = $scope.app.maskParams.itemInfo
                    self.num = itemInfo.TextNum
                    self.name = {
                        "zh-CN": itemInfo.Text["zh-CN"][0],
                        "en-US": itemInfo.Text["en-US"][0]
                    }
                    self.time = {
                        "zh-CN": itemInfo.Text["zh-CN"][1],
                        "en-US": itemInfo.Text["en-US"][1]
                    }
                    self.obj = {
                        "zh-CN": itemInfo.Text["zh-CN"][2],
                        "en-US": itemInfo.Text["en-US"][2]
                    }
                    // 获取编辑多语言信息
                    self.editLangs = util.getParams('editLangs');
                }


                self.cancel = function () {
                    $scope.app.showHideMask(false);
                }

                self.save = function () {
                    var zhArray = [
                        self.name["zh-CN"] ? self.name["zh-CN"] : '',
                        self.time["zh-CN"] ? self.time["zh-CN"] : '',
                        self.obj["zh-CN"] ? self.obj["zh-CN"] : ''
                    ]
                    var enArray = [
                        self.name["en-US"] ? self.name["en-US"] : '',
                        self.time["en-US"] ? self.time["en-US"] : '',
                        self.obj["en-US"] ? self.obj["en-US"] : ''
                    ]
                    var data = JSON.stringify({
                        "token": util.getParams('token'),
                        "action": "editText",
                        "viewID": Number(self.viewId),
                        "data": {
                            "ID": $scope.app.maskParams.itemId,
                            "Text": {
                                "zh-CN": zhArray,
                                "en-US": enArray
                            },
                            "TextNum": self.num
                        },
                        "lang": util.langStyle()
                    })
                    self.saving = true;
                    $http({
                        method: 'POST',
                        url: util.getApiUrl('commonview', '', 'server'),
                        data: data
                    }).then(function successCallback(response) {
                        var data = response.data;
                        if (data.rescode == '200') {
                            alert('操作成功');
                            $state.reload();
                        } else if (data.rescode == '401') {
                            alert('访问超时，请重新登录');
                            $state.go('login');
                        } else {
                            alert('操作失败，' + data.errInfo);
                        }
                    }, function errorCallback(response) {
                        alert('连接服务器出错');
                    }).finally(function (value) {
                        self.saving = false;
                    });

                }
            }
        ])

        // 分类多视频图文
        .controller('multVideoCategoryController', ['$scope', '$state', '$http', '$stateParams', '$location', 'util',
            function ($scope, $state, $http, $stateParams, $location, util) {
                var self = this;
                self.info = []; // 分类＋分类下的图文信息
                self.cateIndex; // 当前选中分类index

                self.init = function () {
                    self.cateIndex = 0;
                    self.viewId = $stateParams.moduleId;
                    self.defaultLangCode = util.getDefaultLangCode();
                    self.importing = false
                    self.loadInfo();
                }

                self.toggleLocal = function (val) {
                    if (val == false) {
                        self.localInfo = {
                            url: '',
                            param: ''
                        }
                        self.saveLocal()
                    }
                }

                self.saveLocal = function () {
                    var data = JSON.stringify({
                        "token": util.getParams('token'),
                        "action": "editLocalInfo",
                        "viewID": self.viewId - 0,
                        "lang": util.langStyle(),
                        "data": {
                            "localInfo": self.localInfo
                        }
                    });

                    return $http({
                        method: 'POST',
                        url: util.getApiUrl('commonview', '', 'server'),
                        data: data
                    }).then(function successCallback(response) {
                        if (response.data.rescode == 200) {
                            alert("修改成功")
                        }
                    }, function errorCallback(response) {
                        alert('连接服务器出错');
                    }).finally(function (value) {
                        self.saving = false;
                    });
                }

                // 导出json数据
                self.export = function () {
                    var obj = {
                        type: 'multVideoCategory',
                        data: self.info
                    }
                    util.exportData(JSON.stringify(obj), 'multVideoCategory.json')
                }

                // 导入json数据
                self.import = function () {
                    if (self.info.length > 0) {
                        if (!confirm('当前模块已有数据，确定导入吗？')) {
                            return
                        }
                    }
                    util.importData(self, $scope, 'multVideoCategory', self.importData)
                }

                // 导入数据后，先添加分类
                self.importData = function (D) {
                    self.importItemdIdx = 0  // 导入数据计数
                    self.importTotalNum = 0  // 导入数据总数
                    self.importing = true
                    self.process = "0%"
                    self.jsonData.data.forEach(function (item) {
                        self.importTotalNum += item.Videos.length
                    })
                    self.addCate(D)
                }

                // 每次添加完分类，在当前分类下添加子项目
                self.addCate = function (D) {
                    var data = JSON.stringify({
                        "token": util.getParams('token'),
                        "action": "addCategory",
                        "viewID": self.viewId - 0,
                        "lang": util.langStyle(),
                        "data": {
                            "CategoryName": D.CategoryName
                        }
                    });

                    self.saving = true;

                    return $http({
                        method: 'POST',
                        url: util.getApiUrl('commonview', '', 'server'),
                        data: data
                    }).then(function successCallback(response) {
                        if (response.data.rescode == 200) {
                            // 将json内的子项目添加进分类
                            D.Videos.forEach(function (item) {
                                self.addItem(response.data.data.ID, item)
                            })
                        }
                    }, function errorCallback(response) {
                        alert('连接服务器出错');
                    }).finally(function (value) {
                        self.saving = false;
                    });
                }

                // 添加子项目方法
                self.addItem = function (cateID, item) {
                    data = JSON.stringify({
                        "token": util.getParams('token'),
                        "action": "addVideo",
                        "viewID": Number(self.viewId),
                        "data": {
                            "CategoryID": cateID,
                            "Title": item.Title,
                            "PicURL": item.PicURL,
                            "PicSize": item.PicSize,
                            "VideoURL": item.VideoURL,
                            "VideoSize": item.VideoSize
                        },
                        "lang": util.langStyle()
                    })
                    $http({
                        method: 'POST',
                        url: util.getApiUrl('commonview', '', 'server'),
                        data: data
                    }).then(function successCallback(response) {
                        var data = response.data;
                        if (data.rescode == '200') {
                            self.importItemdIdx++
                            self.process = Math.floor((self.importItemdIdx / self.importTotalNum) * 100) + '%'
                            // 全部导入成功
                            if (self.importItemdIdx == self.importTotalNum) {
                                self.importing = false
                                $state.reload();
                            }
                        } else if (data.rescode == '401') {
                            alert('访问超时，请重新登录');
                            $state.go('login');
                        } else {
                            alert('添加失败，' + data.errInfo);
                        }
                    }, function errorCallback(response) {
                        alert('连接服务器出错');
                    }).finally(function (value) {
                        self.saving = false;
                    });
                }


                /**
                 * 添加该模版的图文分类
                 *
                 * @method addCategory
                 */
                self.addCategory = function () {
                    $scope.app.maskParams.viewId = self.viewId;
                    $scope.app.maskParams.loadInfo = self.loadInfo;
                    $scope.app.showHideMask(true, 'pages/tv/multVideoCategoryAdd.html');
                }

                /**
                 * 删除图文分类
                 *
                 * @method delCate
                 */
                self.delCate = function () {
                    if (self.info[self.cateIndex].Videos.length > 0) {
                        alert("请先删除该分类下视频")
                        return false;
                    }
                    if (!confirm('确认删除？')) {
                        return;
                    }
                    var data = JSON.stringify({
                        "token": util.getParams('token'),
                        "action": "delCategory",
                        "viewID": self.viewId,
                        "lang": util.langStyle(),
                        "data": {
                            "CategoryList": [{
                                "ID": self.info[self.cateIndex].ID
                            }]
                        }
                    });
                    self.cateDeleting = true;
                    $http({
                        method: 'POST',
                        url: util.getApiUrl('commonview', '', 'server'),
                        data: data
                    }).then(function successCallback(response) {
                        var data = response.data;
                        if (data.rescode == '200') {
                            alert("删除成功")
                            self.loadInfo();
                        }
                        else {
                            alert('连接服务器出错' + data.errInfo);
                        }
                    }, function errorCallback(response) {
                        alert('连接服务器出错');
                    }).finally(function (value) {
                        self.cateDeleting = false;
                    });
                }

                /**
                 * 编辑该模版的图文分类
                 *
                 * @method editCate
                 */
                self.editCate = function () {
                    $scope.app.maskParams.viewId = self.viewId;
                    $scope.app.maskParams.cateId = self.info[self.cateIndex].ID;
                    $scope.app.maskParams.info = self.info[self.cateIndex];
                    $scope.app.maskParams.loadInfo = self.loadInfo;
                    $scope.app.showHideMask(true, 'pages/tv/multVideoCategoryEdit.html');
                }

                /**
                 * 编辑图文
                 *
                 * @method editPic
                 * @param index 图片在该分类下列表中的序号
                 */
                self.editVideo = function (index) {
                    $scope.app.maskParams.viewId = self.viewId;
                    $scope.app.maskParams.cateId = self.info[self.cateIndex].ID;
                    $scope.app.maskParams.videoInfo = self.info[self.cateIndex].Videos[index];
                    $scope.app.maskParams.loadInfo = self.loadInfo;
                    $scope.app.showHideMask(true, 'pages/tv/multVideoTextEdit.html');
                }

                /**
                 * 添加该模版的图文
                 *
                 * @method addPic
                 */
                self.addVideo = function () {
                    $scope.app.maskParams.viewId = self.viewId;
                    $scope.app.maskParams.cateId = self.info[self.cateIndex].ID;
                    $scope.app.maskParams.loadInfo = self.loadInfo;
                    $scope.app.showHideMask(true, 'pages/tv/multVideoTextAdd.html');
                }

                /**
                 * 删除图文
                 *
                 * @method delPic
                 * @param index 图文在列表中的序号
                 */
                self.delVideo = function (index) {
                    if (!confirm('确认删除？')) {
                        return;
                    }
                    var data = JSON.stringify({
                        "token": util.getParams('token'),
                        "action": "delVideo",
                        "viewID": self.viewId,
                        "lang": util.langStyle(),
                        "data": {
                            "CategoryID": self.info[self.cateIndex].ID,
                            "ID": self.info[self.cateIndex].Videos[index].ID
                        }
                    });
                    self.picDeleting = true;
                    $http({
                        method: 'POST',
                        url: util.getApiUrl('commonview', '', 'server'),
                        data: data
                    }).then(function successCallback(response) {
                        var data = response.data;
                        if (data.rescode == '200') {
                            alert("删除成功")
                            self.loadInfo();
                        }
                        else {
                            alert('连接服务器出错' + data.errInfo);
                        }
                    }, function errorCallback(response) {
                        alert('连接服务器出错');
                    }).finally(function (value) {
                        self.picDeleting = false;
                    });
                }

                /**
                 * 加载分类及分类下的信息
                 *
                 * @method loadInfo
                 */
                self.loadInfo = function () {
                    var data = JSON.stringify({
                        "token": util.getParams('token'),
                        "action": "get",
                        "viewID": self.viewId,
                        "lang": util.langStyle()
                    });
                    self.loading = true;
                    return $http({
                        method: 'POST',
                        url: util.getApiUrl('commonview', '', 'server'),
                        data: data
                    }).then(function successCallback(response) {
                        var data = response.data;
                        if (data.rescode == '200') {
                            self.info = data.data.res;
                            self.localInfo = data.data.localInfo
                            self.local = !!self.localInfo.url
                            R.forEach(function (t) {
                                t.CategoryName = JSON.parse(t.CategoryName)
                            })(self.info)

                            if (!self.cateIndex || (self.cateIndex + 1) > self.info.length) {
                                self.cateIndex = 0;
                            }
                            //判断分类下内容为空时，sub属性为空数组，不然模板的ng-repeat会报错
                            if (self.info.length == 0) {
                                self.info[0].sub = [];
                            }
                        }
                        else {
                            alert('连接服务器出错' + data.errInfo);
                        }
                    }, function errorCallback(response) {
                        alert('连接服务器出错');
                    }).finally(function (value) {
                        self.loading = false;
                    });
                }

                /**
                 * 加载分类及分类下的信息
                 *
                 * @method loadInfo
                 * @param index 跳转分类在self.info中的index
                 */
                self.loadVideoList = function (index) {
                    self.cateIndex = index;
                }

            }
        ])

        //分类多视频图文添加分类
        .controller('multVideoCategoryAddController', ['$scope', '$state', '$http', '$stateParams', '$location', 'util', 'CONFIG',
            function ($scope, $state, $http, $stateParams, $location, util, CONFIG) {
                var self = this;
                self.viewId = 0;
                self.imgs1 = null;
                self.editLangs = util.getParams('editLangs');

                self.init = function () {
                    self.viewId = $scope.app.maskParams.viewId;
                    self.imgs1 = new util.initUploadImgs([], $scope, true);
                }
                // 图片上传相关
                self.clickUpload = function (e) {
                    setTimeout(function () {
                        document.getElementById(e).click();
                    }, 0);
                }
                /**
                 * 关闭弹窗
                 *
                 * @method cancel
                 */
                self.cancel = function () {
                    $scope.app.showHideMask(false);
                }

                /**
                 * 新建分类提交
                 *
                 * @method save
                 */
                self.save = function () {
                    // if (!(self.imgs1.data[0] && self.imgs1.data[0].src)) {
                    //     alert('请上传图片');
                    //     return;
                    // }

                    var data = JSON.stringify({
                        "token": util.getParams('token'),
                        "action": "addCategory",
                        "viewID": self.viewId - 0,
                        "lang": util.langStyle(),
                        "data": {
                            "CategoryName": self.cateName
                        }
                    });

                    self.saving = true;

                    return $http({
                        method: 'POST',
                        url: util.getApiUrl('commonview', '', 'server'),
                        data: data
                    }).then(function successCallback(response) {
                        if (response.data.rescode == 200) {
                            alert("添加成功")
                        }
                        $scope.app.showHideMask(false);
                        $scope.app.maskParams.loadInfo();
                    }, function errorCallback(response) {
                        alert('连接服务器出错');
                    }).finally(function (value) {
                        self.saving = false;
                    });
                }
            }
        ])

        //分类多视频图文编辑分类
        .controller('multVideoCategoryEditController', ['$scope', '$state', '$http', '$stateParams', '$location', 'util', 'CONFIG',
            function ($scope, $state, $http, $stateParams, $location, util, CONFIG) {
                var self = this;
                self.viewId = 0;
                self.imgs1 = null;
                self.editLangs = util.getParams('editLangs');

                self.init = function () {
                    self.viewId = $scope.app.maskParams.viewId;
                    self.cateName = $scope.app.maskParams.info.CategoryName
                }
                /**
                 * 关闭弹窗
                 *
                 * @method cancel
                 */
                self.cancel = function () {
                    $scope.app.showHideMask(false);
                }

                /**
                 * 新建分类提交
                 *
                 * @method save
                 */
                self.save = function () {
                    // if (!(self.imgs1.data[0] && self.imgs1.data[0].src)) {
                    //     alert('请上传图片');
                    //     return;
                    // }

                    var data = JSON.stringify({
                        "token": util.getParams('token'),
                        "action": "editCategory",
                        "viewID": self.viewId - 0,
                        "lang": util.langStyle(),
                        "data": {
                            "ID": $scope.app.maskParams.cateId,
                            "CategoryName": self.cateName
                        }
                    });

                    self.saving = true;

                    return $http({
                        method: 'POST',
                        url: util.getApiUrl('commonview', '', 'server'),
                        data: data
                    }).then(function successCallback(response) {
                        if (response.data.rescode == 200) {
                            alert("修改成功")
                        }
                        $scope.app.showHideMask(false);
                        $scope.app.maskParams.loadInfo();
                    }, function errorCallback(response) {
                        alert('连接服务器出错');
                    }).finally(function (value) {
                        self.saving = false;
                    });
                }
            }
        ])

        //汇率查询
        .controller('tvExRateController', ['$scope', '$state', '$http', '$stateParams', '$location', 'util',
            function ($scope, $state, $http, $stateParams, $location, util) {
                var self = this;

                self.init = function () {
                    self.viewId = $stateParams.moduleId;
                    self.defaultLangCode = util.getDefaultLangCode();
                    self.dataList = []
                    self.selected = []
                    self.loadExRateList();
                }

                self.edit = function (item) {
                    item.edit = true
                }

                self.add = function () {
                    $scope.app.maskParams.viewId = self.viewId;
                    $scope.app.maskParams.existList = self.dataList.map(function (item) {
                        return item.currencyCode
                    });
                    $scope.app.showHideMask(true, 'pages/tv/ExRateList.html');
                }

                self.del = function (data) {
                    if (data.length == 0) {
                        alert('请至少选择一项')
                        return
                    }
                    if (!confirm('确认删除？')) {
                        return;
                    }
                    var data = JSON.stringify({
                        "token": util.getParams('token'),
                        "action": "delCurrency",
                        "viewID": self.viewId,
                        "data": {
                            "CurrencyList": data.map(function (item) {
                                return { id: item }
                            })
                        },
                        "lang": util.langStyle()
                    })
                    $http({
                        method: 'POST',
                        url: util.getApiUrl('commonview', '', 'server'),
                        data: data
                    }).then(function successCallback(response) {
                        var data = response.data;
                        if (data.rescode == '200') {
                            alert('删除成功');
                            $state.reload()
                        } else if (data.rescode == '401') {
                            alert('访问超时，请重新登录');
                            $state.go('login');
                        } else {
                            alert('删除失败，' + data.errInfo);
                        }
                    }, function errorCallback(response) {
                        alert('连接服务器出错');
                    });
                }

                self.saveEdit = function (obj) {
                    var data = JSON.stringify({
                        "token": util.getParams('token'),
                        "action": "editCurrency",
                        "viewID": self.viewId,
                        "data": obj,
                        "lang": util.langStyle()
                    })
                    $http({
                        method: 'POST',
                        url: util.getApiUrl('commonview', '', 'server'),
                        data: data
                    }).then(function successCallback(response) {
                        var data = response.data;
                        if (data.rescode == '200') {
                            alert('修改成功');
                            $state.reload()
                        } else if (data.rescode == '401') {
                            alert('访问超时，请重新登录');
                            $state.go('login');
                        } else {
                            alert('删除失败，' + data.errInfo);
                        }
                    }, function errorCallback(response) {
                        alert('连接服务器出错');
                    });
                }

                self.updateSelected = function (action, id, name) {
                    if (action == 'add' && self.selected.indexOf(id) == -1) {
                        self.selected.push(id)
                        // self.selectedItem.push(i)
                        // i.checked=true
                    }
                    if (action == 'remove' && self.selected.indexOf(id) != -1) {
                        var idx = self.selected.indexOf(id);
                        self.selected.splice(idx, 1)
                        // self.selectedItem.splice(idx, 1)
                        // i.checked=false
                    }
                }

                self.updateSelection = function ($event, id) {
                    var checkbox = $event.target;
                    var action = (checkbox.checked ? 'add' : 'remove');
                    self.updateSelected(action, id, checkbox.name);
                }

                self.checkAll = function ($event) {
                    self.selected = []
                    self.dataList.forEach(function (item) {
                        var checked = $event.target.checked
                        item.checked = checked
                        if (checked) {
                            self.selected.push(item.id)
                        }
                    })
                }

                self.loadExRateList = function () {
                    var data = JSON.stringify({
                        "token": util.getParams('token'),
                        "action": "get",
                        "viewID": self.viewId - 0,
                        "lang": util.langStyle()
                    })
                    self.loading = true;
                    $http({
                        method: 'POST',
                        url: util.getApiUrl('commonview', '', 'server'),
                        data: data
                    }).then(function successCallback(response) {
                        var data = response.data;
                        if (data.rescode == '200') {
                            self.dataList = data.data.CurrencyList;
                            self.dataList.forEach(function (item) {
                                item.currencyOldNum = item.currencyNum
                            })
                        } else if (data.rescode == '401') {
                            alert('访问超时，请重新登录');
                            $state.go('login');
                        } else {
                            alert('加载机场列表信息失败，' + data.errInfo);
                        }
                    }, function errorCallback(response) {
                        alert('连接服务器出错');
                    }).finally(function (value) {
                        self.loading = false;
                    });
                }

            }
        ])

        .controller('ExRateListController', ['$scope', '$state', '$http', '$stateParams', '$location', 'util',
            function ($scope, $state, $http, $stateParams, $location, util) {
                var self = this;

                self.init = function () {
                    self.viewId = $scope.app.maskParams.viewId;
                    self.existList = $scope.app.maskParams.existList
                    // 获取编辑多语言信息
                    self.editLangs = util.getParams('editLangs');
                    self.selected = []
                    self.selectedItem = []
                    self.loadExRateList();

                    // var model=document.getElementById('model')
                    // model.onscroll=function(e){
                    //     console.log(e)
                    // }
                }

                self.cancel = function () {
                    $scope.app.showHideMask(false);
                }


                self.updateSelected = function (action, i, name) {
                    if (action == 'add' && self.selected.indexOf(i.id) == -1) {
                        self.selected.push(i.id)
                        self.selectedItem.push(i)
                        i.checked = true
                    }
                    if (action == 'remove' && self.selected.indexOf(i.id) != -1) {
                        var idx = self.selected.indexOf(i.id);
                        self.selected.splice(idx, 1)
                        self.selectedItem.splice(idx, 1)
                        i.checked = false
                    }
                }

                self.updateSelection = function ($event, i) {
                    var checkbox = $event.target;
                    var action = (checkbox.checked ? 'add' : 'remove');
                    self.updateSelected(action, i, checkbox.name);
                }


                self.loadExRateList = function () {
                    var protocol = $location.protocol()
                    var data = JSON.stringify({
                        "token": util.getParams('token'),
                        "action": "get",
                        "viewID": self.viewId - 0,
                        "lang": util.langStyle()
                    })
                    self.loading = true;
                    $http({
                        method: 'GET',
                        url: protocol + '://openvod.cleartv.cn/u/exrate?action=getCurrency',
                        data: data
                    }).then(function successCallback(response) {
                        var data = response.data.filter(function (item) {
                            return self.existList.indexOf(item.currencyCode) < 0
                        });

                        self.dataList = R.groupBy(function (i) {
                            return i.currencyCode.slice(0, 1)
                        })(data)
                    }, function errorCallback(response) {
                        alert('连接服务器出错');
                    }).finally(function (value) {
                        self.loading = false;
                    });
                }

                self.submit = function () {
                    // console.log(self.selectedItem)
                    // return
                    self.selectedItem.forEach(function (item) {
                        item.currencyNum = 0
                    })
                    var data = JSON.stringify({
                        "token": util.getParams('token'),
                        "action": "addCurrency",
                        "viewID": Number(self.viewId),
                        "data": {
                            "CurrencyList": self.selectedItem
                        },
                        "lang": util.langStyle()
                    })
                    self.loading = true;
                    $http({
                        method: 'POST',
                        url: util.getApiUrl('commonview', '', 'server'),
                        data: data
                    }).then(function successCallback(response) {
                        var data = response.data;
                        if (data.rescode == '200') {
                            alert('添加成功');
                            $state.reload();
                        } else if (data.rescode == '401') {
                            alert('访问超时，请重新登录');
                            $state.go('login');
                        } else {
                            alert('添加失败，' + data.errInfo);
                        }
                    }, function errorCallback(response) {
                        alert('连接服务器出错');
                    }).finally(function (value) {
                        self.loading = false;
                    });
                }
            }
        ])


})();