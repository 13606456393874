'use strict';
(function () {
    var app = angular.module('app.expressEidt', [])
        // 商城管理
        .controller('expressEidtController', ['$scope', '$state', '$translate', '$http', '$stateParams', '$filter', 'util',
            function ($scope, $state, $translate, $http, $stateParams, $filter, util) {
                console.log('expressEidt');
                var self = this;
                var originalAreaList
                self.init = function () {
                    self.maskParams = $scope.app.maskParams;
                    self.langStyle = util.langStyle();
                    self.multiLang = util.getParams('editLangs');
                    self.noData = false;
                    self.loading = false;
                    self.saving = false;

                    self.homeVisible = true;
                    self.addVisible = false;
                    self.eidtVisible = false;

                    self.form = {};
                    // 多语言
                    self.form.shopName = {}

                    self.getTemplates()

                    self.areaList = [
                        [
                            [],
                            [],
                            []
                        ]
                    ]

                    self.area = [
                        ['', '', '']
                    ]

                    self.getArea('', 0, 0)
                }

                self.showAdd = function () {
                    self.homeVisible = false
                    self.addVisible = true
                }

                self.showEdit = function (id) {
                    self.homeVisible = false
                    self.editVisible = true
                    self.getTemplateDetail(id)
                }

                self.goHome = function () {
                    self.addVisible = false
                    self.editVisible = false
                    self.homeVisible = true
                    $scope.addFormData = {}
                    $scope.addForm.$setPristine()

                    this.areaList = [
                        [
                            originalAreaList, [],
                            []
                        ]
                    ]

                    this.area = [
                        [
                            "", "", ""
                        ]
                    ]
                }

                self.cancel = function () {
                    console.log('cancel')
                    $scope.app.showHideMask(false);
                };

                self.range = function (n) {
                    return new Array(n);
                }

                self.getTemplates = function () {
                    var data = {
                        "action": "getCourierChargeTemplateList",
                        "token": util.getParams("token"),
                        "data": {
                            "ShopID": self.maskParams.ShopID
                        }
                    }

                    data = JSON.stringify(data);
                    $http({
                        method: 'POST', //$filter('ajaxMethod')()
                        url: util.getApiUrl('couriercharge', 'expressTemplate.json', 'server'),
                        data: data
                    }).then(function successCallback(data, status, headers, config) {
                        if (data.data.rescode == "200") {
                            self.expressList = data.data.data
                        } else if (data.data.rescode == "401") {
                            alert('访问超时，请重新登录');
                            $state.go('login')
                        } else {
                            alert(data.data.errInfo);
                        }
                    }, function errorCallback(data, status, headers, config) {
                        alert('连接服务器出错')
                    }).finally(function (value) {
                        self.saving = false;
                    });
                }

                self.getTemplateDetail = function (id) {
                    var data = {
                        "action": "getCourierChargeTemplate",
                        "token": util.getParams("token"),
                        "data": {
                            "TemplateID": id
                        }
                    }
                    data = JSON.stringify(data);
                    $http({
                        method: 'POST', //$filter('ajaxMethod')()
                        url: util.getApiUrl('couriercharge', 'expressTemplateDetail.json', 'server'),
                        data: data
                    }).then(function successCallback(data, status, headers, config) {
                        if (data.data.rescode == "200") {
                            var data = data.data.data
                            $scope.editFormData = {
                                TemplateID: id,
                                Name: data.Name,
                                FirstWeight: data.FirstWeight / 1000,
                                FirstPrice: data.FirstPrice / 100,
                                AdditionalWeight: data.AdditionalWeight / 1000,
                                AdditionalPrice: data.AdditionalPrice / 100
                            }

                            console.log(
                                {
                                    TemplateID: id,
                                    Name: data.Name,
                                    FirstWeight: data.FirstWeight / 1000,
                                    FirstPrice: data.FirstPrice / 100,
                                    AdditionalWeight: data.AdditionalWeight / 1000,
                                    AdditionalPrice: data.AdditionalPrice / 100
                                }  
                            )
                            data.Areas.forEach(function (area, index) {
                                if (index > 0) {
                                    self.addArea()
                                }
                                // console.log(area)
                                var area = getAreaArray(area)
                                self.area[index] = area.map(function (item) {
                                    return item.Code
                                })

                                if (area.length > 1) {
                                    area.forEach(function (item, idx) {
                                        if (idx == 0) return
                                        self.getAreaList(area[idx - 1].Code, index, idx)
                                    })
                                }

                                if (area.length == 1) {
                                    self.getAreaList(area[0].Code, index, 1)
                                }
                            })
                        } else if (data.data.rescode == "401") {
                            alert('访问超时，请重新登录');
                            $state.go('login')
                        } else {
                            alert(data.data.errInfo);
                        }
                    }, function errorCallback(data, status, headers, config) {
                        alert('连接服务器出错')
                    }).finally(function (value) {
                        self.saving = false;
                    });
                }

                // 设置地址联动
                self.getArea = function (code, i, j) {
                    if (!code) {
                        for (var x = 0; x < 2; x++) {
                            if (x >= j) {
                                self.area[i][x] = ''
                                self.areaList[i][x] = []
                            }
                        }
                        if (j == 0) {
                            self.getAreaList(code, i, j)
                        }
                    } else {
                        if (j == 2) return
                        self.getAreaList(code, i, j)
                    }
                }

                // 设置区域列表
                self.getAreaList = function (code, i, j) {
                    var data = {
                        "action": "getSubAreaList",
                        "token": util.getParams("token"),
                        "data": {}
                    }

                    code && (data.data.Code = code)

                    data = JSON.stringify(data);
                    $http({
                        method: 'POST', //$filter('ajaxMethod')()
                        url: util.getApiUrl('express', 'areaList' + (j + 1) + '.json', 'server'),
                        data: data
                    }).then(function successCallback(data, status, headers, config) {
                        if (data.data.rescode == "200") {
                            self.areaList[i][j] = data.data.data
                            if (i == 0 && j == 0) {
                                originalAreaList = self.areaList[i][j]
                            }
                        } else if (data.data.rescode == "401") {
                            alert('访问超时，请重新登录');
                            $state.go('login')
                        } else {
                            alert(data.data.errInfo);
                        }
                    }, function errorCallback(data, status, headers, config) {
                        alert('连接服务器出错')
                    }).finally(function (value) {
                        self.saving = false;
                    });
                }

                self.addArea = function () {
                    self.area.push(['', '', ''])
                    self.areaList.push([
                        self.areaList[0][0],
                        [],
                        []
                    ])
                }

                self.delArea = function (index) {
                    self.area.splice(index, 1)
                    self.areaList.splice(index, 1)
                }

                self.saveAddForm = function () {
                    var area = self.area.map(function (item) {
                        var arr = item.filter(function (i) {
                            return i
                        })
                        return arr[arr.length - 1]
                    })

                    area = area.filter(function (i) {
                        return i
                    })

                    // 去重
                    var areaResult = []
                    area.forEach(function (i) {
                        if (areaResult.indexOf(i) < 0) {
                            areaResult.push(i)
                        }
                    })

                    if (area.length == 0) {
                        alert("请选择区域")
                        return
                    }
                    var data = {
                        "action": "addCourierChargeTemplate",
                        "token": util.getParams("token"),
                        "data": {
                            "ShopID": self.maskParams.ShopID,
                            "Type": "Weight",
                            "data": {
                                "Name": $scope.addFormData.Name,
                                "Description": $scope.addFormData.Description || '',
                                "Areas": areaResult,
                                "FirstWeight": $scope.addFormData.FirstWeight * 1000,
                                "FirstPrice": $scope.addFormData.FirstPrice * 100,
                                "AdditionalWeight": $scope.addFormData.AdditionalWeight * 1000,
                                "AdditionalPrice": $scope.addFormData.AdditionalPrice * 100
                            }
                        }
                    }

                    $http({
                        method: 'POST', //$filter('ajaxMethod')()
                        url: util.getApiUrl('couriercharge'),
                        data: data
                    }).then(function successCallback(data, status, headers, config) {
                        if (data.data.rescode == "200") {
                            alert("添加成功")
                            self.goHome()
                            self.getTemplates()
                        } else if (data.data.rescode == "401") {
                            alert('访问超时，请重新登录');
                            $state.go('login')
                        } else {
                            alert(data.data.errInfo);
                        }
                    }, function errorCallback(data, status, headers, config) {
                        alert('连接服务器出错')
                    })
                }

                self.saveEditForm = function () {
                    var area = self.area.map(function (item) {
                        var arr = item.filter(function (i) {
                            return i
                        })
                        return arr[arr.length - 1]
                    })
                    
                    area = area.filter(function (i) {
                        return i
                    })

                    if (area.length == 0) {
                        alert("请选择区域")
                        return
                    }

                    // 去重
                    var areaResult = []
                    area.forEach(function (i) {
                        if (areaResult.indexOf(i) < 0) {
                            areaResult.push(i)
                        }
                    })
                    var data = {
                        "action": "editCourierChargeTemplate",
                        "token": util.getParams("token"),
                        "data": {
                            "TemplateID": $scope.editFormData.TemplateID,
                            "Name": $scope.editFormData.Name,
                            "Description": $scope.editFormData.Description || '',
                            "Areas": areaResult,
                            "FirstWeight": $scope.editFormData.FirstWeight * 1000,
                            "FirstPrice": $scope.editFormData.FirstPrice * 100,
                            "AdditionalWeight": $scope.editFormData.AdditionalWeight * 1000,
                            "AdditionalPrice": $scope.editFormData.AdditionalPrice * 100
                        }
                    }

                    $http({
                        method: 'POST', //$filter('ajaxMethod')()
                        url: util.getApiUrl('couriercharge'),
                        data: data
                    }).then(function successCallback(data, status, headers, config) {
                        if (data.data.rescode == "200") {
                            alert("保存成功")
                            self.goHome()
                            self.getTemplates()
                        } else if (data.data.rescode == "401") {
                            alert('访问超时，请重新登录');
                            $state.go('login')
                        } else {
                            alert(data.data.errInfo);
                        }
                    }, function errorCallback(data, status, headers, config) {
                        alert('连接服务器出错')
                    })
                }

                self.delTemplate = function (id) {
                    if (!confirm("确定删除吗？")) return
                    var data = {
                        "action": "delCourierChargeTemplate",
                        "token": util.getParams("token"),
                        "data": {
                            "TemplateID": id
                        }
                    }

                    data = JSON.stringify(data);
                    $http({
                        method: 'POST', //$filter('ajaxMethod')()
                        url: util.getApiUrl('couriercharge'),
                        data: data
                    }).then(function successCallback(data, status, headers, config) {
                        if (data.data.rescode == "200") {
                            alert('删除成功')
                            self.getTemplates()
                        } else if (data.data.rescode == "401") {
                            alert('访问超时，请重新登录');
                            $state.go('login')
                        } else {
                            alert(data.data.errInfo);
                        }
                    }, function errorCallback(data, status, headers, config) {
                        alert('连接服务器出错')
                    }).finally(function (value) {
                        self.saving = false;
                    });
                }


                // self.handleSelect=function(index,i){
                //     if(i==1 && self.areaList[index][i].length==0){
                //         self.getAreaList(self.area[index][i-1],index,i)
                //     }
                // }

                // 获取区域对应数组
                function getAreaArray(obj) {
                    var currentNode = obj
                    var arr = []
                    arr.push(currentNode)
                    // console.log(arr)
                    while (currentNode.Parent) {
                        currentNode = currentNode.Parent
                        arr.push(currentNode)
                    }

                    arr.forEach(function (item) {
                        if (item.Parent) {
                            delete item.Parent
                        }
                    })

                    return arr.reverse()
                }
            }
        ])
})();