'use strict';
/*
* This file contains *MOST* of the controllers used in terminal page
* not all because I was too lazy to move everything here. Plus
* the app was working just fine so I didn't bother moving them over.
*
* Last edit: 2019/March/13 by Benson zhang
*/
/*
* Not sure where to put the utils functions
* So, just gonna put them here
*/
// A Simple util to extractId, used for map
function extractId(e) {
    return e.ID
}

(function() {
    var app = angular.module('app.terminal-controllers', []).controller('terminalController', [
        '$scope',
        '$state',
        '$http',
        '$stateParams',
        '$filter',
        '$document',
        '$uibModal',
        'util',
        function($scope, $state, $http, $stateParams, $filter, $document, $uibModal, util) {
            // 终端管理
            console.log('terminalController');
            var vm = this;
            // This is like a class constant
            var OPTIONS = {
                OFFLINE: 'OFFLINE',
                ONLINE: 'ONLINE',
                NOTERM: 'NOTERM',
                VIP: 'VIP'
            }
            vm.legend = [{
                    class: 'mdc-bg-grey',
                    activeClass: 'mdc-bg-grey-50',
                    text: '离线',
                    filterOptions: OPTIONS.OFFLINE
                }, {
                    class: 'mdc-bg-green',
                    activeClass: 'mdc-bg-grey-50',
                    text: '在线',
                    filterOptions: OPTIONS.ONLINE
                }, {
                    class: 'mdc-bg-red',
                    activeClass: 'mdc-bg-grey-50',
                    text: '无机顶盒',
                    filterOptions: OPTIONS.NOTERM
                },
                // {
                //     class: 'img',
                //     activeClass: 'mdc-bg-grey-50',
                //     imgUrl: '',
                //     text: '电影VIP',
                //     filterOptions: OPTIONS.VIP
                // }
            ]
            vm.legendForSelect = {
                class: 'mdc-bg-purple-400',
                text: '选中'
            }
            vm.init = function() {
                // Data initialization
                vm.hotelList = [];
                vm.currentHotelID = null;
                vm.onlineNumber = 0;
                vm.totalNumber = 0;

                vm.groupTypesOfHotel = [];

                // This is for stuff like online/offline/no terminal/VIP
                vm.statusFilter = null;
                // a search key to search in MAC/IP/RoomID
                vm.searchKey = '';
                // a groupType that the user can click to filter
                vm.groupTypeFilter = null;
                // Save a copy of original complete data
                vm.allTeriminals = [];
                // This keeps track of currently selected terminals
                vm.selectedTerminals = [];
                // These are UI only
                vm.displayTerminals = [];
                vm.didSelectSomething = false;

                vm.defaultLangCode = util.getDefaultLangCode();
                vm.langStyle = util.langStyle();
                // On init, a search for hotel list is triggered,
                // Then a getDevNum is triggered from view's init of header
                // Basically this means we getDevNum after the hotels are
                // loaded
                vm.getHotelList();
                // uncomment this to see the modal on entry
                // vm.showNewPushMessageModal();
                /* Fun fact, if you wish to add selectables, or dragSelect
                * To this project, just don't.
                * Because I have tried and it just doens't go well.
                * Angular sucks!
                */
            };

            // Get a list of group types by currentHotelID
            vm.getGroupList = function(){
                var request = {
                    "action": "getTermGroupList",
                    "token": util.getParams("token"),
                    "hotelID":vm.currentHotelID,
                    // "lang": vm.langStyle
                };
                request = JSON.stringify(request);
                $http({
                    method: $filter('ajaxMethod')(),
                    url: util.getApiUrl('termgroup', 'termgroup.json', ''),
                    data: request
                }).then(function (data, status, headers, config) {
                    if (data.data.rescode == "200") {
                        vm.groupTypesOfHotel = data.data.groupList;
                        if(vm.allTeriminals) {
                            vm.populateGroupTypeNameToTerminal();
                        }
                    } else if (data.data.rescode == "401") {
                        alert('访问超时，请重新登录');
                        $state.go('login')
                    } else {
                        alert('列表获取失败， ' + data.data.errInfo);
                    }

                }, function (data, status, headers, config) {
                    alert('获取失败， ' + data.data.errInfo);
                })
            }
            // Gets a hotel list of current user, store the result in
            // vm.hotelList
            vm.getHotelList = function() {
                var data = {
                    "action": "getHotelList",
                    "token": util.getParams("token"),
                    "lang": vm.langStyle
                };
                data = JSON.stringify(data);
                $http({
                    method: $filter('ajaxMethod')(),
                    url: util.getApiUrl('hotelroom', 'shopList', 'server'),
                    data: data
                }).then(function successCallback(data, status, headers, config) {
                    if (data.data.rescode == "200") {
                        vm.hotelList = data.data.data;
                    } else if (data.data.rescode == "401") {
                        alert('访问超时，请重新登录');
                        $state.go('login')
                    } else {
                        alert('列表获取失败， ' + data.data.errInfo);
                    }

                }, function errorCallback(data, status, headers, config) {
                    alert('获取失败， ' + data.data.errInfo);
                }). finally(function(value) {
                    vm.loading = false;
                });

            }
            // Gets all devices related to current selected hotel, store the reuslt in
            // vm.allTeriminals,
            // then call getDisplayTerminals() to  populate
            // vm.displayTerminals
            vm.getDevList = function() {
                vm.noData = false;
                vm.loading = true;
                var data = {
                    'action': 'getDevList',
                    'token': util.getParams('token'),
                    'lang': vm.langStyle,
                    'Online': null,
                    'HotelID': vm.currentHotelID,
                    'RoomID': null,
                    // Force getting all data
                    'per_page': 5000,
                    'page': 1
                }
                data = JSON.stringify(data);
                $http({
                    method: $filter('ajaxMethod')(),
                    url: util.getApiUrl('devinfo', 'shopList', 'server'),
                    data: data
                }).then(function(data, status, headers, config) {
                    if (data.data.rescode == '200') {
                        if (data.data.total == 0) {
                            vm.noData = true;
                        }
                        vm.allTeriminals = data.data.devlist.sort(vm.sortTerminalFunc);
                        vm.populateGroupTypeNameToTerminal();
                        vm.getDisplayTerminals();
                    } else if (msg.rescode == '401') {
                        alert('访问超时，请重新登录');
                        $state.go('login');
                    } else {
                        alert(data.rescode + ' ' + data.errInfo);
                    }
                }, function errorCallback(data, status, headers, config) {
                    alert(response.status + ' 服务器出错');
                }). finally(function(value) {
                    vm.loading = false;
                })
            }

            vm.sortTerminalFunc = function(first, second){
                var rid1 = parseInt(first.RoomID);
                var rid2 = parseInt(second.RoomID);
                if(rid1 < rid2) return -1;
                if(rid1 > rid2) return 1;
                return 0;
            }

            // This is a really expensive calculation,
            // So I'd not use this too much.
            vm.populateGroupTypeNameToTerminal = function(){
                vm.allTeriminals.map(function(e){
                    e.TermGroupTypeName = vm.getGroupTypeNamebyID(e.TermGroupType);
                });
            }
            vm.getGroupTypeNamebyID = function(id) {
                if(!vm.groupTypesOfHotel) return id;
                for(var i in vm.groupTypesOfHotel) {
                    var gt = vm.groupTypesOfHotel[i];
                    if (gt.termGroupID == id) {
                        return gt.termGroupName;
                    }
                }
                return '未分组';
            }

            // Gets the current device number of current hotel
            vm.getDevNum = function() {
                var data = {
                    "action": "getDevNum",
                    "token": util.getParams("token"),
                    "lang": vm.langStyle,
                    "HotelID": vm.currentHotelID
                }

                data = JSON.stringify(data);
                $http({
                    method: $filter('ajaxMethod')(),
                    url: util.getApiUrl('devinfo', '', 'server'),
                    data: data
                }).then(function successCallback(data, status, headers, config) {
                    if (data.data.rescode == '200') {
                        vm.totalNumber = data.data.total;
                        vm.onlineNumber = data.data.online;
                    } else if (msg.rescode == '401') {
                        alert('访问超时，请重新登录');
                        $state.go('login');
                    } else {
                        alert(data.rescode + ' ' + data.errInfo);
                    }
                }, function errorCallback(data, status, headers, config) {
                    alert(response.status + ' 服务器出错');
                }). finally(function(value) {
                    vm.loading = false;
                })

            }
            /*
             * This function sequentially applies the three
             * types of filters we have, first is filter by online/offline
             * second is searchKey
             * third is groupType
             */
            vm.getDisplayTerminals = function() {
                var f = vm.getFilterFunc();
                var channel = vm.allTeriminals.filter(f);
                if (vm.searchKey && vm.searchKey != '') {
                    var sk = vm.searchKey;
                    channel = channel.filter(function(e) {
                        var a = e.RoomID && e.RoomID.includes(sk);
                        var b = e.TermMac && e.TermMac.includes(sk);
                        var c = e.IP && e.IP.includes(sk);
                        return a || b || c;
                    })
                }
                if (vm.groupTypeFilter && vm.groupTypeFilter.termGroupID !== -1) {
                    channel = channel.filter(function(e) {
                        return e.TermGroupType == vm.groupTypeFilter.termGroupID;
                    })
                }
                vm.displayTerminals = channel;
            }

            // Clears all selected devices
            vm.clearSelected = function() {
                vm.selectedTerminals = [];
                vm.allTeriminals.map(function(e){
                    e.selected = false
                });
                vm.didSelectSomething = false;
            }
            vm.clearSearchKey = function() {
                vm.searchKey = '';
                vm.getDisplayTerminals();
            }
            vm.removeSelected = function(data) {
                vm.selectedTerminals = vm.selectedTerminals.filter(function(e){
                    return e.ID != data.ID
                });
            }
            // Filter
            vm.getFilterFunc = function() {
                var f = function(e) {
                    return true;
                };
                switch (vm.statusFilter) {
                    case OPTIONS.ONLINE:
                        f = function(e) {
                            return e.Online == 1;
                        };
                        break;
                    case OPTIONS.OFFLINE:
                        f = function(e) {
                            return e.Online == 0;
                        };
                        break;
                    case OPTIONS.NOTERM:
                        f = function(e) {
                            return !e.TermType;
                        };
                        break;
                    case OPTIONS.VIP:
                        f = function(e) {
                            return e.isVIP;
                        };
                        break;
                    default:
                        break;
                }
                return f
            }
            // A simple util to check whether anything is selected
            vm.didSelectAnything = function() {
                vm.didSelectSomething = vm.selectedTerminals.length != 0;
            }
            /* ****************************
            * Functions the html page calls
            * *****************************
            */
            // Selects a hotel, then get the device number
            // Then gets the device number of this hotel
            vm.selectHotel = function(hotel){
                // No deselect for this because we must at least select one hotel
                vm.currentHotelID = hotel.ID;
                // Then gets all related info of this hotel
                vm.getDevNum();
                vm.getGroupList();
                vm.getDevList();
            }
            // selects a group type
            vm.selectGroupType = function(type){
                if(type == vm.groupTypeFilter) {
                    // deselect
                    vm.groupTypeFilter = null;
                } else {
                    vm.groupTypeFilter = type;
                }
                vm.getDisplayTerminals();
            }
            vm.selectStatusFilter = function(data) {
                if (data.filterOptions == vm.statusFilter) {
                    // Deselect
                    vm.statusFilter = null
                } else {
                    vm.statusFilter = data.filterOptions;
                }
                vm.getDisplayTerminals();
            }

            vm.toggleSelectOff = function(data){
                data.selected = false;
                vm.selectedTerminals = vm.selectedTerminals.filter(function(e){
                    return e.ID != data.ID
                });
            }
            vm.toggleSelectOn = function(data) {
                data.selected = true;
                vm.selectedTerminals.push(data);
            }
            // Handles the select/deselect of a card-block
            vm.toggleSelect = function(data) {
                // This creates a selected field in data if not already
                // has it. This data is also binded with vm.res[] 's data
                // so changing this also modifies the list
                if (!data.selected) {
                    vm.toggleSelectOn(data);
                } else {
                    vm.toggleSelectOff(data);
                }
                // Update didSelectSomething
                vm.didSelectAnything();
            }
            // This deletes a term by its ID
            vm.delTerm = function(id) {
                var conf = confirm('确认删除终端？');
                if (!conf) {
                    return;
                }
                var data = {
                    "action": "delDev",
                    "token": util.getParams("token"),
                    "lang": vm.langStyle,
                    "ID": id
                }

                data = JSON.stringify(data);
                $http({
                    method: $filter('ajaxMethod')(),
                    url: util.getApiUrl('devinfo', '', 'server'),
                    data: data
                }).then(function successCallback(data, status, headers, config) {
                    if (data.data.rescode == '200') {
                        vm.getDevList();
                        vm.getDevNum(vm.form.HotelID, vm.hotelListIndex);
                    } else if (msg.rescode == '401') {
                        alert('访问超时，请重新登录');
                        $state.go('login');
                    } else {
                        alert(data.data.errInfo);
                    }
                }, function errorCallback(data, status, headers, config) {
                    alert('连接服务器出错');
                })
            }
            // This adds a new Term
            vm.addDev = function() {
                $scope.app.maskParams = {
                    'HotelID': vm.currentHotelID
                };
                $scope.app.showHideMask(true, 'pages/addDev.html');
            }
            // This removes selected terminals from group
            vm.removeFromGroup = function() {
                var conf = confirm('确认移出分组？');
                if (!conf) {
                    return;
                }
                var request = {
                    "action": "moveTermToGroup",
                    "token": util.getParams("token"),
                    "hotelID": vm.currentHotelID,
                    "termGroupID": -1,
                    // Only need their ids
                    "termID":vm.selectedTerminals.map(function(e){
                        return e.ID
                    })
                };
                vm.apiCall(request, 'termgroup').then(function(data){
                    if (data.data.rescode == '200') {
                        alert('移出分组成功!');
                        $state.reload();
                    } else if (data.data.rescode == '401') {
                        alert('访问超时，请重新登录');
                        $state.go('login');
                    } else {
                        alert('移出分组失败!' + data.data.errInfo);
                    }
                }, function (e) {
                    alert('服务器出错!');
                });
            }
            // This deletes a group type
            vm.deleteGroup = function(type) {
                var conf = confirm('确认删除分组:' + type.termGroupName + '?');
                if(!conf) return;
                var request = {
                    'action': 'delTermGroup',
                    'token': util.getParams('token'),
                    'hotelID': vm.currentHotelID,
                    'termGroupID': type.termGroupID
                }
                vm.apiCall(request, 'termgroup').then(function (data){
                    if (data.data.rescode == "200") {
                        alert('删除成功')
                        $state.reload();
                    } else if (data.data.rescode == "401") {
                        alert('访问超时，请重新登录');
                        $state.go('login')
                    } else {
                        alert('删除失败， ' + data.data.errInfo);
                    }
                }, function(err){
                    alert('删除失败， ' + data.data.errInfo);
                });
            }
            vm.showNewGroupModel = function() {
                // Use scope to pass parameters, what a move
                // This is like a global variable I guess.
                $scope.app.maskParams = {
                    callback: function(succ) {
                        if (succ) {
                            vm.clearSelected();
                            $state.reload();
                        }
                    },
                    HotelID: vm.currentHotelID,
                    selectedTerminals: vm.selectedTerminals
                }
                $scope.app.showHideMask(true, 'pages/terminal/newGroupModal.html');
            }

            vm.showMoveToGroupModal = function() {
                $scope.app.maskParams = {
                    hasGroup: vm.selectedTerminals.reduce(function(acc,val){
                        return val.TermGroupType != -1 || acc
                    }, false),
                    termID: vm.selectedTerminals.map(extractId),
                    HotelID: vm.currentHotelID,
                    groupID: vm.groupTypesOfHotel,
                    reloadPage: 'app.terminal'
                }
                $scope.app.showHideMask(true, 'pages/group/moveTerminal.html');
            }

            vm.showEditTermModal = function(termData) {
                $scope.app.maskParams = {
                    callback: function(succ) {
                        if (succ) {
                            $state.reload();
                        }
                    },
                    termData: termData
                }
                $scope.app.showHideMask(true, 'pages/terminal/editModal.html');
            }

            vm.showNewPushMessageModal = function() {
                var instance = $uibModal.open({
                    templateUrl: 'pages/terminal/pushNewMessageModal.html', controller: 'pushNewMessageModalController',
                    controllerAs: 'pc',
                    resolve: {
                        createNewScrollingMarquee: function(){
                            return vm.createNewScrollingMarquee;
                        },
                        createNewComments: function(){
                            return vm.createNewComments;
                        },
                        createNewPushMessage: function(){
                            return vm.createNewPushMessage;
                        },
                        selectedTerminals: function(){
                            return vm.selectedTerminals;
                        },
                        allGroupTypes: function(){
                            var c = vm.groupTypesOfHotel.map(function(e){
                              return {
                                value: e.termGroupID,
                                name: e.termGroupName
                              }
                            });
                            return [{
                                value: -1,
                                name: '全部'
                            }].concat(c);
                        }
                    }
                });

                instance.result.then(function(result){
                    // vm.createNewPushMessage(result);
                    vm.clearSelected();
                }, function(){
                    // dismiss case
                    console.log('newPushMessageModal Dissmissed');
                });
            }

            /* *************************
            * END OF HTML PAGE FUNCTIONS
            * **************************
            */
            vm.getTermsFromData = function(data){
                console.log(data);
                var myTerms;
                // If there were a targetedRooms, use that
                if (data.targetedRooms) {
                    myTerms = data.targetedRooms.map(extractId);
                } else if(data.groupType) {
                    if (data.groupType.value == -1) {
                        // sent to all
                        myTerms = vm.allTeriminals.map(extractId);
                    } else {
                        myTerms = vm.allTeriminals.filter(function(e){
                            return e.TermGroupType == data.groupType.value;
                        }).map(extractId);
                    }
                } else {
                    throw new Error('Unable to get Term Ids for pushing new Message');
                }
                return myTerms;
            }

            vm.createNewComments = function(data){
                var myTerms = vm.getTermsFromData(data);
                var request = {
                    'action': 'addRealTimeCmd',
                    'token': util.getParams('token'),
                    'data': {
                        'CmdType': 'hotelMessage',
                        'Terms': myTerms,
                        'CmdParas': {
                            'Content': data.comments,
                            'Date': util.getToday(),
                            'switch': 1
                        },
                        'Creator': util.getParams('userName')
                    }
                }
                return vm.apiCall(request, 'realtimecmd');
            }

            vm.createNewScrollingMarquee = function(data){
                var myTerms = vm.getTermsFromData(data);
                var st = util.format_dateTime(data.startTime);
                var et = util.format_dateTime(data.endTime);
                var request = {
                    'action': 'addRealTimeCmd',
                    'token': util.getParams('token'),
                    'data': {
                        'CmdType': 'ScrollingMarquee',
                        'Terms': myTerms,
                        'CmdParas': {
                            'Content': data.scrollingMarquee,
                            'startDate': st,
                            'endDate': et,
                            'Duration': data.singleDuration,
                            'Interval': data.interval,
                            'switch': 1
                        }
                    }
                };
                return vm.apiCall(request, 'realtimecmd');
            }

            vm.createNewPushMessage = function(data){
                var myTerms = vm.getTermsFromData(data);
                var st = util.format_dateTime(data.startTime);
                var et = util.format_dateTime(data.endTime);
                var request = {
                    'action': 'addRealTimeCmd',
                    'token': util.getParams('token'),
                    'data': {
                        'CmdType': 'picMessage',
                        'Terms': myTerms,
                        'CmdParas': {
                            'Title': data.title,
                            'Content': data.body,
                            'picUrl': data.imgUrl,
                            'startTime': st,
                            'endTime': et
                        },
                        'Creator': util.getParams('userName')
                    }
                };
                console.log(request);
                return vm.apiCall(request, 'realtimecmd');
            }


            vm.apiCall = function(req, uri) {
                return $http({
                    method: 'POST',
                    url: util.getApiUrl(uri),
                    data: JSON.stringify(req)
                });
            }
        }
    ]).controller('newGroupModalController', [
        '$scope',
        '$state',
        '$http',
        '$stateParams',
        '$translate',
        '$filter',
        '$timeout',
        'util',
        function($scope, $state, $http, $stateParams, $translate, $filter, $timeout, util) {
            var vm = this;
            function unauthorized () {
                alert('访问超时，请重新登录');
                $state.go('login')
            }
            function foo(prefix, info){
                alert(prefix+ '失败! ' + errInfo);
                vm.submitting = false;
            }

            vm.init = function() {
                console.log($scope.app.maskParams.selectedTerminals);
                // like a state hydration!
                vm.selectedTerminals = $scope.app.maskParams.selectedTerminals;
                vm.HotelID = $scope.app.maskParams.HotelID;
                vm.callback = $scope.app.maskParams.callback;
                vm.submitting = false;
            }
            vm.createGroupType = function(){
                var request = JSON.stringify({
                    "action": "setTermGroup",
                    "token": util.getParams("token"),
                    "hotelID": vm.HotelID,
                    "termGroupName":vm.form.groupName
                });
                return $http({
                    method: $filter('ajaxMethod')(),
                    url: util.getApiUrl('termgroup', 'addGroup.json', ''),
                    data: request
                });
            }
            vm.addTerminalsToGroup = function(groupId){

                var request = JSON.stringify({
                    "action": "moveTermToGroup",
                    "token": util.getParams("token"),
                    "hotelID": vm.HotelID,
                    "termGroupID":groupId,
                    // Only need their ids
                    "termID":vm.selectedTerminals.map(function(e){
                        return e.ID
                    })
                });
                $http({
                    method: $filter('ajaxMethod')(),
                    url: util.getApiUrl('termgroup', 'addGroup.json', ''),
                    data: request
                }).then(function (data, status, headers, config) {
                    if (data.data.rescode == "200") {
                        alert('添加成功');
                        vm.exitModal();
                    } else if (data.data.rescode == "401") {
                        unauthorized();
                    } else {
                        foo('添加终端', data.data.errInfo);
                    }
                }, function (data, status, headers, config) {
                    foo('添加终端', data.data.errInfo);
                }).finally(function (value) {
                    vm.submitting = false;
                });
            }
            vm.ok = function() {
                var hasGroup = vm.selectedTerminals.reduce(function(acc,val){
                    return val.TermGroupType != -1 || acc
                }, false);
                if (hasGroup) {
                    var conf = confirm('当前所选终端(部分/全部)已有分组, 确认移动到新的分组吗?');
                    if (!conf) {
                        vm.cancel();
                        return;
                    };
                }
                vm.submitting = true;
                // The real request
                vm.createGroupType().then(function (data){
                    if (data.data.rescode == "200") {
                        var gid = data.data.groupID;
                        vm.addTerminalsToGroup(gid);
                    } else if (data.data.rescode == "401") {
                        unauthorized();
                    } else {
                        foo('创建分组',data.data.errInfo)
                    }
                }, function (err){
                    foo('创建分组',data.data.errInfo)
                })
            };

            // a clean up function to exit modal elegantly
            vm.exitModal = function() {
                vm.cancel();
                vm.callback(true);
            }
            vm.cancel = function() {
                $scope.app.showHideMask(false);
            };
        }
    ]).controller('pushNewMessageModalController', [
        '$scope',
        '$uibModalInstance',
        '$timeout',
        'Upload',
        'CONFIG',
        'moment',
        'createNewScrollingMarquee',
        'createNewComments',
        'createNewPushMessage',
        'selectedTerminals',
        'allGroupTypes',
        function($scope, $uibModalInstance, $timeout, Upload, CONFIG, moment, createNewScrollingMarquee, createNewComments, createNewPushMessage, selectedTerminals, allGroupTypes) {
            var vm = this;

            vm.pushTypes = [{
                value: '1',
                name: '滚动字幕'
            },{
                value: '2',
                name: '酒店留言'
            },{
                value: '3',
                name: '客信弹窗'
            }];
            vm.buttonBar = {
                show: true,
                now: {text: '现在'},
                today: {text: '今天'},
                clear: {text: '清除'},
                date: {text: '返回选择日期'},
                time: {text: '选择时间'},
                close: {text: '确认'},
                cancel: {text: '取消'}
            }
            vm.init = function(){
                // initialization + default values injection
                vm.isStartCalendarOpen = false;
                vm.isEndCalendarOpen = false;

                var today = moment();
                var tomorrow = moment().add(1, 'day');

                vm.form = {
                    pushType: vm.pushTypes[2],
                    targetedRoomsID: null,
                    targetedRooms: null,
                    groupType: null,
                    // scrollingMarquee
                    scrollingMarquee: null,
                    singleDuration: null,
                    interval: null,
                    // comments 留言
                    comments: null,
                    // group message/客信弹窗
                    title: null,
                    body: null,
                    imgUrl: null,
                    // common
                    startTime: today.toDate(),
                    endTime: tomorrow.toDate(),
                };
                vm.file = null;
                vm.invalidFile = null;

                vm.uploading = false;
                vm.uploadProgress = 0;
                vm.allGroupTypes = allGroupTypes;
                if (selectedTerminals && selectedTerminals.length > 0) {
                    // Only dispatch messages to these terminals
                    vm.form.targetedRooms = selectedTerminals;
                    vm.form.targetedRoomsID = selectedTerminals.map(function(e){
                        return e.RoomID;
                    });
                }

            }

            vm.openCalendar = function(type){
                switch (type) {
                    case 'start':
                        vm.isStartCalendarOpen = true;
                        break;
                    case 'end':
                        vm.isEndCalendarOpen = true;
                        break;
                    default:
                        break;
                }
            }

            vm.selectPushType = function(data){
                vm.form.pushType = data;
            }

            vm.selectGroupType = function(data){
                vm.form.groupType = data;
            }

            vm.removeImg = function(){
                vm.form.imgUrl = null;
                vm.file = null;
            }
            vm.upload = function(){
                // wait update, this is so dumb
                $timeout(vm.uploadActual, 100);
            }
            vm.uploadActual = function(){
                console.log("INVALID FILE:", vm.invalidFile);
                console.log("FILE:", vm.file);
                if (vm.invalidFile) {
                    alert('文件必须小于1MB!');
                    return;
                }
                if (!vm.file){
                    return;
                }
                vm.uploading = true;
                Upload.upload({
                    url: CONFIG.uploadUrl,
                    data: {
                        file: vm.file
                    }
                }).then(function(resp){
                    if(resp.status == 200){
                        vm.form.imgUrl = resp.data.upload_path;
                        console.log('Successfully uploaded ' + resp.data.upload_path);
                    } else {
                        console.log(resp);
                    }
                }, function(err){
                    console.log(err);
                }, function(evt){
                    var progressPercentage = parseInt(100.0 * evt.loaded / evt.total);
                    vm.uploadProgress = progressPercentage;
                    console.log('progress: ' + progressPercentage + '% ' + evt.config.data.file.name);
                }).finally(function(){
                    vm.uploading = false;
                });
            }

            vm.ok = function(){
                switch (vm.form.pushType.value) {
                    case vm.pushTypes[0].value:
                        createNewScrollingMarquee(vm.form).then(vm.succ, vm.fail);
                        break;
                    case vm.pushTypes[1].value:
                        createNewComments(vm.form).then(vm.succ, vm.fail);
                        break;
                    case vm.pushTypes[2].value:
                        createNewPushMessage(vm.form).then(vm.succ, vm.fail);
                    default:
                        throw new Error('UNKNOWN PushType!');
                    return
                }
            }
            vm.succ = function(data){
                if (data.data.rescode == "200") {
                    alert('创建成功!')
                    $uibModalInstance.close();
                } else if (data.data.rescode == "401") {
                    alert('访问超时，请重新登录');
                    $state.go('login')
                } else {
                    alert('创建失败! ' + data.data.errInfo);
                }
            }

            vm.fail = function(data){
                alert('创建失败! ' + err.data.errInfo);
            }

            vm.cancel = function(){
                $uibModalInstance.dismiss('cancel');
            }
        }
    ])
})();
