'use strict';
(function () {
    var app = angular.module('app.report', [])
        .controller('reportFormController', ['$scope', '$http', '$state', 'util', '$filter', 'NgTableParams', '$location',
            function ($scope, $http, $state, util, $filter, NgTableParams, $location) {
                var self = this;
                self.init = function () {

                }
            }
        ])
        .controller('playLogController', ['$scope', '$http', '$state', 'util', '$filter', 'NgTableParams', '$location',
            function ($scope, $http, $state, util, $filter, NgTableParams, $location) {
                var self = this;
                self.init = function () {
                    self.downloading = false;
                    self.complete = false;
                    self.ticketDownloading = false;
                    self.ticketComplete = false;
                    /*
                    *  初始化日历插件
                    *  设置$scope.endTime,$scope.startTime
                    */
                    util.initRangeDatePicker($scope)
                    self.search();
                    console && console.log(self.searchDate, self.endDate);
                };
                /**
                 * datepiker
                 */
                self.open = function ($event) {
                    if ($event.target.className.indexOf('start') != -1) {
                        self.startOpened = true;
                        self.endOpened = false;
                    } else {
                        self.startOpened = false;
                        self.endOpened = true;
                    }
                };

                self.search = function () {
                    self.tableParams = new NgTableParams(
                        {
                            page: 1,
                            count: 10,
                            url: ''
                        },
                        {
                            counts: false,
                            getData: function (params) {
                                var paramsUrl = params.url();

                                var data = JSON.stringify({
                                    "page_index": paramsUrl.page - 0,
                                    "page_num": paramsUrl.count - 0,
                                    "action": "getMovieLogList",
                                    "token": util.getParams("token"),
                                    "projectList": [util.getParams("projectName")],
                                    "startDate": $scope.startTime ? $scope.startTime + ' 00:00:00' : null,
                                    "endDate": $scope.endTime ? $scope.endTime + ' 00:00:00' : null
                                });
                                self.loading = true;
                                self.noData = false;

                                return $http({
                                    method: 'POST',
                                    url: util.getApiUrl('luan/statistics', '', 'server'),
                                    data: data
                                }).then(function successCallback(response) {
                                    var data = response.data;
                                    if (data.rescode == '200') {
                                        if (data.movieLog.totleNum == 0) {
                                            self.noData = true;
                                        }
                                        params.total(data.movieLog.totleNum);
                                        return data.movieLog.logList;
                                    } else if (data.rescode == '401') {
                                        alert('访问超时，请重新登录');
                                        $state.go('login');
                                    } else {
                                        alert('获取商城订单列表失败，' + data.errInfo);
                                    }
                                }, function errorCallback(response) {
                                    alert('连接服务器出错');
                                }).finally(function (value) {
                                    self.loading = false;
                                });
                            }
                        }
                    );
                }

                // 获取报表数据
                self.getInfo = function () {
                    if (!util.resetTime($scope)) return; // 处理时间数据，31天限制
                    self.noData = false;
                    self.loading = true;
                    var qrcodeData = [], roomData = {}
                    var data = {
                        "page_index": 1, "page_num": 10,
                        "action": "getMovieLogList",
                        "token": util.getParams("token"),
                        "projectList": [util.getParams("projectName")],
                        "startDate": $scope.startTime ? $scope.startTime + ' 00:00:00' : null,
                        "endDate": $scope.endTime ? $scope.endTime + ' 00:00:00' : null
                    }
                    $http({
                        method: 'POST',
                        url: util.getApiUrl('luan/statistics', '', 'server'),
                        data: data
                    }).then(function successCallback(response) {
                        var data = response.data;
                        console.log(data.movieLog.logList, data.movieLog.totleNum)
                        if (data.rescode == '200') {

                        } else if (data.rescode == '401') {
                            alert('访问超时，请重新登录');
                            $state.go('login');
                        } else {
                            alert('获取失败' + data.errInfo);
                        }
                    }, function errorCallback(response) {
                        alert('连接服务器出错');
                    }).finally(function (value) {
                        self.saving = false;
                    });
                }
            }
        ])
        .controller('placeOrderFormController', ['$scope', '$http', '$state', 'util', '$filter', 'NgTableParams', '$location',
            function ($scope, $http, $state, util, $filter, NgTableParams, $location) {
                var self = this;
                self.init = function () {
                    self.downloading = false;
                    self.complete = false;
                    self.ticketDownloading = false;
                    self.ticketComplete = false;
                    /*
                    *  初始化日历插件
                    *  设置$scope.endTime,$scope.startTime
                    */
                    util.initRangeDatePicker($scope)
                    self.getInfo();
                    console && console.log(self.searchDate, self.endDate);
                };
                /**
                 * datepiker
                 */
                self.open = function ($event) {
                    if ($event.target.className.indexOf('start') != -1) {
                        self.startOpened = true;
                        self.endOpened = false;
                    } else {
                        self.startOpened = false;
                        self.endOpened = true;
                    }
                };
                // 获取报表数据
                self.getInfo = function () {
                    if (!util.resetTime($scope)) return; // 处理时间数据，31天限制
                    self.noData = false;
                    self.loading = true;
                    var qrcodeData = [], roomData = {}
                    var data = {
                        "action": "getOrderStatisXlsShow",
                        "token": util.getParams("token"),
                        "projectList": [util.getParams("projectName")],
                        "startDate": $scope.startTime ? $scope.startTime + ' 00:00:00' : null,
                        "endDate": $scope.endTime ? $scope.endTime + ' 00:00:00' : null
                    }
                    $http({
                        method: 'POST',
                        url: util.getApiUrl('luan/statistics', '', 'server'),
                        data: data
                    }).then(function successCallback(response) {
                        var data = response.data;
                        console.log(data)
                        if (data.rescode == '200') {
                            // 拼接扫码统计数据
                            for (var key1 in data.qrcode) {
                                var total1 = 0
                                for (var i = 0; i < data.qrcode[key1].length; i++) {
                                    total1 += data.qrcode[key1][i]
                                }
                                // array1.push(key1)
                                // array2.push(total1)
                                var item = {};
                                item[key1] = total1
                                qrcodeData.push(item)
                            }
                            // 扫码统计中的“总计”移动到最后
                            for (var k = 0; k < qrcodeData.length; k++) {
                                for (var key in qrcodeData[k]) {
                                    if (key == '总计') {
                                        qrcodeData.push(qrcodeData[k])
                                        qrcodeData.splice(k, 1);
                                    }
                                }
                            }
                            // 拼接订房统计数据
                            for (var key2 in data.room) {
                                var total2 = 0
                                for (var j = 0; j < data.room[key2].length; j++) {
                                    total2 += data.room[key2][j]
                                }
                                var item = {};
                                roomData[key2] = total2
                            }
                            self.qrcodeTable = qrcodeData
                            self.roomTable = roomData
                            self.loading = false
                        } else if (data.rescode == '401') {
                            alert('访问超时，请重新登录');
                            $state.go('login');
                        } else {
                            alert('获取失败' + data.errInfo);
                        }
                    }, function errorCallback(response) {
                        alert('连接服务器出错');
                    }).finally(function (value) {
                        self.saving = false;
                    });
                }

                // 获取下载链接
                self.export = function () {
                    if (!util.resetTime($scope)) return; // 处理时间数据，31天限制
                    self.sTime = $scope.startTime
                    self.eTime = $scope.endTime
                    self.downloading = true
                    self.complete = false
                    var data = {
                        "action": "getOrderStatisXlsLoad",
                        "token": util.getParams("token"),
                        "projectList": [util.getParams("projectName")],
                        "startDate": $scope.startTime ? $scope.startTime + ' 00:00:00' : null,
                        "endDate": $scope.endTime ? $scope.endTime + ' 00:00:00' : null
                    }
                    $http({
                        method: 'POST',
                        url: util.getApiUrl('luan/statistics', '', 'server'),
                        data: data
                    }).then(function successCallback(response) {
                        var data = response.data;
                        console.log(data)
                        if (data.rescode == '200') {
                            console.log(data)
                            self.downloadLink = data.file_url[0]
                            self.downloading = false
                            self.complete = true
                        } else if (data.rescode == '401') {
                            alert('访问超时，请重新登录');
                            $state.go('login');
                        } else {
                            alert('导出失败' + data.errInfo);
                        }
                    }, function errorCallback(response) {
                        alert('连接服务器出错，请重新导出');
                    }).finally(function (value) {
                        self.saving = false;
                    });
                }
            }
        ])
        .controller('orderCompleteController', ['$scope', '$http', '$state', 'util', '$filter', 'NgTableParams', '$location',
            function ($scope, $http, $state, util, $filter, NgTableParams, $location) {
                var self = this;
                self.init = function () {
                    self.downloading = false;
                    self.complete = false;
                    self.ticketDownloading = false;
                    self.ticketComplete = false;
                    /*
                    *  初始化日历插件
                    *  设置$scope.endTime,$scope.startTime
                    */
                    util.initRangeDatePicker($scope)

                    self.getInfo();
                };
                /**
                 * datepiker
                 */
                self.open = function ($event) {
                    if ($event.target.className.indexOf('start') != -1) {
                        self.startOpened = true;
                        self.endOpened = false;
                    } else {
                        self.startOpened = false;
                        self.endOpened = true;
                    }
                };
                // 获取报表数据
                self.getInfo = function () {
                    if (!util.resetTime($scope)) return; // 处理时间数据，31天限制
                    self.noData = false;
                    self.loading = true;
                    var qrcodeData = {}, roomData = {}

                    var data = {
                        "action": "getCompleteStatisXlsShow",
                        "token": util.getParams("token"),
                        "projectList": [util.getParams("projectName")],
                        "startDate": $scope.startTime ? $scope.startTime + ' 00:00:00' : null,
                        "endDate": $scope.endTime ? $scope.endTime + ' 00:00:00' : null
                    }
                    $http({
                        method: 'POST',
                        url: util.getApiUrl('luan/statistics', '', 'server'),
                        data: data
                    }).then(function successCallback(response) {
                        var data = response.data;
                        console.log(data)
                        if (data.rescode == '200') {
                            // 拼接订房统计数据
                            for (var key2 in data.room) {
                                var total2 = 0
                                for (var j = 0; j < data.room[key2].length; j++) {
                                    total2 += data.room[key2][j]
                                }
                                roomData[key2] = total2
                            }
                            self.roomTable = roomData
                            self.loading = false
                        } else if (data.rescode == '401') {
                            alert('访问超时，请重新登录');
                            $state.go('login');
                        } else {
                            alert('获取失败' + data.errInfo);
                        }
                    }, function errorCallback(response) {
                        alert('连接服务器出错');
                    }).finally(function (value) {
                        self.saving = false;
                    });
                }

                // 获取下载链接
                self.export = function () {
                    if (!util.resetTime($scope)) return; // 处理时间数据，31天限制
                    self.sTime = $scope.startTime
                    self.eTime = $scope.endTime
                    self.downloading = true
                    self.complete = false
                    var data = {
                        "action": "getCompleteStatisXlsLoad",
                        "token": util.getParams("token"),
                        "projectList": [util.getParams("projectName")],
                        "startDate": $scope.startTime ? $scope.startTime + ' 00:00:00' : null,
                        "endDate": $scope.endTime ? $scope.endTime + ' 00:00:00' : null
                    }
                    $http({
                        method: 'POST',
                        url: util.getApiUrl('luan/statistics', '', 'server'),
                        data: data
                    }).then(function successCallback(response) {
                        var data = response.data;
                        console.log(data)
                        if (data.rescode == '200') {
                            console.log(data)
                            self.downloadLink = data.file_url[0]
                            self.downloading = false
                            self.complete = true
                        } else if (data.rescode == '401') {
                            alert('访问超时，请重新登录');
                            $state.go('login');
                        } else {
                            alert('导出失败' + data.errInfo);
                        }
                    }, function errorCallback(response) {
                        alert('连接服务器出错，请重新导出');
                    }).finally(function (value) {
                        self.saving = false;
                    });
                }

                // 获取下载链接
                self.exportTicketReport = function () {
                    if (!util.resetTime($scope)) return; // 处理时间数据，31天限制
                    self.sTime2 = $scope.startTime
                    self.eTime2 = $scope.endTime
                    self.ticketDownloading = true
                    self.ticketComplete = false
                    var data = {
                        "action": "getTicketStatisXlsLoad",
                        "token": util.getParams("token"),
                        "projectList": [util.getParams("projectName")],
                        "startDate": $scope.startTime ? $scope.startTime + ' 00:00:00' : null,
                        "endDate": $scope.endTime ? $scope.endTime + ' 00:00:00' : null
                    }
                    $http({
                        method: 'POST',
                        url: util.getApiUrl('luan/statistics', '', 'server'),
                        data: data
                    }).then(function successCallback(response) {
                        var data = response.data;
                        console.log(data)
                        if (data.rescode == '200') {
                            console.log(data)
                            self.ticketDownloadLink = data.file_url[0]
                            self.ticketDownloading = false
                            self.ticketComplete = true
                        } else if (data.rescode == '401') {
                            alert('访问超时，请重新登录');
                            $state.go('login');
                        } else {
                            alert('修改失败' + data.errInfo);
                        }
                    }, function errorCallback(response) {
                        alert('连接服务器出错，请重新导出');
                    }).finally(function (value) {
                        self.saving = false;
                    });
                }
            }
        ])
        .controller('movieIncomeController', ['$scope', '$http', '$state', 'util', '$filter', 'NgTableParams', '$location',
            function ($scope, $http, $state, util, $filter, NgTableParams, $location) {
                var self = this;
                self.init = function () {
                    self.downloading = false;
                    self.complete = false;
                    self.ticketDownloading = false;
                    self.ticketComplete = false;
                    /*
                    *  初始化日历插件
                    *  设置$scope.endTime,$scope.startTime
                    */
                    util.initRangeDatePicker($scope)
                    self.getInfo();
                    console && console.log(self.searchDate, self.endDate);
                };

                // 获取报表数据
                self.getInfo = function () {
                    if (!util.resetTime($scope)) return; // 处理时间数据，31天限制
                    self.noData = false;
                    self.loading = true;
                    var qrcodeData = [], roomData = []
                    var data = {
                        "action": "getMovieOrderStatisXlsShow",
                        "token": util.getParams("token"),
                        "projectList": [util.getParams("projectName")],
                        "startDate": $scope.startTime ? $scope.startTime + ' 00:00:00' : null,
                        "endDate": $scope.endTime ? $scope.endTime + ' 00:00:00' : null
                    }
                    $http({
                        method: 'POST',
                        url: util.getApiUrl('luan/statistics', '', 'server'),
                        data: data
                    }).then(function successCallback(response) {
                        var data = response.data;
                        if (data.rescode == '200') {
                            self.movieData = data.movie['总计']
                            console.log(self.movieData)
                            self.loading = false
                        } else if (data.rescode == '401') {
                            alert('访问超时，请重新登录');
                            $state.go('login');
                        } else {
                            alert('获取失败' + data.errInfo);
                        }
                    }, function errorCallback(response) {
                        alert('连接服务器出错');
                    }).finally(function (value) {
                        self.saving = false;
                    });
                }

                // 获取下载链接
                self.export = function () {
                    if (!util.resetTime($scope)) return; // 处理时间数据，31天限制
                    self.sTime = $scope.startTime
                    self.eTime = $scope.endTime
                    self.downloading = true
                    self.complete = false
                    var data = {
                        "action": "getMovieOrderStatisXlsLoad",
                        "token": util.getParams("token"),
                        "projectList": [util.getParams("projectName")],
                        "startDate": $scope.startTime ? $scope.startTime + ' 00:00:00' : null,
                        "endDate": $scope.endTime ? $scope.endTime + ' 00:00:00' : null
                    }
                    $http({
                        method: 'POST',
                        url: util.getApiUrl('luan/statistics', '', 'server'),
                        data: data
                    }).then(function successCallback(response) {
                        var data = response.data;
                        if (data.rescode == '200') {
                            console.log(data)
                            self.downloadLink = data.file_url[0]
                            self.downloading = false
                            self.complete = true
                        } else if (data.rescode == '401') {
                            alert('访问超时，请重新登录');
                            $state.go('login');
                        } else {
                            alert('导出失败' + data.errInfo);
                        }
                    }, function errorCallback(response) {
                        alert('连接服务器出错，请重新导出');
                    }).finally(function (value) {
                        self.saving = false;
                    });
                }
            }
        ])
        .controller('prepayOrderFormController', ['$scope', '$http', '$state', 'util', '$filter', 'NgTableParams', '$location',
            function ($scope, $http, $state, util, $filter, NgTableParams, $location) {
                var self = this;
                self.init = function () {
                    self.downloading = false;
                    self.complete = false;
                    self.ticketDownloading = false;
                    self.ticketComplete = false;
                    /*
                    *  初始化日历插件
                    *  设置$scope.endTime,$scope.startTime
                    */
                    util.initRangeDatePicker($scope)
                    self.getInfo();
                    console && console.log(self.searchDate, self.endDate);
                };

                // 获取报表数据
                self.getInfo = function () {
                    if (!util.resetTime($scope)) return; // 处理时间数据，31天限制
                    self.tableParams = new NgTableParams(
                        {
                            page: 1,
                            count: 15,
                            url: ''
                        },
                        {
                            counts: false,
                            getData: function (params) {
                                var paramsUrl = params.url();
                                var data = JSON.stringify({
                                    "action": "getFXShopOrderStatisXlsShow",
                                    "token": util.getParams("token"),
                                    "projectList": [util.getParams("projectName")],
                                    "startDate": $scope.startTime ? $scope.startTime + ' 00:00:00' : null,
                                    "endDate": $scope.endTime ? $scope.endTime + ' 00:00:00' : null,
                                    "page": paramsUrl.page - 0,
                                    "per_page": paramsUrl.count - 0
                                });
                                self.loading = true;
                                self.noData = false;

                                return $http({
                                    method: 'POST',
                                    url: util.getApiUrl('luan/statistics', '', 'server'),
                                    data: data
                                }).then(function successCallback(response) {
                                    var data = response.data;
                                    if (data.rescode == '200') {
                                        if (data.dateList.length == 0) {
                                            self.noData = true;
                                        } else {
                                            params.total(data.fxshop['总计']['总记录数']);
                                            var deatailData = data.fxshop['明细']
                                            deatailData.datelist = data.dateList
                                            var tableData = []
                                            for (var i = 0; i < data.dateList.length; i++) {
                                                var item = {}
                                                item.date = deatailData.datelist[i]
                                                item.orderNum = deatailData['订单数'][i]
                                                item.orderTotalIncome = deatailData['订单总收入（元）'][i]
                                                item.Commission = deatailData['推广佣金（元）'][i]
                                                item.serviceCharge = deatailData['服务费（元）'][i]
                                                item.realIncome = deatailData['订单实收入（元）'][i]
                                                tableData.push(item)
                                                console.log(item.serviceCharge)
                                            }
                                        }
                                        // tableData = R.sortBy(R.prop('date'))(tableData)
                                        self.totalData = data.fxshop['总计']
                                        return tableData;
                                    } else if (data.rescode == '401') {
                                        alert('访问超时，请重新登录');
                                        $state.go('login');
                                    } else {
                                        alert('获取列表失败，' + data.errInfo);
                                    }
                                }, function errorCallback(response) {
                                    alert('连接服务器出错');
                                }).finally(function (value) {
                                    self.loading = false;
                                });
                            }
                        }
                    );
                    // var qrcodeData = [], roomData = []
                    // var data = {
                    //     "action": "getMovieOrderStatisXlsShow",
                    //     "token": util.getParams("token"),
                    //     "projectList": [util.getParams("projectName")],
                    //     "startDate": $scope.startTime ? $scope.startTime + ' 00:00:00' : null,
                    //     "endDate": $scope.endTime ? $scope.endTime + ' 00:00:00' : null
                    // }
                    // $http({
                    //     method: 'POST',
                    //     url: util.getApiUrl('luan/statistics', '', 'server'),
                    //     data: data
                    // }).then(function successCallback (response) {
                    //     var data = response.data;
                    //     if (data.rescode == '200') {
                    //         self.movieData = data.movie['总计']
                    //         console.log(self.movieData)
                    //         self.loading = false
                    //     } else if (data.rescode == '401') {
                    //         alert('访问超时，请重新登录');
                    //         $state.go('login');
                    //     } else {
                    //         alert('获取失败' + data.errInfo);
                    //     }
                    // }, function errorCallback (response) {
                    //     alert('连接服务器出错');
                    // }).finally(function (value) {
                    //     self.saving = false;
                    // });
                }

                // 获取下载链接
                self.export = function () {
                    if (!util.resetTime($scope)) return; // 处理时间数据，31天限制
                    self.sTime = $scope.startTime
                    self.eTime = $scope.endTime
                    self.downloading = true
                    self.complete = false
                    var data = {
                        "action": "getFXShopOrderStatisXlsLoad",
                        "token": util.getParams("token"),
                        "projectList": [util.getParams("projectName")],
                        "startDate": $scope.startTime ? $scope.startTime + ' 00:00:00' : null,
                        "endDate": $scope.endTime ? $scope.endTime + ' 00:00:00' : null
                    }
                    $http({
                        method: 'POST',
                        url: util.getApiUrl('luan/statistics', '', 'server'),
                        data: data
                    }).then(function successCallback(response) {
                        var data = response.data;
                        if (data.rescode == '200') {
                            console.log(data)
                            self.downloadLink = data.file_url[0]
                            self.downloading = false
                            self.complete = true
                        } else if (data.rescode == '401') {
                            alert('访问超时，请重新登录');
                            $state.go('login');
                        } else {
                            alert('导出失败' + data.errInfo);
                        }
                    }, function errorCallback(response) {
                        alert('连接服务器出错，请重新导出');
                    }).finally(function (value) {
                        self.saving = false;
                    });
                }
            }
        ])
        .controller('pageStayInfoController', ['$scope', '$http', '$state', 'util', '$filter', 'NgTableParams', '$location',
            function ($scope, $http, $state, util, $filter, NgTableParams, $location) {
                var self = this;
                self.init = function () {
                    self.downloading = false;
                    self.complete = false;
                    self.ticketDownloading = false;
                    self.ticketComplete = false;
                    /*
                    *  初始化日历插件
                    *  设置$scope.endTime,$scope.startTime
                    */
                    util.initRangeDatePicker($scope)
                    self.getInfo();
                    console && console.log(self.searchDate, self.endDate);
                };

                // 获取报表数据
                self.getInfo = function () {
                    if (!util.resetTime($scope)) return; // 处理时间数据，31天限制
                    self.noData = false;
                    self.loading = true;
                    var data = {
                        "action": "statis_page_stay",
                        "project": util.getParams("projectName"),
                        // "project":'atour',
                        "start_date": $scope.startTime ? $scope.startTime : null,
                        "end_date": $scope.endTime ? $scope.endTime : null
                    }
                    $http({
                        method: 'POST',
                        url: 'http://maintaining.cleartv.cn/backend_vodlogmgt/query',
                        data: data
                    }).then(function successCallback(response) {
                        var data = response.data;
                        if (data.code == '200') {
                            self.tableData = data.records.slice(0, data.records.length-1)
                            console.log(self.movieData)
                            self.loading = false
                        } else if (data.rescode == '401') {
                            alert('访问超时，请重新登录');
                            $state.go('login');
                        } else {
                            alert('获取失败' + data.errInfo);
                        }
                    }, function errorCallback(response) {
                        alert('连接服务器出错');
                    }).finally(function (value) {
                        self.saving = false;
                    });
                }

                // 获取下载链接
                self.export = function () {
                    if (!util.resetTime($scope)) return; // 处理时间数据，31天限制
                    self.sTime = $scope.startTime
                    self.eTime = $scope.endTime
                    self.downloading = true
                    self.complete = false
                    var data = {
                        "action": "getMovieOrderStatisXlsLoad",
                        "token": util.getParams("token"),
                        "projectList": [util.getParams("projectName")],
                        "startDate": $scope.startTime ? $scope.startTime + ' 00:00:00' : null,
                        "endDate": $scope.endTime ? $scope.endTime + ' 00:00:00' : null
                    }
                    $http({
                        method: 'POST',
                        url: util.getApiUrl('luan/statistics', '', 'server'),
                        data: data
                    }).then(function successCallback(response) {
                        var data = response.data;
                        if (data.rescode == '200') {
                            console.log(data)
                            self.downloadLink = data.file_url[0]
                            self.downloading = false
                            self.complete = true
                        } else if (data.rescode == '401') {
                            alert('访问超时，请重新登录');
                            $state.go('login');
                        } else {
                            alert('导出失败' + data.errInfo);
                        }
                    }, function errorCallback(response) {
                        alert('连接服务器出错，请重新导出');
                    }).finally(function (value) {
                        self.saving = false;
                    });
                }
            }
        ])
        .controller('movieFeverController', ['$scope', '$http', '$state', 'util', '$filter', 'NgTableParams', '$location',
            function ($scope, $http, $state, util, $filter, NgTableParams, $location) {
                var self = this;
                self.init = function () {
                    self.downloading = false;
                    self.complete = false;
                    self.ticketDownloading = false;
                    self.ticketComplete = false;
                    self.isActive = false;
                    /*
                    *  初始化日历插件
                    *  设置$scope.endTime,$scope.startTime
                    */
                    util.initRangeDatePicker($scope)
                    self.search();
                };

                /**
                 * datepiker
                 */
                self.open = function ($event) {
                    if ($event.target.className.indexOf('start') != -1) {
                        self.startOpened = true;
                        self.endOpened = false;
                    } else {
                        self.startOpened = false;
                        self.endOpened = true;
                    }
                };

                self.search = function () {
                    self.tableParams = new NgTableParams(
                        {
                            page: 1,
                            count: 10,
                            url: ''
                        },
                        {
                            counts: false,
                            getData: function (params) {
                                var paramsUrl = params.url();

                                var data = JSON.stringify({
                                    "action": "statis_live",
                                    "start_date": $scope.startTime ? $scope.startTime : null,
                                    "end_date": $scope.endTime ? $scope.endTime : null,
                                    "type": "Movie",
                                    "project": util.getParams("projectName"),
                                    "token": util.getParams("token")
                                });
                                self.loading = true;
                                self.noData = false;

                                return $http({
                                    method: 'POST',
                                    url: 'http://maintaining.cleartv.cn/backend_vodlogmgt/query',
                                    // url: util.getApiUrl('luan/statistics', '', 'server'),
                                    data: data
                                }).then(function successCallback(response) {
                                    var data = response.data;
                                    if (data.code == '200') {
                                        self.tableData = data.records.slice(0, data.records.length-1)
                                        self.loading = false
                                    } else if (data.code == '401') {
                                        alert('访问超时，请重新登录');
                                        $state.go('login');
                                    } else {
                                        alert('获取列表失败，' + data.errInfo);
                                    }
                                }, function errorCallback(response) {
                                    alert('连接服务器出错');
                                }).finally(function (value) {
                                    self.loading = false;
                                });
                            }
                        }
                    );
                }

            }
        ])
        .controller('liveTVController', ['$scope', '$http', '$state', 'util', '$filter', 'NgTableParams', '$location',
            function ($scope, $http, $state, util, $filter, NgTableParams, $location) {
                var self = this;
                self.init = function () {
                    self.downloading = false;
                    self.complete = false;
                    self.ticketDownloading = false;
                    self.ticketComplete = false;
                    self.isActive = false;
                    /*
                    *  初始化日历插件
                    *  设置$scope.endTime,$scope.startTime
                    */
                    util.initRangeDatePicker($scope)
                    self.search();
                };

                /**
                 * datepiker
                 */
                self.open = function ($event) {
                    if ($event.target.className.indexOf('start') != -1) {
                        self.startOpened = true;
                        self.endOpened = false;
                    } else {
                        self.startOpened = false;
                        self.endOpened = true;
                    }
                };

                self.search = function () {
                    self.tableParams = new NgTableParams(
                        {
                            page: 1,
                            count: 10,
                            url: ''
                        },
                        {
                            counts: false,
                            getData: function (params) {
                                var paramsUrl = params.url();

                                var data = JSON.stringify({
                                    "action": "statis_live",
                                    "start_date": $scope.startTime ? $scope.startTime : null,
                                    "end_date": $scope.endTime ? $scope.endTime : null,
                                    "type": "Live",
                                    "project": util.getParams("projectName"),
                                    "token": util.getParams("token")
                                });
                                self.loading = true;
                                self.noData = false;

                                return $http({
                                    method: 'POST',
                                    url: 'http://maintaining.cleartv.cn/backend_vodlogmgt/query',
                                    // url: util.getApiUrl('luan/statistics', '', 'server'),
                                    data: data
                                }).then(function successCallback(response) {
                                    var data = response.data;
                                    if (data.code == '200') {
                                        self.tableData = data.records.slice(0, data.records.length-1)
                                        self.loading = false
                                    } else if (data.code == '401') {
                                        alert('访问超时，请重新登录');
                                        $state.go('login');
                                    } else {
                                        alert('获取列表失败，' + data.errInfo);
                                    }
                                }, function errorCallback(response) {
                                    alert('连接服务器出错');
                                }).finally(function (value) {
                                    self.loading = false;
                                });
                            }
                        }
                    );
                }

            }
        ])
        .controller('activeTeController', ['$scope', '$http', '$state', 'util', '$filter', 'NgTableParams', '$location', '$document', '$timeout',
            function ($scope, $http, $state, util, $filter, NgTableParams, $location, $document, $timeout) {
                var self = this;
                self.init = function () {
                    self.downloading = false;
                    self.complete = false;
                    self.ticketDownloading = false;
                    self.ticketComplete = false;
                    self.isActive = false;
                    /*
                    *  初始化日历插件
                    *  设置$scope.endTime,$scope.startTime
                    */
                    util.initRangeDatePicker($scope)
                    self.search();
                };

                function initChart(data) {
                    $timeout(function () {
                        // 基于准备好的dom，初始化echarts实例
                        var myChart = echarts.init(document.getElementById('charts'));

                        var series = [];
                        var le = data[0].length - 1;
                        while (le--) {
                            series.push({
                                type: "line",
                                smooth: true,
                                symbol: "circle",
                                symbolSize: 8, //标记的大小
                            })

                        }
                        var option = {
                            legend: {
                                textStyle: {
                                    color: "#000"
                                }
                            },
                            // backgroundColor: "#1D4160",
                            color: ["#9E87FF", '#73DDFF', '#fe9a8b', '#F56948', '#9E87FF'],
                            tooltip: {
                                trigger: 'axis',
                                axisPointer: { // 坐标轴指示器，坐标轴触发有效
                                    type: 'line' // 默认为直线，可选为：'line' | 'shadow'
                                }
                            },
                            grid: {
                                left: "10%",
                                right: "10%",
                                bottom: "40",
                                containLabel: true
                            },
                            xAxis: [{
                                type: 'category',
                                // axisLine: {
                                //     lineStyle: {
                                //         color: "#D0E4E3"
                                //     }
                                // },
                                axisTick: {
                                    show: false
                                },
                                axisLabel: {
                                    show: true
                                    // textStyle: {
                                    //     color: "#D0E4E3" // X轴文字颜色
                                    // }
                                },
                            }],
                            yAxis: [{
                                name: "终端数(个)",
                                // nameTextStyle: {
                                //     color: "#D0E4E3"
                                // },
                                // splitLine: {
                                //     lineStyle: {
                                //         color: "#2C577C"
                                //     }
                                // },
                                axisTick: {
                                    show: false
                                },
                                axisLine: {
                                    show: false
                                },
                                axisLabel: {
                                    show: true
                                    // textStyle: {
                                    //     color: "#D0E4E3" // X轴文字颜色
                                    // }
                                },
                            }],
                            dataZoom: [{
                                show: true,
                                height: 14,
                                xAxisIndex: [0],
                                bottom: 15,
                                start: 10,
                                end: 60,
                                handleIcon: "path://M306.1,413c0,2.2-1.8,4-4,4h-59.8c-2.2,0-4-1.8-4-4V200.8c0-2.2,1.8-4,4-4h59.8c2.2,0,4,1.8,4,4V413z",
                                handleSize: "110%",
                                // handleStyle: {
                                //     color: "#fff"
                                // },
                                // textStyle: {
                                //     color: "#fff"
                                // },
                                borderColor: "#376a96"
                            }],
                            dataset: {
                                source: data
                            },
                            series: series
                        };
                        // 使用刚指定的配置项和数据显示图表。
                        myChart.setOption(option);

                        window.onresize = function () {
                            myChart.resize();
                        }

                    }, 0)
                }
                /**
                 * datepiker
                 */
                self.open = function ($event) {
                    if ($event.target.className.indexOf('start') != -1) {
                        self.startOpened = true;
                        self.endOpened = false;
                    } else {
                        self.startOpened = false;
                        self.endOpened = true;
                    }
                };

                self.search = function () {
                    self.tableParams = new NgTableParams(
                        {
                            page: 1,
                            count: 10,
                            url: ''
                        },
                        {
                            counts: false,
                            getData: function (params) {
                                var paramsUrl = params.url();

                                var data = JSON.stringify({
                                    "action": "get_term_day_online",
                                    // "action": "get_term_hour_online",
                                    "token": util.getParams("token"),
                                    "projectList": [util.getParams("projectName")],
                                    "startDate": $scope.startTime ? $scope.startTime + ' 00:00:00' : null,
                                    "endDate": $scope.endTime ? $scope.endTime + ' 00:00:00' : null
                                });
                                self.loading = true;
                                self.noData = false;

                                return $http({
                                    method: 'POST',
                                    url: util.getApiUrl('luan/statistics', '', 'server'),
                                    data: data
                                }).then(function successCallback(response) {
                                    var data = response.data;
                                    if (data.rescode == '200') {
                                        if (data.dayData == 0) {
                                            self.noData = true;
                                        }
                                        params.total(data.dayData);

                                        var chartData = data.dayData.map(function (item) {
                                            return [item.date, item.totalCount, item.onlineCount]
                                        })
                                        chartData.unshift(['/', '总数', '上线'])

                                        initChart(chartData)
                                        return data.dayData;
                                    } else if (data.rescode == '401') {
                                        alert('访问超时，请重新登录');
                                        $state.go('login');
                                    } else {
                                        alert('获取商城订单列表失败，' + data.errInfo);
                                    }
                                }, function errorCallback(response) {
                                    alert('连接服务器出错');
                                }).finally(function (value) {
                                    self.loading = false;
                                });
                            }
                        }
                    );
                }
            }
        ])
        .controller('overViewController', ['$scope', '$http', '$state', 'util', '$filter', 'NgTableParams', '$location', '$document', '$timeout',
            function ($scope, $http, $state, util, $filter, NgTableParams, $location, $document, $timeout) {
                var self = this;
                self.init = function () {
                    self.downloading = false;
                    self.complete = false;
                    self.ticketDownloading = false;
                    self.ticketComplete = false;
                    /*
                    *  初始化日历插件
                    *  设置$scope.endTime,$scope.startTime
                    */
                    util.initRangeDatePicker($scope)
                    self.hourActiveTe();
                    self.activeTe();
                    self.pageStayInfo();
                    self.movieFever();
                    self.liveTV();
                };
                /**
                 * datepiker
                 */

                self.toActiveTe = function () {
                    $state.go('app.reportForm.activeTe');
                };
                self.toPageStayInfo = function () {
                    $state.go('app.reportForm.pageStayInfo');
                };
                self.toMovieFever = function () {
                    $state.go('app.reportForm.movieFever');
                };
                self.toLiveTV = function () {
                    $state.go('app.reportForm.liveTV');
                };
            


                // 终端24小时在线折线图
                function hourActiveTeChart(yData) {
                    $timeout(function () {
                        // 基于准备好的dom，初始化echarts实例
                        var myChart = echarts.init(document.getElementById('hourActiveTe'));

                        var xData = ['0：00', '1：00', '2：00', '3：00', '4：00', '5：00', '6：00', '7：00', '8：00', '9：00', '10：00', '11：00', '12：00', '13：00', '14：00', '15：00', '16：00', '17：00', '18：00', '19：00', '20：00', '21：00', '22：00', '23：00']

                        var option = {
                            backgroundColor: '#fff',
                            grid: {
                                left: '10%',
                                right: '10%',
                                top: '5%',
                                bottom: '15%',
                                containLabel: true
                            },
                            tooltip: {
                                trigger: 'axis',
                                axisPointer: {
                                    lineStyle: {
                                        color: '#57617B'
                                    }
                                }
                            },
                            xAxis: {
                                type: 'category',
                                boundaryGap: false,
                                data: xData
                            },
                            yAxis: {
                                type: 'value',
                                boundaryGap: [0, '10%']
                            },
                            dataZoom: [{
                                type: 'inside',
                                start: 0,
                                end: 50
                            }, {
                                start: 0,
                                end: 50,
                                handleIcon: 'M10.7,11.9v-1.3H9.3v1.3c-4.9,0.3-8.8,4.4-8.8,9.4c0,5,3.9,9.1,8.8,9.4v1.3h1.3v-1.3c4.9-0.3,8.8-4.4,8.8-9.4C19.5,16.3,15.6,12.2,10.7,11.9z M13.3,24.4H6.7V23h6.6V24.4z M13.3,19.6H6.7v-1.4h6.6V19.6z',
                                handleSize: '80%',
                                handleStyle: {
                                    color: '#fff',
                                    shadowBlur: 3,
                                    shadowColor: 'rgba(0, 0, 0, 0.5)',
                                    shadowOffsetX: 2,
                                    shadowOffsetY: 2
                                }
                            }],
                            series: [{
                                name: '在线终端数',
                                type: 'line',
                                smooth: false,
                                symbol: 'circle',
                                symbolSize: 3,
                                showSymbol: false,
                                lineStyle: {
                                    normal: {
                                        width: 1
                                    }
                                },
                                areaStyle: {
                                    normal: {
                                        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                                            offset: 0,
                                            color: 'rgba(223, 190, 73, 1)'
                                        }, {
                                            offset: 1,
                                            color: 'rgba(223, 190, 73,0)'
                                        }], false),
                                        shadowColor: 'rgba(0, 0, 0, 0.1)',
                                        shadowBlur: 10
                                    }
                                },
                                itemStyle: {
                                    normal: {
                                        color: 'rgb(223,190,73)',
                                        borderColor: 'rgba(223,190,73,0.3)',
                                        borderWidth: 12

                                    }
                                },
                                data: yData
                            }]
                        };
                        // 使用刚指定的配置项和数据显示图表。
                        myChart.setOption(option);

                        window.addEventListener('resize',function () {
                            myChart.resize();
                        })
                    }, 0)
                }

                // 终端每天在线总数折线图
                function activeTeChart(data) {
                    $timeout(function () {
                        // 基于准备好的dom，初始化echarts实例
                        var myChart = echarts.init(document.getElementById('activeTe'));

                        var series = [];
                        var le = data[0].length - 1;
                        while (le--) {
                            series.push({
                                type: "line",
                                smooth: true,
                                symbol: "circle",
                                symbolSize: 8, //标记的大小
                            })

                        }
                        var option = {
                            legend: {
                                textStyle: {
                                    color: "#000"
                                }
                            },
                            color: ["#9E87FF", '#73DDFF', '#fe9a8b', '#F56948', '#9E87FF'],
                            tooltip: {
                                trigger: 'axis',
                                axisPointer: { // 坐标轴指示器，坐标轴触发有效
                                    type: 'line' // 默认为直线，可选为：'line' | 'shadow'
                                }
                            },
                            grid: {
                                left: "10%",
                                right: "10%",
                                bottom: "40",
                                containLabel: true
                            },
                            xAxis: [{
                                type: 'category',
                                axisTick: {
                                    show: false
                                },
                                axisLabel: {
                                    show: true
                                },
                            }],
                            yAxis: [{
                                name: "终端数(个)",
                                axisTick: {
                                    show: false
                                },
                                axisLine: {
                                    show: false
                                },
                                axisLabel: {
                                    show: true
                                },
                            }],
                            dataZoom: [{
                                show: true,
                                height: 14,
                                xAxisIndex: [0],
                                bottom: 15,
                                startValue: 24,
                                endValue: 30,
                                handleIcon: "path://M306.1,413c0,2.2-1.8,4-4,4h-59.8c-2.2,0-4-1.8-4-4V200.8c0-2.2,1.8-4,4-4h59.8c2.2,0,4,1.8,4,4V413z",
                                handleSize: "110%",
                                borderColor: "#376a96"
                            }],
                            dataset: {
                                source: data
                            },
                            series: series
                        };
                        // 使用刚指定的配置项和数据显示图表。
                        myChart.setOption(option);
                        window.addEventListener('resize',function () {
                            myChart.resize();
                        })

                    }, 0)
                }

                // 模块热度饼图
                function pageStayInfoChart(sData, lData) {
                    $timeout(function () {
                        // 基于准备好的dom，初始化echarts实例
                        var myChart = echarts.init(document.getElementById('pageStayInfo'));
                        var colorList = ['#47A2FF ', '#53C8D1', '#59CB74', '#FBD444', '#7F6AAD', '#EC7FBC'];
                        var option = {
                            title: {
                                x: 'center',
                                y: 'center'
                            },
                            tooltip: {
                                trigger: 'item',
                                borderColor: 'rgba(255,255,255,.3)',
                                backgroundColor: 'rgba(13,5,30,.6)',
                                borderWidth: 1,
                                padding: 5,
                                formatter: function (parms) {
                                    var str = parms.marker + "" + parms.data.name + "</br>" +
                                        "个数：" + parms.data.value + "</br>" +
                                        "占比：" + Number(parms.data.percent*100).toFixed(1) + "%";
                                    return str;
                                }
                            },
                            legend: {
                                icon: 'circle',
                                orient: 'vertical',
                                right: '0',
                                left: '25',
                                bottom: '70',
                                textStyle: {
                                    color: '#000',
                                    fontSize: 15
                                },
                                data: lData
                            },
                            series: [{
                                type: 'pie',
                                center: ['50%', '50%'],
                                radius: ['35%', '60%'],
                                clockwise: true,
                                avoidLabelOverlap: true,
                                top: '0',
                                left: '100',
                                bottom: '0',
                                hoverOffset: 15,
                                itemStyle: {
                                    normal: {
                                        color: function (params) {
                                            return colorList[params.dataIndex]
                                        }
                                    }
                                },
                                label: {
                                    show: false
                                },
                                data: sData
                            }]
                        };
                        // 使用刚指定的配置项和数据显示图表。
                        myChart.setOption(option);
                        window.addEventListener('resize',function () {
                            myChart.resize();
                        })

                    }, 0)
                }

                // 点播热度饼图
                function movieFeverChart(sData, lData) {
                    $timeout(function () {
                        // 基于准备好的dom，初始化echarts实例
                        var myChart = echarts.init(document.getElementById('movieFever'));
                        var colorList = ['#73DDFF', '#73ACFF', '#FDD56A', '#FDB36A', '#FD866A', '#DA7FEC'];
                        var option = {
                            title: {
                                x: 'center',
                                y: 'center'
                            },
                            tooltip: {
                                trigger: 'item',
                                borderColor: 'rgba(255,255,255,.3)',
                                backgroundColor: 'rgba(13,5,30,.6)',
                                borderWidth: 1,
                                padding: 5,
                                formatter: function (parms) {
                                    var str = parms.marker + "" + parms.data.name + "</br>" +
                                        "个数：" + parms.data.value + "</br>" +
                                        "占比：" + Number(parms.data.percent*100).toFixed(1) + "%";
                                    return str;
                                }
                            },
                            legend: {
                                icon: 'circle',
                                orient: 'vertical',
                                right: '0',
                                left: '25',
                                top: '40',
                                textStyle: {
                                    color: '#000',
                                    fontSize: 14
                                },
                                data: lData
                            },
                            series: [{
                                type: 'pie',
                                center: ['50%', '50%'],
                                radius: ['35%', '60%'],
                                clockwise: true,
                                avoidLabelOverlap: true,
                                top: '0',
                                left: '100',
                                right: '-80',
                                bottom: '0',
                                hoverOffset: 15,
                                itemStyle: {
                                    normal: {
                                        color: function (params) {
                                            return colorList[params.dataIndex]
                                        }
                                    }
                                },
                                label: {
                                    show: false
                                },
                                data: sData
                            }]
                        };
                        // 使用刚指定的配置项和数据显示图表。
                        myChart.setOption(option);
                        window.addEventListener('resize',function () {
                            myChart.resize();
                        })

                    }, 0)
                }

                // 直播热度饼图
                function liveTVChart(sData, lData) {
                    $timeout(function () {
                        // 基于准备好的dom，初始化echarts实例
                        var myChart = echarts.init(document.getElementById('liveTV'));
                        var colorList = ['#86D560', '#AF89D6', '#59ADF3', '#FF999A', '#FFCC67', '#7FECEB'];
                        var option = {
                            title: {
                                x: 'center',
                                y: 'center'
                            },
                            tooltip: {
                                trigger: 'item',
                                borderColor: 'rgba(255,255,255,.3)',
                                backgroundColor: 'rgba(13,5,30,.6)',
                                borderWidth: 1,
                                padding: 5,
                                formatter: function (parms) {
                                    var str = parms.marker + "" + parms.data.name + "</br>" +
                                        "个数：" + parms.data.value + "</br>" +
                                        "占比：" + Number(parms.data.percent*100).toFixed(1) + "%";
                                    return str;
                                }
                            },
                            legend: {
                                icon: 'circle',
                                orient: 'vertical',
                                right: '0',
                                left: '25',
                                bottom: 'center',
                                textStyle: {
                                    color: '#000',
                                    fontSize: 15
                                },
                                data: lData
                            },
                            series: [{
                                type: 'pie',
                                center: ['50%', '50%'],
                                radius: ['35%', '60%'],
                                clockwise: true,
                                avoidLabelOverlap: true,
                                top: '0',
                                bottom: '0',
                                left: '100',
                                hoverOffset: 15,
                                itemStyle: {
                                    normal: {
                                        color: function (params) {
                                            return colorList[params.dataIndex]
                                        }
                                    }
                                },
                                label: {
                                    show: false
                                },
                                data: sData
                            }]
                        };
                        // 使用刚指定的配置项和数据显示图表。
                        myChart.setOption(option);
                        window.addEventListener('resize',function () {
                            myChart.resize();
                        })

                    }, 0)
                }


                self.open = function ($event) {
                    if ($event.target.className.indexOf('start') != -1) {
                        self.startOpened = true;
                        self.endOpened = false;
                    } else {
                        self.startOpened = false;
                        self.endOpened = true;
                    }
                };

                

                // 终端24小时在线折线图接口
                self.hourActiveTe = function () {
                    var data = JSON.stringify({
                        "action": "get_term_hour_online",
                        "token": util.getParams("token"),
                        "projectList": [util.getParams("projectName")],
                        "startDate": $filter('date')(new Date()-24*60*60*1000,'yyyy-MM-dd') ? $filter('date')(new Date()-24*60*60*1000,'yyyy-MM-dd') + ' 00:00:00' : null,
                        "endDate":  $filter('date')(new Date()-24*60*60*1000,'yyyy-MM-dd') ? $filter('date')(new Date()-24*60*60*1000,'yyyy-MM-dd') + ' 23:59:59' : null
                    });
                    self.loading = true;
                    self.noData = false;

                    return $http({
                        method: 'POST',
                        url: util.getApiUrl('luan/statistics', '', 'server'),
                        data: data
                    }).then(function successCallback(response) {
                        var data = response.data;
                        if (data.rescode == '200') {
                            if (data.hourData == 0) {
                                self.noData = true;
                            }

                            var yData = [data.hourData.hour0, data.hourData.hour1, data.hourData.hour2, data.hourData.hour3, data.hourData.hour4, data.hourData.hour5, data.hourData.hour6, data.hourData.hour7, data.hourData.hour8, data.hourData.hour9, data.hourData.hour10, data.hourData.hour11, data.hourData.hour12, data.hourData.hour13, data.hourData.hour14, data.hourData.hour15, data.hourData.hour16, data.hourData.hour17, data.hourData.hour17, data.hourData.hour18, data.hourData.hour19, data.hourData.hour20, data.hourData.hour21, data.hourData.hour22, data.hourData.hour23]
                        
                            // var yData = [];
                            // for (var i = 0; i < 24; ++i) {
                            //     console.log(i);
                            //     console.log(data.hourData.hour[i]);
                            //     // yData = data.hourData.hour[i]
                            //     // console.log(yData);
                            // }
                            hourActiveTeChart(yData)


                            return data.hourData;
                        } else if (data.rescode == '401') {
                            alert('访问超时，请重新登录');
                            $state.go('login');
                        } else {
                            alert('获取商城订单列表失败，' + data.errInfo);
                        }
                    }, function errorCallback(response) {
                        alert('连接服务器出错');
                    }).finally(function (value) {
                        self.loading = false;
                    });



                }

                // 终端每天在线总数折线图接口
                self.activeTe = function () {
                    var data = JSON.stringify({
                        "action": "get_term_day_online",
                        "token": util.getParams("token"),
                        "projectList": [util.getParams("projectName")],
                        "startDate": $scope.startTime ? $scope.startTime + ' 00:00:00' : null,
                        "endDate": $scope.endTime ? $scope.endTime + ' 00:00:00' : null
                    });
                    self.loading = true;
                    self.noData = false;

                    return $http({
                        method: 'POST',
                        url: util.getApiUrl('luan/statistics', '', 'server'),
                        data: data
                    }).then(function successCallback(response) {
                        var data = response.data;
                        if (data.rescode == '200') {
                            if (data.dayData == 0) {
                                self.noData = true;
                            }

                            var chartData = data.dayData.map(function (item) {
                                return [item.date, item.totalCount, item.onlineCount]
                            })
                            chartData.unshift(['/', '总数', '上线'])
                            activeTeChart(chartData)

                            return data.dayData;
                        } else if (data.rescode == '401') {
                            alert('访问超时，请重新登录');
                            $state.go('login');
                        } else {
                            alert('获取商城订单列表失败，' + data.errInfo);
                        }
                    }, function errorCallback(response) {
                        alert('连接服务器出错');
                    }).finally(function (value) {
                        self.loading = false;
                    });



                }

                // 模块热度饼图接口
                self.pageStayInfo = function () {
                    var data = {
                        "action": "statis_page_stay",
                        "project": util.getParams("projectName"),
                        "start_date": $scope.startTime ? $scope.startTime : null,
                        "end_date": $scope.endTime ? $scope.endTime : null
                    }
                    $http({
                        method: 'POST',
                        url: 'http://maintaining.cleartv.cn/backend_vodlogmgt/query',
                        data: data
                    }).then(function successCallback(response) {
                        var data = response.data;
                        if (data.code == '200') {
                            self.tableData = data.records
                            self.loading = false

                            var sData = data.records.slice(0, 5).map(function (item) {
                                return { name: item.Name, value: item.amount, percent: item.percent }
                            })
                            var lData = data.records.slice(0, 5).map(function (item) {
                                return item.Name
                            })
                            var d = data.records[data.records.length - 1];
                            sData.push({ name: d.Name, value: d.amount, percent: d.percent });
                            lData.push(d.Name);

                            pageStayInfoChart(sData, lData)

                        } else if (data.rescode == '401') {
                            alert('访问超时，请重新登录');
                            $state.go('login');
                        } else {
                            alert('获取失败' + data.errInfo);
                        }
                    }, function errorCallback(response) {
                        alert('连接服务器出错');
                    }).finally(function (value) {
                        self.saving = false;
                    });

                }

                // 点播热度饼图接口
                self.movieFever = function () {
                    var data = JSON.stringify({
                        "action": "statis_live",
                        "start_date": $scope.startTime ? $scope.startTime : null,
                        "end_date": $scope.endTime ? $scope.endTime : null,
                        "type": "Movie",
                        "project": util.getParams("projectName"),
                        "token": util.getParams("token")
                    });
                    self.loading = true;
                    self.noData = false;

                    return $http({
                        method: 'POST',
                        url: 'http://maintaining.cleartv.cn/backend_vodlogmgt/query',
                        data: data
                    }).then(function successCallback(response) {
                        var data = response.data;
                        if (data.code == '200') {
                            self.tableData = data.records
                            self.loading = false

                            var sData = data.records.slice(0, 5).map(function (item) {
                                return { name: item.name, value: item.total, percent: item.percent }
                            })
                            var lData = data.records.slice(0, 5).map(function (item) {
                                return item.name
                            })
                            
                            var d = data.records[data.records.length - 1];
                            sData.push({ name: d.name, value: d.total, percent: d.percent });
                            lData.push(d.name);
                            // console.log(sData, lData)

                            movieFeverChart(sData, lData)

                        } else if (data.code == '401') {
                            alert('访问超时，请重新登录');
                            $state.go('login');
                        } else {
                            alert('获取列表失败，' + data.errInfo);
                        }
                    }, function errorCallback(response) {
                        alert('连接服务器出错');
                    }).finally(function (value) {
                        self.loading = false;
                    });


                }

                // 直播热度饼图接口
                self.liveTV = function () {
                    var data = JSON.stringify({
                        "action": "statis_live",
                        "start_date": $scope.startTime ? $scope.startTime : null,
                        "end_date": $scope.endTime ? $scope.endTime : null,
                        "type": "Live",
                        "project": util.getParams("projectName"),
                        "token": util.getParams("token")
                    });
                    self.loading = true;
                    self.noData = false;

                    return $http({
                        method: 'POST',
                        url: 'http://maintaining.cleartv.cn/backend_vodlogmgt/query',
                        data: data
                    }).then(function successCallback(response) {
                        var data = response.data;
                        if (data.code == '200') {
                            self.tableData = data.records
                            self.loading = false

                            var sData = data.records.slice(0, 5).map(function (item) {
                                return { name: item.name, value: item.total, percent: item.percent }
                            })
                            var lData = data.records.slice(0, 5).map(function (item) {
                                return item.name
                            })

                            var d = data.records[data.records.length - 1];
                            sData.push({ name: d.name, value: d.total, percent: d.percent });
                            lData.push(d.name);

                            liveTVChart(sData, lData)

                        } else if (data.code == '401') {
                            alert('访问超时，请重新登录');
                            $state.go('login');
                        } else {
                            alert('获取列表失败，' + data.errInfo);
                        }
                    }, function errorCallback(response) {
                        alert('连接服务器出错');
                    }).finally(function (value) {
                        self.loading = false;
                    });

                }
            }
        ])
})();