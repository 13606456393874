'use strict';
(function () {
    var app = angular.module('app.fujiaVideo', [])
        // 社区视频
        .controller('FujiaVideoController', ['$scope', '$state', '$http', '$stateParams', '$location', 'util',
            function ($scope, $state, $http, $stateParams, $location, util) {
                var self = this;
                self.info = []; // 视频分类＋分类下的图文信息
                self.cateIndex; // 当前选中分类index
                self.recoIndex;
                self.vinfo = []; // 推荐分类

                self.init = function () {
                    self.cateIndex = 0;
                    self.recoIndex = 0;
                    self.currentLabelID = '';
                    self.currentCateID = ''
                    self.viewId = $stateParams.moduleId;
                    self.defaultLangCode = util.getDefaultLangCode();
                    self.importing = false
                    self.loadInfo();
                }

                self.toggleLocal = function (val) {
                    if (val == false) {
                        self.localInfo = {
                            url: '',
                            param: ''
                        }
                        self.saveLocal()
                    }
                }

                self.saveLocal = function () {
                    var data = JSON.stringify({
                        "token": util.getParams('token'),
                        "action": "editLocalInfo",
                        "viewID": self.viewId - 0,
                        "lang": util.langStyle(),
                        "data": {
                            "localInfo": self.localInfo
                        }
                    });

                    return $http({
                        method: 'POST',
                        url: util.getApiUrl('commonview', '', 'server'),
                        data: data
                    }).then(function successCallback(response) {
                        if (response.data.rescode == 200) {
                            alert("修改成功")
                        }
                    }, function errorCallback(response) {
                        alert('连接服务器出错');
                    }).finally(function (value) {
                        self.saving = false;
                    });
                }

                // 导出json数据
                self.export = function () {
                    var obj = {
                        type: 'FujiaVideo',
                        data: self.info || self.vinfo
                    }
                    util.exportData(JSON.stringify(obj), 'FujiaVideo.json')
                }

                // 导入json数据
                self.import = function () {
                    if (self.info.length > 0 || self.vinfo > 0) {
                        if (!confirm('当前模块已有数据，确定导入吗？')) {
                            return
                        }
                    }
                    util.importData(self, $scope, 'FujiaVideo', self.importData)
                }

                // 导入数据后，先添加分类
                self.importData = function (D) {
                    self.importItemdIdx = 0  // 导入数据计数
                    self.importTotalNum = 0  // 导入数据总数
                    self.importing = true
                    self.process = "0%"
                    self.jsonData.data.forEach(function (item) {
                        self.importTotalNum += item.Videos.length
                    })
                    self.addCate(D)
                    self.addReco(D)
                }

                // 每次添加完分类，在当前分类下添加子项目
                self.addCate = function (D) {
                    var data = JSON.stringify({
                        "token": util.getParams('token'),
                        "action": "addCategory",
                        "viewID": self.viewId - 0,
                        "lang": util.langStyle(),
                        "data": {
                            "CategoryName": D.CategoryName
                        }
                    });

                    self.saving = true;

                    return $http({
                        method: 'POST',
                        url: util.getApiUrl('commonview', '', 'server'),
                        data: data
                    }).then(function successCallback(response) {
                        if (response.data.rescode == 200) {
                            // 将json内的子项目添加进分类
                            D.Videos.forEach(function (item) {
                                self.addItem(response.data.data.ID, item)
                            })
                        }
                    }, function errorCallback(response) {
                        alert('连接服务器出错');
                    }).finally(function (value) {
                        self.saving = false;
                    });
                }

                // 添加子项目方法
                self.addItem = function (cateID, item) {
                    data = JSON.stringify({
                        "token": util.getParams('token'),
                        "action": "addVideo",
                        "viewID": Number(self.viewId),
                        "data": {
                            "CategoryID": cateID,
                            "Title": item.Title,
                            "LableID": item.LableID,
                            "Text": item.Text,
                            "PicURL": item.PicURL,
                            "PicSize": item.PicSize,
                            "VideoURL": item.VideoURL,
                            "VideoSize": item.VideoSize
                        },
                        "lang": util.langStyle()
                    })
                    $http({
                        method: 'POST',
                        url: util.getApiUrl('commonview', '', 'server'),
                        data: data
                    }).then(function successCallback(response) {
                        var data = response.data;
                        if (data.rescode == '200') {
                            self.importItemdIdx++
                            self.process = Math.floor((self.importItemdIdx / self.importTotalNum) * 100) + '%'
                            // 全部导入成功
                            if (self.importItemdIdx == self.importTotalNum) {
                                self.importing = false
                                $state.reload();
                            }
                        } else if (data.rescode == '401') {
                            alert('访问超时，请重新登录');
                            $state.go('login');
                        } else {
                            alert('添加失败，' + data.errInfo);
                        }
                    }, function errorCallback(response) {
                        alert('连接服务器出错');
                    }).finally(function (value) {
                        self.saving = false;
                    });
                }

                /**
                 * 添加视频分类
                 * @method addCategory
                 */
                self.addCategory = function () {
                    $scope.app.maskParams.viewId = self.viewId;
                    $scope.app.maskParams.loadInfo = self.loadInfo;
                    $scope.app.showHideMask(true, 'pages/tv/multVideoCategoryAdd.html');
                }

                /**
                 * 编辑视频分类
                 * @method editCate
                 */
                self.editCate = function () {
                    if (!self.currentCateID) {
                        alert('请选择分类')
                        return
                    }
                    $scope.app.maskParams.viewId = self.viewId;
                    $scope.app.maskParams.cateId = self.currentCateID;
                    $scope.app.maskParams.info = self.info[self.cateIndex];
                    $scope.app.maskParams.loadInfo = self.loadInfo;
                    $scope.app.showHideMask(true, 'pages/tv/multVideoCategoryEdit.html');
                }

                /**
                 * 删除视频分类
                 * @method delCate
                 */
                self.delCate = function () {
                    if (!self.currentCateID) {
                        alert('请选择分类')
                        return
                    }
                    if (self.info[self.cateIndex].Videos.length > 0) {
                        alert("请先删除该分类下视频")
                        return false;
                    }
                    if (!confirm('确认删除？')) {
                        return;
                    }
                    var data = JSON.stringify({
                        "token": util.getParams('token'),
                        "action": "delCategory",
                        "viewID": self.viewId,
                        "lang": util.langStyle(),
                        "data": {
                            "ID": self.info[self.cateIndex].ID
                        }
                    });

                    self.cateDeleting = true;
                    $http({
                        method: 'POST',
                        url: util.getApiUrl('commonview', '', 'server'),
                        data: data
                    }).then(function successCallback(response) {
                        var data = response.data;
                        if (data.rescode == '200') {
                            alert("删除成功")
                            self.loadInfo();

                            self.currentCateID = ''
                        }
                        else {
                            alert('连接服务器出错' + data.errInfo);
                        }
                    }, function errorCallback(response) {
                        alert('连接服务器出错');
                    }).finally(function (value) {
                        self.cateDeleting = false;
                    });
                }

                // 添加推荐分类 addLable
                self.addLable = function () {
                    $scope.app.maskParams.viewId = self.viewId;
                    $scope.app.maskParams.loadInfo = self.loadInfo;
                    $scope.app.showHideMask(true, 'pages/tv/FujiaVideoLabelAdd.html');
                }

                // 编辑推荐分类 editReco
                self.editReco = function () {
                    if (!self.currentLabelID) {
                        alert('请选择推荐分类')
                        return
                    }
                    $scope.app.maskParams.viewId = self.viewId;
                    $scope.app.maskParams.recoId = self.vinfo[self.recoIndex].ID;
                    $scope.app.maskParams.vinfo = self.vinfo[self.recoIndex];
                    $scope.app.maskParams.loadInfo = self.loadInfo;
                    $scope.app.showHideMask(true, 'pages/tv/FujiaVideoLabelEdit.html');
                }

                // 删除推荐分类 delReco
                self.delReco = function () {
                    if (!self.currentLabelID) {
                        alert('请选择推荐分类')
                        return
                    }
                    if (self.vinfo[self.recoIndex].videoList.length > 0) {
                        alert("请先删除该分类下视频")
                        return false;
                    }
                    if (!confirm('确认删除？')) {
                        return;
                    }
                    var data = JSON.stringify({
                        "token": util.getParams('token'),
                        "action": "delLable",
                        "viewID": self.viewId,
                        "lang": util.langStyle(),
                        "data": {
                            "ID": self.vinfo[self.recoIndex].ID
                        }
                    });
                    self.cateDeleting = true;
                    $http({
                        method: 'POST',
                        url: util.getApiUrl('commonview', '', 'server'),
                        data: data
                    }).then(function successCallback(response) {
                        var data = response.data;
                        if (data.rescode == '200') {
                            alert("删除成功")
                            self.loadInfo();

                            self.currentLabelID = ''
                        }
                        else {
                            alert('连接服务器出错' + data.errInfo);
                        }
                    }, function errorCallback(response) {
                        alert('连接服务器出错');
                    }).finally(function (value) {
                        self.cateDeleting = false;
                    });
                }

                /**
                 * 添加视频分类下添加视频（包括视频的推荐类型）
                 * @method addPic
                 */
                self.addVideo = function () {
                    $scope.app.maskParams.viewId = self.viewId;
                    $scope.app.maskParams.cateId = self.info[self.cateIndex].ID;
                    $scope.app.maskParams.loadInfo = self.loadInfo;
                    $scope.app.maskParams.labelList = self.vinfo;
                    $scope.app.showHideMask(true, 'pages/tv/FujiaVideoTextAdd.html');
                }

                /**
                 * 编辑添加的视频
                 * @method editVideo
                 * @param index 图片在该分类下列表中的序号
                 */
                self.editVideo = function (video) {
                    $scope.app.maskParams.viewId = self.viewId;
                    $scope.app.maskParams.videoInfo = video;
                    $scope.app.maskParams.loadInfo = self.loadInfo;
                    $scope.app.maskParams.labelList = self.vinfo;
                    $scope.app.showHideMask(true, 'pages/tv/FujiaVideoTextEdit.html');
                }

                /**
                 * 删除视频
                 * @method delVideo
                 * @param index 图文在列表中的序号
                 */
                self.delVideo = function (video) {
                    if (!confirm('确认删除？')) {
                        return;
                    }
                    var data = JSON.stringify({
                        "token": util.getParams('token'),
                        "action": "delVideo",
                        "viewID": self.viewId,
                        "lang": util.langStyle(),
                        "data": {
                            "CategoryID": video.CategoryID,
                            "ID": video.ID
                        }
                    });
                    self.picDeleting = true;
                    $http({
                        method: 'POST',
                        url: util.getApiUrl('commonview', '', 'server'),
                        data: data
                    }).then(function successCallback(response) {
                        var data = response.data;
                        if (data.rescode == '200') {
                            alert("删除成功")
                            self.loadInfo();
                        }
                        else {
                            alert('连接服务器出错' + data.errInfo);
                        }
                    }, function errorCallback(response) {
                        alert('连接服务器出错');
                    }).finally(function (value) {
                        self.picDeleting = false;
                    });
                }

                /**
                 * 加载分类及分类下的信息
                 * @method loadInfo
                 */
                self.loadInfo = function () {
                    var data = JSON.stringify({
                        "token": util.getParams('token'),
                        "action": "get",
                        "viewID": self.viewId,
                        "lang": util.langStyle()
                    });
                    self.loading = true;
                    return $http({
                        method: 'POST',
                        url: util.getApiUrl('commonview', '', 'server'),
                        data: data
                    }).then(function successCallback(response) {
                        var data = response.data;
                        if (data.rescode == '200') {
                            self.info = data.data.res;
                            self.vinfo = data.data.lableList;

                            self.allVideoList = []
                            var labelNameMap = {}
                            self.vinfo.forEach(function (item) {
                                labelNameMap[item.ID] = item.Name
                            })

                            self.info.forEach(function (item) {
                                item.Videos.forEach(function (video) {
                                    video.CategoryID = item.ID
                                    video.LabelName = labelNameMap[video.LableID] || ''
                                    self.allVideoList.push(video)
                                })
                            })



                            if (self.currentCateID) {
                                var index = self.info.findIndex(function (item) {
                                    return item.ID === self.currentCateID
                                })
                                self.videoList = self.info[index].Videos
                            } else if (self.currentLabelID) {
                                self.videoList = []
                                self.info.forEach(function (item) {
                                    item.Videos.forEach(function (video) {
                                        if (video.LableID === self.currentLabelID) {
                                            self.videoList.push(video)
                                        }
                                    })
                                })
                            } else {
                                self.videoList = self.allVideoList
                            }

                            self.localInfo = data.data.localInfo
                            self.local = !!self.localInfo.url
                            R.forEach(function (t) {
                                t.CategoryName = JSON.parse(t.CategoryName)
                                t.Name = JSON.parse(t.Name)
                            })(self.info || self.vinfo)
                            if (!self.cateIndex || (self.cateIndex + 1) > self.info.length) {
                                self.cateIndex = 0;
                            }

                            if (!self.recoIndex || (self.recoIndex + 1) > self.vinfo.length) {
                                self.recoIndex = 0;
                            }

                            //判断分类下内容为空时，sub属性为空数组，不然模板的ng-repeat会报错
                            if (self.info.length == 0) {
                                self.info[0].sub = [];
                            }
                            if (self.vinfo.length == 0) {
                                self.vinfo[0].sub = [];
                            }
                        }
                        else {
                            alert('连接服务器出错' + data.errInfo);
                        }
                    }, function errorCallback(response) {
                        alert('连接服务器出错');
                    }).finally(function (value) {
                        self.loading = false;
                    });
                }

                /**
                 * 加载分类及分类下的信息
                 *
                 * @method loadInfo
                 * @param index 跳转分类在self.info中的index
                 */
                self.loadVideoList = function (cateId, index) {
                    self.cateIndex = index
                    self.currentCateID = cateId;
                    self.currentLabelID = ''
                    if (cateId) {
                        self.videoList = self.info[index].Videos
                    } else {
                        self.videoList = self.allVideoList
                    }
                }

                self.loadLabelList = function (labelID, index) {
                    self.recoIndex = index
                    self.currentLabelID = labelID;
                    self.currentCateID = ''
                    if (labelID) {
                        self.videoList = []
                        self.info.forEach(function (item) {
                            item.Videos.forEach(function (video) {
                                if (video.LableID === labelID) {
                                    self.videoList.push(video)
                                }
                            })
                        })
                    } else {
                        self.videoList = self.allVideoList
                    }
                }

            }
        ])

        //添加视频分类弹窗
        .controller('multVideoCategoryAddController', ['$scope', '$state', '$http', '$stateParams', '$location', 'util', 'CONFIG',
            function ($scope, $state, $http, $stateParams, $location, util, CONFIG) {
                var self = this;
                self.viewId = 0;
                self.imgs1 = null;
                self.editLangs = util.getParams('editLangs');

                self.init = function () {
                    self.viewId = $scope.app.maskParams.viewId;
                    self.imgs1 = new util.initUploadImgs([], $scope, true);
                }
                // 图片上传相关
                self.clickUpload = function (e) {
                    setTimeout(function () {
                        document.getElementById(e).click();
                    }, 0);
                }
                /**
                 * 关闭弹窗
                 *
                 * @method cancel
                 */
                self.cancel = function () {
                    $scope.app.showHideMask(false);
                }

                /**
                 * 新建分类提交
                 *
                 * @method save
                 */
                self.save = function () {
                    // if (!(self.imgs1.data[0] && self.imgs1.data[0].src)) {
                    //     alert('请上传图片');
                    //     return;
                    // }

                    var data = JSON.stringify({
                        "token": util.getParams('token'),
                        "action": "addCategory",
                        "viewID": self.viewId - 0,
                        "lang": util.langStyle(),
                        "data": {
                            "CategoryName": self.cateName
                        }
                    });

                    self.saving = true;

                    return $http({
                        method: 'POST',
                        url: util.getApiUrl('commonview', '', 'server'),
                        data: data
                    }).then(function successCallback(response) {
                        if (response.data.rescode == 200) {
                            alert("添加成功")
                        }
                        $scope.app.showHideMask(false);
                        $scope.app.maskParams.loadInfo();
                    }, function errorCallback(response) {
                        alert('连接服务器出错');
                    }).finally(function (value) {
                        self.saving = false;
                    });
                }
            }
        ])

        //编辑视频分类弹窗
        .controller('multVideoCategoryEditController', ['$scope', '$state', '$http', '$stateParams', '$location', 'util', 'CONFIG',
            function ($scope, $state, $http, $stateParams, $location, util, CONFIG) {
                var self = this;
                self.viewId = 0;
                self.imgs1 = null;
                self.editLangs = util.getParams('editLangs');

                self.init = function () {
                    self.viewId = $scope.app.maskParams.viewId;
                    self.cateName = $scope.app.maskParams.info.CategoryName
                }
                /**
                 * 关闭弹窗
                 *
                 * @method cancel
                 */
                self.cancel = function () {
                    $scope.app.showHideMask(false);
                }

                /**
                 * 新建分类提交
                 *
                 * @method save
                 */
                self.save = function () {

                    var data = JSON.stringify({
                        "token": util.getParams('token'),
                        "action": "editCategory",
                        "viewID": self.viewId - 0,
                        "lang": util.langStyle(),
                        "data": {
                            "ID": $scope.app.maskParams.cateId,
                            "CategoryName": self.cateName
                        }
                    });

                    self.saving = true;

                    return $http({
                        method: 'POST',
                        url: util.getApiUrl('commonview', '', 'server'),
                        data: data
                    }).then(function successCallback(response) {
                        if (response.data.rescode == 200) {
                            alert("修改成功")
                        }
                        $scope.app.showHideMask(false);
                        $scope.app.maskParams.loadInfo();
                    }, function errorCallback(response) {
                        alert('连接服务器出错');
                    }).finally(function (value) {
                        self.saving = false;
                    });
                }
            }
        ])

        //添加推荐分类弹窗
        .controller('FujiaVideoLabelAddController', ['$scope', '$state', '$http', '$stateParams', '$location', 'util', 'CONFIG',
            function ($scope, $state, $http, $stateParams, $location, util, CONFIG) {
                var self = this;
                self.viewId = 0;
                self.imgs1 = null;
                self.editLangs = util.getParams('editLangs');

                self.init = function () {
                    self.viewId = $scope.app.maskParams.viewId;
                    self.imgs1 = new util.initUploadImgs([], $scope, true);
                }
                // 图片上传相关
                self.clickUpload = function (e) {
                    setTimeout(function () {
                        document.getElementById(e).click();
                    }, 0);
                }
                /**
                 * 关闭弹窗
                 *
                 * @method cancel
                 */
                self.cancel = function () {
                    $scope.app.showHideMask(false);
                }

                /**
                 * 新建分类提交
                 *
                 * @method save
                 */
                self.save = function () {
                    var data = JSON.stringify({
                        "token": util.getParams('token'),
                        "action": "addLable",
                        "viewID": self.viewId - 0,
                        "lang": util.langStyle(),
                        "data": {
                            "Name": self.Name,
                            "Seq": self.Seq
                        }
                    });

                    self.saving = true;

                    return $http({
                        method: 'POST',
                        url: util.getApiUrl('commonview', '', 'server'),
                        data: data
                    }).then(function successCallback(response) {
                        if (response.data.rescode == 200) {
                            alert("添加成功")
                        }
                        $scope.app.showHideMask(false);
                        $scope.app.maskParams.loadInfo();
                    }, function errorCallback(response) {
                        alert('连接服务器出错');
                    }).finally(function (value) {
                        self.saving = false;
                    });
                }
            }
        ])

        //编辑推荐分类弹窗
        .controller('FujiaVideoLabelEditController', ['$scope', '$state', '$http', '$stateParams', '$location', 'util', 'CONFIG',
            function ($scope, $state, $http, $stateParams, $location, util, CONFIG) {
                var self = this;
                self.viewId = 0;
                self.imgs1 = null;
                self.editLangs = util.getParams('editLangs');

                self.init = function () {
                    self.viewId = $scope.app.maskParams.viewId;
                    self.Seq = $scope.app.maskParams.vinfo.Seq;
                    self.Name = $scope.app.maskParams.vinfo.Name
                }
                /**
                 * 关闭弹窗
                 *
                 * @method cancel
                 */
                self.cancel = function () {
                    $scope.app.showHideMask(false);
                }

                /**
                 * 新建分类提交
                 *
                 * @method save
                 */
                self.save = function () {
                    var data = JSON.stringify({
                        "token": util.getParams('token'),
                        "action": "editLable",
                        "viewID": self.viewId - 0,
                        "lang": util.langStyle(),
                        "data": {
                            "ID": $scope.app.maskParams.vinfo.ID,
                            "Name": self.Name,
                            "Seq": self.Seq
                        }
                    });

                    self.saving = true;

                    return $http({
                        method: 'POST',
                        url: util.getApiUrl('commonview', '', 'server'),
                        data: data
                    }).then(function successCallback(response) {
                        if (response.data.rescode == 200) {
                            alert("修改成功")
                        }
                        $scope.app.showHideMask(false);
                        $scope.app.maskParams.loadInfo();
                    }, function errorCallback(response) {
                        alert('连接服务器出错');
                    }).finally(function (value) {
                        self.saving = false;
                    });
                }
            }
        ])


        // 添加视频弹窗
        .controller('FujiaVideoAddController', ['$scope', '$state', '$http', '$stateParams', '$location', 'util', 'CONFIG',
            function ($scope, $state, $http, $stateParams, $location, util, CONFIG) {

                var self = this;
                self.init = function () {
                    self.viewId = $scope.app.maskParams.viewId;
                    self.cateId = $scope.app.maskParams.cateId;
                    self.labelList = $scope.app.maskParams.labelList
                    self.LableID = ''
                    // 获取编辑多语言信息
                    self.editLangs = util.getParams('editLangs');

                    // 初始化频道图片
                    self.imgs1 = new Imgs([], true);
                    // 初始化频道图片
                    self.imgs2 = new Imgs([], true);
                }


                self.cancel = function () {
                    $scope.app.showHideMask(false);
                }

                self.save = function () {
                    if (self.imgs1.data.length == 0) {
                        alert('请上传封面图片');
                        return;
                    }
                    // 视频未上传完成
                    else if (self.imgs1.data[0].progress < 100) {
                        alert('封面图片正在上传中，请稍后...');
                        return;
                    }
                    // 视频上传失败时
                    else if (self.imgs1.data[0].progress == -1) {
                        alert('封面图片上传失败，请重新上传');
                        return;
                    }

                    if (self.imgs2.data.length == 0) {
                        alert('请上传视频');
                        return;
                    }
                    // 视频未上传完成
                    else if (self.imgs2.data[0].progress < 100) {
                        alert('视频正在上传中，请稍后...');
                        return;
                    }
                    // 视频上传失败时
                    else if (self.imgs2.data[0].progress == -1) {
                        alert('视频上传失败，请重新上传');
                        return;
                    }

                    var data = JSON.stringify({
                        "token": util.getParams('token'),
                        "action": "add",
                        "viewID": Number(self.viewId),
                        "data": {
                            "Title": self.Title,
                            "LableID": self.LableID,
                            "Text": self.Text || '',
                            "PicURL": self.imgs1.data[0].src,
                            "PicSize": self.imgs1.data[0].fileSize,
                            "VideoURL": self.imgs2.data[0].src,
                            "VideoSize": self.imgs2.data[0].fileSize
                        },
                        "lang": util.langStyle()
                    })
                    if (self.cateId) {
                        data = JSON.stringify({
                            "token": util.getParams('token'),
                            "action": "addVideo",
                            "viewID": Number(self.viewId),
                            "data": {
                                "CategoryID": self.cateId,
                                "Title": self.Title,
                                "LableID": self.LableID,
                                "Text": self.Text || '',
                                "PicURL": self.imgs1.data[0].src,
                                "PicSize": self.imgs1.data[0].fileSize,
                                "VideoURL": self.imgs2.data[0].src,
                                "VideoSize": self.imgs2.data[0].fileSize
                            },
                            "lang": util.langStyle()
                        })
                    }
                    self.saving = true;
                    $http({
                        method: 'POST',
                        url: util.getApiUrl('commonview', '', 'server'),
                        data: data
                    }).then(function successCallback(response) {
                        var data = response.data;
                        if (data.rescode == '200') {
                            alert('添加成功');
                            $scope.app.showHideMask(false)
                            $scope.app.maskParams.loadInfo()
                        } else if (data.rescode == '401') {
                            alert('访问超时，请重新登录');
                            $state.go('login');
                        } else {
                            alert('添加失败，' + data.errInfo);
                        }
                    }, function errorCallback(response) {
                        alert('连接服务器出错');
                    }).finally(function (value) {
                        self.saving = false;
                    });

                }


                // 图片上传相关
                self.clickUpload = function (e) {
                    setTimeout(function () {
                        document.getElementById(e).click();
                    }, 0);
                }

                function Imgs(imgList, single) {
                    this.initImgList = imgList;
                    this.data = [];
                    this.maxId = 0;
                    this.single = single ? true : false;
                }

                Imgs.prototype = {
                    initImgs: function () {
                        var l = this.initImgList;
                        for (var i = 0; i < l.length; i++) {
                            this.data[i] = {
                                "src": l[i].ImageURL,
                                "fileSize": l[i].ImageSize,
                                "id": this.maxId++,
                                "progress": 100
                            };
                        }
                    },
                    deleteById: function (id) {
                        var l = this.data;
                        for (var i = 0; i < l.length; i++) {
                            if (l[i].id == id) {
                                // 如果正在上传，取消上传
                                if (l[i].progress < 100 && l[i].progress != -1) {
                                    l[i].xhr.abort();
                                }
                                l.splice(i, 1);
                                break;
                            }
                        }
                    },

                    add: function (xhr, fileName, fileSize) {
                        this.data.push({
                            "xhr": xhr,
                            "fileName": fileName,
                            "fileSize": fileSize,
                            "progress": 0,
                            "id": this.maxId
                        });
                        return this.maxId++;
                    },

                    update: function (id, progress, leftSize, fileSize) {
                        for (var i = 0; i < this.data.length; i++) {
                            var f = this.data[i];
                            if (f.id === id) {
                                f.progress = progress;
                                f.leftSize = leftSize;
                                f.fileSize = fileSize;
                                break;
                            }
                        }
                    },

                    setSrcSizeByXhr: function (xhr, src, size) {
                        for (var i = 0; i < this.data.length; i++) {
                            if (this.data[i].xhr == xhr) {
                                this.data[i].src = src;
                                this.data[i].fileSize = size;
                                break;
                            }
                        }
                    },

                    uploadFile: function (e, o) {

                        // 如果这个对象只允许上传一张图片
                        if (this.single) {
                            // 删除第二张以后的图片
                            for (var i = 1; i < this.data.length; i++) {
                                this.deleteById(this.data[i].id);
                            }
                        }

                        var file = $scope[e];
                        var uploadUrl = CONFIG.uploadUrl;
                        var xhr = new XMLHttpRequest();
                        var fileId = this.add(xhr, file.name, file.size, xhr);
                        // self.search();

                        util.uploadFileToUrl(xhr, file, uploadUrl, 'normal',
                            function (evt) {
                                $scope.$apply(function () {
                                    if (evt.lengthComputable) {
                                        var percentComplete = Math.round(evt.loaded * 100 / evt.total);
                                        o.update(fileId, percentComplete, evt.total - evt.loaded, evt.total);
                                        console && console.log(percentComplete);
                                    }
                                });
                            },
                            function (xhr) {
                                var ret = JSON.parse(xhr.responseText);
                                console && console.log(ret);
                                $scope.$apply(function () {
                                    o.setSrcSizeByXhr(xhr, ret.upload_path, ret.size);
                                    // 如果这个对象只允许上传一张图片
                                    if (o.single) {
                                        // 如果长度大于1张图片，删除前几张图片
                                        if (o.data.length > 1) {
                                            for (var i = 0; i < o.data.length - 1; i++) {
                                                o.deleteById(o.data[i].id);
                                            }
                                        }
                                    }
                                });
                            },
                            function (xhr) {
                                $scope.$apply(function () {
                                    o.update(fileId, -1, '', '');
                                });
                                console && console.log('failure');
                                xhr.abort();
                            }
                        );
                    }
                }

            }
        ])

        // 编辑视频弹窗
        .controller('FujiaVideoEditController', ['$scope', '$state', '$http', '$stateParams', '$location', 'util', 'CONFIG',
            function ($scope, $state, $http, $stateParams, $location, util, CONFIG) {
                var self = this;

                self.init = function () {
                    self.viewId = $scope.app.maskParams.viewId;
                    self.info = $scope.app.maskParams.videoInfo;
                    self.cateId = $scope.app.maskParams.cateId;
                    self.labelList = $scope.app.maskParams.labelList;
                    self.Title = self.info.Title;
                    self.Text = self.info.Text;
                    self.LableID = self.info.LableID


                    // 获取编辑多语言信息
                    self.editLangs = util.getParams('editLangs');

                    // 初始化频道图片
                    self.imgs1 = new util.initUploadImgs([{ "ImageURL": self.info.PicURL, "ImageSize": self.info.PicSize }], $scope, true);
                    self.imgs1.initImgs();

                    // 初始化频道图片
                    self.imgs2 = new util.initUploadImgs([{ "ImageURL": self.info.VideoURL, "ImageSize": self.info.VideoSize }], $scope, true);
                    self.imgs2.initImgs();

                }


                self.cancel = function () {
                    $scope.app.showHideMask(false);
                }

                self.save = function () {
                    if (self.imgs1.data.length == 0) {
                        alert('请上传封面图片');
                        return;
                    }
                    // 视频未上传完成
                    else if (self.imgs1.data[0].progress < 100) {
                        alert('封面图片正在上传中，请稍后...');
                        return;
                    }
                    // 视频上传失败时
                    else if (self.imgs1.data[0].progress == -1) {
                        alert('封面图片上传失败，请重新上传');
                        return;
                    }

                    if (self.imgs2.data.length == 0) {
                        alert('请上传视频');
                        return;
                    }
                    // 视频未上传完成
                    else if (self.imgs2.data[0].progress < 100) {
                        alert('视频正在上传中，请稍后...');
                        return;
                    }
                    // 视频上传失败时
                    else if (self.imgs2.data[0].progress == -1) {
                        alert('视频上传失败，请重新上传');
                        return;
                    }
                    var data = JSON.stringify({
                        "token": util.getParams('token'),
                        "action": "editVideo",
                        "viewID": Number(self.viewId),
                        "data": {
                            "ID": self.info.ID,
                            "CategoryID": self.info.CategoryID,
                            "Title": self.Title,
                            "LableID": self.LableID,
                            "Text": self.Text || '',
                            "PicURL": self.imgs1.data[0].src,
                            "PicSize": self.imgs1.data[0].fileSize,
                            "VideoURL": self.imgs2.data[0].src,
                            "VideoSize": self.imgs2.data[0].fileSize
                        },
                        "lang": util.langStyle()
                    })
                    self.saving = true;
                    $http({
                        method: 'POST',
                        url: util.getApiUrl('commonview', '', 'server'),
                        data: data
                    }).then(function successCallback(response) {
                        var data = response.data;
                        if (data.rescode == '200') {
                            alert('更新成功！');
                            $scope.app.showHideMask(false)
                            $scope.app.maskParams.loadInfo()
                        } else if (data.rescode == '401') {
                            alert('访问超时，请重新登录');
                            $state.go('login');
                        } else {
                            alert('添加失败，' + data.errInfo);
                        }
                    }, function errorCallback(response) {
                        alert('连接服务器出错');
                    }).finally(function (value) {
                        self.saving = false;
                    });

                }


                // 图片上传相关
                self.clickUpload = function (e) {
                    setTimeout(function () {
                        document.getElementById(e).click();
                    }, 0);
                }

            }
        ])


})();