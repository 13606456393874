'use strict';

(function() {
    var app = angular.module('app.goods-controllers', []).controller('goodsEditController', [
        '$scope',
        '$state',
        '$http',
        '$stateParams',
        '$filter',
        '$timeout',
        'Upload',
        'util',
        'CONFIG',
        function($scope, $state, $http, $stateParams, $filter, $timeout,Upload, util, CONFIG) {
            console.log('goodsEditController');
            var vm = this;

            vm.init = function() {
                vm.productId = $scope.app.maskParams.productId;
                vm.editLangs=util.getParams('editLangs')
                vm.maskParams = $scope.app.maskParams
                vm.langStyle = util.langStyle();
                vm.name = {};
                vm.intro = {};

                // upload a single file at a time
                vm.file = null;
                vm.uploading = false;
                vm.uploadProgress = 0;
                vm.imgs = [];
                // get goods info on init
                vm.getGoodsInfo();
            }

            /* Image upload */
            vm.removeImgByIdx = function(idx){
                vm.imgs.splice(idx,1);
            }
            vm.removeUploadImg = function(){
                vm.file = null;
            }
            vm.upload = function() {
                // wait update, this is so dumb
                $timeout(vm.uploadActual, 100);
            }

            vm.uploadActual = function() {
                console.log("INVALID FILE:", vm.invalidFile);
                console.log("FILE:", vm.file);
                if (vm.invalidFile) {
                    alert('文件必须小于1MB!!');
                    return;
                }
                if (!vm.file) {
                    return;
                }
                vm.uploading = true;
                Upload.upload({
                    url: CONFIG.uploadUrl,
                    data: {
                        file: vm.file
                    }
                }).then(function(resp) {
                    if (resp.status == 200) {
                        // push to our images
                        vm.imgs.push({
                            ImageURL: resp.data.upload_path,
                            ImageSize: resp.data.size
                        });
                        console.log('Successfully uploaded ' + resp.data.upload_path);
                    } else {
                        console.log(resp);
                    }
                }, function(err) {
                    console.log(err);
                }, function(evt) {
                    var progressPercentage = parseInt(100.0 * evt.loaded / evt.total);
                    vm.uploadProgress = progressPercentage;
                    console.log('progress: ' + progressPercentage + '% ' + evt.config.data.file.name);
                }). finally(function() {
                    // reset file
                    vm.file = null;
                    vm.invalidFile = null;
                    vm.uploading = false;
                });
            }

            vm.getGoodsInfo = function() {
                vm.loading = true;

                var data = JSON.stringify({"action": "getMgtProductDetail", "token": util.getParams('token'), "lang": vm.langStyle, "productId": vm.productId})

                $http({
                    method: 'POST',
                    url: util.getApiUrl('shopinfo', '', 'server'),
                    data: data
                }).then(function(data, status, headers, config) {
                    if (data.data.rescode == 200) {
                        var data = data.data.data;
                        vm.name = data.product.name;
                        vm.invetory = data.product.invetory - 0;
                        vm.seq = data.product.seq - 0;
                        vm.intro = data.product.intro;
                        vm.weight = data.product.weight / 1000;
                        vm.tvShow = data.product.tvshow == 1
                            ? true
                            : false
                        vm.imgs = data.product.imgSrc;

                        var _price = data.product.price;
                        if (_price.money.Enable) {
                            vm.paytype = 'price';
                            vm.price = (_price.money.price - 0) / 100;
                            vm.decline = (_price.money.Decline - 0) / 100;
                        } else if (_price.point.Enable) {
                            vm.paytype = 'score';
                            vm.score = _price.point.point - 0;
                        }
                        var _deliveryType = data.product.deliveryType;
                        if (_deliveryType.indexOf('express') !== -1) {
                            vm.byDelivery = true;
                        }
                        if (_deliveryType.indexOf('bySelf') !== -1) {
                            vm.bySelf = true;
                        }
                        if (_deliveryType.indexOf('homeDelivery') !== -1) {
                            vm.byHomeDelivery = true;
                        }
                    } else {
                        alert('读取商品失败' + data.data.rescode + '，' + data.data.errInfo);
                    }
                }, function errorCallback(data, status, headers, config) {
                    alert('连接服务器出错');
                }). finally(function(value) {
                    vm.loading = false;
                });
            }

            vm.cancel = function() {
                console.log('cancel')
                $scope.app.showHideMask(false);
            }
            vm.deleteGoods = function() {
                var flag = confirm('确认删除？');
                if (!flag) {
                    return;
                }
                // vm.saving = true;
                var data = {
                    "action": "editMgtProductStatus",
                    "token": util.getParams("token"),
                    "lang": vm.langStyle,
                    "product": {
                        "productID": vm.productId - 0,
                        "Status": 2 //0是下架，1是上架,2已删除
                    }
                };
                data = JSON.stringify(data);
                $http({
                    method: $filter('ajaxMethod')(),
                    url: util.getApiUrl('shopinfo', '', 'server'),
                    data: data
                }).then(function successCallback(data, status, headers, config) {
                    if (data.data.rescode == "200") {
                        alert('删除成功')
                        $state.reload();
                    } else if (data.data.rescode == "401") {
                        alert('访问超时，请重新登录');
                        $state.go('login');
                    } else {
                        alert('删除失败， ' + data.data.errInfo);
                    }

                }, function errorCallback(data, status, headers, config) {
                    alert('连接服务器出错')
                }). finally(function(value) {
                    // vm.saving = false;
                });
            }

            vm.editGoods = function() {
                // 价格设置检查
                if (vm.paytype == 'price') {
                    if (vm.price === undefined || vm.price === null) {
                        alert('请输入价格');
                        return;
                    }
                } else if (vm.paytype == 'score') {
                    if (vm.score === undefined || vm.price === null) {
                        alert('请输入积分');
                        return;
                    }
                }

                // 配送方式检查
                if (!vm.byDelivery && !vm.bySelf && !vm.byHomeDelivery) {
                    alert('请选择配送方式');
                    return;
                }

                // 图片不能为空
                if (vm.imgs.length == 0) {
                    alert('请上传图片');
                    return;
                } else if (vm.uploading) {
                    alert('请等待图片上传完成');
                    return;
                }
                var imgSrc = [];
                for (var i = 0; i < vm.imgs.length; i++) {
                    var e = vm.imgs[i];
                    imgSrc[i] = {
                        ImageURL: e.ImageURL,
                        Seq: i,
                        ImageSize : Number(e.ImageSize)
                    };
                }
                var _price = {
                    money: {
                        Enable: false,
                        price: 0,
                        Decline: 0
                    },
                    point: {
                        Enable: false,
                        point: 0
                    }
                }
                var _deliveryType = [];
                vm.byDelivery && _deliveryType.push('express');
                vm.bySelf && _deliveryType.push('bySelf');
                vm.byHomeDelivery && _deliveryType.push('homeDelivery');

                if (vm.paytype == 'price') {
                    _price.money.Enable = true;
                    _price.money.price = vm.price * 100;
                    _price.money.Decline = vm.decline * 100;
                } else if (vm.paytype == 'score') {
                    _price.point.Enable = true;
                    _price.point.point = vm.score;
                }
                var data = JSON.stringify({
                    "action": "editMgtProductDetail",
                    "token": util.getParams('token'),
                    "lang": vm.langStyle,
                    "product": {
                        "productID": vm.productId,
                        "name": vm.name,
                        "invetory": vm.invetory,
                        "seq": vm.seq,
                        "price": _price,
                        "deliveryType": _deliveryType,
                        "intro": vm.intro,
                        "imgSrc": imgSrc,
                        "weight": vm.weight * 1000 || 0,
                        "TVGoodsShow": vm.tvShow
                            ? 1
                            : 0
                    }
                });
                console.log(data);
                vm.saving = true;
                $http({
                    method: 'POST',
                    url: util.getApiUrl('shopinfo', '', 'server'),
                    data: data
                }).then(function successCallback(data, status, headers, config) {
                    if (data.data.rescode == "200") {
                        alert('修改成功');
                        $state.reload('app.shop.goods.goodsList');
                        vm.cancel();
                    } else {
                        alert('修改失败，错误编码：' + data.data.rescode + '，' + data.data.errInfo);
                    }
                }, function errorCallback(data, status, headers, config) {
                    alert('连接服务器出错');
                }). finally(function(value) {
                    vm.saving = false;
                });
            }

        }
    ])

})();
