"use strict";
(function() {
  var app = angular
    .module("app.movieRoom", [])
    .controller("movieRoomListController", [
      "$scope",
      "$http",
      "$state",
      "util",
      "$filter",
      "NgTableParams",
      "$location",
      function($scope, $http, $state, util, $filter, NgTableParams, $location) {
        var self = this;
        self.init = function() {
          self.getInfo();
          self.showDate = [];
          self.editable = []; //各房间编辑状态
          self.roomFree = []; //各房间是否免费
          self.idx = null;
          // 每秒检查一次房间状态
          setInterval(function() {
            if (self.tableParams.data) {
              self.checkStatus(self.tableParams.data);
            }
          }, 1000);
          document.onclick = function(e) {
            var dateContainer = document.querySelector(".layui-laydate");
            // console.log(e.target.id !== "open" + self.idx);
            if (
              !(dateContainer && dateContainer.contains(e.target)) &&
              e.target.id !== "open" + self.idx &&
              e.target.id !== "endTime" + self.idx
            ) {
              $scope.$apply(function() {
                self.showDate[self.idx] = false;
              });
            }
          };
        };

        self.checkStatus = function(data) {
          var nowTime = new Date();
          for (var i = 0; i < data.length; i++) {
            var endTime = new Date(data[i].ValidDateEnd);
            // console.log(endTime)
            if (data[i].ValidDateEnd && endTime > nowTime) {
              self.roomFree[i] = true;
            } else {
              self.roomFree[i] = false;
            }
          }
        };

        // 获取AddDayCount天后的日期
        function getDateStr(AddDayCount) {
          var dd = new Date();
          dd.setDate(dd.getDate() + AddDayCount); //获取AddDayCount天后的日期
          var y = dd.getFullYear();
          var m = dd.getMonth() + 1; //获取当前月份的日期
          var d = dd.getDate();
          return y + "-" + m + "-" + d;
        }

        self.editDate = function(val, idx) {
          if (!val) {
            var time = new Date();
            self.tableParams.data[idx].ValidDateEnd = util.format_dateTime(
              new Date(time.getTime() - 60 * 60 * 1000)
            );
            self.roomFree[idx] = false;
            self.saveDate(idx);
            return false
          }
          for(var i=0;i<self.showDate.length;i++){
            self.showDate[i] = false;
          }
          self.idx = idx;
          self.showDate[idx] = true;
          self.tableParams.data[idx].submitValidDateEnd =
            getDateStr(1) + " 14:00:00";
          var closeName = val ? "open" + idx : "close" + idx;
          setTimeout(function() {
            // 日期选择-开始时间
            laydate.render({
              elem: "#endTime" + idx,
              btns: ["confirm"],
              type: "datetime",
              show: true,
              closeStop: closeName,
              done: function(value, date, endDate) {
                // if(value.substring(11)=="00:00:00"){
                //     var new_date=value.substring(0,11)+"23:59:59"
                // }
                // console.log(value.substring(11))
                self.showDate[idx] = false;
                $scope.$apply(function() {
                  self.tableParams.data[idx].ValidDateEnd = value;
                  self.saveDate(idx);
                  self.roomFree[idx] = true;
                });
              }
            });
          }, 100);
        };

        self.cancel = function(idx) {
          self.editable[idx] = false;
        };

        self.saveDate = function(idx) {
          var itemData = self.tableParams.data[idx];
          var data = {
            action: "updateMovieRecord",
            token: util.getParams("token"),
            lang: "zh-CN",
            ID: itemData.ID,
            ValidDateEnd: itemData.ValidDateEnd
          };
          $http({
            method: "POST",
            url: util.getApiUrl("movie", "", "server"),
            data: data
          })
            .then(
              function successCallback(response) {
                console.log(response);
                var data = response.data;
                if (data.rescode == "200") {
                  alert("修改成功！");
                  self.editable[idx] = false;
                } else if (data.rescode == "401") {
                  alert("访问超时，请重新登录");
                  $state.go("login");
                } else {
                  alert("保存失败" + data.errInfo);
                }
              },
              function errorCallback(response) {
                alert("连接服务器出错，请重新导出");
              }
            )
            .finally(function(value) {
              self.saving = false;
            });
        };

        // 获取报表数据
        self.getInfo = function() {
          self.tableParams = new NgTableParams(
            {
              page: 1,
              count: 12,
              url: ""
            },
            {
              counts: false,
              getData: function(params) {
                var paramsUrl = params.url();
                var data = JSON.stringify({
                  action: "getMovieDevList",
                  token: util.getParams("token"),
                  lang: "zh-CN",
                  page: paramsUrl.page - 0,
                  per_page: paramsUrl.count - 0
                });
                self.loading = true;
                self.noData = false;

                return $http({
                  method: "POST",
                  url: util.getApiUrl("movie", "", "server"),
                  data: data
                })
                  .then(
                    function successCallback(response) {
                      var data = response.data;
                      // console.log(response);

                      if (data.rescode == "200") {
                        params.total(data.total);
                        self.checkStatus(data.devlist);
                        return data.devlist;
                      } else if (data.rescode == "401") {
                        alert("访问超时，请重新登录");
                        $state.go("login");
                      } else {
                        alert("获取列表失败，" + data.result);
                      }
                    },
                    function errorCallback(response) {
                      alert("连接服务器出错");
                    }
                  )
                  .finally(function(value) {
                    self.loading = false;
                  });
              }
            }
          );
        };
      }
    ])
    .controller("movieRoomRecordController", [
      "$scope",
      "$http",
      "$state",
      "util",
      "$filter",
      "NgTableParams",
      "$location",
      function($scope, $http, $state, util, $filter, NgTableParams, $location) {
        var self = this;
        self.init = function() {
          self.getInfo();
        };

        self.getInfo = function() {
          self.tableParams = new NgTableParams(
            {
              page: 1,
              count: 12,
              url: ""
            },
            {
              counts: false,
              getData: function(params) {
                var paramsUrl = params.url();
                var data = JSON.stringify({
                  action: "getMovieRecordList",
                  token: util.getParams("token"),
                  lang: "zh-CN",
                  page: paramsUrl.page - 0,
                  per_page: paramsUrl.count - 0
                });
                self.loading = true;
                self.noData = false;

                return $http({
                  method: "POST",
                  url: util.getApiUrl("movie", "", "server"),
                  data: data
                })
                  .then(
                    function successCallback(response) {
                      var data = response.data;
                      console.log(response);

                      if (data.rescode == "200") {
                        params.total(data.total);
                        return data.recordlist;
                      } else if (data.rescode == "401") {
                        alert("访问超时，请重新登录");
                        $state.go("login");
                      } else {
                        alert("获取列表失败，" + data.errInfo);
                      }
                    },
                    function errorCallback(response) {
                      alert("连接服务器出错");
                    }
                  )
                  .finally(function(value) {
                    self.loading = false;
                  });
              }
            }
          );
        };
      }
    ]);
})();
